import React from 'react'
import PropTypes from 'prop-types'
import { TableHead, TableRow, Typography, Checkbox, TableSortLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TableCell from '../CustomTableCell'
import classnames from 'classnames'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	head: {
		background: `${theme.palette.almostBlack[600]}14`,
		height: '32px',
		textTransform: 'uppercase',
		// borderRadius: '10px',
		'& th:first-child': {
			// borderRadius: '10px 0 0 10px'
		},
		'& th:first-child:not(.MuiTableCell-paddingCheckbox), td:first-child:not(.MuiTableCell-paddingCheckbox)': {
			paddingLeft: theme.spacing(2),
		},
		'& th:last-child': {
			// borderRadius: '0 10px 10px 0'
		},
	},
	tableCellHead: {
		padding: '4px',
		borderBottom: 'none',
		boxSizing: 'border-box',
		fontSize: theme.typography.pxToRem(10),
		fontWeight: 700,
		textTransform: 'uppercase !important',
		'& .MuiTypography-subtitle2': {
			fontSize: theme.typography.pxToRem(10),
			fontWeight: 700,
			letterSpacing: theme.typography.pxToRem(2),
		},
		'& .MuiCheckbox-root': {
			padding: '2px',
		},
		'&.MuiTableCell-paddingCheckbox': {
			paddingLeft: '8px',
		},
	},
	muiTableRowHead: {
		height: '38px',
		textTransform: 'uppercase',
	},
	checkBoxPadding: {
		paddingLeft: theme.spacing(1),
	},
	firstColPadding: {
		paddingLeft: theme.spacing(2),
	},
	muiTableSortLabel: {
		color: 'inherit !important',
		'&:focus,:hover': {
			color: 'inherit !important',
		},
	},
}))

const TableHeaders = ({ headers, sortSettings, isMobile, mobHeaders }) => {
	const classes = useStyles()

	const renderCellContent = (header) => {
		const isIndeterminate = !_.isEmpty(_.get(header, 'selectedList', []))
			? _.get(header, 'selectedList', []).length === _.get(header, 'total', -1)
				? false
				: _.indexOf(_.get(header, 'selectedList', []), 'all') === -1
				? true
				: false
			: false

		if (header.isCheck) {
			return (
				<Checkbox
					onClick={header.allCheckFxn}
					checked={
						_.indexOf(_.get(header, 'selectedList', []), 'all') !== -1 ||
						(_.get(header, 'selectedList', []).length === _.get(header, 'total', -1) && _.get(header, 'selectedList', []).length !== 0)
					}
					indeterminate={isIndeterminate}
				/>
			)
		} else {
			return <Typography variant='subtitle2'>{header.name}</Typography>
		}
	}

	const modifiedHeaders = isMobile && !_.isEmpty(mobHeaders) ? mobHeaders : headers

	return (
		<TableHead>
			<TableRow classes={{ head: classes.head }}>
				{modifiedHeaders.map((header, idx) => (
					<TableCell
						key={idx}
						style={
							_.isNil(header.style)
								? {
										...(_.isEmpty(header.width) ? {} : { width: header.width, maxWidth: header.width }),
								  }
								: {
										...header.style,
										...(_.isEmpty(header.width) ? {} : { width: header.width, maxWidth: header.width }),
								  }
						}
						className={classnames(
							classes.tableCellHead,
							{ [classes.checkBoxPadding]: header.isCheck },
							{ [classes.firstColPadding]: header.padding }
						)}
						align={header.align}
						{...(header.isImage ? { padding: 'none' } : header.isCheck ? { padding: 'checkbox' } : {})}
					>
						{_.get(header, 'isSortEnabled', false) && sortSettings ? (
							<TableSortLabel
								active={sortSettings.keyToSort === header.dataKey}
								classes={{ root: classes.muiTableSortLabel }}
								onClick={() => header && header.onSortClick && _.get(header, 'onSortClick')(header.dataKey)}
								direction={sortSettings.sortDirection ? 'asc' : 'desc'}
							>
								{renderCellContent(header)}
							</TableSortLabel>
						) : (
							renderCellContent(header)
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

TableHeaders.propTypes = {
	headers: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			align: PropTypes.string.isRequired,
			dataKey: PropTypes.string,
			isImage: PropTypes.bool,
			isFlag: PropTypes.bool,
			isCheck: PropTypes.bool,
			isActionCol: PropTypes.bool,
			isEditEnabled: PropTypes.bool,
		})
	).isRequired,
}

export default TableHeaders
