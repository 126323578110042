import React, { useState, useImperativeHandle } from 'react'
import { IconButton, Icon, Avatar, Grid, Typography } from '@material-ui/core'
import _ from 'lodash'
import { formTheName, getImgUrl, stringToHslColor } from 'utils'

export const withCellRenderState = (CellRenderer) => {
	return React.forwardRef((props, ref) => {
		const [value, setValue] = useState(props.value)

		useImperativeHandle(ref, () => {
			return {
				refresh: (params) => {
					if (params.value !== value) {
						setValue(params.value)
					}
					return true
				},
			}
		})
		return <CellRenderer {...props}></CellRenderer>
	})
}
export const CodeCellRenderer = (props) => {
	const { value, data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	return (
		<div className={containerClassName}>
			{/* <span style={{ backgroundColor: value ? '#82c43c' : '#fc5a5a' }}></span>
			{data ? data.sticky_code : ''} */}
			{!_.isEmpty(data.glass_code) && !_.isEqual(data.glass_code, data.parent_glass_code) ? (
				<Grid container direction='column'>
					<Grid item>
						<Typography variant='body2'>{data.sticky_code}</Typography>
					</Grid>
					<Grid item>
						<Typography variant='body2' style={{ color: '#65b17f', fontSize: 'small' }}>
							{data.glass_code}
						</Typography>
					</Grid>
				</Grid>
			) : (
				<>{data ? data.sticky_code : ''}</>
			)}
		</div>
	)
}
export const NameCellRenderer = (props) => {
	const { value } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')

	return (
		<div className={containerClassName} title={value}>
			<span className='details-span'>{value}</span>
		</div>
	)
}

// export const CreateDateCellRenderer = (props) => {
// 	const { value } = props
// 	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
// 	console.log('Dare', value)
// 	return (
// 		<div className={containerClassName} title={value}>
// 			<span className='details-span'>{value}</span>
// 		</div>
// 	)
// }

export const CreateDateCellRenderer = (props) => {
	const { value } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	console.log('CLASSNAME', containerClassName)

	// Convert value to local time format
	const formattedDate = new Intl.DateTimeFormat('en-US', {
		year: 'numeric',
		month: 'short',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		hour12: true,
		// timeZoneName: 'short',
	}).format(new Date(value))

	console.log('Date', formattedDate)

	return (
		<div className={containerClassName} title={formattedDate}>
			<span className='details-span'>{formattedDate}</span>
		</div>
	)
}

export const AssignCellRenderer = (props) => {
	const { value, data } = props

	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClassName')
	const token = _.get(props, 'colDef.cellRendererParams.token', '')
	const avatarData = data?.assigned_to || data?.assignee
	return (
		<div className={containerClassName}>
			{!_.isEmpty(avatarData) ? (
				<Avatar
					title={`${formTheName(value ? value : avatarData?.first_name, avatarData?.last_name || '')} - ${avatarData?.email || ''}`}
					style={{
						width: 30,
						height: 30,
						fontSize: 14,
						backgroundColor: stringToHslColor(formTheName(avatarData?.first_name, avatarData?.last_name)),
					}}
					src={getImgUrl(token, avatarData?.profile_img)}
				>
					{_.slice(value ? value : avatarData?.first_name, 0, 1)}
				</Avatar>
			) : (
				<div style={{ textAlign: 'center' }}>-</div>
			)}
		</div>
	)
}

export const StatusCellRenderer = (props) => {
	const { data } = props
	return (
		<div>
			<span title={_.startCase(data?.sticky_status?.status)} className='details-span'>
				{_.startCase(data?.sticky_status?.status) || ''}
			</span>
		</div>
	)
}

export const ActionCellRenderer = (props) => {
	const onClickDelete = _.get(props, 'colDef.cellRendererParams.onClickDeleteMenu')
	const { data } = props
	return (
		<div>
			<IconButton
				size='small'
				onClick={(e) => {
					e.stopPropagation()
					onClickDelete(data.sticky_code)
				}}
				title='delete'
				aria-label='delete-button'
			>
				<Icon fontSize='small'>close</Icon>
			</IconButton>
		</div>
	)
}

export const PriorityCellRenderer = (props) => {
	const { data } = props
	return (
		<div>
			{data?.priority ? (
				<Icon
					fontSize='small'
					title={data?.priority}
					style={{ color: _.isEqual(data?.priority, 'low') ? '#82C43C' : _.isEqual(data?.priority, 'medium') ? '#ffa500' : '#ff0000' }}
				>
					{_.isEqual(data?.priority, 'low') ? 'south' : 'north'}
				</Icon>
			) : (
				'-'
			)}
		</div>
	)
}
