import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import _ from 'lodash'
import TabsHeader from './TabsHeader'
import TabPanel from 'components/TabPanel'
import RecordsTable from './RecordsTable'

DatasourceTabs.propTypes = {
	isSelectedAll: PropTypes.bool.isRequired,
	datasourceDetails: PropTypes.arrayOf(
		PropTypes.shape({
			ds_id: PropTypes.string,
			ds_name: PropTypes.string,
			count: PropTypes.number,
		})
	),
	updateDatasourceInfo: PropTypes.func.isRequired,
	data: PropTypes.array,
	searchParams: PropTypes.object,
	isDsUpdating: PropTypes.bool.isRequired,
}

const getDataForDatasource = (data, datasourceId) => {
	return _.filter(data, (record) => record.ds_id === datasourceId)
}

function DatasourceTabs({ datasourceDetails, isDsUpdating, updateDatasourceInfo, data, isSelectedAll, searchParams }) {
	const [activeTabIdx, setActiveTabIdx] = useState(0)

	const tabs = useMemo(
		() =>
			_.map(
				datasourceDetails,
				(datasource) =>
					`${_.get(datasource, 'ds_name')} (${
						isSelectedAll ? _.get(datasource, 'total_records') : getDataForDatasource(data, datasource?.ds_id)?.length
					})`
			),
		[datasourceDetails, isSelectedAll, data]
	)

	const selectedCount = useMemo(
		() =>
			_.map(
				datasourceDetails,
				(datasource) =>
					`${isSelectedAll ? _.get(datasource, 'total_records') : getDataForDatasource(data, datasource?.ds_id)?.length}`
			),
		[datasourceDetails, isSelectedAll, data]
	)
	const onChangeTab = (e, tabIdx) => {
		setActiveTabIdx(tabIdx)
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				<TabsHeader tabs={tabs} value={activeTabIdx} onChangeTab={onChangeTab} />
			</Grid>
			<Grid item xs={12}>
				{_.map(datasourceDetails, (datasource, index) => (
					<TabPanel key={datasource?.ds_id} index={index} value={activeTabIdx}>
						<RecordsTable
							datasourceInfo={datasource}
							updateDatasourceInfo={updateDatasourceInfo}
							data={getDataForDatasource(data, datasource?.ds_id)}
							isDsUpdating={isDsUpdating}
							isSelectedAll={isSelectedAll}
							searchParams={searchParams}
							selectedCount={selectedCount}
						/>
					</TabPanel>
				))}
			</Grid>
		</Grid>
	)
}

export default DatasourceTabs
