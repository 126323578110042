import React from 'react'
import classnames from 'classnames'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import DiscoverXSvgImg from 'images/discover-x-svg.svg'
import DiscoverXImg from 'images/discover-x-fallback.png'
import DiscoverXSearch from 'images/discover-x-search.png'
import DisXInpBox from 'components/DisXInpBox'

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
		boxSizing: 'border-box',
		padding: theme.spacing(2),
	},
	searchWrapper: {
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	searchContent: {
		textAlign: 'center',
	},
	searchBox: {
		maxWidth: '480px',
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(6),
		[theme.breakpoints.down('xs')]: {
			maxWidth: '320px',
			marginTop: theme.spacing(4),
			marginBottom: theme.spacing(4),
		},
	},
	discoverXLogo: {
		[theme.breakpoints.down('xs')]: {
			maxWidth: '166px',
		},
		[theme.breakpoints.up('sm')]: {
			maxWidth: '240px',
		},
		[theme.breakpoints.up('md')]: {
			maxWidth: '260px',
		},
	},
	text: {
		fontWeight: '500',
		[theme.breakpoints.down(600)]: {
			fontSize: '28px',
		},
	},
	logo: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	discoverXSearch: {
		height: '55.32px',
		width: '50.41px',
		marginLeft: '8px',
		[theme.breakpoints.down(600)]: {
			height: '38.26px',
			width: '34.86px',
		},
	},
}))

const DiscoverX = ({ history }) => {
	const classes = useStyles()

	const onChangeSearch = (e, value) => {
		if (value) {
			history.push(`/discoverx/search?q=${value}`)
		}
	}

	return (
		<div className={classnames('container-no-fluid', 'page-content', classes.root)}>
			<Grid container className={classes.searchWrapper}>
				<Grid item xs={12} className={classes.searchContent}>
					{/* <object data={DiscoverXSvgImg} className={classes.discoverXLogo} type='image/svg+xml'> */}
					<div className={classes.logo}>
						<Typography variant='h3' className={classes.text}>Data</Typography>
						<img src={DiscoverXSearch} className={classes.discoverXSearch} alt='img' />
					</div>
					{/* </object> */}
					<DisXInpBox onChange={onChangeSearch} inputClassName={classes.searchBox} />
					<Typography variant='caption' color='textSecondary'>
						Real business runs on Data, Wersel runs on Data
					</Typography>
				</Grid>
			</Grid>
		</div>
	)
}

DiscoverX.propTypes = {}

export default DiscoverX
