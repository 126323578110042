import { SAGA_ACTIONS } from '../../../constants'

export const getProductsTable = (query, search, redirect, cancelExecutor) => {
	return {
		type: SAGA_ACTIONS.GET_PEOPLEX_TABLE,
		query,
		search,
		redirect,
		cancelExecutor,
	}
}

export const getPeoplexDetails = (peoplexId, cancelExecutor) => {
	return {
		type: SAGA_ACTIONS.GET_PEOPLEX_DETAILS,
		peoplexId,
		cancelExecutor,
	}
}
