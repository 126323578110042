import update from 'immutability-helper'
import _ from 'lodash'

export const initialState = {
	isFetchingPeoplex: false,
	people: [],
	totalPeoplex: 0,
}

export function reducer(state = initialState, action) {
	switch (action.type) {
		case 'fetchigPeoplexPending':
			return { ...state, isFetchingPeoplex: true }
		case 'fetchingPeoplexSuccess':
			return {
				...state,
				isFetchingPeoplex: false,
				people: action.data,
				totalPeoplex: action.totalPeoplex,
			}
		case 'peoplexUpdate':
			let tempActIndex = _.findIndex(state.people, (o) => o?.people_code === action?.data?.people_code)
			if (tempActIndex > -1) {
				let tempState = update(state, {
					people: { [tempActIndex]: { $merge: action.data } },
				})
				return tempState
			}
			return state
		default:
			throw new Error()
	}
}
