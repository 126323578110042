import React, { useCallback, useEffect, /*  useMemo, */ useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
	AppBar,
	Avatar,
	Box,
	Button,
	Grid,
	Icon,
	IconButton,
	ButtonGroup,
	makeStyles,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core'
import { getErrMsg, stringToHslColor } from 'utils'
import { getAvatarTxt, getImgUrl } from 'utils'
import { Skeleton } from '@material-ui/lab'
import classnames from 'classnames'
import GlassSwitchPopover from './GlassSwitchPopover'
// import CustomSwitch from 'components/CustomSwitch'
import { MY_TASK_LAYOUT } from 'constants/index'
import { useDispatch, useSelector } from 'react-redux'
import { updateGlassLayout } from 'services/glass/actions'
import { updateMytaskLayout } from 'services/myTask/actions'
import PopoverMenus from 'components/PopoverMenus'
import MytaskApi from 'services/myTask/api'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import SearchBox from 'components/SearchBox'
import _ from 'lodash'
import CustomTooltip from 'components/ToolTip'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { refreshStickyList } from 'services/glass/actions'
import DashboardsApi from '../../../services/dashboards/api'
import CustomPopoverMenus from 'components/CustomPopoverMenus'
import { CgFeed } from 'react-icons/cg'
import RefreshIcon from '@material-ui/icons/Refresh'

Header.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	openSettings: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	openActivity: PropTypes.func.isRequired,
	glassLayout: PropTypes.string.isRequired,
	glassCode: PropTypes.string.isRequired,
	sessionId: PropTypes.string,
	data: PropTypes.string,
	filterMenuProps: PropTypes.bool.isRequired,
	onSearchChange: PropTypes.func.isRequired,
}

const useStyles = makeStyles((theme) => ({
	appBar: {
		top: '65px',
		[theme.breakpoints.down(600)]: {
			top: '57px',
		},
		left: 'auto',
		right: 0,
		position: 'fixed',
		width: '100%',
		display: 'flex',
		zIndex: '1000',
		boxSizing: 'border-box',
		height: 48,
		boxShadow: 'none',
		backgroundColor: theme.palette.common.white,
		borderBottom: `1px solid ${theme.palette.divider}`,
		'& div': {
			lineHeight: 'unset',
			gap: '8px',
		},
		// zIndex: 1,
		flexWrap: 'nowrap',
		[theme.breakpoints.down(993)]: {
			padding: theme.spacing(0, 1),
		},
		'& .MuiToolbar-gutters': {
			[theme.breakpoints.only('md')]: {
				padding: '0 1rem',
			},
			justifyContent: 'flex-end',
		},
		'& .MuiToolbar-dense': {
			[theme.breakpoints.down(500)]: {
				justifyContent: 'end',
			},
			[theme.breakpoints.down(335)]: {
				gap: '2px',
			},
		},
	},
	avatarWrapper: {
		width: 'unset',
	},
	avatar: {
		width: '38px',
		height: '38px',
		fontSize: '14px',
		marginRight: theme.spacing(1.5),
	},
	avatarBg: {
		backgroundColor: (props) => stringToHslColor(props.title),
	},
	titleWrapper: {
		flexGrow: '1',
		display: 'flex',
		alignItems: 'center',
		overflow: 'hidden',
		'& > div': {
			overflow: 'hidden',
		},
		'& .MuiListItem-root': {
			padding: '8px 12px',
			'& .MuiIcon-root': {
				paddingRight: '10px',
				color: 'rgba(0, 0, 0, 0.54)',
			},
		},
	},
	layoutBtn: {
		gap: '0px !important',
	},
	viewMenu: {
		boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)`,
	},
	menuIcon: {
		'& .material-icons': {
			fontSize: '1.15rem',
		},
	},
	overflowHidden: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	mb1: {
		marginBottom: theme.spacing(0.5),
	},
	btnContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down(500)]: {
			display: 'flex',
			flexDirection: 'row',
			gap: '0px !important',
		},
	},
	btnContainerView: {
		display: 'flex',
		justifyContent: 'space-between',
		marginLeft: theme.spacing(2),
	},
	switchContainer: {
		width: 'auto',
		paddingRight: theme.spacing(1.5),
		'& Button.action-btn': {
			marginLeft: theme.spacing(1),
			border: `1px solid ${theme.palette.grey[400]}`,
		},
		// [theme.breakpoints.down(370)]: {
		// 	display: 'contents',
		// },
		[theme.breakpoints.down('sm')]: {
			paddingRight: theme.spacing(0),
			display: 'contents',
		},
	},
	actionBtn: {
		marginRight: '8px',
	},
	dropdownBtn: {
		marginLeft: theme.spacing(1),
		color: theme.palette.grey[600],
	},
	infoBtn: {
		marginLeft: theme.spacing(1),
	},
	searchBoxContainer: {
		padding: theme.spacing(0, 0.5),
		[theme.breakpoints.down(993)]: {
			flex: '1',
			padding: 0,
		},
	},
	searchWrapper: {
		[theme.breakpoints.down(993)]: {
			width: '100%',
			height: '99%',
			position: 'absolute',
			top: 0,
			left: 0,
			zIndex: '1',
			backgroundColor: theme.palette.background.paper,
			display: 'none',
			'&.show': {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			},
		},
	},
	searchBox: {
		[theme.breakpoints.down(993)]: {
			border: 'none!important',
			'&.focused, &:hover': {
				boxShadow: 'none!important',
			},
		},
	},
	titleHolder: {
		maxWidth: '18rem',
		[theme.breakpoints.down(1290)]: {
			maxWidth: '15rem',
		},
		[theme.breakpoints.down(1305)]: {
			maxWidth: '13rem',
		},
	},
	title: {
		[theme.breakpoints.down(1450)]: {
			maxWidth: '12rem',
		},
		[theme.breakpoints.down(1377)]: {
			maxWidth: '6rem',
		},
		[theme.breakpoints.between(1269, 1350)]: {
			maxWidth: '2rem',
		},
		[theme.breakpoints.down(1270)]: {
			maxWidth: '10rem',
		},
	},
	btnGrp: {
		'& .MuiButtonGroup-groupedContainedPrimary': {
			borderColor: 'transparent',
		},
		'& .MuiButtonBase-root': {
			padding: '4px',
		},
	},
	actionPopoverRoot: {
		marginTop: '8px',
	},
	actionContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		'& Button': {
			[theme.breakpoints.up(500)]: {
				marginLeft: theme.spacing(1),
			},
		},
		[theme.breakpoints.down('xs')]: {
			justifyContent: 'center',
		},
	},
	sortBtn: {
		width: '100%',
		textTransform: 'capitalize',
		fontWeight: 'normal',
		color: theme.palette.grey[600],
		padding: '4px 10px',
		'&:hover': {
			backgroundColor: 'transparent',
			color: theme.palette.primary.main,
		},
		'& .MuiIcon-fontSizeSmall': {
			fontSize: '1.25rem',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '4px 5px',
		},
		[theme.breakpoints.only('sm')]: {
			textAlign: 'right',
		},
	},
	sortedValBtn: {
		padding: '4px 1px 4px 10px',
		width: '80px',
		textAlign: 'end',
		'& .MuiButton-label': {
			width: '95%',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			display: 'block',
			textOverflow: 'ellipsis',

			[theme.breakpoints.down('sm')]: {
				lineHeight: 'unset',
			},
			[theme.breakpoints.down(330)]: {
				width: '65%',
			},
		},
	},
	iconBtn: {
		padding: '12px 3px',
	},
}))

const actionSettingMenus = [
	{
		label: 'Action',
		icon: 'history',
		actionName: 'action',
	},
	{
		label: 'Settings',
		icon: 'settings',
		actionName: 'settings',
	},
]

const options = [
	{
		reactIcon: true,
		label: 'Feed View',
		icon: 'CgFeed',
		actionName: 'feed',
	},
	{
		reactIcon: false,
		label: 'List View',
		icon: 'table_rows',
		actionName: 'list',
	},
]

const buildDashOptions = (dashboardList) => {
	return (
		_.map(dashboardList, (dashboard) => ({
			id: _.get(dashboard, 'dashboard_id'),
			name: _.get(dashboard, 'dashboard_name'),
			is_system_generated: _.get(dashboard, 'is_system_generated'),
		})) || []
	)
}

function Header({
	isLoading,
	title,
	subtitle,
	openSettings,
	glassDesc,
	openActivity,
	dashboard,
	onClickDashboard,
	glassLayout,
	glassCode,
	sessionId,
	data,
	filterMenuProps,
	onSearchChange,
	searchText,
	totalstickies,
	gridTotalStickies,
	isListView,
	sortable_fields,
	sortingDetails,
	setSortingDetails,
	sortFilter,
	selectedView,
	setSelectedView,
	changeView,
	setData,
	setDataEmpty,
	setParam,
}) {
	const avatarTxt = getAvatarTxt(title)
	const theme = useTheme()
	const isSmallDevice = useMediaQuery(theme.breakpoints.down(500))
	// const isMD = useMediaQuery(theme.breakpoints.down('md'))
	const isSmartPhone = useMediaQuery(theme.breakpoints.down(993))
	const classes = useStyles({ title: avatarTxt, isMobile: isSmallDevice })
	const [popoverAncherEl, setPopoverAncherEl] = React.useState(null)
	const [popoverAncherE2, setPopoverAncherE2] = React.useState(null)
	const anchorEl = useRef()
	const moreOptionsAchorEl = useRef()
	const dispatch = useDispatch()
	const [openSwitchPop, setOpenSwitchPop] = useState(false)
	const [openActionMenus, setOpenActionMenus] = useState(false)
	const [openActionSettingMenus, setOpenActionSettingMenus] = useState(false)
	const [openSearchBar, setOpenSearchBar] = useState(false)
	const [open, setOpen] = React.useState(false)
	const anchorRef = React.useRef(null)
	const [selectedIndex, setSelectedIndex] = React.useState(1)
	const [dashboardState, setDashboardState] = useState({
		isFetching: true,
		dashboardList: [],
	})
	const { isFetching, dashboardList } = dashboardState

	const currentUserId = useSelector((state) => _.get(state, 'session.user.userDetails.user_id'))

	const [sortAnchorEl, setSortAnchorEl] = React.useState(null)

	const activityState = sortingDetails?.sort_by
	const activityIcon = sortingDetails?.sort_by_order === 'desc' ? 'arrow_upward' : 'arrow_downward'

	const sortable = sortable_fields.map((item) => {
		const container = {}
		container.label = item.name
		container.id = item.id
		container.actionName = 'SORT'

		return container
	})

	sortable?.unshift({ label: 'None', id: null, actionName: 'SORT' })

	const toggleSortBtn = (event) => {
		setSortAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setSortAnchorEl(null)
	}

	const sortBy = (field, type) => {
		let order = _.isEqual(type, 'arrow_downward') ? 'asc' : 'desc'
		setData((prevState) => ({ ...prevState, loading: true, myTaskStickies: [], totalCount: 0 }))
		setDataEmpty(true)
		setParam((prevState) => ({ ...prevState, offset: 0 }))
		if (field === 'None') {
			setSortingDetails({ sort_by: '', sort_by_order: '' })
			setSortAnchorEl(null)
			sortFilter('none', 'asc')
		} else {
			setSortingDetails({ sort_by: field, sort_by_order: order })
			setSortAnchorEl(null)
			sortFilter(field, order)
		}
	}

	let result =
		dashboard?.dashboard_type !== 'Default' &&
		_.find(dashboardList, function (n) {
			if (n.id === dashboard?.dashboard_id) {
				return true
			}
		})

	const actionMenus = [
		{
			iconType: 'feed',
			label: 'Feed View',
			icon: 'CgFeed',
			actionName: 'feed',
		},
		{
			label: 'List View',
			icon: 'table_rows',
			actionName: 'list',
		},
		// {
		// 	label: 'Compact View',
		// 	icon: 'view_compact_alt',
		// 	actionName: 'compact',
		// },
		// {
		// 	label: 'Grid View',
		// 	icon: 'view_column',
		// 	actionName: 'normal',
		// },
		// {
		// 	label: 'List View',
		// 	icon: 'table_rows',
		// 	actionName: 'list',
		// },
		// {
		// 	label: 'Action',
		// 	icon: 'history',
		// 	actionName: 'action',
		// },
		// {
		// 	label: 'Settings',
		// 	icon: 'settings',
		// 	actionName: 'settings',
		// },
		// dashboard?.dashboard_config &&
		// 	result !== undefined && {
		// 		label: 'View Dashboard',
		// 		icon: 'dashboard',
		// 		actionName: 'dashboard',
		// 	},
	].filter(Boolean)

	const toggleSwitchPopover = (e) => {
		setOpenSwitchPop(!openSwitchPop)
	}

	const toggleActionMenus = (e) => {
		if (e) {
			setPopoverAncherEl(e.currentTarget)
		}
		setOpenActionMenus(!openActionMenus)
	}

	const toggleActionSettingMenus = (e) => {
		if (e) {
			setPopoverAncherE2(e.currentTarget)
		}
		setOpenActionSettingMenus(!openActionSettingMenus)
	}

	const toggleSearchBar = (e) => {
		if (openSearchBar) {
			onSearchChange('')
		}
		setOpenSearchBar(!openSearchBar)
	}

	const performAction = (actionName) => {
		switch (actionName) {
			case 'settings':
				openSettings()
				break
			case 'action':
				openActivity()
				break
			case 'dashboard':
				onClickDashboard()
				break
			// case 'compact':
			// 	handleMenuItemClick('', 0)
			// 	break
			case 'feed':
				handleMenuItemClick('', 0)
				break
			case 'list':
				handleMenuItemClick('', 1)
				break
			default:
				break
		}
		toggleActionMenus()
	}

	const performActionSetting = (actionName) => {
		switch (actionName) {
			case 'settings':
				openSettings()
				break
			case 'action':
				openActivity()
				break

			default:
				break
		}
		toggleActionSettingMenus()
	}

	const handleMenuItemClick = (event, index) => {
		// const glassLayout = options[index].label === 'Feed View' ? MY_TASK_LAYOUT.FEED : MY_TASK_LAYOUT.LIST
		// if (glassLayout !== MY_TASK_LAYOUT.LIST) {
		// 	dispatch(refreshStickyList(glassCode, searchText))
		// }
		// dispatch(updateGlassLayout(glassLayout))
		// GlassApi.updateGlassLayout(glassCode, glassLayout, sessionId).then(
		// 	(resp) => {},
		// 	(err) => {
		// 		dispatch(showSnackbarWithTimeout(getErrMsg(err), 2000))
		// 	}
		// )
		const layout = options[index].actionName
		// setData((prevState) => ({ ...prevState, loading: true, myTaskStickies: [], totalCount: 0 }))
		MytaskApi.updateMytaskLayout(layout).then(
			(resp) => {
				changeView(index)
			},
			(err) => {
				dispatch(showSnackbarWithTimeout(getErrMsg(err), 2000))
			}
		)
		// dispatch(updateMytaskLayout(layout))
		// setSelectedIndex(index)
		setOpen(false)
		// setSelectedView(index)
		// changeView()
	}

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen)
	}

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return
		}
		setOpen(false)
	}

	// const compactSwitchVal = useMemo(() => glassLayout === GLASS_LAYOUT.COMPACT, [glassLayout])

	// const onChangeCompactSwitch = (e) => {
	// 	const glassLayout = e.target.checked ? GLASS_LAYOUT.COMPACT : GLASS_LAYOUT.NORMAL
	// 	dispatch(updateGlassLayout(glassLayout))
	// 	GlassApi.updateGlassLayout(glassCode, glassLayout, sessionId).then(
	// 		(resp) => {},
	// 		(err) => {
	// 			dispatch(showSnackbarWithTimeout(getErrMsg(err), 2000))
	// 		}
	// 	)
	// }

	const onChangeSearchBox = useCallback(
		_.debounce((e) => {
			onSearchChange(e.target.value)
		}, 300),
		[isListView]
	)
	const handleRefresh = () => {
		window.location.reload() // Refresh the entire page
	}
	return (
		<AppBar className={classes.appBar} position='static' color='default'>
			<Toolbar variant='dense' disableGutters={isSmartPhone} ref={anchorEl}>
				{/* {isLoading ? (
					<Skeleton variant='rect' className={classes.avatar} />
				) : (
					<Avatar className={classnames(classes.avatar, classes.avatarBg)} color='primary' variant='square'>
						{avatarTxt}
					</Avatar>
				)} */}

				{isLoading ? (
					<Box className={classes.titleWrapper}>
						<div>
							<Skeleton variant='rect' className={classes.mb1} marginbottom={0.5} width={100} height={20} />
							<Skeleton variant='rect' width={80} height={10} />
						</div>
					</Box>
				) : (
					<>
						{/* <Box className={classes.titleWrapper}>
							<div className={classnames(classes.titleHolder, filterMenuProps.isOpen && classes.title)}>
								<Typography className={classes.overflowHidden} variant='h6' component='div' color='inherit'>
									{title}
								</Typography>
								<Typography className={classes.overflowHidden} variant='caption' component='div' color='textSecondary'>
									{subtitle}
								</Typography>
							</div>
						</Box> */}
						{!isSmallDevice && (
							<Box className={classes.titleWrapper}>
								<ButtonGroup variant='outlined' color='#666' ref={anchorRef} className={classes.layoutBtn} aria-label='split button'>
									<Button size='small' variant='outlined' onClick={handleToggle} color='#666'>
										{options[selectedView]?.reactIcon ? (
											<CgFeed style={{ fontSize: '1.25rem', verticalAlign: 'middle', color: '#666666' }} />
										) : (
											<Icon
												fontSize='small'
												style={{ fontSize: options[selectedView]?.iconSize ? options[selectedView]?.iconSize : '1.25rem', color: '#666' }}
											>
												{options[selectedView]?.icon}
											</Icon>
										)}
									</Button>
									<Button
										color='#666'
										size='small'
										variant='outlined'
										aria-controls={open ? 'split-button-menu' : undefined}
										aria-expanded={open ? 'true' : undefined}
										aria-label='select merge strategy'
										aria-haspopup='menu'
										onClick={handleToggle}
									>
										{open ? <ArrowDropUpIcon fontSize='small' color='#666' /> : <ArrowDropDownIcon fontSize='small' color='#666' />}
									</Button>
								</ButtonGroup>

								{/* <PopoverMenus
								open={open}
								id={'popover'}
								menus={options}
								className={options['actionName'] === options[selectedView]?.actionName && classes.viewMenu}
								anchorEl={anchorRef.current}
								onClickAction={(event) => performAction(event)}
								onClose={handleClose}
							/> */}

								<Popper
									class={classes.viewMenu}
									open={open}
									anchorEl={anchorRef.current}
									role={undefined}
									transition
									disablePortal
									placement='bottom-start'
								>
									{({ TransitionProps, placement }) => (
										<Grow
											{...TransitionProps}
											style={{
												transformOrigin: placement === 'center bottom',
											}}
										>
											<Paper>
												<ClickAwayListener onClickAway={handleClose}>
													<MenuList id='split-button-menu'>
														{options.map((option, index) => (
															<MenuItem
																key={option.actionName}
																selected={index === selectedView}
																onClick={(event) => handleMenuItemClick(event, index)}
															>
																{option?.reactIcon ? (
																	<CgFeed
																		style={{ paddingRight: '8px', fontSize: '1.25rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.54)' }}
																	/>
																) : (
																	<Icon fontSize='small' classes={classes.menuIcon}>
																		{option.icon}
																	</Icon>
																)}
																{option.label}
															</MenuItem>
														))}
													</MenuList>
												</ClickAwayListener>
											</Paper>
										</Grow>
									)}
								</Popper>
							</Box>
						)}
					</>
				)}
				{isLoading ? (
					<>
						<Skeleton variant='rect' className={classes.actionBtn} width={80} height={20} />
						<Skeleton variant='rect' className={classes.settingsBtn} width={80} height={20} />
					</>
				) : (
					<>
						<Grid className={classes.switchContainer} container alignItems='center'>
							<Grid item className={classes.actionContainer}>
								<div className={classes.btnContainer} style={{ display: 'flex', flexDirection: 'row' }}>
									{!isLoading && (
										<IconButton onClick={handleRefresh} color='textSecondary'>
											<RefreshIcon />
										</IconButton>
									)}
									<Button
										size='small'
										className={classnames(classes.sortBtn, !_.isEqual(sortingDetails?.sort_by, null) && classes.sortedValBtn)}
										onClick={toggleSortBtn}
										startIcon={_.isEqual(sortingDetails?.sort_by, null) && <Icon fontSize='small'>sort</Icon>}
									>
										{_.isEqual(sortingDetails?.sort_by, null) ? 'Sort by' : _.get(_.find(sortable_fields, { id: sortingDetails.sort_by }), 'name')}
									</Button>
									{!_.isEqual(sortingDetails?.sort_by, null) && (
										<IconButton
											onClick={() => sortBy(activityState, activityIcon === 'arrow_upward' ? 'arrow_downward' : 'arrow_upward')}
											className={classes.iconBtn}
										>
											<Icon
												onClick={() => sortBy(activityState, activityIcon === 'arrow_upward' ? 'arrow_downward' : 'arrow_upward')}
												fontSize='small'
											>
												{activityIcon}
											</Icon>
										</IconButton>
									)}
								</div>
							</Grid>
							<CustomPopoverMenus
								open={Boolean(sortAnchorEl)}
								id={'0'}
								menus={sortable}
								anchorEl={sortAnchorEl}
								onClose={handleCloseMenu}
								sortBy={sortBy}
								activityState={activityState}
								activityIcon={activityIcon}
								className={classes.sortPopover}
							/>
							{!isSmartPhone & !filterMenuProps.isOpen ? (
								<Grid item>
									<Typography component='div' variant='caption' color='initial'>
										TOTAL TASKS: {`${totalstickies}`}
									</Typography>
								</Grid>
							) : (
								<Grid item>
									<Typography component='div' variant='caption' color='initial'>
										({totalstickies})
									</Typography>
								</Grid>
							)}
							<Grid item className={classnames(classes.searchWrapper, { show: openSearchBar })}>
								{isSmartPhone && (
									<IconButton size='small' onClick={toggleSearchBar}>
										<Icon fontSize='small'>arrow_back</Icon>
									</IconButton>
								)}

								<SearchBox
									placeholder='Search'
									onChange={onChangeSearchBox}
									searchBarClassName={classes.searchBox}
									containerClassName={classes.searchBoxContainer}
									size='small'
								/>
							</Grid>
							{/* <Grid item>
								{!isSmallDevice && dashboard?.dashboard_id && dashboard?.dashboard_config && result !== undefined && (
									<div className={classes.btnContainerView}>
										<Button size='small' variant='outlined' onClick={onClickDashboard} className={classes.actionBtn} color='primary'>
											<Icon size='small' fontSize='small'>
												dashboard
											</Icon>{' '}
											&nbsp; View Dashboard
										</Button>
									</div>
								)}
							</Grid> */}
							{/* {!isSmallDevice && (
								<Grid item>
									<Box className={classes.titleWrapper}>
										<ButtonGroup variant='outlined' color='primary' ref={anchorRef} className={classes.btnGrp} aria-label='split button'>
											<Button size='small' variant='outlined' onClick={handleToggle} color='primary'>

												<Icon fontSize='small'>{options[selectedView].icon}</Icon>
											</Button>
										</ButtonGroup>
										<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement='bottom-end'>
											{({ TransitionProps, placement }) => (
												<Grow
													{...TransitionProps}
													style={{
														transformOrigin: placement === 'center bottom',
													}}
												>
													<Paper>
														<ClickAwayListener onClickAway={handleClose}>
															<MenuList id='split-button-menu'>
																{options.map((option, index) => (
																	<MenuItem
																		key={option.actionName}
																		selected={index === selectedView}
																		onClick={(event) => handleMenuItemClick(event, index)}
																	>
																		<Icon fontSize='small'>{option.icon}</Icon>
																		{option.label}
																	</MenuItem>
																))}
															</MenuList>
														</ClickAwayListener>
													</Paper>
												</Grow>
											)}
										</Popper>
									</Box>
								</Grid>
							)} */}

							{isSmartPhone && (
								<Grid item>
									<IconButton className='action-btn' size='small' onClick={toggleSearchBar}>
										<Icon fontSize='small'>search</Icon>
									</IconButton>
								</Grid>
							)}
							{isSmallDevice && (
								<>
									<Grid item>
										<IconButton
											className='action-btn'
											aria-describedby={'popover'}
											onClick={toggleActionMenus}
											size='small'
											ref={(ref) => (moreOptionsAchorEl.current = ref)}
										>
											<Icon fontSize='small'>more_horiz</Icon>
										</IconButton>
									</Grid>
									<PopoverMenus
										open={openActionMenus}
										id={'popover'}
										menus={actionMenus}
										anchorEl={popoverAncherEl}
										onClickAction={performAction}
										onClose={toggleActionMenus}
									/>
									{/* <Popper
										class={classes.mobViewMenu}
										open={openActionMenus}
										anchorEl={popoverAncherEl}
										role={undefined}
										transition
										disablePortal
										placement='bottom-start'
									>
										{({ TransitionProps, placement }) => (
											<Grow
												{...TransitionProps}
												style={{
													transformOrigin: placement === 'center bottom',
												}}
											>
												<Paper>
													<ClickAwayListener onClickAway={toggleActionMenus}>
														<MenuList id='split-button-menu'>
															{options.map((option, index) => (
																<MenuItem
																	key={option.actionName}
																	selected={index === selectedView}
																	onClick={(event) => handleMenuItemClick(event, index)}
																>
																	{option?.reactIcon ? (
																		<CgFeed
																			style={{ paddingRight: '8px', fontSize: '1.25rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.54)' }}
																		/>
																	) : (
																		<Icon fontSize='small' classes={classes.menuIcon}>
																			{option.icon}
																		</Icon>
																	)}
																	{option.label}
																</MenuItem>
															))}
														</MenuList>
													</ClickAwayListener>
												</Paper>
											</Grow>
										)}
									</Popper> */}
								</>
							)}
						</Grid>
						{/* {!isSmallDevice && (
							<>
								<Grid item>
									<IconButton size='small' edge='end' onClick={toggleActionSettingMenus}>
										<Icon>more_vert</Icon>
									</IconButton>
								</Grid>
								<PopoverMenus
									open={openActionSettingMenus}
									id={'popover'}
									menus={actionSettingMenus}
									anchorEl={popoverAncherE2}
									onClickAction={performActionSetting}
									onClose={toggleActionSettingMenus}
									className={classes.actionPopoverRoot}
								/>
							</>
						)} */}
						{/* {isSmallDevice ? (
							<></>
						) : (
							<div className={classes.btnContainer}>
								<Button size='small' variant='outlined' onClick={openActivity} className={classes.actionBtn} color='primary'>
									Action
								</Button>
								<Button size='small' variant='outlined' onClick={openSettings} className={classes.settingsBtn} color='primary'>
									Settings
								</Button>
							</div>
						)} */}
					</>
				)}
			</Toolbar>

			<GlassSwitchPopover anchorEl={anchorEl.current} open={openSwitchPop} handleClose={toggleSwitchPopover} />
		</AppBar>
	)
}

export default Header
