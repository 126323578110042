import {
	Card,
	CardHeader,
	makeStyles,
	Typography,
	Avatar,
	CardContent,
	CardActions,
	ButtonBase,
	Icon,
	Grid,
	Button,
	Divider,
	Box,
	Snackbar,
	IconButton,
	FormControlLabel,
	Checkbox,
	TextField,
	Popover,
	MenuItem,
	Container,
	Paper,
} from '@material-ui/core'
import Attachments from 'components/Attachments'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { convertGmtToLocalTime, formTheName, getErrMsg, getImgUrl, stringToHslColor } from 'utils'
import _ from 'lodash'
import { Skeleton } from '@material-ui/lab'
import GlassApi from 'services/glass/api'
import { useDispatch, useSelector } from 'react-redux'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { hideLoader, showLoader, showErrorMessage } from 'services/loader/actions'
import useLazyPaginate from 'hooks/useLazyPaginate'
import CommentBox from 'components/CommentBox'
import update from 'immutability-helper'
import PropTypes from 'prop-types'
import { AccountCircle } from '@material-ui/icons'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined'
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined'

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(1, 0),
		paddingBottom: theme.spacing(2),
		'& .MuiCardContent-root': {
			padding: theme.spacing(0, 1, 0, 1),
		},
		'& .MuiCardHeader-root': {
			padding: theme.spacing(1),
		},
		'& .MuiCardHeader-action': {
			marginTop: 0,
			marginRight: 0,
		},
		[theme.breakpoints.down('sm')]: {
			'& .MuiCardHeader-root': {
				padding: theme.spacing(1),
				paddingBottom: 0,
			},
			'& .MuiCardContent-root': {
				padding: theme.spacing(0, 1, 1, 1),
			},
			'& .MuiCardHeader-avatar': {
				marginRight: theme.spacing(1),
			},
		},
		'& .wysiwyg-mention': {
			textDecoration: 'none',
			color: '#1236ff',
			backgroundColor: '#f0fbff',
			padding: '1px 2px',
			borderRadius: '2px',
		},
	},
	cardRoot: {
		backgroundColor: '#fcfcfc',
		margin: theme.spacing(1, 0),
	},
	avatar: {
		width: 32,
		height: 32,
		fontSize: 17,
		// marginRight: theme.spacing(1),
	},
	chatCard: {
		paddingTop: '8px',
	},
	mainChat: {
		paddingTop: '8px',
		paddingBottom: '8px',
	},
	subChat: {
		paddingTop: '8px',
		paddingBottom: '8px',
		// paddingLeft: '40px',
	},
	commentAvatar: {
		width: 24,
		height: 24,
		fontSize: 9,
	},
	comment: {
		paddingTop: '8px',
		paddingBottom: '8px',
		flexFlow: 'nowrap',
		display: 'block',
	},
	reply: {
		paddingTop: '8px',
		paddingBottom: '8px',
		flexFlow: 'nowrap',
	},
	subCommentBox: {
		'& .MuiOutlinedInput-multiline': {
			[theme.breakpoints.down('300')]: {
				width: '70px',
			},
			[theme.breakpoints.between('300', '350')]: {
				width: '127px',
			},
			[theme.breakpoints.between('350', '395')]: {
				width: '175px',
			},
			[theme.breakpoints.between('395', '500')]: {
				width: '200px',
			},
			[theme.breakpoints.between('960', '1134')]: {
				width: '200px',
			},
			width: '300px',
			borderRadius: '24px',
			padding: '8.5px 14px',
		},
	},
	commentBox: {
		'& .MuiOutlinedInput-multiline': {
			[theme.breakpoints.down('300')]: {
				width: '70px',
			},
			[theme.breakpoints.between('300', '350')]: {
				width: '130px',
			},
			[theme.breakpoints.between('350', '395')]: {
				width: '175px',
			},
			[theme.breakpoints.between('395', '420')]: {
				width: '220px',
			},
			[theme.breakpoints.between('420', '530')]: {
				width: '258px',
			},
			[theme.breakpoints.between('960', '1185')]: {
				width: '265px',
			},
			width: '370px',
			borderRadius: '24px',
			padding: '8.5px 14px',
		},
	},
	loadMore: {
		paddingTop: '8px',
	},
	subLoadMore: {
		textAlign: 'end',
	},
	chatBox: {
		display: 'flex',
		flexDirection: 'column',
		'& .MuiGrid-item': {
			maxWidth: '-webkit-fill-available',
		},
	},
	chatHeader: {
		'& .MuiCardHeader-title': {
			fontSize: '14px',
		},
		'& .MuiCardHeader-subheader': {
			fontSize: '14px',
		},
	},
	chatText: {
		backgroundColor: '#F2F2F2',
		borderRadius: '0px 14px 14px 14px',
		padding: '5px 5px',
		// display: 'inline-flex',
		// display: 'inline-table',
		fontSize: '14px',
		'& .MuiCardHeader-root': {
			paddingTop: '2px',
			paddingLeft: '2px',
		},
		'& .MuiCardContent-root': {
			// paddingLeft: '2px',
			padding: `0px 8px 0px 8px !important`,
		},
	},
	activityReactions: {
		marginLeft: '0px',
		display: 'flex',
		flexDirection: 'row',
		paddingTop: '2px',
	},
	reactions: {
		marginLeft: '7px',
		display: 'flex',
		flexDirection: 'row',
		paddingTop: '2px',
	},
	likeIcon: {
		fontSize: '14px',
		padding: '3px 1px',
		color: '#65b17f',
		transform: 'scaleX(-1)',
	},
	reactionCount: {
		fontSize: '13px',
		color: 'slategray',
	},
	dislikeIcon: {
		fontSize: '14px',
		padding: '3px 1px',
		color: 'rgb(244, 67, 54)',
	},
	dot: {
		color: 'slategray',
	},
	bar: {
		color: 'slategray',
	},
	replyBtn: {
		fontSize: '10px',
		padding: '0px 0px',
		minWidth: '42px',
	},
	// title: {
	// 	marginBottom: theme.spacing(1),
	// },
	description: {
		margin: theme.spacing(1, 0),
		fontSize: '14px',
	},
	labelsWrap: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-end',
		width: '100%',
		gap: '5px',
	},
	labelBtnBase: {
		height: '28px',
		padding: '3px 9px',
		borderRadius: '2px',
		color: theme.palette.common.white,
	},
	pinnedIcon: {
		transform: 'rotate(45deg)',
	},
	attachmentsSection: {
		width: '100%',
	},
	sortButton: {
		textTransform: 'none',
		color: '#92929d',
		lineHeight: 'normal',
	},
	activitiesHead: { justifyContent: 'space-between' },
	loadingSkeleton: {
		display: 'flex',
		padding: '8px',
		margin: '8px 0px',
		'& .MuiSkeleton-circle': {
			marginRight: '10px',
		},
	},
	lineSkeleton: {
		width: '100%',
	},
	activityTypeItem: {
		textAlign: '-webkit-right',
	},
	mb2: {
		marginBottom: theme.spacing(2),
	},
}))

const SubReply = (props) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const {
		subReply,
		userDetails,
		token,
		activity,
		reply,
		// subReplyId,
		updateActivitySubReplyReactions,
		// handleSubReplyBoxOpen,
		// subReplyBoxShow,
		glassLabels,
		glassCode,
		stickyId,
		activityTypes,
		sessionId,
		commentLoading,
		suggestions,
		updateActivityItem,
		// updateActivityReply,
		subReply_replyId,
		setSubReplyState,
		updateReplyItem,
		handleCustomLabel,
		newCustomLabel,
	} = props

	const [subReplyId, setSubReplyId] = useState('')
	const [subReplyBoxShow, setSubReplyBoxShow] = useState(false)
	const [subReplyValue, setSubReplyValue] = useState('')

	const subReplyLikeCount = _.sum(
		_.map(
			_.map(subReply?.sub_reply_reactions, (reactions) => {
				return reactions
			}),
			(data) => {
				return data?.like
			}
		)
	)
	const subReplyDislikeCount = _.sum(
		_.map(
			_.map(subReply?.sub_reply_reactions, (reactions) => {
				return reactions
			}),
			(data) => {
				return data?.dislike
			}
		)
	)
	const subReplyReactionStatus = _.toString(
		_.map(_.filter(subReply?.sub_reply_reactions, { created_by: userDetails?.user_id }), (data) => {
			return data?.reactions
		})
	)
	let name = formTheName(subReply?.created_by?.first_name, subReply?.created_by?.last_name)

	const handleSubReplyBoxOpen = (replyId) => {
		setSubReplyBoxShow(!subReplyBoxShow)
		setSubReplyValue('')
		setSubReplyId(replyId)
	}

	const updateActivitySubReply = (data, formik, activity, replyId) => {
		dispatch(showLoader('Loading please wait...'))
		let formData = new FormData()
		formData.append('desc', data?.desc)
		data?.activity_type_id !== null && formData.append('activity_type_id', data?.activity_type_id?.activity_id)
		formData.append('labels', _.join(data?.labels, ','))
		data?.attachments.map((file) => formData.append('attachments', file))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			// setReplyValue('')
			// setReplyBoxShow(false)
			setSubReplyBoxShow(false)
			setSubReplyValue('')
			_.isEqual(replyId, subReply_replyId)
				? setSubReplyState((prevState) => ({
						...prevState,
						subReplyList: [res?.data?.data?.sub_reply[0], ...prevState.subReplyList],
						subReplyOffset: 0,
						subReply_activityId: res?.data?.data?.activity_id,
						subReply_replyId: res?.data?.data?.reply_id,
				  }))
				: setSubReplyState((prevState) => ({
						...prevState,
						subReplyList: [res?.data?.data?.sub_reply[0]],
						subReplyOffset: 0,
						subReply_activityId: res?.data?.data?.activity_id,
						subReply_replyId: res?.data?.data?.reply_id,
				  }))
			updateReplyItem(res?.data?.data)
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
			// setReplyValue('')
			// setReplyBoxShow(false)
			setSubReplyBoxShow(false)
			setSubReplyValue('')
		}
		GlassApi.updateStickyActivitiesSubReply(glassCode, stickyId, activity?.activity_id, replyId, sessionId, formData).then(onSuccess, onFailure)
	}

	// const updateActivitySubReply = (activity, replyId) => {
	// 	dispatch(showLoader('Loading please wait...'))
	// 	let formData = {
	// 		desc: subReplyValue,
	// 		is_parent: false,
	// 		parent_id: replyId,
	// 	}
	// 	const onSuccess = (res) => {
	// 		dispatch(hideLoader())
	// 		updateActivityItem(res?.data?.data)
	// 		setSubReplyValue('')
	// 		setSubReplyBoxShow(false)
	// 	}
	// 	const onFailure = (err) => {
	// 		dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
	// 		setSubReplyValue('')
	// 		setSubReplyBoxShow(false)
	// 	}
	// 	GlassApi.updateStickyActivitiesReply(glassCode, stickyId, activity?.activity_id, sessionId, formData).then(onSuccess, onFailure)
	// }

	return (
		<>
			<Grid container alignItems='flex-start' className={classes.subChat} key={subReply?.reply_id}>
				<Grid item xs={1} lg={1}></Grid>
				<Grid item xs={1} lg={1}>
					<Avatar
						size='small'
						className={classes.commentAvatar}
						src={getImgUrl(token, subReply?.created_by?.profile_img) || null}
						style={{
							backgroundColor: stringToHslColor(formTheName(subReply?.created_by?.first_name, subReply?.created_by?.last_name)),
						}}
					>
						{_.slice(_.words(formTheName(subReply?.created_by?.first_name, subReply?.created_by?.last_name)), 0, 1).reduce((a, t) => (a += t[0]), '')}
					</Avatar>
				</Grid>
				<Grid container item xs={10} lg={10} className={classes.chatBox}>
					<Grid item>
						<Card elevation={0} className={classes.chatText}>
							<CardHeader
								className={classes.chatHeader}
								title={name}
								subheader={convertGmtToLocalTime(_.get(subReply, 'created_date', ''), 'DD MMM YYYY | hh:mm a')}
							/>
							<CardContent>
								{subReply?.desc && <div className={classes.description} dangerouslySetInnerHTML={{ __html: subReply?.desc }}></div>}
								{!_.isEmpty(_.get(subReply, 'attachments', [])) && (
									<div className={classes.attachmentsSection}>
										<Attachments
											attachmentList={_.get(subReply, 'attachments', [])}
											showDivider={false}
											isActivity={true}
											disableDelete={true}
											enableAddBtn={false}
										/>
									</div>
								)}
								<div className={classes.labelsWrap}>
									{!_.isEmpty(subReply?.labels) &&
										_.map(subReply?.labels, (label) => (
											<ButtonBase
												key={label?.label_id}
												onClick={null}
												disabled={true}
												style={{ background: label?.label_color }}
												className={classes.labelBtnBase}
											>
												<Typography variant='caption'>{label?.label_name}</Typography>
											</ButtonBase>
										))}
								</div>
							</CardContent>
						</Card>
					</Grid>
					<Grid item className={classes.reactions}>
						{_.isEqual(subReplyReactionStatus, 'like') ? (
							<Icon
								className={classes.likeIcon}
								onClick={() => {
									updateActivitySubReplyReactions(activity, reply?.reply_id, subReply?.reply_id, '')
								}}
							>
								thumb_up_icon
							</Icon>
						) : (
							<ThumbUpOutlinedIcon
								className={classes.likeIcon}
								onClick={() => {
									updateActivitySubReplyReactions(activity, reply?.reply_id, subReply?.reply_id, 'like')
								}}
							/>
						)}
						&nbsp;<div className={classes.dot}>.</div>&nbsp;
						<Typography className={classes.reactionCount}>{subReplyLikeCount}</Typography>&nbsp;
						<div className={classes.bar}>|</div>&nbsp;
						{_.isEqual(subReplyReactionStatus, 'dislike') ? (
							<Icon
								className={classes.dislikeIcon}
								onClick={() => {
									updateActivitySubReplyReactions(activity, reply?.reply_id, subReply?.reply_id, '')
								}}
							>
								thumb_down_icon
							</Icon>
						) : (
							<ThumbDownOutlinedIcon
								className={classes.dislikeIcon}
								onClick={() => {
									updateActivitySubReplyReactions(activity, reply?.reply_id, subReply?.reply_id, 'dislike')
								}}
							/>
						)}
						&nbsp;<div className={classes.dot}>.</div>&nbsp;
						<Typography className={classes.reactionCount}>{subReplyDislikeCount}</Typography>&nbsp;
						<div className={classes.bar}>|</div>&nbsp;
						<Button
							className={classes.replyBtn}
							onClick={() => {
								handleSubReplyBoxOpen(subReply?.reply_id)
							}}
						>
							Reply
						</Button>
					</Grid>
				</Grid>
			</Grid>
			{subReplyBoxShow && (
				<Grid container alignItems='center'>
					<Grid item xs={1} lg={1}></Grid>
					<Grid item xs={11} lg={11}>
						<div>
							<Grid item>
								<CommentBox
									glassLabels={glassLabels}
									glassCode={glassCode}
									activityTypes={activityTypes}
									sessionId={sessionId}
									placeholder={'Add a comment...'}
									onSubmit={(data, formik) => updateActivitySubReply(data, formik, activity, reply?.reply_id)}
									loading={commentLoading}
									suggestions={suggestions}
									handleCustomLabel={handleCustomLabel}
									newCustomLabel={newCustomLabel}
								/>
							</Grid>
						</div>
					</Grid>
				</Grid>
			)}
		</>
	)
}

export default SubReply
