import { ACTION_TYPES, SAGA_ACTIONS } from '../../../constants'

export const getUserTable = (query, search, redirect, module, cancelExecutor) => {
	return {
		type: SAGA_ACTIONS.GET_USER_LABEL,
		query,
		search,
		redirect,
		module,
		cancelExecutor,
	}
}

export const getLabelCategory = (cancelExecutor) => {
	return {
		type: SAGA_ACTIONS.GET_LABEL_CATEGORY,
		cancelExecutor,
	}
}

export const addUserLabel = (sessionId, labelName, module, successCallback, errCallback) => ({
	type: SAGA_ACTIONS.ADD_USER_LABEL,
	sessionId,
	labelName,
	module,
	successCallback,
	errCallback,
})

export const updateUserLabel = (sessionId, labelId, labelName, module, successCallback, errCallback) => ({
	type: SAGA_ACTIONS.UPDATE_USER_LABEL,
	sessionId,
	labelId,
	labelName,
	module,
	successCallback,
	errCallback,
})

export const removeUserLabel = (sessionId, labelId, module, successCallback, errCallback) => ({
	type: SAGA_ACTIONS.REMOVE_USER_LABEL,
	sessionId,
	labelId,
	module,
	successCallback,
	errCallback,
})

export const getUserDepartment = (query, search, redirect, module, cancelExecutor) => {
	return {
		type: SAGA_ACTIONS.GET_USER_DEPARTMENT,
		query,
		search,
		redirect,
		module,
		cancelExecutor,
	}
}

export const addUserDepartment = (sessionId, departmentName, module, successCallback, errCallback) => ({
	type: SAGA_ACTIONS.ADD_USER_DEPARTMENT,
	sessionId,
	departmentName,
	module,
	successCallback,
	errCallback,
})

export const updateUserDepartment = (sessionId, departmentId, departmentName, module, successCallback, errCallback) => ({
	type: SAGA_ACTIONS.UPDATE_USER_DEPARTMENT,
	sessionId,
	departmentId,
	departmentName,
	module,
	successCallback,
	errCallback,
})

export const removeUserDepartment = (sessionId, departmentId, module, successCallback, errCallback) => ({
	type: SAGA_ACTIONS.REMOVE_USER_DEPARTMENT,
	sessionId,
	departmentId,
	module,
	successCallback,
	errCallback,
})

export const getUserDesignation = (query, search, redirect, module, cancelExecutor) => {
	return {
		type: SAGA_ACTIONS.GET_USER_DESIGNATION,
		query,
		search,
		redirect,
		module,
		cancelExecutor,
	}
}

export const addUserDesignation = (sessionId, designationName, module, successCallback, errCallback) => ({
	type: SAGA_ACTIONS.ADD_USER_DESIGNATION,
	sessionId,
	designationName,
	module,
	successCallback,
	errCallback,
})

export const updateUserDesignation = (sessionId, designationId, designationName, module, successCallback, errCallback) => ({
	type: SAGA_ACTIONS.UPDATE_USER_DESIGNATION,
	sessionId,
	designationId,
	designationName,
	module,
	successCallback,
	errCallback,
})

export const removeUserDesignation = (sessionId, designationId, module, successCallback, errCallback) => ({
	type: SAGA_ACTIONS.REMOVE_USER_DESIGNATION,
	sessionId,
	designationId,
	module,
	successCallback,
	errCallback,
})