import React, { useRef, useEffect } from 'react'
import { Divider, Grid, makeStyles, Typography, Chip, TextField, Avatar } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { formTheName, getImgUrl, stringToHslColor } from 'utils'
import { getModelxDetails } from '../../../../../services/modelx/actions'
import _ from 'lodash'
import classnames from 'classnames'
import RichTextEditor from 'components/RichTextEditor'
import Progressbar from './Progressbar'

const useStyles = makeStyles((theme) => ({
    headerRoot: {
		'& .MuiFormControl-fullWidth': {
			marginTop: 0,
		},
		'& .MuiOutlinedInput-inputMarginDense': {
			padding: theme.spacing(1),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1.2, 2, 0, 2),
		},
		padding: '16px 20px',
	},
    detailsContent: {
		padding: theme.spacing(1, 0),
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: theme.spacing(0.5, 0),
		},
	},
    objBg: {
		backgroundColor: '#f2f6fa',
		padding: theme.spacing(2, '20px'),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1, 2),
		},
	},
    bold: {
		fontWeight: '500',
	},
    noneSection: {
		backgroundColor: theme.palette.grey[100],
		padding: 5,
		fontSize: 14,
		color: theme.palette.grey[600],
		marginTop: theme.spacing(1),
		cursor: 'pointer',
	},
    titleSec: {
		overflow: 'hidden',
		cursor: 'text',
        '& .MuiTypography-h6': {
            fontSize: '14px',
            fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: '500',
            lineHeight: '1.57',
            letterSpacing: '0.00714em',
            },
		'& h5': {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
    content: {
        margin: '8px 0px',
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 16px 8px 0px',
    },
    contentKey: {
        fontSize: '14px',
        fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
        fontWeight: '500',
        lineHeight: '1.57',
        letterSpacing: '0.00714em',
        color: '#171725',
    },
    contentValue: {
        marginTop: '8px',
        alignItems: 'center',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
        fontSize: '0.875rem',
        fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
        fontWeight: '400',
        lineHeight: '1.43',
        letterSpacing: '0.01071em',
        color: '#171725',
    },
    assigneeDiv: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flex: 1,
		alignItems: 'center',
		marginTop: theme.spacing(1),
	},
	text: {
		fontSize: 14,
		flex: 1,
	},
    userPicSmall: {
		width: 24,
		height: 24,
		fontSize: 13,
	},
    chipContent: {
        margin: '8px 0px',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiChip-sizeSmall': {
            height: '18px',
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
            fontWeight: '400',
            lineHeight: '1.43',
            letterSpacing: '0.03333em',
            color: '#171725',
            fontSize: '0.875rem',
            boxSizing: 'borderBox',
            alignItems: 'center',
        },
    },
    chip: {
        marginTop: '8px',
        display: 'flex',
        gap: '5px',
    },
    multiTextField: {
        minWidth: '100%',
        maxWidth: '100%',
        '& .MuiOutlinedInput-multiline': {
            color: 'black',
        },
    },
}))


const BasicDetails = ({ slider, basicDetails, project }) => {
 
    const classes = useStyles()

    const token = useSelector((state) => _.get(state, 'session.authToken'))

    // const dispatch = useDispatch()

    // const cancelExecutor = useRef()

    // const basicDetails = useSelector((state) => state.modelxTable?.modelx_details)

    // useEffect(() => {
    //     // if (cancelExecutor.current !== undefined) {
    //     //     cancelExecutor.current()
    //     // }
    //     dispatch(getModelxDetails(slider?.id, cancelExecutor))
    // }, [])

    console.log('project?.total_assigned_tasks', project?.total_assigned_tasks)

    return (
        <div>
            <Grid container justifyContent='space-between' alignItems='flex-start' className={classes.headerRoot}>
                <Grid item lg={12} sm={12} xs={12} className={classes.content}>
                    <Progressbar completed={project?.completed_percentage}/>
                </Grid>
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                        <Typography variant='caption' className={classes.contentKey}>
                            {'Total Tasks Assigned'}
                        </Typography>
                        {_.gte(project?.total_assigned_tasks, 0) ? (
                            <Typography variant='caption' className={classes.contentValue}>
                                {project?.total_assigned_tasks}
                            </Typography>
                        ) : (
                            <p className={classes.noneSection}>
                                None
                            </p>
                        )}
                </Grid>
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                        <Typography variant='caption' className={classes.contentKey}>
                            {'Pending Tasks'}
                        </Typography>
                        {_.gte(project?.pending_tasks, 0) ? (
                            <Typography variant='caption' className={classes.contentValue}>
                                {project?.pending_tasks}
                            </Typography>
                        ) : (
                            <p className={classes.noneSection}>
                                None
                            </p>
                        )}
                </Grid>
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'Completed Tasks'}
                    </Typography>
                    {_.gte(project?.completed_tasks, 0) ? (
                            <Typography variant='caption' className={classes.contentValue}>
                                {project?.completed_tasks}
                            </Typography>
                        ) : (
                            <p className={classes.noneSection}>
                                None
                            </p>
                        )}
                </Grid>
            </Grid>
        </div>
    )
}

export default BasicDetails
