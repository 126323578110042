import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { Grid, makeStyles, Typography, Button } from '@material-ui/core'
import classnames from 'classnames'
import _ from 'lodash'
import SelectBox from 'components/SelectBox'
import DashboardsApi from '../../../services/dashboards/api'
import { usePrevious } from '../../../hooks'
import { useDispatch } from 'react-redux'
import { updateUserDashboard } from '../../../services/session/actions'
import { hideLoader } from 'services/loader/actions'
import Axios from 'axios'
import { usePermission } from 'hooks/usePermission'
import { PERMISSION_TYPE, ROLE_MODULES, ROLE_MODULES_LABEL } from 'constants/modules'
import { isModuleHasPerm } from 'utils'
import { fetchProfile } from '../../../services/session/actions'
import ProfileApi from '../../../services/session/api'

const CancelToken = Axios.CancelToken

const useStyles = makeStyles((theme) => ({
	root: {
		// margin: 'auto',
		padding: theme.spacing(1),
		boxSizing: 'border-box',
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0),
		},
	},
	titleContainer: {
		padding: theme.spacing(0, 0, 2),
	},
	title: {
		padding: theme.spacing(2, 0),
		fontSize: '1rem',
		fontWeight: 'bold',
	},
	desc: {
		color: theme.palette.almostBlack[600],
	},
	itemRow: {
		padding: theme.spacing(2, 0),
		borderBottom: `1px solid ${theme.palette.almostBlack[200]}`,
		'& .MuiTypography-caption': {
			color: theme.palette.almostBlack[600],
		},
	},
	selectbox: {
		'& div': {
			margin: 0,
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(1),
		},
	},
	label: {
		paddingTop: theme.spacing(1),
	},
	subtitle: {
		padding: theme.spacing(2, 0),
	},
}))

const buildDashOptions = (dashboardList) => {
	return (
		_.map(dashboardList, (dashboard) => ({
			id: _.get(dashboard, 'dashboard_id'),
			name: _.get(dashboard, 'dashboard_name'),
		})) || []
	)
}

const HomePageConfig = ({ homeDashboardId, config_landing }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const isMounted = useRef(true)
	const cancelDashLstReq = useRef(null)
	const [dashboardState, setDashboardState] = useState({
		isFetching: true,
		dashboardList: [],
	})
	const [dashboardId, setDashboardId] = useState(homeDashboardId)
	const { isFetching, dashboardList } = dashboardState
	const prevDashbardId = usePrevious(dashboardId)
	const [permissionOption, setPermissionOption] = useState([])
	const [config, setConfig] = useState(config_landing)
	const prevConfig = usePrevious(config)

	const loadDashboardList = useCallback(() => {
		cancelDashLstReq.current && cancelDashLstReq.current('cancel')
		setDashboardState({ isFetching: true, dashboardList: [] })
		DashboardsApi.fetchDashboards(
			new CancelToken((exec) => {
				cancelDashLstReq.current = exec
			})
		)
			.then((resp) => {
				if (isMounted.current) {
					setDashboardState({
						isFetching: false,
						dashboardList: buildDashOptions(_.get(resp, 'data.data', [])),
					})
				}
			})
			.catch((err) => {
				if (isMounted.current) setDashboardState({ isFetching: false, dashboardList: [] })
			})
	}, [])

	useEffect(() => {
		loadDashboardList()
		return () => {
			cancelDashLstReq.current && cancelDashLstReq.current('cancel')
			isMounted.current = false
		}
	}, [loadDashboardList])

	useEffect(() => {
		ProfileApi.fetchProfile(
			new CancelToken((exec) => {
				cancelDashLstReq.current = exec
			})
		).then((res) => {
			const landing = res.data.data.config_landing
			setConfig(landing)
		})
	}, [loadDashboardList])

	const dashboardPerm = usePermission(ROLE_MODULES.DASHBOARDS)
	const isAbleToViewDash = isModuleHasPerm(dashboardPerm, PERMISSION_TYPE.VIEW)

	const discoverXPerm = usePermission(ROLE_MODULES.DISCOVERX)
	const isAbleToViewDiscoverx = isModuleHasPerm(discoverXPerm, PERMISSION_TYPE.VIEW)

	const showOnlyAccModules = useMemo(() => {
		const results = []

		results.push({ id: 'home', name: 'Home' }, { id: 'my_task', name: 'My Tasks' })

		if (_.get(discoverXPerm, PERMISSION_TYPE.VIEW, false)) results.push({ id: 'discoverx', name: 'Data' })

		return results
	}, [dashboardPerm, discoverXPerm])

	const onChangeSelectBox = (value) => {
		setDashboardId(value)
	}

	const onChangeLanding = (value) => {
		setConfig(value)
	}

	//Api success or failure dialog close btn callback
	const dialogCloseBtnCallback = () => {
		dispatch(hideLoader())
	}

	const onClickSaveBtn = () => {
		dispatch(updateUserDashboard(dashboardId, config, dialogCloseBtnCallback))
	}

	const isFormModified = !_.isEqual(prevDashbardId, dashboardId)
	const isConfigModified = !_.isEqual(prevConfig, config)

	return (
		<Grid container item xs={12}>
			<Grid container item xs={12} className={classnames(classes.root)}>
				<Grid container alignItems='flex-start' className={classes.itemRow}>
					{isAbleToViewDash && (
						<>
							<Grid item xs={12} container>
								<Typography className={classes.title} variant='h5'>
									Configure Home Page
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} container justifyContent='flex-start' alignItems='center'>
								<Typography className={classes.label} variant='subtitle2'>
									Choose Dashboard
								</Typography>
							</Grid>
							<Grid item sm={6} xs={12}>
								<SelectBox
									isLoading={isFetching}
									// label={isFetching ? 'Loading...' : ''}
									// disabled={isViewMode}
									noOptionsText='No Dashboard Found'
									inputClass={classes.selectbox}
									name={'dashboard'}
									value={dashboardId}
									onChange={onChangeSelectBox}
									options={dashboardList}
								/>
							</Grid>
							<Grid item xs={12} container>
								<Typography className={classes.subtitle} variant='caption'>
									You can customize your homepage with a Dashboard.
								</Typography>
							</Grid>
						</>
					)}
					{isAbleToViewDiscoverx && (
						<>
							<Grid item xs={12} container>
								<Typography className={classes.title} variant='h5'>
									Configure Landing Page
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} container justifyContent='flex-start' alignItems='center'>
								<Typography className={classes.label} variant='subtitle2'>
									Choose Landing
								</Typography>
							</Grid>
							<Grid item sm={6} xs={12}>
								<SelectBox
									isLoading={isFetching}
									// label={isFetching ? 'Loading...' : ''}
									// disabled={isViewMode}
									noOptionsText='No Landingpage Found'
									inputClass={classes.selectbox}
									name={'landing'}
									value={config}
									onChange={onChangeLanding}
									options={showOnlyAccModules}
								/>
							</Grid>
						</>
					)}
					<Grid item xs={12} container>
						<Typography className={classes.subtitle} variant='caption'>
							{/* You can customize your homepage with a Dashboard. */}
						</Typography>
					</Grid>
					<Grid item xs={12} container justifyContent='center'>
						<Button
							disableElevation
							disabled={!isFormModified && !isConfigModified}
							variant='contained'
							size='small'
							color='primary'
							type='submit'
							onClick={onClickSaveBtn}
						>
							Save
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default HomePageConfig
