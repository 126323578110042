import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import { CirclePicker } from 'react-color'
import * as Yup from 'yup'
import classNames from 'classnames'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	colorRoot: {
		justifyContent: 'center',
		marginBottom: '0!important',
		gap: '10px',
	},
	fieldset: {
		top: '-5px',
		overflow: 'hidden',
		borderStyle: 'solid',
		borderColor: 'rgba(0, 0, 0, 0.23)',
		padding: theme.spacing(1, 1, 2),
		borderWidth: '1px',
		borderRadius: '4px',
		'&.error': {
			borderColor: theme.palette.error.main,
			'& span': {
				color: theme.palette.error.main,
			},
		},
	},
	errTxt: {
		marginLeft: '14px',
		marginRight: '14px',
		marginTop: '4px',
		color: theme.palette.error.main,
		display: 'inline-block',
	},
}))

const LabelCreateForm = React.forwardRef(({ onSubmit, initialValues, name, smallName }, ref) => {
	const classes = useStyles()

	const LabelSchema = Yup.object().shape({
		label_name: Yup.string()
			//   .min(2, 'Too Short!')
			//   .max(50, 'Too Long!')
			.required(`Please enter the ${smallName} name`),
		// label_color: Yup.string().required('Please choose the label color'),
	})

	const formik = useFormik({
		initialValues: initialValues || {
			label_name: '',
			label_color: '',
			label_id: '',
		},
		onSubmit: onSubmit,
		validationSchema: LabelSchema,
	})

	useEffect(() => {
		ref.current = formik
		return () => {
			ref.current = null
		}
	}, [ref, formik])

	return (
		<form onSubmit={formik.handleSubmit}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<TextField
						name='label_name'
						value={formik.values.label_name}
						error={Boolean(formik.errors.label_name)}
						helperText={formik.errors?.label_name}
						onChange={formik.handleChange}
						label={`${name} Name*`}
						id='label-name'
						variant='outlined'
						size='small'
						fullWidth
					/>
				</Grid>
				{/* <Grid item xs={12}>
					<fieldset className={classNames(classes.fieldset, { error: Boolean(formik.touched.label_color && formik.errors.label_color) })}>
						<legend>
							<Typography variant='caption' color='textSecondary' gutterBottom>
								Choose Label Color
							</Typography>
						</legend>
						<CirclePicker
							onChange={(color, event) => {
								formik.handleChange({ target: { name: 'label_color', value: color.hex } })
							}}
							name='label_color'
							color={formik.values.label_color}
							className={classes.colorRoot}
							width='100%'
							circleSpacing={0}
						/>
					</fieldset>
					{formik.touched.label_color && formik.errors.label_color && (
						<Typography className={classes.errTxt} variant='caption'>
							{formik.errors.label_color}
						</Typography>
					)}
				</Grid> */}
			</Grid>
		</form>
	)
})

LabelCreateForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	initialValues: PropTypes.shape({
		label_id: PropTypes.string,
		label_name: PropTypes.string,
		label_color: PropTypes.string,
	}),
}

export default LabelCreateForm
