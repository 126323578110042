import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import {
	Grid,
	makeStyles,
	TextField,
	Typography,
	Icon,
	Button,
	Popper,
	Paper,
	ClickAwayListener,
	InputAdornment,
	IconButton,
	FormControlLabel, 
    Switch,
} from '@material-ui/core'
import classnames from 'classnames'
import File from 'components/File'
import SelectBox from 'components/SelectBox'
import { Form, Field } from 'formik'
import Validator from './validator'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { hideLoader, showLoader, showErrorMessage } from 'services/loader/actions'
import labelAdjectiveImg1 from '../../../images/counterfeit-toggle.png'
import AppSettingsApi from '../../../services/appsettings/api'
import { fetchAppConfigs } from 'services/appsettings/actions'
import { PERMISSION_TYPE } from 'constants/modules'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { getErrMsg, getSuccessMsg, userHasAdminRole } from 'utils'
import { useSelector } from 'react-redux'
import UserApi from '../../../services/user_config/api'
import Axios from 'axios'
import { Autocomplete } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
	root: {
		// margin: 'auto',
		padding: theme.spacing(1),
		boxSizing: 'border-box',
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0),
		},
	},
	titleContainer: {
		padding: theme.spacing(0, 0, 2),
	},
	titleText: {
		letterSpacing: '1px',
		fontSize: 18,
		fontWeight: '500',
		[theme.breakpoints.down('xs')]: {
			fontSize: 16,
		},
	},
	itemRow: {
		padding: theme.spacing(2, 0),
		borderBottom: `1px solid ${theme.palette.almostBlack[200]}`,
	},
	inputFieldContainer: {
		// maxWidth: 360
	},
	selectFieldContainer: {
		'& .MuiAutocomplete-root': {
			minWidth: '100%',
		},
		'& .MuiFormControl-marginNormal': {
			marginTop: '0px',
			marginBottom: '0px',
		},
	},
	configContent: {
		display: 'flex',
		'& .MuiFormControlLabel-labelPlacementStart': {
			marginTop: '-10px',
		},
	},
	SelectField: {
		'& .MuiInputLabel-formControl': {
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			maxWidth: '76%',
		},
	},
	logoRatioClass: {
		width: 320,
		height: 80,
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(1),
		},
		// margin: '0 auto'
	},
	faviconRatioClass: {
		width: 48,
		height: 48,
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(1),
		},
	},
	activityTypeChip: {
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	stepperRoot: {
		padding: theme.spacing(0, 0, 3),
		'& .MuiStep-vertical': {
			height: 20,
			display: 'flex',
			'& .MuiStepLabel-root': {
				display: 'flex',
				alignItems: 'center',
				'& .MuiStepLabel-labelContainer': {
					width: '250px',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					'& .MuiStepLabel-label': {
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					},
				},
			},
		},
		'& .MuiStepConnector-vertical': {
			padding: theme.spacing(0.5, 0),
			marginLeft: 9,
			'& .MuiStepConnector-lineVertical': {
				borderLeftStyle: 'dashed',
				borderLeftWidth: 2,
			},
		},
	},
	stepIcon: {
		width: 20,
		height: 20,
		borderRadius: '50%',
		borderWidth: 4,
		borderStyle: 'solid',
		borderColor: theme.palette.primary.main,
		boxSizing: 'border-box',
	},
	firstStep: {
		position: 'relative',
		'&::after': {
			content: '"DEFAULT"',
			fontSize: 11,
			color: theme.palette.almostBlack[600],
			position: 'absolute',
			right: '2px',
			bottom: '0px',
		},
		'& $stepIcon': {
			borderColor: theme.palette.secondary.main,
		},
		'& .MuiStepLabel-labelContainer': {
			borderBottom: `1px solid ${theme.palette.almostBlack[400]}`,
		},
	},
	finalStep: {
		position: 'relative',
		'&::after': {
			content: '"END STATUS"',
			fontSize: 11,
			color: theme.palette.almostBlack[600],
			position: 'absolute',
			right: '2px',
			bottom: '0px',
		},
		'& $stepIcon': {
			borderColor: theme.palette.error.main,
		},
		'& .MuiStepLabel-labelContainer': {
			borderBottom: `1px solid ${theme.palette.almostBlack[400]}`,
		},
	},
	buttonContainer: {
		width: 278,
	},
	deleteJobStatusIcon: {
		'& .MuiIcon-root': {
			fontSize: '20px',
		},
	},
	infoSpan: {
		fontSize: '16px',
		color: theme.palette.error.light,
		marginLeft: theme.spacing(1),
		cursor: 'pointer',
	},
	popperImage: {
		objectFit: 'cover',
	},
	textField: {
		marginBottom: theme.spacing(2.2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(0),
		},
	},
}))

const BasicDetails = (props) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const { data, perms, openIframeDialog } = props

	const isEditAllowed = useMemo(() => _.get(perms, PERMISSION_TYPE.EDIT, false), [perms])

	const [initVals, setInitVals] = useState({})
	const [isEdited, setIsEdited] = useState(false)

	const [peopleDashboard, setPeopleDashboard] = useState({
		peopleDashboardQuery: '',
		peopleDashboardLoading: false,
		peopleDashboardList: [],
	})

	const [peopleDatasource, setPeopleDatasource] = useState({
		peopleDatasourceQuery: '',
		peopleDatasourceLoading: false,
		peopleDatasourceList: [],
	})

	const [peopleFields, setPeopleFields] = useState({
		peopleFieldsQuery: '',
		peopleFieldsLoading: false,
		peopleFieldsList: [],
	})

	const [modelDashboard, setModelDashboard] = useState({
		modelDashboardQuery: '',
		modelDashboardLoading: false,
		modelDashboardList: [],
	})

	const [modelDatasource, setModelDatasource] = useState({
		modelDatasourceQuery: '',
		modelDatasourceLoading: false,
		modelDatasourceList: [],
	})

	const [modelFields, setModelFields] = useState({
		modelFieldsQuery: '',
		modelFieldsLoading: false,
		modelFieldsList: [],
	})

	const [projectDashboard, setProjectDashboard] = useState({
		projectDashboardQuery: '',
		projectDashboardLoading: false,
		projectDashboardList: [],
	})

	const [projectDatasource, setProjectDatasource] = useState({
		projectDatasourceQuery: '',
		projectDatasourceLoading: false,
		projectDatasourceList: [],
	})

	const [projectFields, setProjectFields] = useState({
		projectFieldsQuery: '',
		projectFieldsLoading: false,
		projectFieldsList: [],
	})

	const [selectedPeopleDash, setSelectedPeopleDash] = useState({})
	const [selectedPeopleDs, setSelectedPeopleDs] = useState({})

	const [selectedModelDash, setSelectedModelDash] = useState({})
	const [selectedModelDs, setSelectedModelDs] = useState({})

	const [selectedProjectDash, setSelectedProjectDash] = useState({})
	const [selectedProjectDs, setSelectedProjectDs] = useState({})

	const [peopleDashOpen, setPeopleDashOpen] = useState(false)
	const [peopleDsOpen, setPeopleDsOpen] = useState(false)
	const [peopleFieldOpen, setPeopleFieldOpen] = useState(false)

	const [modelDashOpen, setModelDashOpen] = useState(false)
	const [modelDsOpen, setModelDsOpen] = useState(false)
	const [modelFieldOpen, setModelFieldOpen] = useState(false)

	const [projectDashOpen, setProjectDashOpen] = useState(false)
	const [projectDsOpen, setProjectDsOpen] = useState(false)
	const [projectFieldOpen, setProjectFieldOpen] = useState(false)

	const peopleDashbaordCancelExecutor = useRef()
	const peopleDatasourceCancelExecutor = useRef()
	const peopleFieldCancelExecutor = useRef()

	const modelDashbaordCancelExecutor = useRef()
	const modelDatasourceCancelExecutor = useRef()
	const modelFieldCancelExecutor = useRef()

	const projectDashbaordCancelExecutor = useRef()
	const projectDatasourceCancelExecutor = useRef()
	const projectFieldCancelExecutor = useRef()

	const { peopleDashboardQuery, peopleDashboardList, peopleDashboardLoading } = peopleDashboard
	const { peopleDatasourceQuery, peopleDatasourceList, peopleDatasourceLoading } = peopleDatasource
	const { peopleFieldsQuery, peopleFieldsList, peopleFieldsLoading } = peopleFields

	const { modelDashboardQuery, modelDashboardList, modelDashboardLoading } = modelDashboard
	const { modelDatasourceQuery, modelDatasourceList, modelDatasourceLoading } = modelDatasource
	const { modelFieldsQuery, modelFieldsList, modelFieldsLoading } = modelFields

	const { projectDashboardQuery, projectDashboardList, projectDashboardLoading } = projectDashboard
	const { projectDatasourceQuery, projectDatasourceList, projectDatasourceLoading } = projectDatasource
	const { projectFieldsQuery, projectFieldsList, projectFieldsLoading } = projectFields

	const user = useSelector((state) => _.get(state, 'session.user.userDetails', ''))
	const isUserHasAdminRole = userHasAdminRole(_.get(user, 'roles', []))

	useEffect(() => {
        setPeopleDashOpen(false)
		setPeopleDsOpen(false)
        setPeopleFieldOpen(false)
		setModelDashOpen(false)
		setModelDsOpen(false)
        setModelFieldOpen(false)
		setProjectDashOpen(false)
		setProjectDsOpen(false)
        setProjectFieldOpen(false)
    }, [data, initVals])

	useEffect(() => {
		let tempVals = {
			labelTitle: _.get(data, 'company_name', ''),
			labelName: _.get(data, 'company_adjective', ''),
			logoImg: _.get(data, 'logo', ''),
			logoFavicon: _.get(data, 'favicon', ''),
			workflowURL: _.get(data, 'workflow_url', ''),
			dataflowURL: _.get(data, 'dataflow_url', ''),
			peoplex_enable_dashboard: _.get(data, 'peoplex_dashboard_config.enable_dashboard', false),
			peoplex_dashboard_id: _.get(data, 'peoplex_dashboard_config.dashboard_id', null),
			peoplex_ds_id: _.get(data, 'peoplex_dashboard_config.ds_id', null),
			peoplex_field_name: _.get(data, 'peoplex_dashboard_config.field_name', ''),
			modelx_enable_dashboard: _.get(data, 'modelx_dashboard_config.enable_dashboard', false),
			modelx_dashboard_id: _.get(data, 'modelx_dashboard_config.dashboard_id', null),
			modelx_ds_id: _.get(data, 'modelx_dashboard_config.ds_id', null),
			modelx_field_name: _.get(data, 'modelx_dashboard_config.field_name', ''),
			project_enable_dashboard: _.get(data, 'project_dashboard_config.enable_dashboard', false),
			project_dashboard_id: _.get(data, 'project_dashboard_config.dashboard_id', null),
			project_ds_id: _.get(data, 'project_dashboard_config.ds_id', null),
			project_field_name: _.get(data, 'project_dashboard_config.field_name', ''),
		}
		setInitVals({ ...tempVals })
	}, [data])

	const [anchorEl, setAnchorEl] = useState(null)
	const [isEditMode, setIsEditMode] = useState(true)

	const handleOpenPopper = (e) => setAnchorEl(anchorEl ? null : e.currentTarget)

	const handleSave = (newData) => {
		const formData = new FormData()

		formData.append('company_name', _.get(newData, 'labelTitle', ''))
		formData.append('company_adjective', _.get(newData, 'labelName', ''))
		formData.append('logo', _.get(newData, 'logoImg'))
		formData.append('favicon', _.get(newData, 'logoFavicon'))
		formData.append('workflow_url', _.get(newData, 'workflowURL'))
		formData.append('dataflow_url', _.get(newData, 'dataflowURL'))
		formData.append('peoplex_enable_dashboard', _.get(newData, 'peoplex_enable_dashboard', false))
		formData.append('peoplex_dashboard_id', _.get(newData, 'peoplex_dashboard_id.dashboard_id'))
		formData.append('peoplex_ds_id', _.get(newData, 'peoplex_ds_id.ds_id'))
		formData.append('peoplex_field_name', _.get(newData, 'peoplex_field_name'))
		formData.append('modelx_enable_dashboard', _.get(newData, 'modelx_enable_dashboard', false))
		formData.append('modelx_dashboard_id', _.get(newData, 'modelx_dashboard_id.dashboard_id'))
		formData.append('modelx_ds_id', _.get(newData, 'modelx_ds_id.ds_id'))
		formData.append('modelx_field_name', _.get(newData, 'modelx_field_name'))
		formData.append('project_enable_dashboard', _.get(newData, 'project_enable_dashboard', false))
		formData.append('project_dashboard_id', _.get(newData, 'project_dashboard_id.dashboard_id'))
		formData.append('project_ds_id', _.get(newData, 'project_ds_id.ds_id'))
		formData.append('project_field_name', _.get(newData, 'project_field_name'))

		dispatch(showLoader('Please wait...'))
		AppSettingsApi.saveAppConfigs(formData)
			.then((resp) => {
				dispatch(fetchAppConfigs())
				dispatch(hideLoader())
				dispatch(showSnackbarWithTimeout(getSuccessMsg(resp), 1500))
				setIsEdited(false)
			})
			.catch((err) => {
				dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
			})
	}

	const checkForChange = (old, newVal) => {
		if (_.isEqual(old, newVal)) {
			setIsEdited(false)
		} else {
			setIsEdited(true)
		}
	}

	const openNewLinkOnNewTab = (title, link) => {
		openIframeDialog(title, link)
	}

	const fetchPeopleDashboard = useCallback((e, inputValue) => {
		setPeopleDashboard((prevState) => ({ ...prevState, peopleDashboardLoading: true, peopleDashboardList: [] }))
		UserApi.fetchUserDashboard(
			peopleDashbaordCancelExecutor,
			(res) => {
				const list = _.get(res, 'data.data.dashboards', [])
				setPeopleDashboard((prevState) => ({ ...prevState, peopleDashboardLoading: false, peopleDashboardList: list || [] }))
			},
			(err) => {
				if (!Axios.isCancel(err)) {
					setPeopleDashboard((prevState) => ({ ...prevState, peopleDashboardLoading: false, peopleDashboardList: [] }))
				}
			}
		)
	}, [])

	useEffect(() => {
		fetchPeopleDashboard()
	}, [fetchPeopleDashboard])

	const fetchPeoplexDatasource = useCallback((e, inputValue, dsId) => {
        const dashboardId = !_.isEmpty(selectedPeopleDash) ? selectedPeopleDash?.dashboard_id : !_.isEmpty(dsId) && !_.isEqual(dsId, 'clear') && !_.isEqual(dsId, 'reset') ? dsId : _.get(data, 'peoplex_dashboard_config.dashboard_id.dashboard_id')
		{!_.isEmpty(dashboardId) &&
        setPeopleDatasource((prevState) => ({ ...prevState, peopleDatasourceLoading: true, peopleDatasourceList: [] }))
		UserApi.fetchUserDataSource(
            dashboardId,
			'peoplex',
			peopleDatasourceCancelExecutor,
			(res) => {
				const list = _.get(res, 'data.data.datasources', [])
				setPeopleDatasource((prevState) => ({ ...prevState, peopleDatasourceLoading: false, peopleDatasourceList: list || [] }))
			},
			(err) => {
				if (!Axios.isCancel(err)) {
					setPeopleDatasource((prevState) => ({ ...prevState, peopleDatasourceLoading: false, peopleDatasourceList: [] }))
				}
			}
		)
		}
	}, [selectedPeopleDash])

	useEffect(() => {
		!_.isEmpty(_.get(data, 'peoplex_dashboard_config.dashboard_id.dashboard_id')) && fetchPeoplexDatasource()
	}, [fetchPeoplexDatasource, data])

	const fetchPeopleFields = useCallback((e, inputValue, dsId) => {
        const datasourceId =  !_.isEmpty(selectedPeopleDs) ? selectedPeopleDs?.ds_id : !_.isEmpty(dsId) && !_.isEqual(dsId, 'clear') && !_.isEqual(dsId, 'reset') ? dsId : _.get(data, 'peoplex_dashboard_config.ds_id.ds_id')
		{!_.isEmpty(datasourceId) &&
        setPeopleFields((prevState) => ({ ...prevState, peopleFieldsLoading: true, peopleFieldsList: [] }))
			UserApi.fetchUserFields(
			datasourceId,
			'peoplex',
			peopleFieldCancelExecutor,
			(res) => {
				const list = _.get(res, 'data.data.fields', [])
				setPeopleFields((prevState) => ({ ...prevState, peopleFieldsLoading: false, peopleFieldsList: list || [] }))
			},
			(err) => {
				if (!Axios.isCancel(err)) {
					setPeopleFields((prevState) => ({ ...prevState, peopleFieldsLoading: false, peopleFieldsList: [] }))
				}
			}
		)
		}
	}, [selectedPeopleDs])

	useEffect(() => {
		!_.isEmpty(_.get(data, 'peoplex_dashboard_config.ds_id.ds_id')) && fetchPeopleFields()
	}, [fetchPeopleFields, data])

	const fetchModelxDatasource = useCallback((e, inputValue, dsId) => {
        const dashboardId = !_.isEmpty(selectedModelDash) ? selectedModelDash?.dashboard_id : !_.isEmpty(dsId) && !_.isEqual(dsId, 'clear') && !_.isEqual(dsId, 'reset') ? dsId : _.get(data, 'modelx_dashboard_config.dashboard_id.dashboard_id')
        {!_.isEmpty(dashboardId) &&
        setModelDatasource((prevState) => ({ ...prevState, modelDatasourceLoading: true, modelDatasourceList: [] }))
		UserApi.fetchUserDataSource(
            dashboardId,
			'modelx',
			modelDatasourceCancelExecutor,
			(res) => {
				const list = _.get(res, 'data.data.datasources', [])
				setModelDatasource((prevState) => ({ ...prevState, modelDatasourceLoading: false, modelDatasourceList: list || [] }))
			},
			(err) => {
				if (!Axios.isCancel(err)) {
					setModelDatasource((prevState) => ({ ...prevState, modelDatasourceLoading: false, modelDatasourceList: [] }))
				}
			}
		)
		}
	}, [selectedModelDash])

	useEffect(() => {
		!_.isEmpty(_.get(data, 'modelx_dashboard_config.dashboard_id.dashboard_id')) && fetchModelxDatasource()
	}, [fetchModelxDatasource, data])

	const fetchModelFields = useCallback((e, inputValue, dsId) => {
        const datasourceId =  !_.isEmpty(selectedModelDs) ? selectedModelDs?.ds_id : !_.isEmpty(dsId) && !_.isEqual(dsId, 'clear') && !_.isEqual(dsId, 'reset') ? dsId : _.get(data, 'modelx_dashboard_config.ds_id.ds_id')
        {!_.isEmpty(datasourceId) &&
        setModelFields((prevState) => ({ ...prevState, modelFieldsLoading: true, modelFieldsList: [] }))
			UserApi.fetchUserFields(
			datasourceId,
			'modelx',
			modelFieldCancelExecutor,
			(res) => {
				const list = _.get(res, 'data.data.fields', [])
				setModelFields((prevState) => ({ ...prevState, modelFieldsLoading: false, modelFieldsList: list || [] }))
			},
			(err) => {
				if (!Axios.isCancel(err)) {
					setModelFields((prevState) => ({ ...prevState, modelFieldsLoading: false, modelFieldsList: [] }))
				}
			}
		)
		}
	}, [selectedModelDs])

	useEffect(() => {
		!_.isEmpty(_.get(data, 'modelx_dashboard_config.ds_id.ds_id')) && fetchModelFields()
	}, [fetchModelFields, data])

	const fetchProjectDatasource = useCallback((e, inputValue, dsId) => {
        const dashboardId = !_.isEmpty(selectedProjectDash) ? selectedProjectDash?.dashboard_id : !_.isEmpty(dsId) && !_.isEqual(dsId, 'clear') && !_.isEqual(dsId, 'reset') ? dsId : _.get(data, 'project_dashboard_config.dashboard_id.dashboard_id')
        {!_.isEmpty(dashboardId) &&
        setProjectDatasource((prevState) => ({ ...prevState, projectDatasourceLoading: true, projectDatasourceList: [] }))
		UserApi.fetchUserDataSource(
            dashboardId,
			'project',
			projectDatasourceCancelExecutor,
			(res) => {
				const list = _.get(res, 'data.data.datasources', [])
				setProjectDatasource((prevState) => ({ ...prevState, projectDatasourceLoading: false, projectDatasourceList: list || [] }))
			},
			(err) => {
				if (!Axios.isCancel(err)) {
					setProjectDatasource((prevState) => ({ ...prevState, projectDatasourceLoading: false, projectDatasourceList: [] }))
				}
			}
		)
		}
	}, [selectedProjectDash])

	useEffect(() => {
		!_.isEmpty(_.get(data, 'project_dashboard_config.dashboard_id.dashboard_id')) && fetchProjectDatasource()
	}, [fetchProjectDatasource, data])

	const fetchProjectFields = useCallback((e, inputValue, dsId) => {
        const datasourceId =  !_.isEmpty(selectedProjectDs) ? selectedProjectDs?.ds_id : !_.isEmpty(dsId) && !_.isEqual(dsId, 'clear') && !_.isEqual(dsId, 'reset') ? dsId : _.get(data, 'project_dashboard_config.ds_id.ds_id')
        {!_.isEmpty(datasourceId) &&
        setProjectFields((prevState) => ({ ...prevState, projectFieldsLoading: true, projectFieldsList: [] }))
			UserApi.fetchUserFields(
			datasourceId,
			'project',
			projectFieldCancelExecutor,
			(res) => {
				const list = _.get(res, 'data.data.fields', [])
				setProjectFields((prevState) => ({ ...prevState, projectFieldsLoading: false, projectFieldsList: list || [] }))
			},
			(err) => {
				if (!Axios.isCancel(err)) {
					setProjectFields((prevState) => ({ ...prevState, projectFieldsLoading: false, projectFieldsList: [] }))
				}
			}
		)
		}
	}, [selectedProjectDs])

	useEffect(() => {
		!_.isEmpty(_.get(data, 'project_dashboard_config.ds_id.ds_id')) && fetchProjectFields()
	}, [fetchProjectFields, data])


	if (_.isEmpty(initVals)) {
		return null
	} else {
		return (
			<Grid container item xs={12}>
				<Validator initVals={initVals} onSubmit={handleSave} checkForChange={(currVal) => checkForChange(initVals, currVal)}>
					{({ resetForm }) => (
						<Form id='brandForm'>
							<Grid container item xs={12} lg={8} className={classes.root}>
								<Grid container alignItems='center' justifyContent='space-between'>
									<Grid item xs={6}>
										<Typography variant='subtitle1' className={classes.titleText}>
											Label Management
										</Typography>
									</Grid>
									{isEditAllowed && (
										<Grid item xs={6} container justifyContent='flex-end'>
											{isEditMode ? (
												<>
													<Button
														disableElevation
														disabled={!isEdited}
														variant='outlined'
														size='small'
														color='primary'
														type='reset'
														style={{ marginRight: 12 }}
														onClick={() => {
															resetForm()
															setIsEdited(false)
														}}
													>
														Cancel
													</Button>
													<Button disableElevation disabled={!isEdited} variant='contained' size='small' color='primary' type='submit'>
														Save
													</Button>
												</>
											) : (
												<Button disableElevation variant='contained' size='small' color='primary' onClick={() => setIsEditMode(true)}>
													Edit
												</Button>
											)}
										</Grid>
									)}
								</Grid>
							</Grid>
							<Grid container item xs={12} lg={8} className={classnames(classes.root)}>
								<Grid container alignItems='flex-start' className={classes.itemRow}>
									<Grid item xs={12} sm={6}>
										<Grid container alignItems='flex-start'>
											<Grid item xs={12}>
												<Typography variant='subtitle2'>Company Logo</Typography>
											</Grid>
											<Grid item xs={10} container>
												<Typography variant='caption' color='textSecondary'>
													The logo will be used in Header. So use an image with a minimum resolution of 320x80 px or higher with Aspect ratio of 4:1{' '}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field type='text' name='logoImg'>
											{({ field, form }) => (
												<File
													disabled={!isEditMode || !isEditAllowed}
													value={form.values.logoImg}
													name='logoImg'
													label='Active'
													field={field}
													form={form}
													acceptsExts={['png']}
													resolution={[320, 80]}
													placeholder={'Choose Logo'}
													imgRatioClass={classes.logoRatioClass}
												/>
											)}
										</Field>
									</Grid>
								</Grid>

								<Grid container alignItems='flex-start' className={classes.itemRow}>
									<Grid item xs={12} sm={6}>
										<Grid container alignItems='flex-start'>
											<Grid item xs={12}>
												<Typography variant='subtitle2'>Company Name</Typography>
											</Grid>
											<Grid item xs={10} container>
												<Typography variant='caption' color='textSecondary'>
													Also will be used in Page Title.{' '}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={6} container align='flex-start' className={classes.inputFieldContainer}>
										<Field type='text' name='labelTitle'>
											{({ field, form }) => (
												<TextField
													variant='outlined'
													margin='none'
													size='small'
													fullWidth
													name={field.name}
													onChange={(e) => {
														e.target.value === ' ' ? form.setFieldValue(field.name, '') : form.setFieldValue(field.name, e.target.value)
													}}
													onBlur={field.onBlur}
													value={field.value}
													className={classes.textField}
													disabled={!isEditMode || !isEditAllowed}
													helperText={form.errors[field.name]}
													error={Boolean(form.errors[field.name] && form.touched[field.name])}
												/>
											)}
										</Field>
									</Grid>
								</Grid>

								<Grid container alignItems='flex-start' className={classes.itemRow}>
									<Grid item xs={12} sm={6}>
										<Grid container alignItems='flex-start'>
											<Grid item xs={12} container alignItems='center'>
												<Typography variant='subtitle2' component='span'>
													Label Adjective
												</Typography>
												<Icon onClick={handleOpenPopper} className={classes.infoSpan}>
													info_outlined
												</Icon>
											</Grid>
											<Grid item xs={10}>
												<Typography variant='caption' color='textSecondary' component='span'>
													To be used in places like toggling between objective products only and all products.{' '}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={6} container align='flex-start' className={classes.inputFieldContainer}>
										<Field type='text' name='labelName'>
											{({ field, form }) => (
												<TextField
													variant='outlined'
													margin='none'
													size='small'
													fullWidth
													name={field.name}
													onChange={field.onChange}
													onBlur={field.onBlur}
													value={field.value}
													className={classes.textField}
													disabled={!isEditMode || !isEditAllowed}
													helperText={form.errors[field.name] && form.touched[field.name] && String(form.errors[field.name])}
													error={Boolean(form.errors[field.name] && form.touched[field.name])}
												/>
											)}
										</Field>
									</Grid>
								</Grid>

								<Grid container alignItems='flex-start' className={classes.itemRow}>
									<Grid item xs={12} sm={6}>
										<Grid container alignItems='flex-start'>
											<Grid item xs={12}>
												<Typography variant='subtitle2'>Favicon</Typography>
											</Grid>
											<Grid item xs={10} container>
												<Typography variant='caption' color='textSecondary'>
													Use 48x48px resolution image. Image must be one of png, jpg, ico formats.
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field type='text' name='logoFavicon'>
											{({ field, form }) => (
												<File
													disabled={!isEditMode || !isEditAllowed}
													value={form.values.logoFavicon}
													label='Fav icon'
													name='logoFavicon'
													field={field}
													form={form}
													acceptsExts={['png']}
													resolution={[48, 48]}
													placeholder={''}
													imgRatioClass={classes.faviconRatioClass}
												/>
											)}
										</Field>
									</Grid>
								</Grid>

								<Grid container alignItems='flex-start' className={classes.itemRow}>
									<Grid item xs={12} sm={6}>
										<Grid container alignItems='flex-start'>
											<Grid item xs={12}>
												<Typography variant='subtitle2'>Workflow URL</Typography>
											</Grid>
											<Grid item xs={10} container>
												<Typography variant='caption' color='textSecondary'>
													Set and Execute the workflow in the URL
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={6} container align='flex-start' className={classes.inputFieldContainer}>
										<Field type='text' name='workflowURL'>
											{({ field, form }) => (
												<TextField
													variant='outlined'
													margin='none'
													size='small'
													fullWidth
													name={field.name}
													onChange={(e) => {
														e.target.value === ' ' ? form.setFieldValue(field.name, '') : form.setFieldValue(field.name, e.target.value)
													}}
													onBlur={field.onBlur}
													value={field.value}
													className={classes.textField}
													disabled={!isEditMode || !isEditAllowed}
													helperText={form.errors[field.name]}
													error={Boolean(form.errors[field.name] && form.touched[field.name])}
													InputProps={{
														endAdornment: isUserHasAdminRole && field.value && !form.errors[field.name] && (
															<InputAdornment position='end'>
																<IconButton
																	size='small'
																	aria-label='open workflow'
																	onClick={(e) => {
																		openNewLinkOnNewTab('Wersel Workflow', field.value)
																	}}
																	edge='end'
																>
																	<Icon fontSize='small'>open_in_new</Icon>
																</IconButton>
															</InputAdornment>
														),
													}}
												/>
											)}
										</Field>
									</Grid>
								</Grid>

								<Grid container alignItems='flex-start' className={classes.itemRow}>
									<Grid item xs={12} sm={6}>
										<Grid container alignItems='flex-start'>
											<Grid item xs={12}>
												<Typography variant='subtitle2'>Dataflow URL</Typography>
											</Grid>
											<Grid item xs={10} container>
												<Typography variant='caption' color='textSecondary'>
													Set and Execute the dataflow in the URL
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={6} container align='flex-start' className={classes.inputFieldContainer}>
										<Field type='text' name='dataflowURL'>
											{({ field, form }) => (
												<TextField
													variant='outlined'
													margin='none'
													size='small'
													fullWidth
													name={field.name}
													onChange={(e) => {
														e.target.value === ' ' ? form.setFieldValue(field.name, '') : form.setFieldValue(field.name, e.target.value)
													}}
													onBlur={field.onBlur}
													value={field.value}
													className={classes.textField}
													disabled={!isEditMode || !isEditAllowed}
													helperText={form.errors[field.name]}
													InputProps={{
														endAdornment: isUserHasAdminRole && field.value && !form.errors[field.name] && (
															<InputAdornment position='end'>
																<IconButton
																	size='small'
																	aria-label='open dataflow'
																	onClick={(e) => {
																		openNewLinkOnNewTab('Wersel Dataflow', field.value)
																	}}
																	edge='end'
																>
																	<Icon fontSize='small'>open_in_new</Icon>
																</IconButton>
															</InputAdornment>
														),
													}}
													error={Boolean(form.errors[field.name] && form.touched[field.name])}
												/>
											)}
										</Field>
									</Grid>
								</Grid>
								<Grid container alignItems='flex-start' className={classes.itemRow}>
									<Grid item xs={12} sm={6}>
										<Grid container alignItems='flex-start'>
											<Grid item xs={12} className={classes.configContent}>
												<Typography variant='subtitle2'>People Dashboard Configuration</Typography>
												<Field
													type='text'
													name='peoplex_enable_dashboard'
													render={({ field, form }) => {
														return (
														<FormControlLabel
															// value='Enable Dashboard Configuration'
															name='Enable Dashboard Configuration'
															control={<Switch color='primary' />}
															label=''
															labelPlacement='start'
															onChange={(e, v) => {form.setFieldValue(field.name, e.target.checked)}}
															checked={field.value}
															disabled={!isEditMode || !isEditAllowed}
														/>
														)
													}}
												/>
											</Grid>
											<Grid item xs={10} container>
												<Typography variant='caption' color='textSecondary'>
												Configure and View the Dashboard in the module
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={6} align='flex-start' className={classes.selectFieldContainer}>
										<Grid container spacing={2} alignItems='flex-start' className={classes.SelectField}>
											<Grid item xs={12} sm={6}>
												<Field
													type='text'
													name='peoplex_dashboard_id'
													render={({ field, form }) => {
														return (
															<Autocomplete
																title='peoplex_dashboard_id'
																name='peoplex_dashboard_id'
																options={peopleDashboardList}
																size={'small'}
																open={peopleDashOpen}
																onOpen={() => {
																	setPeopleDashOpen(true)
																}}
																onClose={() => {
																	setPeopleDashOpen(false)
																}}
																// onBlur={toggleAssigneeList}
																// disableClearable
																// popupIcon={null}
																fullWidth
																openOnFocus
																// onInputChange={fetchPeopleDashboard}
																getOptionLabel={(option) => option ? option?.dashboard_name : ''}
																getOptionSelected={(option, value) => option?.dashboard_id === value}
																renderOption={(option, index) => {
																	return (
																		<Grid key={index} container alignItems='center'>
																			<Grid item xs>
																				<Typography variant='body2'>{option?.dashboard_name}</Typography>
																			</Grid>
																		</Grid>
																	)
																}}
																filterSelectedOptions
																onChange={(e, v) => {
																	// onChange(e, v)
																	// toggleAssigneeList()
																	// formik.setFieldValue('dashboard_id', v)
																	// formik.setFieldValue('ds_id', null)
																	// formik.setFieldValue('field_name', '')
																	form.setFieldValue(field.name, v)
																	form.setFieldValue('peoplex_ds_id', null)
																	form.setFieldValue('peoplex_field_name', '')
																	setSelectedPeopleDash(v)
																	const dashboardId = _.get(v, 'dashboard_id')
																	if (!_.isEmpty(dashboardId)) {
																	fetchPeoplexDatasource({}, '', dashboardId)
																	}
																}}
																// disabled={!enabled}
																disabled={!isEditMode || !isEditAllowed || _.isEqual(form.values.peoplex_enable_dashboard, false)}
																value={field.value}
																renderInput={(params) => <TextField autoFocus {...params} variant='outlined' label='Select Dashboard' 
																helperText={form.errors[field.name]}
																error={form.errors[field.name] && form.touched[field.name]}
																/>}
															/>
														)
													}}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Field
													type='text'
													name='peoplex_ds_id'
													render={({ field, form }) => {
														return (
															<Autocomplete
																title='peoplex_ds_id'
																name='peoplex_ds_id'
																options={peopleDatasourceList}
																size={'small'}
																open={peopleDsOpen}
																onOpen={() => {
																	setPeopleDsOpen(true)
																}}
																onClose={() => {
																	setPeopleDsOpen(false)
																}}
																// onBlur={toggleAssigneeList}
																// disableClearable
																// popupIcon={null}
																fullWidth
																openOnFocus
																disabled={!isEditMode || !isEditAllowed || _.isEqual(form.values.peoplex_enable_dashboard, false) || _.isEmpty(form.values.peoplex_dashboard_id)}
																// onInputChange={fetchPeoplexDatasource}
																getOptionLabel={(option) => option ? option?.ds_name : ''}
																getOptionSelected={(option, value) => option === value}
																renderOption={(option, index) => {
																	return (
																		<Grid key={index} container alignItems='center'>
																			<Grid item xs>
																				<Typography variant='body2'>{option?.ds_name}</Typography>
																			</Grid>
																		</Grid>
																	)
																}}
																filterSelectedOptions
																onChange={(e, v) => {
																	// onChange(e, v)
																	// toggleAssigneeList()
																	form.setFieldValue(field.name, v)
																	form.setFieldValue('peoplex_field_name', '')
																	setSelectedPeopleDs(v)
																	const dsId = _.get(v, 'ds_id')
																	if (!_.isEmpty(dsId)) {
																	fetchPeopleFields({}, '', dsId)
																	}
																}}
																value={field.value}
																renderInput={(params) => <TextField autoFocus {...params} variant='outlined' label='Select Data Source' 
																helperText={form.errors[field.name]}
																error={form.errors[field.name] && form.touched[field.name]}
																/>}
															/>
														)
													}}
												/>
											</Grid>
											<Grid item xs={12} sm={12} className={classes.selectFieldContainer}>
												<Field
													type='text'
													name='peoplex_field_name'
													render={({ field, form }) => {
														return (
															<Autocomplete
																title='peoplex_field_name'
																name='peoplex_field_name'
																options={peopleFieldsList}
																size={'small'}
																open={peopleFieldOpen}
																onOpen={() => {
																	setPeopleFieldOpen(true)
																}}
																onClose={() => {
																	setPeopleFieldOpen(false)
																}}
																// onInputChange={fetchPeopleFields}
																// onBlur={toggleAssigneeList}
																// disableClearable
																// popupIcon={null}
																fullWidth
																openOnFocus
																disabled={!isEditMode || !isEditAllowed || _.isEqual(form.values.peoplex_enable_dashboard, false) || _.isEmpty(form.values.peoplex_ds_id)}
																getOptionLabel={(option) => option ? option : ''}
																getOptionSelected={(option, value) => option === value}
																renderOption={(option, index) => {
																	return (
																		<Grid key={index} container alignItems='center'>
																			<Grid item xs>
																				<Typography variant='body2'>{option}</Typography>
																			</Grid>
																		</Grid>
																	)
																}}
																filterSelectedOptions
																onChange={(e, v) => {
																	// onChange(e, v)
																	// toggleAssigneeList()
																	form.setFieldValue(field.name, v)
																}}
																value={field.value}
																renderInput={(params) => <TextField autoFocus {...params} variant='outlined' label='Select Dashboard Filter Column' 
																helperText={form.errors[field.name]}
																error={form.errors[field.name] && form.touched[field.name]}
																/>}
															/>
														)
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid container alignItems='flex-start' className={classes.itemRow}>
									<Grid item xs={12} sm={6}>
										<Grid container alignItems='flex-start'>
											<Grid item xs={12} className={classes.configContent}>
												<Typography variant='subtitle2'>Models Dashboard Configuration</Typography>
												<Field
													type='text'
													name='modelx_enable_dashboard'
													render={({ field, form }) => {
														return (
														<FormControlLabel
															// value='Enable Dashboard Configuration'
															name='Enable Dashboard Configuration'
															control={<Switch color='primary' />}
															label=''
															labelPlacement='start'
															onChange={(e, v) => {form.setFieldValue(field.name, e.target.checked)}}
															checked={field.value}
															disabled={!isEditMode || !isEditAllowed}
														/>
														)
													}}
												/>
											</Grid>
											<Grid item xs={10} container>
												<Typography variant='caption' color='textSecondary'>
												Configure and View the Dashboard in the module
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={6} align='flex-start' className={classes.selectFieldContainer}>
										<Grid container spacing={2} alignItems='flex-start' className={classes.SelectField}>
											<Grid item xs={12} sm={6}>
												<Field
													type='text'
													name='modelx_dashboard_id'
													render={({ field, form }) => {
														return (
															<Autocomplete
																title='modelx_dashboard_id'
																name='modelx_dashboard_id'
																options={peopleDashboardList}
																size={'small'}
																open={modelDashOpen}
																onOpen={() => {
																	setModelDashOpen(true)
																}}
																onClose={() => {
																	setModelDashOpen(false)
																}}
																// onBlur={toggleAssigneeList}
																// disableClearable
																// popupIcon={null}
																fullWidth
																openOnFocus
																// onInputChange={fetchModelDashboard}
																getOptionLabel={(option) => option ? option?.dashboard_name : ''}
																getOptionSelected={(option, value) => option?.dashboard_id === value}
																renderOption={(option, index) => {
																	return (
																		<Grid key={index} container alignItems='center'>
																			<Grid item xs>
																				<Typography variant='body2'>{option?.dashboard_name}</Typography>
																			</Grid>
																		</Grid>
																	)
																}}
																filterSelectedOptions
																onChange={(e, v) => {
																	// onChange(e, v)
																	// toggleAssigneeList()
																	// formik.setFieldValue('dashboard_id', v)
																	// formik.setFieldValue('ds_id', null)
																	// formik.setFieldValue('field_name', '')
																	form.setFieldValue(field.name, v)
																	form.setFieldValue('modelx_ds_id', null)
																	form.setFieldValue('modelx_field_name', '')
																	setSelectedModelDash(v)
																	const dashboardId = _.get(v, 'dashboard_id')
																	if (!_.isEmpty(dashboardId)) {
																	fetchModelxDatasource({}, '', dashboardId)
																	}
																}}
																disabled={!isEditMode || !isEditAllowed || _.isEqual(form.values.modelx_enable_dashboard, false)}
																value={field.value}
																renderInput={(params) => <TextField autoFocus {...params} variant='outlined' label='Select Dashboard' 
																helperText={form.errors[field.name]}
																error={form.errors[field.name] && form.touched[field.name]}
																/>}
															/>
														)
													}}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Field
													type='text'
													name='modelx_ds_id'
													render={({ field, form }) => {
														return (
															<Autocomplete
																title='modelx_ds_id'
																name='modelx_ds_id'
																options={modelDatasourceList}
																size={'small'}
																open={modelDsOpen}
																onOpen={() => {
																	setModelDsOpen(true)
																}}
																onClose={() => {
																	setModelDsOpen(false)
																}}
																// onBlur={toggleAssigneeList}
																// disableClearable
																// popupIcon={null}
																fullWidth
																openOnFocus
																disabled={!isEditMode || !isEditAllowed || _.isEqual(form.values.modelx_enable_dashboard, false) || _.isEmpty(form.values.modelx_dashboard_id)}
																// onInputChange={fetchModelxDatasource}
																getOptionLabel={(option) => option ? option?.ds_name : ''}
																getOptionSelected={(option, value) => option === value}
																renderOption={(option, index) => {
																	return (
																		<Grid key={index} container alignItems='center'>
																			<Grid item xs>
																				<Typography variant='body2'>{option?.ds_name}</Typography>
																			</Grid>
																		</Grid>
																	)
																}}
																filterSelectedOptions
																onChange={(e, v) => {
																	// onChange(e, v)
																	// toggleAssigneeList()
																	form.setFieldValue(field.name, v)
																	form.setFieldValue('modelx_field_name', '')
																	setSelectedModelDs(v)
																	const dsId = _.get(v, 'ds_id')
																	if (!_.isEmpty(dsId)) {
																	fetchModelFields({}, '', dsId)
																	}
																}}
																value={field.value}
																renderInput={(params) => <TextField autoFocus {...params} variant='outlined' label='Select Data Source' 
																helperText={form.errors[field.name]}
																error={form.errors[field.name] && form.touched[field.name]}
																/>}
															/>
														)
													}}
												/>
											</Grid>
											<Grid item xs={12} sm={12} className={classes.selectFieldContainer}>
												<Field
													type='text'
													name='modelx_field_name'
													render={({ field, form }) => {
														return (
															<Autocomplete
																title='modelx_field_name'
																name='modelx_field_name'
																options={modelFieldsList}
																size={'small'}
																open={modelFieldOpen}
																onOpen={() => {
																	setModelFieldOpen(true)
																}}
																onClose={() => {
																	setModelFieldOpen(false)
																}}
																// onInputChange={fetchModelFields}
																// onBlur={toggleAssigneeList}
																// disableClearable
																// popupIcon={null}
																fullWidth
																openOnFocus
																disabled={!isEditMode || !isEditAllowed || _.isEqual(form.values.modelx_enable_dashboard, false) || _.isEmpty(form.values.modelx_ds_id)}
																getOptionLabel={(option) => option ? option : ''}
																getOptionSelected={(option, value) => option === value}
																renderOption={(option, index) => {
																	return (
																		<Grid key={index} container alignItems='center'>
																			<Grid item xs>
																				<Typography variant='body2'>{option}</Typography>
																			</Grid>
																		</Grid>
																	)
																}}
																filterSelectedOptions
																onChange={(e, v) => {
																	// onChange(e, v)
																	// toggleAssigneeList()
																	form.setFieldValue(field.name, v)
																}}
																value={field.value}
																renderInput={(params) => <TextField autoFocus {...params} variant='outlined' label='Select Dashboard Filter Column' 
																helperText={form.errors[field.name]}
																error={form.errors[field.name] && form.touched[field.name]}
																/>}
															/>
														)
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid container alignItems='flex-start' className={classes.itemRow}>
									<Grid item xs={12} sm={6}>
										<Grid container alignItems='flex-start'>
											<Grid item xs={12} className={classes.configContent}>
												<Typography variant='subtitle2'>Projects Dashboard Configuration</Typography>
												<Field
													type='text'
													name='project_enable_dashboard'
													render={({ field, form }) => {
														return (
														<FormControlLabel
															// value='Enable Dashboard Configuration'
															name='Enable Dashboard Configuration'
															control={<Switch color='primary' />}
															label=''
															labelPlacement='start'
															onChange={(e, v) => {form.setFieldValue(field.name, e.target.checked)}}
															checked={field.value}
															disabled={!isEditMode || !isEditAllowed}
														/>
														)
													}}
												/>
											</Grid>
											<Grid item xs={10} container>
												<Typography variant='caption' color='textSecondary'>
												Configure and View the Dashboard in the module
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={6} align='flex-start' className={classes.selectFieldContainer}>
										<Grid container spacing={2} alignItems='flex-start' className={classes.SelectField}>
											<Grid item xs={12} sm={6}>
												<Field
													type='text'
													name='project_dashboard_id'
													render={({ field, form }) => {
														return (
															<Autocomplete
																title='project_dashboard_id'
																name='project_dashboard_id'
																options={peopleDashboardList}
																size={'small'}
																open={projectDashOpen}
																onOpen={() => {
																	setProjectDashOpen(true)
																}}
																onClose={() => {
																	setProjectDashOpen(false)
																}}
																// onBlur={toggleAssigneeList}
																// disableClearable
																// popupIcon={null}
																fullWidth
																openOnFocus
																// onInputChange={fetchModelDashboard}
																getOptionLabel={(option) => option ? option?.dashboard_name : ''}
																getOptionSelected={(option, value) => option?.dashboard_id === value}
																renderOption={(option, index) => {
																	return (
																		<Grid key={index} container alignItems='center'>
																			<Grid item xs>
																				<Typography variant='body2'>{option?.dashboard_name}</Typography>
																			</Grid>
																		</Grid>
																	)
																}}
																filterSelectedOptions
																onChange={(e, v) => {
																	// onChange(e, v)
																	// toggleAssigneeList()
																	// formik.setFieldValue('dashboard_id', v)
																	// formik.setFieldValue('ds_id', null)
																	// formik.setFieldValue('field_name', '')
																	form.setFieldValue(field.name, v)
																	form.setFieldValue('project_ds_id', null)
																	form.setFieldValue('project_field_name', '')
																	setSelectedProjectDash(v)
																	const dashboardId = _.get(v, 'dashboard_id')
																	if (!_.isEmpty(dashboardId)) {
																	fetchProjectDatasource({}, '', dashboardId)
																	}
																}}
																disabled={!isEditMode || !isEditAllowed || _.isEqual(form.values.project_enable_dashboard, false)}
																value={field.value}
																renderInput={(params) => <TextField autoFocus {...params} variant='outlined' label='Select Dashboard' 
																helperText={form.errors[field.name]}
																error={form.errors[field.name] && form.touched[field.name]}
																/>}
															/>
														)
													}}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Field
													type='text'
													name='project_ds_id'
													render={({ field, form }) => {
														return (
															<Autocomplete
																title='project_ds_id'
																name='project_ds_id'
																options={projectDatasourceList}
																size={'small'}
																open={projectDsOpen}
																onOpen={() => {
																	setProjectDsOpen(true)
																}}
																onClose={() => {
																	setProjectDsOpen(false)
																}}
																// onBlur={toggleAssigneeList}
																// disableClearable
																// popupIcon={null}
																fullWidth
																openOnFocus
																disabled={!isEditMode || !isEditAllowed || _.isEqual(form.values.project_enable_dashboard, false) || _.isEmpty(form.values.project_dashboard_id)}
																// onInputChange={fetchModelxDatasource}
																getOptionLabel={(option) => option ? option?.ds_name : ''}
																getOptionSelected={(option, value) => option === value}
																renderOption={(option, index) => {
																	return (
																		<Grid key={index} container alignItems='center'>
																			<Grid item xs>
																				<Typography variant='body2'>{option?.ds_name}</Typography>
																			</Grid>
																		</Grid>
																	)
																}}
																filterSelectedOptions
																onChange={(e, v) => {
																	// onChange(e, v)
																	// toggleAssigneeList()
																	form.setFieldValue(field.name, v)
																	form.setFieldValue('project_field_name', '')
																	setSelectedProjectDs(v)
																	const dsId = _.get(v, 'ds_id')
																	if (!_.isEmpty(dsId)) {
																	fetchProjectFields({}, '', dsId)
																	}
																}}
																value={field.value}
																renderInput={(params) => <TextField autoFocus {...params} variant='outlined' label='Select Data Source' 
																helperText={form.errors[field.name]}
																error={form.errors[field.name] && form.touched[field.name]}
																/>}
															/>
														)
													}}
												/>
											</Grid>
											<Grid item xs={12} sm={12} className={classes.selectFieldContainer}>
												<Field
													type='text'
													name='project_field_name'
													render={({ field, form }) => {
														return (
															<Autocomplete
																title='project_field_name'
																name='project_field_name'
																options={projectFieldsList}
																size={'small'}
																open={projectFieldOpen}
																onOpen={() => {
																	setProjectFieldOpen(true)
																}}
																onClose={() => {
																	setProjectFieldOpen(false)
																}}
																// onInputChange={fetchModelFields}
																// onBlur={toggleAssigneeList}
																// disableClearable
																// popupIcon={null}
																fullWidth
																openOnFocus
																disabled={!isEditMode || !isEditAllowed || _.isEqual(form.values.project_enable_dashboard, false) || _.isEmpty(form.values.project_ds_id)}
																getOptionLabel={(option) => option ? option : ''}
																getOptionSelected={(option, value) => option === value}
																renderOption={(option, index) => {
																	return (
																		<Grid key={index} container alignItems='center'>
																			<Grid item xs>
																				<Typography variant='body2'>{option}</Typography>
																			</Grid>
																		</Grid>
																	)
																}}
																filterSelectedOptions
																onChange={(e, v) => {
																	// onChange(e, v)
																	// toggleAssigneeList()
																	form.setFieldValue(field.name, v)
																}}
																value={field.value}
																renderInput={(params) => <TextField autoFocus {...params} variant='outlined' label='Select Dashboard Filter Column' 
																helperText={form.errors[field.name]}
																error={form.errors[field.name] && form.touched[field.name]}
																/>}
															/>
														)
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Form>
					)}
				</Validator>
				<Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
					<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
						<Paper>
							<img src={labelAdjectiveImg1} className={classes.popperImage} alt='Example for Label Adjective' />
						</Paper>
					</ClickAwayListener>
				</Popper>
			</Grid>
		)
	}
}

export default BasicDetails
