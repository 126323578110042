import {
	Button,
	ButtonBase,
	Fab,
	Grid,
	Icon,
	makeStyles,
	Paper,
	TablePagination,
	Typography,
	IconButton,
	Popover,
	MenuList,
	MenuItem,
	ListItemIcon,
} from '@material-ui/core'
import classnames from 'classnames'
import SearchBar from 'components/SearchBox'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import _ from 'lodash'
import ProjectFilterDialog from '../ProjectFilterDialog'
// import Skeleton from '@material-ui/lab/Skeleton'
import { NoRecordsIllustration } from 'illustrators'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import LoadingIndicator from 'components/LoadingIndicator'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4, 3, 1, 3),
		position: 'relative',
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(2, 2, 1, 2),
		},
	},
	title: {
		verticalAlign: 'middle',
		color: '#666',
		letterSpacing: '2px',
		fontSize: theme.typography.pxToRem(22),
		[theme.breakpoints.down('sm')]: {
			paddingBottom: theme.spacing(2),
		},
	},
	titleIco: {
		verticalAlign: 'middle',
		fontSize: '30px',
		marginRight: theme.spacing(2),
	},
	searchBox: {
		padding: 0,
		flex: '1',
		marginRight: theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
			marginRight: theme.spacing(0),
		},
		'& .searchbar': {
			maxWidth: 'unset',
		},
	},
	listContainer: {
		flexGrow: 1,
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2),
		},
	},
	paper: {
		width: '100%',
		padding: theme.spacing(1, 2, 1.5, 2),
		height: 76,
		textAlign: 'left',
		[theme.breakpoints.down('xs')]: {
			height: 64,
			padding: theme.spacing(1, 1.5, 1, 1.5),
		},
		overflowWrap: 'anywhere',
	},
	paperContent: {
		display: 'flex',
		flexDirection: 'row',
		// alignItems: 'center',
		justifyContent: 'space-between',
	},
	projectTitle: {
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
	},
	glassCode: {
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		marginTop: '0.35em',
	},
	glassTitle: {
		fontSize: theme.typography.pxToRem(18),
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		[theme.breakpoints.down('xs')]: {
			fontSize: theme.typography.pxToRem(16),
		},
	},
	btnBase: {
		width: '100%',
	},
	listItemIcoRoot: {
		minWidth: '28px',
	},
	unpinIcon: {
		transform: 'rotate(45deg)',
	},
	unpinIconGlass: {
		transform: 'rotate(45deg)',
	},
	moreBtn: {
		display: 'flex',
		flexDirection: 'column',
		'& .MuiButton-textSizeSmall': {
			textTransform: 'capitalize',
			padding: '4px 0px',
		},
	},
	moreIcon: {
		"&:hover": {
			margin: '0px 8px 0px 8px',
		},
	},
	menuIcon: {
		paddingRight: '12px',
		fontSize: '1.2em !important',
	},
	menuText: {
		fontSize: '13px',
	},
	rightContent: {
		display: 'flex',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'row',
		},
	},
	addBtn: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	fabAddBtn: {
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	paginationToolbar: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-end',
		[theme.breakpoints.down('sm')]: {
			paddingBottom: theme.spacing(14),
		},
		[theme.breakpoints.down('xs')]: {
			justifyContent: 'center',
			paddingRight: 0,
			paddingLeft: 0,
		},
	},
	fabFilterBtn: {
		position: 'fixed',
		bottom: theme.spacing(10),
		right: theme.spacing(2.5),
	},
	emptyIco: {
		margin: '0 auto',
		fontSize: theme.typography.pxToRem(80),
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(2),
		display: 'block',
	},
}))

GlassListComponent.propTypes = {
	listData: PropTypes.arrayOf(
		PropTypes.shape({
			glass_id: PropTypes.string,
			glass_name: PropTypes.string,
			project_name: PropTypes.string,
			tu_name: PropTypes.string,
		})
	),
	isSmallDevice: PropTypes.bool.isRequired,
	projFilterVal: PropTypes.arrayOf(
		PropTypes.shape({
			project_name: PropTypes.string,
			project_id: PropTypes.string,
		})
	),
	onClickAddBtn: PropTypes.func.isRequired,
	onChangeProjFilter: PropTypes.func.isRequired,
	limit: PropTypes.number.isRequired,
	offset: PropTypes.number.isRequired,
	totalCount: PropTypes.number.isRequired,
	searchTxt: PropTypes.string.isRequired,
	onChangePage: PropTypes.func.isRequired,
	onChangeRowsPerPage: PropTypes.func.isRequired,
	onChangeSearchBox: PropTypes.func.isRequired,
	onClickListItem: PropTypes.func.isRequired,
	isCreateAllowed: PropTypes.bool.isRequired,
}

function GlassListComponent({
	isSmallDevice,
	listData,
	loading,
	projFilterVal,
	onChangePage,
	onChangeRowsPerPage,
	isCreateAllowed,
	isEditAllowed,
	isDeleteAllowed,
    isDashXViewAllowed,
	searchTxt,
	limit,
	offset,
	totalCount,
	onChangeProjFilter,
	onClickAddBtn,
	onChangeSearchBox,
	onClickListItem,
	onClickEdit,
	onClickDelete,
	onClickPin,
	onClickUnpin,
	history,
}) {
	const classes = useStyles()

	const [openFilterDialog, setOpenFilterDialog] = useState(false)

	const toggleFilterDialog = () => {
		setOpenFilterDialog(!openFilterDialog)
	}

	return (
		<div className={classes.root}>
			<GlassListHeader
				searchTxt={searchTxt}
				onChangeSearchBox={onChangeSearchBox}
				classes={classes}
				onClickAddBtn={onClickAddBtn}
				showAddBtn={isCreateAllowed}
				disableAddBtn={loading}
			/>
			<GlassesList
				loading={loading}
				isEditAllowed={isEditAllowed}
				isDeleteAllowed={isDeleteAllowed}
                isDashXViewAllowed={isDashXViewAllowed}
				listData={listData}
				onClickListItem={onClickListItem}
				classes={classes}
				onClickEdit={onClickEdit}
				onClickDelete={onClickDelete}
				onClickPin={onClickPin}
				onClickUnpin={onClickUnpin}
				history={history}
			/>
			{!loading && _.isEmpty(listData) && (
				<>
					<NoRecordsIllustration className={classes.emptyIco} />
					<Typography variant='body1' component='div' align='center' color='textSecondary'>
						No Boards Found
					</Typography>
				</>
			)}
			{!loading && !_.isEmpty(listData) && (
				<TablePagination
					component='div'
					count={totalCount}
					page={offset / limit}
					rowsPerPageOptions={[12, 24, 48]}
					classes={{ toolbar: classes.paginationToolbar }}
					className={classes.tblPagination}
					onChangePage={onChangePage}
					rowsPerPage={limit}
					onChangeRowsPerPage={onChangeRowsPerPage}
				/>
			)}
			{isSmallDevice && (
				<>
					<Fab key={'filter'} onClick={toggleFilterDialog} color='default' size='small' className={classes.fabFilterBtn}>
						<Icon>filter_list</Icon>
					</Fab>
					{isCreateAllowed && (
						<Fab key={'add'} onClick={onClickAddBtn} size='medium' color='primary' className={classes.fabAddBtn}>
							<Icon>add</Icon>
						</Fab>
					)}
					<ProjectFilterDialog
						key={'filter-dialog'}
						projFilterVal={projFilterVal}
						onChangeProjFilter={onChangeProjFilter}
						open={openFilterDialog}
						handleClose={toggleFilterDialog}
					/>
				</>
			)}
		</div>
	)
}

function GlassListHeader({ searchTxt, onChangeSearchBox, classes, onClickAddBtn, showAddBtn, disableAddBtn = false }) {
	return (
		<Grid container>
			<Grid item xs={12} sm={12} md={6}>
				<Typography variant='h5' className={classes.title}>
					<Icon className={classes.titleIco}>view_column</Icon> Boards
				</Typography>
			</Grid>
			<Grid item xs={12} sm={12} md={6} className={classes.rightContent}>
				<SearchBar onChange={onChangeSearchBox} size='small' placeholder='Search Boards' containerClassName={classes.searchBox} />
				{showAddBtn && (
					<Button
						onClick={onClickAddBtn}
						disabled={disableAddBtn}
						className={classes.addBtn}
						size='small'
						color='primary'
						variant='contained'
						disableElevation
					>
						Add New
					</Button>
				)}
			</Grid>
		</Grid>
	)
}

function GlassesList({ loading, onClickListItem, listData, isEditAllowed, isDeleteAllowed, isDashXViewAllowed, onClickEdit, onClickDelete, onClickPin, onClickUnpin, history }) {
	const classes = useStyles()
	const [popOverState, setPopOverState] = useState({
		open: false,
		anchorEl: null,
		data: {},
	})
	const { open, anchorEl, data } = popOverState
	const handleOpenPopover = (event, data, dashboardId) => {
		event.stopPropagation()
		setPopOverState((prevState) => ({ ...prevState, anchorEl: event.currentTarget, open: true, data, dashboardId }))
	}
	const handleClose = () => {
		setPopOverState((prevState) => ({ ...prevState, anchorEl: null, open: false, data: {}, dashboardId: null }))
	}

	const closePopover = useCallback(() => {
		setPopOverState((popOverState) => ({ ...popOverState, open: false }))
	}, [])

	const editFxn = () => {
		closePopover()
		onClickEdit({ ...data })
	}
	const deleteFxn = () => {
		closePopover()
		onClickDelete({ ...data })
	}
	const pinFxn = () => {
		closePopover()
		onClickPin({ ...data }, true)
	}
	const unpinFxn = () => {
		closePopover()
		onClickUnpin({ ...data }, false)
	}
	const pinBtnFxn = (e, glass) => {
		e.stopPropagation()
		closePopover()
		onClickPin({ ...glass }, true)
	}
	const unpinBtnFxn = (e, glass) => {
		e.stopPropagation()
		closePopover()
		onClickUnpin({ ...glass }, false)
	}

	const onClickDashboard = () => {
		history.push(`dashx/view/${popOverState?.dashboardId}?stack=1`)
	}

	return (
		<Grid container className={classes.listContainer} spacing={2}>
			{loading ? (
				//  _.map(_.range(0, 3), (index) => (
				// 		<Grid key={index} item xs={12} sm={6} md={4}>
				// 			<Skeleton variant='rect' width={'100%'} height={100} />
				// 		</Grid>
				//   ))
				<LoadingIndicator />
			) : (
				_.map(listData, (glass) => (
					<Grid key={_.get(glass, 'glass_code')} item xs={12} sm={6} md={4}>
						<ButtonBase
							className={classes.btnBase}
							onClick={() => {
								onClickListItem(_.get(glass, 'glass_code'))
							}}
							component='div'
						>
							<Paper variant='outlined' className={classes.paper}>
								<div className={classes.paperContent}>
									<div>
										<Typography variant='caption' className={classes.projectTitle} color='textSecondary' gutterBottom>
											{_.get(glass, 'project_name')}
										</Typography>
										<Typography variant='h5' className={classes.glassTitle} component='h2'>
											{_.get(glass, 'glass_name')}
										</Typography>
										<Typography variant='caption' className={classes.glassCode} color='textSecondary' gutterBottom>
											{_.get(glass, 'glass_code')}
										</Typography>
									</div>
									{/* {isEditAllowed || isDeleteAllowed ? ( */}
										<div className={classes.moreBtn}>
											<IconButton
												aria-label='dots'
												onClick={(event) => {
													handleOpenPopover(event, glass, glass?.dashboard?.dashboard_id)
												}}
												className={classes.moreIcon}
											>
												<MoreHorizIcon/>
											</IconButton>
											{_.isEqual(_.get(glass, 'is_pinned'), true) &&
											<Button color='primary' size='small' onClick={(e)=> unpinBtnFxn(e, glass)}>
													<Icon className={classes.unpinIconGlass} fontSize='small'>
														push_pin
													</Icon>
													{'  '}
													&nbsp; unpin
											</Button>}
											{_.isEqual(_.get(glass, 'is_pinned'), false) &&
											<Button color='primary' size='small' onClick={(e)=> pinBtnFxn(e, glass)}>
													<Icon fontSize='small'>push_pin</Icon>
													{'  '}
													&nbsp; pin
											</Button>}
										</div>
									{/* ) : null} */}
								</div>
							</Paper>
						</ButtonBase>
					</Grid>
				))
			)}
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<MenuList autoFocusItem={open} id='options_list'>
				    {_.isEqual(data.is_pinned, true) && (
						<MenuItem dense={true} onClick={unpinFxn}>
							<ListItemIcon classes={{ root: classes.listItemIcoRoot }}>
								<Icon className={classnames(classes.menuIcon, classes.unpinIcon)} fontSize='small'>
									push_pin
								</Icon>
							</ListItemIcon>
							<Typography className={classes.menuText} variant='inherit'>
								Unpin
							</Typography>
						</MenuItem>
					)}
					{_.isEqual(data.is_pinned, false) && (
						<MenuItem dense={true} onClick={pinFxn}>
							<ListItemIcon classes={{ root: classes.listItemIcoRoot }}>
								<Icon className={classes.menuIcon} fontSize='small'>
									push_pin
								</Icon>
							</ListItemIcon>
							<Typography className={classes.menuText} variant='inherit'>
								Pin
							</Typography>
						</MenuItem>
					)}
					{isEditAllowed && (
						<MenuItem dense={true} onClick={editFxn}>
							<ListItemIcon classes={{ root: classes.listItemIcoRoot }}>
								<Icon className={classes.menuIcon} fontSize='small'>
									edit
								</Icon>
							</ListItemIcon>
							<Typography className={classes.menuText} variant='inherit'>
								Edit
							</Typography>
						</MenuItem>
					)}
					{isDeleteAllowed && (
						<MenuItem dense={true} onClick={deleteFxn}>
							<ListItemIcon classes={{ root: classes.listItemIcoRoot }}>
								<Icon className={classes.menuIcon} fontSize='small'>
									delete
								</Icon>
							</ListItemIcon>
							<Typography className={classes.menuText} variant='inherit'>
								Delete
							</Typography>
						</MenuItem>
					)}
					{popOverState?.dashboardId && popOverState?.data?.dashboard?.dashboard_config && isDashXViewAllowed && (
						<MenuItem dense={true} onClick={onClickDashboard}>
							<ListItemIcon classes={{ root: classes.listItemIcoRoot }}>
								<Icon className={classes.menuIcon} fontSize='small'>
									dashboard
								</Icon>
							</ListItemIcon>
							<Typography className={classes.menuText} variant='inherit'>
								View Dashboard
							</Typography>
						</MenuItem>
					)}
				</MenuList>
			</Popover>
		</Grid>
	)
}

export default GlassListComponent
