import React, { useState } from "react"
import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'
import _ from 'lodash'

const config = {
  endpoint: API_ENDPOINTS.CHAT_BOT,
  header: {
    'Content-Type': 'application/json',
  },
};

let next_tag = null
let meta_data = null


const convertString = (data) => {
  const msg = `Project Scope: ${data?.project_scope} \n\n Data Source: ${data?.data_sources} \n\n Infrastructure: ${data?.infrastructure} \n\n Resources: ${data?.resources} \n\n Budget Duration: ${data?.budget_duration} \n\n KPI: ${data?.kpi}`
  return msg
}

class ActionProvider {
    constructor(createChatBotMessage, setStateFunc) {
      this.createChatBotMessage = createChatBotMessage;
      this.setState = setStateFunc;
    }
    
    hiGreet() {
      const greetingMessage = this.createChatBotMessage("Hi, friend.")
      this.updateChatbotState(greetingMessage)
    }

    helloGreet() {
      const greetingMessage = this.createChatBotMessage("Hello, friend.")
      this.updateChatbotState(greetingMessage)
    }

    callbot(message) {

      axios.post(
        config.endpoint, 
        {
          sender: "karthik",
          metadata: meta_data,
          message: (_.isEqual(next_tag, null) || _.isEqual(next_tag, undefined) || _.isEqual(next_tag, 'null') || _.isEqual(next_tag, 'undefined')) ? `${message}` : `${next_tag} : ${message}`,
        }, 
        { headers: config.header }
      )
      .then((response) => {
        // Handle the response from the API
        const message = !_.isEmpty(response?.data[0]?.custom?.message) ? response?.data[0]?.custom?.message : response?.data[0]?.text
        const extraMsg = !_.isEmpty(response?.data[0]?.custom?.data) ? convertString(response?.data[0]?.custom?.data) : null
        const greetingMessage = this.createChatBotMessage(!_.isEmpty(extraMsg) ? `${message} \n\n ${extraMsg}` : message)
        next_tag = (!_.isEmpty(response?.data[0]?.custom?.metadata?.next_tag) && !_.isEqual(response?.data[0]?.custom?.metadata?.next_tag, 'confirmation')) ? response?.data[0]?.custom?.metadata?.next_tag : null
        meta_data = !_.isEmpty(response?.data[0]?.custom?.metadata) ? response?.data[0]?.custom?.metadata : null
        this.updateChatbotState(greetingMessage)
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
    }
    
    updateChatbotState(message) {
   
  // NOTE: This function is set in the constructor, and is passed in      // from the top level Chatbot component. The setState function here     // actually manipulates the top level state of the Chatbot, so it's     // important that we make sure that we preserve the previous state.
   
      
     this.setState(prevState => ({
          ...prevState, messages: [...prevState.messages, message],
     }))


    }

  }
  
  export default ActionProvider