import React, { useState } from 'react'
import _ from 'lodash'
import {
	makeStyles,
	// Avatar,
	Badge,
	Paper,
	Grid,
	Typography,
	Icon,
	Button,
	IconButton,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	FormControlLabel,
	Radio,
	Checkbox,
	CircularProgress,
} from '@material-ui/core'
// import classnames from 'classnames'
import update from 'immutability-helper'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { PRIORITY_LIST, STATUS_LIST } from '../../../constants/index'
import { escapeRegExp, formTheName, getImgUrl } from 'utils'
import SearchBox from 'components/PainSearchBox'
import Axios from 'axios'
import DateRangePicker from './DateSelect'
import ListItemLabel from './Components/ListItemLabel'
import ListItem from './Components/ListItem'
import { BOARD_HEADER_HEIGHT_MOBILE } from './contants'

const useStyles = makeStyles((theme) => ({
	root: {
		border: 'none',
		height: `calc(100vh - (65px))`,
	},
	listContainer: {
		overflowX: 'hidden',
		overflowY: 'auto',
		padding: theme.spacing(0),
		maxHeight: 'calc(100vh - (48px + 74px))',
		'&::-webkit-scrollbar,::-webkit-scrollbar-track': {
			width: '8px',
			height: '8px',
			'-webkit-appearance': 'none',
			backgroundColor: 'transparent',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.palette.almostBlack[400],
			height: '20px',
			borderRadius: '4px',
		},
	},
	muiExpansionPanelRoot: {
		width: '100%',
		'&:before': {
			height: 2,
			background: 'white',
		},
		'&:last-child': {
			marginBottom: 0,
		},
		'&.Mui-expanded': {
			margin: 0,
			'& + .MuiAccordion-root:before': {
				display: 'block',
			},
			'&:before': {
				opacity: 1,
			},
		},
		'& .MuiAccordionSummary-expandIcon': {
			padding: theme.spacing(1),
		},
	},
	muiExpanded: {},
	muiExpansionPanelSummaryRoot: {
		minHeight: 40,
		background: theme.palette.almostBlack[200],
		'&$muiExpanded': {
			minHeight: 40,
		},
	},
	muiExpansionPanelSummaryContent: {
		margin: [[`0px 0px`], '!important'],
		'&$muiExpanded': {
			margin: `12px 0px`,
		},
	},
	filterPanelTitleContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	filterTitleWithChip: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	anchorOriginTopRightRectangle: {
		top: `4px`,
		right: `-4px`,
	},
	filterPanelHeading: {
		fontSize: theme.typography.pxToRem(14),
		color: theme.palette.almostBlack[800],
		fontWeight: 'bold',
		letterSpacing: theme.typography.pxToRem(0.8),
	},
	menuHeader: {
		width: '100%',
		height: 40,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: `1px solid ${theme.palette.almostBlack[200]}`,
		boxShadow: `0px 1px 8px 0px ${theme.palette.almostBlack[200]}`,
		[theme.breakpoints.down('sm')]: {
			minHeight: BOARD_HEADER_HEIGHT_MOBILE,
		},
	},
	menuHeaderIconDiv: {
		width: 32,
		height: 32,
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& .MuiIconButton-root': {
			padding: theme.spacing(0.75),
			fontSize: theme.typography.pxToRem(20),
			'& .MuiIcon-root': {
				fontSize: theme.typography.pxToRem(20),
			},
		},
	},
	menuTitle: {
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 500,
		width: '100%',
		textAlign: 'center',
	},
	optionItemLabel: {
		fontSize: theme.typography.pxToRem(14),
	},
	optionItemRadio: {
		padding: 6,
		'& .MuiSvgIcon-root': {
			fontSize: theme.typography.pxToRem(18),
		},
	},
	optionListItemContainer: {
		width: '100%',
		borderBottom: `1px solid ${theme.palette.almostBlack[200]}`,
		boxSizing: 'border-box',
		padding: theme.spacing(0, 2),
		cursor: 'pointer',
		'&:hover': {
			background: theme.palette.almostBlack[100],
		},
	},
	optionListLabelContainer: {
		display: 'flex',
		gap: `4px`,
		alignItems: 'center',
	},
	optionListLabelColorDiv: {
		width: 16,
		height: 16,
		borderRadius: 4,
	},
	listItemContainer: {
		padding: theme.spacing(0.5),
		margin: theme.spacing(0.5, 0, 0.5, 0),
		borderRadius: 4,
		border: `1px solid transparent`,
		cursor: 'pointer',
	},
	userPic: {
		width: 36,
		height: 36,
		marginRight: theme.spacing(1),
		fontSize: 17,
	},
	muiExpansionPanelDetailsRoot: {
		padding: theme.spacing(0.5, 0, 0.5),
		flexDirection: 'column',
		'& $optionListItemContainer:last-child': {
			borderBottom: `none`,
		},
	},
	searchContainer: {
		padding: theme.spacing(0, 1),
	},
}))

const FilterMenu = ({ setMenuProps, updateFunc, activeFilters, token, glassCode }) => {
	const classes = useStyles()
	const assignees = useSelector((state) => state?.glass?.glass_members || [])
	const labels = useSelector((state) => state?.glass?.settings?.labels || [])
	const stickyGroups = useSelector((state) => state?.glass?.settings?.sticky_group || [])
	const panes = useSelector((state) => state?.glass?.settings?.panes || [])
	const allLabels = labels
		? _.concat(labels, { is_edit: false, label_color: '#FFF', label_id: '', label_name: 'None' })
		: [{ is_edit: false, label_color: '#FFF', label_id: '', label_name: 'None' }]

	const updateFilter = (path, value, isSub, isMulti) => {
		let temp = _.cloneDeep(activeFilters || {})
		let tempValue = value
		let existingPathValue = _.cloneDeep(_.get(temp, path))
		let existingPathState = _.isArray(value)
			? _.isEmpty(_.xor(existingPathValue, value))
			: _.isPlainObject(value)
			? isMulti
				? !!_.find(existingPathValue, value)
				: _.isEqual(existingPathValue, value)
			: _.includes(existingPathValue, value)
		if (existingPathState && !_.isEmpty(existingPathValue)) {
			if (_.isObject(existingPathValue)) {
				if (_.isArray(value)) {
					tempValue = []
				} else if (_.isPlainObject(value)) {
					if (isMulti) {
						_.remove(existingPathValue, value)
						tempValue = existingPathValue
					} else {
						tempValue = {}
					}
				} else {
					_.remove(existingPathValue, (o) => _.isEqual(value, o))
					tempValue = existingPathValue
				}
			} else {
				tempValue = ''
			}
		} else if (!existingPathState && !_.isEmpty(existingPathValue)) {
			if (_.isArray(existingPathValue)) {
				tempValue = _.isString(value) || _.isNumber(value) ? [...existingPathValue, value] : _.uniq(_.concat(existingPathValue, value))
			} else if (_.isPlainObject(value) && isMulti) {
				tempValue = [...existingPathValue, value]
			}
		} else if (!existingPathState && _.isEmpty(existingPathValue) && (_.isPlainObject(value) || _.isString(value)) && isMulti) {
			tempValue = [value]
		}
		_.set(
			temp,
			path,
			(isSub && _.isString(tempValue)) || _.isNumber(tempValue) ? [tempValue] : _.isNull(tempValue) && isMulti ? [tempValue] : tempValue
		)
		updateFunc(temp)
	}

	const resetFunc = (path, value) => {
		let tempState = activeFilters || {}
		let temp = update(tempState, { [path]: { $set: path === 'due_date' || path === 'created_date' ? null : value } })
		updateFunc(temp)
	}

	const filterStructure = [
		{
			label: 'Due Date and Time',
			path: 'due_date',
			type: 'date',
			options: [
				{ label: 'Today', key: predefinedRanges?.today },
				{ label: 'Yesterday', key: predefinedRanges?.yesterday },
				{ label: 'Tomorrow', key: predefinedRanges?.tomorrow },
				{ label: 'This Week', key: predefinedRanges?.this_week },
				{ label: 'Last Week', key: predefinedRanges?.last_week },
				{ label: 'Next Week', key: predefinedRanges?.next_week },
				{ label: 'No Due Date', key: predefinedRanges?.no_due_date, isCustom: true },
				{ label: 'Overdue', key: predefinedRanges?.overdue, isCustom: true },
				{ label: 'Custom', key: 'custom_dates' },
			],
			defaultEmptyValue: {},
			isMulti: false,
		},
		{
			label: 'Assignee',
			path: 'assignee',
			type: 'check',
			isAvatar: true,
			isSearchable: true,
			idKey: 'user_id',
			firstNameKey: 'first_name',
			lastNameKey: 'last_name',
			emailKey: 'email',
			imageKey: 'profile_pic_url',
			token,
			isShowImageKey: 'is_active',
			defaultOptions: _.concat([], assignees, [
				{
					email: '',
					first_name: 'Unassigned',
					last_name: '',
					profile_pic_url: null,
					user_id: null,
				},
			]),
			defaultTotalCount: assignees?.total,
			defaultOptionsReduxPath: 'glass?.glass_members',
			// endpoint: API_ENDPOINTS.GLASS_MEMBERS,
			currentGlassIdReduxPath: 'glass?.activeGlassDetails?.glass_id',
			defaultEmptyValue: [],
			isMulti: true,
		},
		{
			label: 'Reporting To',
			path: 'reporting_to',
			type: 'check',
			isAvatar: true,
			isSearchable: true,
			idKey: 'user_id',
			firstNameKey: 'first_name',
			lastNameKey: 'last_name',
			emailKey: 'email',
			imageKey: 'profile_pic_url',
			token,
			isShowImageKey: 'is_active',
			defaultOptions: _.concat([], assignees, [
				{
					email: '',
					first_name: 'Unassigned',
					last_name: '',
					profile_pic_url: null,
					user_id: null,
				},
			]),
			defaultTotalCount: assignees?.total,
			defaultOptionsReduxPath: 'glass?.glass_members',
			// endpoint: API_ENDPOINTS.GLASS_MEMBERS,
			currentGlassIdReduxPath: 'glass?.activeGlassDetails?.glass_id',
			defaultEmptyValue: [],
			isMulti: true,
		},
		{
			label: 'Created Date',
			path: 'created_date',
			type: 'date',
			options: [
				{ label: 'Today', key: predefinedRanges?.today },
				{ label: 'Yesterday', key: predefinedRanges?.yesterday },
				{ label: 'This Week', key: predefinedRanges?.this_week },
				{ label: 'Last Week', key: predefinedRanges?.last_week },
				{ label: 'This Month', key: predefinedRanges?.this_month },
				{ label: 'Last Month', key: predefinedRanges?.last_month },
				{ label: 'Custom', key: 'custom_dates' },
			],
			defaultEmptyValue: {},
			isMulti: false,
		},
		{
			label: 'Created By',
			path: 'created_by',
			type: 'check',
			isAvatar: true,
			isSearchable: true,
			idKey: 'user_id',
			firstNameKey: 'first_name',
			lastNameKey: 'last_name',
			emailKey: 'email',
			imageKey: 'profile_pic_url',
			token,
			isShowImageKey: 'is_active',
			defaultOptions: assignees,
			defaultTotalCount: assignees?.total,
			defaultOptionsReduxPath: 'glass?.glass_members',
			// endpoint: API_ENDPOINTS.GLASS_MEMBERS,
			currentGlassIdReduxPath: 'glass?.activeGlassDetails?.glass_id',
			defaultEmptyValue: [],
			isMulti: true,
		},
		{
			label: 'Priority',
			path: 'priority',
			type: 'check',
			defaultOptions: _.concat([], PRIORITY_LIST, [
				{
					name: 'No Priority',
					value: null,
					color: '#c0c0c0',
					icon: 'not_interested',
				},
			]),
			idKey: 'value',
			labelKey: 'name',
			colorKey: 'color',
			iconKey: 'icon',
			defaultEmptyValue: [],
			isMulti: true,
		},
		{
			label: 'Label',
			path: 'label',
			type: 'check',
			defaultOptions: allLabels,
			defaultOptionsReduxPath: 'glass?.settings?.labels',
			idKey: 'label_id',
			labelKey: 'label_name',
			colorKey: 'label_color',
			defaultEmptyValue: [],
			isMulti: true,
		},
		{
			label: 'Status',
			path: 'status',
			type: 'check',
			defaultOptions: STATUS_LIST,
			idKey: 'value',
			labelKey: 'name',
			colorKey: 'color',
			iconKey: 'icon',
			defaultEmptyValue: [],
			isMulti: true,
		},
		{
			label: 'Panes',
			path: 'panes',
			type: 'check',
			isSearchable: true,
			token,
			// defaultTotalCount: panes?.total,
			defaultOptions: panes,
			defaultOptionsReduxPath: 'glass?.settings?.panes',
			idKey: 'pane_id',
			labelKey: 'pane_name',
			colorKey: 'color',
			defaultEmptyValue: [],
			isMulti: true,
		},
		{
			label: 'Task Group',
			labelKey: 'sticky_group',
			path: 'sticky_group',
			type: 'check',
			isAvatar: true,
			isSearchable: true,
			idKey: 'sticky_group_id',
			firstNameKey: 'sticky_group',
			// lastNameKey: 'last_name',
			// emailKey: 'email',
			imageKey: 'sticky_group_url',
			token,
			// isShowImageKey: 'is_active',
			defaultOptions: stickyGroups,
			defaultTotalCount: stickyGroups?.total,
			defaultOptionsReduxPath: 'glass?.settings?.sticky_group',
			// endpoint: API_ENDPOINTS.GLASS_MEMBERS,
			// currentGlassIdReduxPath: 'glass?.activeGlassDetails?.glass_id',
			defaultEmptyValue: [],
			isMulti: true,
		},
	]
	const handleClose = () => {
		setMenuProps((prev) => ({ ...prev, isOpen: false }))
	}
	return (
		<Paper className={classes.root} variant='outlined'>
			<div className={classes.menuHeader}>
				<Typography variant='h5' className={classes.menuTitle}>
					Filters
				</Typography>
				<div className={classes.menuHeaderIconDiv}>
					<IconButton onClick={handleClose} title='Close'>
						<Icon>close</Icon>
					</IconButton>
				</div>
			</div>
			<Grid container className={classes.listContainer}>
				{_.isEmpty(filterStructure)
					? 'No Filters Applicable'
					: filterStructure.map((o, i) => (
							<FilterPanel
								key={`filter_${i}`}
								{...o}
								glassCode={glassCode}
								classes={classes}
								selected={_.get(activeFilters, o?.path)}
								resetFunc={resetFunc}
								updateFunc={updateFilter}
							/>
					  ))}
			</Grid>
		</Paper>
	)
}

export default FilterMenu

const useAxios = (endpoint, method = 'post', params, bodyData, cancelExec, isAuthRequired = true) => {
	if (cancelExec.current) {
		cancelExec.current()
	}
	return Axios[method](endpoint, bodyData, {
		cancelToken: new Axios.CancelToken(function executor(c) {
			cancelExec.current = c
		}),
		headers: {
			'Content-Type': 'application/json',
			isAuthRequired,
			path: params,
		},
	})
}

const FilterPanel = (props) => {
	const classes = useStyles()
	const [isLoading, setIsLoading] = useState(false)
	const cancelExecutor = React.useRef()
	const { label, updateFunc = () => null, glassCode, resetFunc = () => null } = props
	const [searchQuery, setSearchQuery] = useState('')
	const [options, setOptions] = useState(() => props?.defaultOptions || [])
	const getData = useAxios

	React.useEffect(() => {
		if (!!props?.isSearchable) {
			if (props?.defaultTotalCount > props?.defaultOptions?.length && !!props?.endpoint) {
				setIsLoading(true)
				getData(props?.endpoint, 'post', { glassCode }, { search_text: searchQuery, offset: 0, limit: 10 }, cancelExecutor)
					.then((resp) => {
						setOptions(_.uniqBy([..._.get(resp, 'data.data.data'), ...props?.defaultOptions], 'last_name'))
						setIsLoading(false)
					})
					.catch((err) => {
						if (Axios.isCancel(err)) {
							return true
						}
						setIsLoading(false)
					})
			} else {
				if (!!searchQuery) {
					setOptions(
						_.filter(props?.defaultOptions, (o) =>
							new RegExp(`\\b(\\w*${escapeRegExp(searchQuery)}\\w*)\\b`, 'gi').test(
								`${formTheName(o[props?.firstNameKey], o[props?.lastNameKey])} ${o[props?.emailKey]}  ${o[props?.labelKey]}`
							)
						)
					)
				} else if (props?.defaultTotalCount !== options.length) {
					setOptions(props?.defaultOptions)
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery])

	return (
		<Accordion
			classes={{
				root: classes.muiExpansionPanelRoot,
				expanded: classes.muiExpanded,
			}}
			elevation={0}
		>
			<AccordionSummary
				classes={{
					root: classes.muiExpansionPanelSummaryRoot,
					content: classes.muiExpansionPanelSummaryContent,
					expanded: classes.muiExpanded,
				}}
				expandIcon={<Icon>keyboard_arrow_down</Icon>}
			>
				<div className={classes.filterPanelTitleContainer}>
					<div className={classes.filterTitleWithChip}>
						<Badge
							color='error'
							badgeContent={_.isPlainObject(props?.selected) ? (_.isEmpty(props?.selected) ? 0 : 1) : _.toLength(props?.selected?.length)}
							variant='dot'
							classes={{
								anchorOriginTopRightRectangle: classes.anchorOriginTopRightRectangle,
							}}
						>
							<Typography className={classes.filterPanelHeading} variant='h6'>
								{label || 'Filter'}
							</Typography>
						</Badge>
					</div>
					{!!(_.isPlainObject(props?.selected) ? (_.isEmpty(props?.selected) ? 0 : 1) : _.toLength(props?.selected?.length)) && (
						<Button
							size='small'
							className={classes.resetBtn}
							onClick={(e) => {
								e.stopPropagation()
								resetFunc(props?.path, props?.defaultEmptyValue || [])
							}}
						>
							Reset
						</Button>
					)}
				</div>
			</AccordionSummary>
			<AccordionDetails classes={{ root: classes.muiExpansionPanelDetailsRoot }}>
				{!!props?.isSearchable && (
					<Grid item xs={12} className={classes.searchContainer}>
						<SearchBox
							onChange={(e) => setSearchQuery(e.target.value)}
							value={searchQuery}
							className={classes.searchInput}
							adornment={
								isLoading ? (
									<CircularProgress style={{ marginRight: '4px' }} size={20} />
								) : searchQuery === '' ? (
									<Icon className={classes.searchIcon}>search</Icon>
								) : (
									<IconButton onClick={() => setSearchQuery('')} size='small' title='Clear Search'>
										<Icon className={classes.searchIcon}>close</Icon>
									</IconButton>
								)
							}
						/>
					</Grid>
				)}
				<RenderFilterOptions
					{...props}
					{...(props?.isSearchable
						? {
								defaultOptions: _.sortBy(options, (o) => _.isUndefined(_.find(props?.selected, (j) => j === o[props?.idKey]))),
						  }
						: {})}
					updateFunc={updateFunc}
					classes={classes}
				/>
			</AccordionDetails>
		</Accordion>
	)
}
const RenderFilterOptions = (props) => {
	const { classes = {} } = props
	switch (props?.type) {
		case 'date': {
			const { options = [], selected, updateFunc, path } = props
			let selectedOptionIdx = _.findIndex(options, (option) => {
				const getSelectedValue = (value) => {
					return option?.isCustom ? value : moment(value).valueOf()
				}

				return (
					_.isEqual(getSelectedValue(option.key?.from), getSelectedValue(selected?.from)) &&
					_.isEqual(getSelectedValue(option.key?.to), getSelectedValue(selected?.to))
				)
			})

			// let isCustom = _.isEmpty(_.filter(props.options, (o) => _.isEqual(o.key, selected))) && !_.isEmpty(selected?.from)
			let isCustom = selectedOptionIdx === -1
			if (!!options?.length) {
				return _.map(props.options, (o, i) => {
					const isChecked = _.isEqual(i, selectedOptionIdx)
					if (o?.key === 'custom_dates') {
						return (
							<DateRangePicker
								key={`option_date_custom_picker`}
								classes={classes}
								isSelected={!_.isEmpty(selected?.key) || isCustom}
								selectedKey={o?.key}
								selected={selected}
								path={path}
								updateFunc={updateFunc}
							/>
						)
					} else {
						return (
							<div
								key={`option_date_${path}_${i}`}
								className={classes.optionListItemContainer}
								onClick={(e) => {
									e.stopPropagation()
									if (!isChecked) {
										updateFunc(path, o?.key, false, false)
									} else {
										updateFunc(path, undefined, false, false)
									}
								}}
							>
								<FormControlLabel
									classes={{ label: classes.optionItemLabel }}
									checked={isChecked}
									value='end'
									control={<Radio className={classes.optionItemRadio} color='primary' />}
									label={<ListItemLabel classes={classes} text={o?.label} />}
								/>
							</div>
						)
					}
				})
			}
			return <Typography variant='body2'>No Options</Typography>
		}
		case 'check': {
			const {
				defaultOptions = [],
				selected,
				updateFunc,
				path,
				idKey,
				labelKey,
				firstNameKey,
				lastNameKey,
				emailKey,
				imageKey,
				isAvatar,
				token,
				colorKey,
				iconKey,
				isShowImageKey,
			} = props

			if (!!defaultOptions?.length && !!idKey && (!!labelKey || (!!firstNameKey && !!lastNameKey && !!emailKey))) {
				return _.map(defaultOptions, (o, i) => {
					if (o?.key === 'custom_dates') {
						return (
							<Typography key={`option_check_${path}_${i}`} variant='h6'>
								Custom Dates
							</Typography>
						)
					} else {
						if (isAvatar) {
							let imgProps = !_.isEmpty(_.get(o, imageKey)) ? { src: getImgUrl(token, _.get(o, imageKey)) } : { src: 'fake_path' }
							let isDontHighlight = !(_.isUndefined(isShowImageKey) || _.get(o, isShowImageKey))
							return (
								<div
									key={`option_check_${path}_${o[idKey]}`}
									className={classes.optionListItemContainer}
									role='button'
									onClick={() => updateFunc(path, o[idKey], false, true)}
								>
									<FormControlLabel
										classes={{ label: classes.optionItemLabel }}
										checked={_.includes(selected || [], o[idKey])}
										value='end'
										control={<Checkbox className={classes.optionItemRadio} color='primary' />}
										label={
											<ListItem
												{...imgProps}
												isDontHighlight={isDontHighlight}
												classes={classes}
												name={formTheName(o[firstNameKey], o[lastNameKey])}
												email={o[emailKey]}
												onClick={() => updateFunc(path, o[idKey], false, true)}
											/>
										}
									/>
								</div>
							)
						}
						return (
							<div
								key={`option_check_${path}_${o[idKey]}`}
								className={classes.optionListItemContainer}
								role='button'
								onClick={() => updateFunc(path, o[idKey], false, true)}
							>
								<FormControlLabel
									classes={{ label: classes.optionItemLabel }}
									checked={_.includes(selected || [], o[idKey])}
									value='end'
									control={<Checkbox className={classes.optionItemRadio} color='primary' />}
									label={<ListItemLabel classes={classes} icon={o[iconKey]} color={o[colorKey]} text={o[labelKey]} />}
								/>
							</div>
						)
					}
				})
			}
			return (
				<div key={`option_check_${path}_no_option`} className={classes.optionListItemContainer} style={{ cursor: 'auto', padding: 8 }}>
					<ListItemLabel classes={classes} text='No Data' />
				</div>
			)
		}
		default: {
			return (
				<div key={`option_check_no_option_main`} className={classes.optionListItemContainer} style={{ cursor: 'auto', padding: 8 }}>
					<ListItemLabel classes={classes} text='No Data' />
				</div>
			)
		}
	}
}
const predefinedRanges = {
	last_week: {
		from: moment().add(-1, 'w').startOf('w').format('YYYY-MM-DD HH:mm:ss'),
		to: moment().add(-1, 'w').endOf('w').format('YYYY-MM-DD HH:mm:ss'),
	},
	yesterday: {
		from: moment().add(-1, 'd').startOf('d').format('YYYY-MM-DD HH:mm:ss'),
		to: moment().add(-1, 'd').endOf('d').format('YYYY-MM-DD HH:mm:ss'),
	},
	today: {
		from: moment().startOf('d').format('YYYY-MM-DD HH:mm:ss'),
		to: moment().endOf('d').format('YYYY-MM-DD HH:mm:ss'),
	},
	tomorrow: {
		from: moment().add(1, 'd').startOf('d').format('YYYY-MM-DD HH:mm:ss'),
		to: moment().add(1, 'd').endOf('d').format('YYYY-MM-DD HH:mm:ss'),
	},
	this_week: {
		from: moment().startOf('w').format('YYYY-MM-DD HH:mm:ss'),
		to: moment().endOf('w').format('YYYY-MM-DD HH:mm:ss'),
	},
	next_week: {
		from: moment().add(1, 'w').startOf('w').format('YYYY-MM-DD HH:mm:ss'),
		to: moment().add(1, 'w').endOf('w').format('YYYY-MM-DD HH:mm:ss'),
	},
	this_month: {
		from: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
		to: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
	},
	last_month: {
		from: moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
		to: moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss'),
	},
	no_due_date: { from: null, to: null },
	overdue: { from: 'overdue', to: 'overdue' },
}
