import { Avatar, Grid, Icon, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import moment from 'moment'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(1),
		width: '100%',
	},
	cbSection: {
		minWidth: 'unset',
		margin: 0,
	},
	imgSection: {
		minWidth: 'unset',
		margin: 0,
	},
	title: {
		fontWeight: '500',
		display: '-webkit-box',
		overflow: 'hidden',
		wordBreak: 'break-word',
		'-webkit-box-orient': 'vertical',
		'-webkit-line-clamp': 2,
	},
	docType: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		padding: '2px 6px',
		fontWeight: '500',
		borderRadius: '4px',
		marginLeft: theme.spacing(0.5),
		fontSize: '12px',
	},
	timeInfo: {
		marginTop: theme.spacing(0.7),
		lineHeight: '10px',
		'& span': {
			verticalAlign: 'middle',
			marginRight: theme.spacing(0.3),
		},
	},
	listItemRoot: {
		// alignItems: 'center',
		[theme.breakpoints.up('md')]: {
			marginBottom: theme.spacing(1.5),
		},
		'& .MuiListItemText-multiline': {
			margin: 0,
		},
	},
	listRootWithoutCheckbox: {
		paddingTop: 0,
		paddingBottom: 0,
		'& .MuiListItemText-multiline': {
			margin: 8,
		},
	},
	desc: {
		display: '-webkit-box',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		width: '98%',
	},

	checkbox: {
		paddingLeft: theme.spacing(0.5),
		paddingTop: theme.spacing(3),
	},
	infoTitle: {
		marginBottom: theme.spacing(1),
	},
	selectAllRoot: {
		padding: theme.spacing(0.5, 1),
		'& .MuiFormControlLabel-root': {
			'& .MuiTypography-body1': {
				color: theme.palette.grey[500],
				fontSize: 14,
			},
		},
	},
	allpagesLink: {
		fontSize: 14,
		display: 'block',
		marginTop: theme.spacing(2),
		cursor: 'pointer',
	},
	selectedContent: {
		display: 'block',
		marginTop: theme.spacing(2),
	},
	exportBtn: {
		textAlign: 'right',
	},
	saveBtn: {
		marginLeft: theme.spacing(2),
		textTransform: 'capitalize',
		fontWeight: 'normal',
		color: theme.palette.primary.main,
		'&:hover': {
			backgroundColor: 'transparent',
			color: theme.palette.primary.main,
		},
	},
	moreBtn: {
		marginLeft: theme.spacing(2),
		textTransform: 'capitalize',
		fontWeight: 'normal',
		color: theme.palette.grey[600],
		'&:hover': {
			backgroundColor: 'transparent',
			color: theme.palette.primary.main,
		},
	},
	whitelistBtn: {
		marginLeft: theme.spacing(2),
		textTransform: 'capitalize',
		color: theme.palette.error.main,
		'&:hover': {
			backgroundColor: 'transparent',
			color: theme.palette.error.main,
		},
	},
	infoSection: {
		display: 'flex',
		// justifyContent: 'space-between',
		paddingRight: theme.spacing(2),
		alignItems: 'center',
	},
	resultImg: {
		maxWidth: 150,
		width: '100%',
		marginTop: theme.spacing(1),
	},
	square: {
		width: '125px',
		height: '100px',
		// marginTop: '6px',
		marginRight: '5px',
		[theme.breakpoints.down('sm')]: {
			width: '75px',
			height: '50px',
		},
	},
	playIcon: {
		position: 'absolute',
		bottom: 10,
		right: 10,
		color: '#fff',
	},
	menuSection: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	titleSec: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		padding: theme.spacing(0, 1, 1, 1),
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap',
		},
		'& .MuiDivider-root': {
			width: '90%',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},
		},
	},
	dateDesc: {
		width: '10%',
		display: '-webkit-box',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		margin: theme.spacing(0, 0),
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	resultContainer: {
		padding: theme.spacing(0, 2),
	},
}))

const ResultList = ({ data, onClickResult, isMobileView }) => {
	const classes = useStyles()
	return (
		<Grid container item lg={8}>
			<List className={classes.root}>
				{!_.isEmpty(data) && (
					<div className={classes.resultContainer}>
						{_.map(data, (result, idx) => (
							<>
								<ListItem classes={{ root: classes.listItemRoot }} key={_.get(result, 'id') + idx} disableGutters alignItems='flex-start'>
									<ListItemText
										primary={
											<React.Fragment>
												<Typography className={classes.infoTitle} variant='caption' color='textSecondary'>
													{_.get(result, 'data_source')} &nbsp;
													<span className={classes.docType}>{_.get(result, 'doc_type')}</span>
												</Typography>
												<Typography
													variant={isMobileView ? 'body2' : 'body1'}
													fontWeight='bold'
													color='textPrimary'
													dangerouslySetInnerHTML={{
														__html: _.get(result, 'title'),
													}}
													className={classes.title}
												/>
											</React.Fragment>
										}
										secondary={
											<React.Fragment>
												<Typography component='div' gutterBottom className={classes.desc} variant='body2' color='textSecondary'>
													{_.get(result, 'description')}
												</Typography>
												<div className={classes.infoSection}>
													{_.get(result, 'date') && (
														<Typography component='span' className={classes.timeInfo} variant='caption'>
															<Icon fontSize='inherit'>query_builder</Icon>
															<span>{moment(_.get(result, 'date')).format('DD MMMM YYYY')}</span>
														</Typography>
													)}
												</div>
											</React.Fragment>
										}
									/>
									{!isMobileView && !_.isEmpty(result?.img_url) && (
										<ListItemAvatar className={classes.imgSection}>
											<Avatar variant='square' className={classes.square} src={result?.img_url}>
												{_.startCase(_.slice(result?.title, 0, 1))}
											</Avatar>
										</ListItemAvatar>
									)}
									{!isMobileView && !_.isEmpty(result?.video_thumbnail_url) && (
										<ListItemAvatar className={classes.imgSection}>
											<Avatar variant='square' className={classes.square} src={result?.video_thumbnail_url}>
												{_.startCase(_.slice(result?.title, 0, 1))}
											</Avatar>
											<Icon className={classes.playIcon}>play_circle_outline</Icon>
										</ListItemAvatar>
									)}
								</ListItem>
								{/* <Divider component='li' /> */}
							</>
						))}
					</div>
				)}
			</List>
		</Grid>
	)
}

export default ResultList
