import React, { useEffect, useState, useRef, useMemo } from 'react'
import Layout from '../../components/Layout'
import { useDispatch, useSelector } from 'react-redux'

import _ from 'lodash'
import { useUrlSearchParams } from '../../hooks/use-url-search-params'
import { getDetailedPaginationProperties } from '../../utils'
import { ACTION_COL_OPTIONS, FILTER_CONSTANTS, API_ENDPOINTS } from '../../constants'

// import Axios from 'axios';
import { showLoader, hideLoader } from '../../services/loader/actions'
import { showSnackbarWithTimeout } from '../../services/snackbar/actions'
import { Icon, Typography, Switch, FormControlLabel, makeStyles, withWidth } from '@material-ui/core'
// import { clearAllFilters } from '../../services/filters/actions';
import ImagePopUp from '../../components/ImagePopUp'
// import { startOfDay, endOfDay } from 'date-fns';
import FlagComponent from '../../components/Flags'
import { getProductsTable } from '../../services/products/actions'
// import {getClientId} from '../../services/clientId/actions'
// import {getBpId} from '../../services/bpId/actions';
import { format } from 'date-fns'
import Axios from 'axios'
import { usePrevious } from 'hooks'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import { usePermission } from 'hooks/usePermission'
import { setThisFilter } from 'services/filtersNew/actions'
import { TASK_BUTTON_TXT } from '../../constants'
import withAddTaskDialog from 'hoc/withAddStickyDialog'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1),
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.almostBlack[0],
			'& + $track': {
				backgroundColor: theme.palette.error.main,
				opacity: 1,
				height: 24,
				border: `1px solid transparent`,
			},
		},
		'&$focusVisible $thumb': {
			color: theme.palette.error.main,
			border: '4px solid #fff',
		},
	},
	thumb: {
		width: 24,
		height: 24,
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.almostBlack[500]}`,
		backgroundColor: theme.palette.almostBlack[300],
		opacity: 1,
		height: 24,
		transition: theme.transitions.create(['background-color', 'border']),
	},
	checked: {},
	focusVisible: {},
	inputLabel: {
		marginRight: 0,
		'& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
			// fontFamily: ['"Poppins"'].join(','),
			fontSize: theme.typography.pxToRem(14),
		},
	},
	sellingPriceText: {
		fontSize: theme.typography.pxToRem(14),
		fontWeight: 500,
		color: theme.palette.info.main,
		paddingRight: theme.spacing(0.5),
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	marketPriceText: {
		fontSize: theme.typography.pxToRem(14),
		// color: theme.palette.almostBlack[500],
		// textDecoration: 'line-through',
		fontWeight: 500,
		paddingRight: theme.spacing(0.5),
		color: theme.palette.success.main,
	},
	discountText: {
		fontSize: theme.typography.pxToRem(13),
		color: theme.palette.secondary.dark,
		paddingRight: theme.spacing(0.5),
	},
}))

const NewProducts = ({ width, openStickyDialog }) => {
	const dispatch = useDispatch()
	const [sortSettings, setSortSettings] = useState({
		keyToSort: '',
		sortDirection: true,
	})

	const history = useHistory()

	const onBackButtonEvent = (e) => {
		e.preventDefault()
		// var currentLocation = window.location.pathname;
		history.goBack()
	}

	useEffect(() => {
		window.addEventListener('popstate', onBackButtonEvent)
		return () => {
			window.removeEventListener('popstate', onBackButtonEvent)
		}
	}, [])

	const [searchQuery, setSearchQuery] = useState('')
	const appliedFilters = useSelector((state) => state.filtersNew)
	const sticky = useSelector((state) => state.snackbar)
	const [prodIDListForFilter, setProdIDListForFilter] = useState([])
	const [tableData, setTableData] = useState([])
	const { date_range, only_counterfeit } = useSelector((state) => _.get(state, 'filtersNew', {}))

	const glassPerms = usePermission(ROLE_MODULES.GLASS)
	const isTaskAddAllowed = useMemo(() => _.get(glassPerms, PERMISSION_TYPE.CREATE, false), [glassPerms])

	const productPerms = usePermission(ROLE_MODULES.PRODUCTS)
	const isViewAllowed = useMemo(() => _.get(productPerms, PERMISSION_TYPE.VIEW, false), [productPerms])
	const isCreateAllowed = useMemo(() => _.get(productPerms, PERMISSION_TYPE.CREATE, false), [productPerms])
	const isEditAllowed = useMemo(() => _.get(productPerms, PERMISSION_TYPE.EDIT, false), [productPerms])
	const isDeleteAllowed = useMemo(() => _.get(productPerms, PERMISSION_TYPE.DELETE, false), [productPerms])
	const isExportAllowed = useMemo(() => _.get(productPerms, PERMISSION_TYPE.EXPORT, false), [productPerms])

	const labelAdjective = useSelector((state) => _.get(state, 'appSettings.data.company_adjective', 'Counterfeit'))
	const dataSourceId = useSelector((state) => _.get(state, 'appSettings.data.ds_id'))

	const [selectionMode, setSelectionMode] = useState(false)

	const classes = useStyles()
	const handleSearchInputChange = (inputValue) => {
		setSearchQuery(inputValue)
		setProdIDListForFilter([])
		setIsFilterChanged(true)
		// setPageQuery({...pageQuery, page:1});
	}

	// useEffect(()=>{
	//     dispatch(showLoader());
	//     Axios.post(API_ENDPOINTS.ELASTIC_SEARCH_API, {"query":searchQuery,"doc_type":"5c9a4aed16209a30106049d2_counterfeit_analysis","retrieve_field":"product_id"}, {headers:{ 'isAuthRequired': true, 'content-type':'application/json'}}).then(resp=>{
	//         if(!_.isEmpty(_.get(resp, 'data.data.result', []))){
	//             setProdIDListForFilter(_.get(resp, 'data.data.result', []));
	//             dispatch(hideLoader())
	//         }
	//         else(
	//             dispatch(hideLoader())
	//         )
	//     }).catch(err => dispatch(hideLoader()))
	// }, [searchQuery, prodIDListForFilter])

	const onSortClick = (dataKey) => {
		const tempSortSettings = Object.assign({}, sortSettings)

		if (tempSortSettings.keyToSort.length > 0) {
			if (tempSortSettings.keyToSort === dataKey) {
				if (tempSortSettings.sortDirection === false) {
					tempSortSettings.keyToSort = ''
					tempSortSettings.sortDirection = true
				} else {
					tempSortSettings.sortDirection = false
				}
			} else {
				tempSortSettings.keyToSort = dataKey
				tempSortSettings.sortDirection = true
			}
		} else {
			tempSortSettings.keyToSort = dataKey
			tempSortSettings.sortDirection = true
		}
		// console.log('tempSortSettings', tempSortSettings)
		setSortSettings(tempSortSettings)
	}

	const sortFilter = (sortSettingObj) => {
		return sortSettingObj && sortSettingObj.keyToSort === '' ? {} : { [sortSettingObj.keyToSort]: sortSettingObj.sortDirection ? 1 : -1 }
	}

	const handleImageClick = (record) => {
		setDialogPropsState({ ...dialogPropsState, record: record })
		setIsModalOpen(true)
	}

	const onCheckBoxClick = (e, record) => {
		e.stopPropagation()
		// console.log("check box clixked", record)
	}

	function formatURL(url) {
		if (!url.match(/^https?:\/\//i)) {
			url = 'https://' + url
		}
		return url
	}

	const actions = [
		{
			action: ACTION_COL_OPTIONS.LINK,
			icon: 'open_in_new',
			onClick: (e, record) => {
				if (window !== 'undefined') {
					window.open(formatURL(record.product_url), '_blank')
				}
			},
		},
	]

	const setTimeParams = (dateObj) => {
		// const startDateTime = startOfDay(dateObj.startDate);
		const startDateTime = dateObj.startDate //format(dateObj.startDate, 'yyyy-MM-dd');
		const endDateTime = dateObj.endDate //format(dateObj.endDate, 'yyyy-MM-dd');
		return { startDate: startDateTime, endDate: endDateTime, key: 'dates' }
	}

	function _getPriceDetails({ sellingPrice, marketPrice, discount }) {
		if ((!_.isNil(discount) && _.toNumber(discount) === 0) || (_.toNumber(sellingPrice) && _.toNumber(marketPrice))) {
			return {
				sellingPrice: !_.isNil(sellingPrice) ? _.toNumber(sellingPrice) : _.toNumber(marketPrice),
			}
		} else if (!_.isNil(sellingPrice) && !_.isNil(marketPrice) && !_.isNil(discount)) {
			return { sellingPrice, marketPrice, discount }
		} else if (!_.isNil(sellingPrice) && !_.isNil(marketPrice) && _.isNil(discount)) {
			return {
				sellingPrice,
				marketPrice,
				discount: ((sellingPrice - marketPrice) / marketPrice) * 100,
			}
		} else if (!_.isNil(sellingPrice) && _.isNil(marketPrice) && !_.isNil(discount)) {
			return {
				sellingPrice,
				marketPrice: (100 * sellingPrice) / (100 - discount),
				discount,
			}
		} else if (_.isNil(sellingPrice) && !_.isNil(marketPrice) && !_.isNil(discount)) {
			return {
				sellingPrice: marketPrice - (marketPrice * discount) / 100,
				marketPrice,
				discount,
			}
		} else {
			return null
		}
	}

	const PriceDetails = (record) => {
		const data = _getPriceDetails({
			sellingPrice: _.isNumber(_.get(record, 'record.selling_price'))
				? _.get(record, 'record.selling_price').toFixed(2)
				: _.get(record, 'record.selling_price'), //_.get(record, 'record.selling_price'),
			marketPrice: _.isNumber(_.get(record, 'record.market_price'))
				? _.get(record, 'record.market_price').toFixed(2)
				: _.get(record, 'record.market_price'),
			discount: _.get(record, 'record.product_discount'),
		})
		return (
			<>
				{!_.isNil(data) && !_.isEmpty(data) ? (
					<>
						{data.marketPrice ? (
							<Typography variant='h5' title={`Market Price: RM${data.marketPrice}`} component='span' className={classes.marketPriceText}>
								Market Price: {_.get(record, 'record.market_price')}
							</Typography>
						) : null}
						{data.sellingPrice ? (
							<Typography variant='h5' title={`Selling Price: RM${data.sellingPrice}`} component='span' className={classes.sellingPriceText}>
								Selling Price: {_.get(record, 'record.selling_price')}
							</Typography>
						) : null}

						{/* {_.toNumber(data.discount) > 0 ? (
							<Typography variant='h5' title={`Discount: ${data.discount}%`} component='span' className={classes.discountText}>
								{_.round(data.discount)}% Off
							</Typography>
						) 
						: null} */}
					</>
				) : null}
			</>
		)
	}

	const MarketplaceDetails = (record) => {
		const data = _.get(record, 'record.market_place')

		return (
			<>
				{!_.isNil(data) && !_.isEmpty(data) ? (
					<>
						<div className={classes.sellingPriceText}>{data}</div>
					</>
				) : null}
			</>
		)
	}

	const flagStatus = {
		name: 'Status',
		dataKey: 'status',
		size: 6,
		type: 'flag',
		textKey: 'counterfeit',
		flagCondition: { yes: 'Authentic', no: 'WIP', indeterminate: 'Suspected' },
		flagComponents: {
			yes: <FlagComponent text='Authentic' color='secondary' />,
			no: <FlagComponent text='WIP' color='info' />,
			indeterminate: <FlagComponent text='Suspected' color='error' />,
		},
	}

	const mobileHeaderKeys = {
		id: 'product_id',
		description: 'product_details',
		actions: {
			action: ACTION_COL_OPTIONS.LINK,
			icon: 'open_in_new',
			onClick: (e, record) => {
				if (window !== 'undefined') {
					window.open(formatURL(record.product_url), '_blank')
				}
			},
		},
	}

	const mobileContent = [
		{
			name: 'Seller',
			dataKey: 'seller_name',
			size: 8,
			subTitleRenderFxn: (record) => <MarketplaceDetails record={record} />,
		},
		{
			name: 'Discount',
			dataKey: 'product_discount_label',
			size: 4,
		},
		{
			name: 'MP',
			dataKey: 'market_price',
			size: 6,
			type: 'marketPrice',
		},
		{
			name: 'SP',
			dataKey: 'selling_price',
			size: 6,
			type: 'sellingPrice',
		},
		{
			name: 'Product Image',
			dataKey: 'image_url',
			size: 12,
			imgPopUpFxn: handleImageClick,
			type: 'image',
		},
	]

	const mobileheaders = [
		{
			name: 'Productx',
			align: 'left',
			width: '100%',
			isMobileView: true,
			style: {
				// width: '100%',
				// maxWidth: '10vw',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				overflow: 'auto',
				textAlign: 'start',
			},
			textElementProps: {
				style: {
					whiteSpace: 'normal',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordBreak: 'break-word',
				},
			},
		},
	]

	const headers = [
		{
			name: 'Product Image',
			dataKey: 'image_url',
			isSortEnabled: true,
			onSortClick: onSortClick,
			imgKey: 'image_url',
			width: '20%',
			align: 'center',
			style: { paddingLeft: 16 },
			isImageWithText: true,
			isLargeImage: true,
			isImagePopUpEnabled: true,
			imgPopUpFxn: handleImageClick,
			// subTitleRenderFxn: (record) => <PriceDetails record={record} />,
			textElementProps: {
				variant: 'body2',
				style: {
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				},
			},
		},
		{
			name: 'Product Id',
			dataKey: 'product_id',
			align: 'center',
			width: '15%',
			style: {
				width: '100%',
				maxWidth: '10vw',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
			},
			format: (object) => object.toUpperCase(),
			textElementProps: {
				style: {
					whiteSpace: 'normal',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					wordBreak: 'break-word',
				},
			},
		},
		{
			name: 'Product Details',
			dataKey: 'product_details',
			isSortEnabled: true,
			onSortClick: onSortClick,
			// imgKey: 'image_url',
			width: '40%',
			align: 'center',
			style: { paddingLeft: 16 },
			// isImageWithText: true,
			// isLargeImage: true,
			// isImagePopUpEnabled: true,
			// imgPopUpFxn: handleImageClick,
			subTitleRenderFxn: (record) => <PriceDetails record={record} />,
			textElementProps: {
				variant: 'body2',
				style: {
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				},
			},
		},
		{
			name: 'Discount',
			dataKey: 'product_discount_label',
			align: 'center',
			// isDate: true,
			width: '10%',
			textElementProps: { style: { whiteSpace: 'normal' } },
		},
		{
			name: 'Status',
			dataKey: 'status',
			isSortEnabled: true,
			onSortClick: onSortClick,
			align: 'center',
			width: '15%',
			isFlag: true,
			textKey: 'counterfeit',
			flagCondition: { yes: 'Authentic', no: 'WIP', indeterminate: 'Suspected' },
			flagComponents: {
				yes: <FlagComponent text='Authentic' color='secondary' />,
				no: <FlagComponent text='WIP' color='info' />,
				indeterminate: <FlagComponent text='Suspected' color='error' />,
			},
		},
		{
			name: 'Seller',
			dataKey: 'seller_name',
			align: 'left',
			width: '15%',
			subTitleRenderFxn: (record) => <MarketplaceDetails record={record} />,
			textElementProps: {
				variant: 'body2',
				style: {
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				},
			},
		},

		{
			name: 'Link',
			isActionCol: true,
			align: 'center',
			actions: actions,
			width: '6%',
		},
	]

	const [isModalOpen, setIsModalOpen] = useState(false)
	const keyForCheckBoxSelectedList = 'id'

	const handleModalClose = () => setIsModalOpen(false)

	const dialogProps = {
		imgKey: 'image_slider',
		secImgKeys: '',
		title: 'product_name',
		subTitle: 'product_id',
		record: {},
		closeFxn: handleModalClose,
	}
	const [dialogPropsState, setDialogPropsState] = useState({ ...dialogProps })
	const initialTime = () => {
		return {
			startDate: date_range?.startDate,
			endDate: date_range?.endDate,
		}
	}
	// console.log(only_counterfeit, 'prod')
	const [pageQuery, setPageQuery] = useUrlSearchParams(
		{
			page: 1,
			limit: 12,
			isOnlyCounterfeits: only_counterfeit,
			startDate: date_range?.startDate,
			endDate: date_range?.endDate,
			filterParam: appliedFilters,
		},
		{
			page: Number,
			limit: Number,
			isOnlyCounterfeits: Boolean,
			startDate: Date,
			endDate: Date,
			filterParam: String,
		}
	)
	const { page, limit, isOnlyCounterfeits, startDate, endDate } = pageQuery
	const [timeStamp] = useState(() => setTimeParams(initialTime()))
	const [paginationFooterDetails, setPaginationFooterDetails] = useState({})
	const [exportList, setExportList] = useState([])
	const [allPageProduct, setAllPageProduct] = useState(false)

	const [type, setType] = useState('TAKEDOWN')

	const [isFilterChanged, setIsFilterChanged] = useState(false)
	const prevAppliedFilters = usePrevious(appliedFilters)

	// const [isOnlyCounterfeits, setIsOnlyCounterfeits] = useState(false);

	const setIsOnlyCounterfeits = (v) => setPageQuery({ ...pageQuery, isOnlyCounterfeits: v })

	let tempSelectedList = Object.assign([], exportList)
	let tempSelectedData = Object.assign([], tableData)

	useEffect(() => {
		if (sticky.showSnackbar === true && sticky.msgText === 'The task has been created successfully') {
			if (!_.isEmpty(finalQuery)) {
				if (cancelExecutor.current !== undefined) {
					cancelExecutor.current()
				}
				dispatch(getProductsTable({ ...finalQuery, ds_id: dataSourceId }, searchQuery, cancelExecutor))
			}
		}
	}, [sticky])

	useEffect(() => {
		// dispatch(setThisFilter({startDate, endDate}, FILTER_CONSTANTS.DATE_RANGE));
		handleTimeChange({ startDate, endDate })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate, endDate])

	useEffect(() => {
		setPageQuery({ ...pageQuery, ...date_range })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date_range])
	useEffect(() => {
		setPageQuery({ ...pageQuery, ...date_range, filterParam: appliedFilters })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appliedFilters, date_range])
	useEffect(() => {
		setPageQuery({ ...pageQuery, ...date_range, isOnlyCounterfeits: only_counterfeit })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [only_counterfeit])

	// const handleTimeChange = (dateObj) => {setTimeStamp(dateObj); setIsFilterChanged(true);};

	const handleTimeChange = (dateObj) => {
		dispatch(setThisFilter({ ...dateObj }, FILTER_CONSTANTS.DATE_RANGE))
		setExportList([])
		setTableData([])
		setAllPageProduct(false)
		setSelectionMode(false)
	}

	const noDataFetching = useRef(undefined)
	const firstTimeFetching = useRef(undefined)

	const cancelExecutor = useRef(undefined)

	const [finalQuery, setFinalQuery] = useState({})
	const prevFinalQuery = usePrevious(finalQuery)

	const dispatchTableDataFetching = () => {
		if (!_.isEmpty(finalQuery)) {
			if (cancelExecutor.current !== undefined) {
				cancelExecutor.current()
			}
			dispatch(getProductsTable({ ...finalQuery, ds_id: dataSourceId }, searchQuery, cancelExecutor))
		}
	}

	useEffect(() => {
		if (!_.isEqual(prevFinalQuery, finalQuery) && !_.isEmpty(finalQuery)) {
			dispatchTableDataFetching()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finalQuery, dispatchTableDataFetching, searchQuery])

	useEffect(() => {
		// console.log('appliedFilters :: ', appliedFilters);
		if (/* noDataFetching.current !== true && */ firstTimeFetching.current === 'done') {
			setFinalQuery({
				...appliedFilters,
				product_id: prodIDListForFilter,
				...timeStamp,
				...pageQuery,
				isOnlyCounterfeits,
			})
		}
		noDataFetching.current = undefined
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageQuery])

	useEffect(() => {
		if (isFilterChanged || firstTimeFetching.current === undefined) {
			noDataFetching.current = true
			if (firstTimeFetching.current === undefined) {
				noDataFetching.current = undefined
				firstTimeFetching.current = 'done'
			}
			setFinalQuery({
				...appliedFilters,
				product_id: searchQuery,
				...timeStamp,
				...pageQuery,
				page: 1,
				isOnlyCounterfeits,
			})
			setPageQuery({ ...pageQuery, page: 1 })
			setIsFilterChanged(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFilterChanged, searchQuery, pageQuery, setPageQuery, page, isOnlyCounterfeits])

	// useEffect(()=>{
	//     console.log('setting date_range Filter :::: ', {...timeStamp});
	//     dispatch(setThisFilter({...timeStamp}, FILTER_CONSTANTS.DATE_RANGE))
	// }, [timeStamp]);

	// useEffect(()=>{
	//     if(_.toString(date_range?.startDate) !== _.toString(timeStamp?.startDate) && _.toString(date_range?.endDate) !== _.toString(timeStamp?.endDate)){
	//         setTimeStamp(setTimeParams(initialTime()));
	//         // return false
	//     }
	// }, [date_range])

	useEffect(() => {
		if (!_.isEqual(appliedFilters, prevAppliedFilters) && !_.isEmpty(prevAppliedFilters)) {
			// console.log('coz of filter')
			setIsFilterChanged(true)
		}
	}, [appliedFilters, prevAppliedFilters, setIsFilterChanged])

	const handleCheckBoxClick = (record) => {
		setAllPageProduct(false)
		// e.stopPropagation()
		const isAlreadyAvailable = _.indexOf(tempSelectedList, _.get(record, keyForCheckBoxSelectedList)) !== -1
		const isAllSelected = _.indexOf(tempSelectedList, 'all') !== -1
		if (!isAlreadyAvailable) {
			if (isAllSelected) {
				tempSelectedList = newProductsData.map((product) => product[keyForCheckBoxSelectedList])
				_.remove(tempSelectedList, (objectID) => objectID === _.get(record, keyForCheckBoxSelectedList))
				tempSelectedData = newProductsData.map((p) => p)
				_.remove(tempSelectedData, (p) => _.get(p, keyForCheckBoxSelectedList) === _.get(record, keyForCheckBoxSelectedList))
			} else {
				// if(_.isEmpty(_.get(record, keyForCheckBoxDisabling, ''))){
				tempSelectedList.push(_.get(record, keyForCheckBoxSelectedList))
				tempSelectedData.push(record)
				// }
				// else{
				//     dispatch(showSnackbarWithTimeout('Already assigned with a Job!', 3000))
				// }
			}
		} else if (isAlreadyAvailable) {
			_.remove(tempSelectedList, (objectID) => objectID === _.get(record, keyForCheckBoxSelectedList))
			_.remove(tempSelectedData, (p) => _.get(p, keyForCheckBoxSelectedList) === _.get(record, keyForCheckBoxSelectedList))
		}
		setExportList(tempSelectedList)
		setTableData(tempSelectedData)
	}

	//add sticky button click event
	const exportFxn = (exportList) => {
		// console.log(exportList, 'export')
		if (_.isEmpty(exportList)) {
			dispatch(showSnackbarWithTimeout('Please select some products to add task', 3000))
		} else {
			openStickyDialog([], ROLE_MODULES.PRODUCTS, { products: tableData })
		}
	}

	const handleAllSelectCheckBoxClick = () => {
		if (newProductsData !== '-') {
			const isAllSelected = _.indexOf(tempSelectedList, 'all') !== -1
			const exportListLength = exportList.length
			if (isAllSelected) {
				setExportList([])
				setTableData([])
				setAllPageProduct(false)
				setSelectionMode(false)
			} else {
				if (exportListLength > 0) {
					setExportList([])
					setTableData([])
					setAllPageProduct(false)
					setSelectionMode(false)
				} else {
					setExportList(newProductsData.map((product) => product[keyForCheckBoxSelectedList]))
					setTableData(newProductsData)
					setAllPageProduct(false)
				}
			}
		}
	}

	const handleSelectCurrentPage = () => {
		if (newProductsData !== '-') {
			setExportList(newProductsData.map((product) => product[keyForCheckBoxSelectedList]))
			setTableData(newProductsData)
			setAllPageProduct(false)
		}
	}

	const handleSelectAllPageProducts = () => {
		if (newProductsData !== '-') {
			const isAllSelected = _.indexOf(tempSelectedList, 'all') !== -1
			const exportListLength = exportList.length
			setExportList(newProductsData.map((product) => product[keyForCheckBoxSelectedList]))
			setTableData(newProductsData)
			setAllPageProduct(true)
			setSelectionMode(true)
		}
	}

	const clearSelection = () => {
		setExportList([])
		setTableData([])
		setAllPageProduct(false)
		setSelectionMode(false)
	}

	const goToPage = (pageNo) => {
		setPageQuery({ ...pageQuery, page: pageNo })
		_.isEqual(allPageProduct, true) && clearSelection()
	}

	const setPageLimit = (selectedLimit) => {
		setSortSettings({ keyToSort: '', sortDirection: true })
		setPageQuery({ ...pageQuery, limit: selectedLimit, page: 1 })
	}

	const paginationProperties = (pageNo, limit, totalCount, selectedList) => {
		return getDetailedPaginationProperties(limit, totalCount, pageNo, selectedList)
	}

	useEffect(() => {
		setExportList([])
		setSelectionMode(false)
		setPageQuery({ ...pageQuery, page: 1, sort: sortFilter(sortSettings) })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortSettings])

	// const getTableData = () => {
	//     // setPageQuery({...pageQuery, page: 1, limit : 10, ...timeStamp, sort: sortFilter(sortSettings)})
	//     // dispatch(getProductsTable(params))
	//     // dispatch(getClientId());
	//     // dispatch(getBpId());

	// }
	// useEffect( getTableData , [])

	const wholeData = useSelector((state) => state.productsTable.data)
	const newProductsData = _.get(wholeData, 'chart_data', '-')
	const totalData = _.get(wholeData, 'data_count', '-')
	const isFetchingNewProducts = useSelector((state) => state.productsTable.isFetchingProducts)
	// const isErrorFetchingNewProducts = useSelector(state => state.productsTable.isFetchingProductsFailed);

	useEffect(() => {
		if (wholeData !== 'undefined') {
			setPaginationFooterDetails({
				...paginationProperties(page, limit, _.get(wholeData, 'data_count', ''), exportList),
			})
		}
	}, [wholeData, exportList, limit, page])

	const switchClassesProp = {
		root: classes.root,
		switchBase: classes.switchBase,
		thumb: classes.thumb,
		track: classes.track,
		checked: classes.checked,
		// focusVisible : classes.focusVisible
	}

	function formatDateForLabel(dateString) {
		let dt = new Date(dateString)
		let today = new Date()
		let todayStr = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
		let yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
		let yesterdayStr = yesterday.getDate() + '/' + (yesterday.getMonth() + 1) + '/' + yesterday.getFullYear()
		let dtStr = dt.getDate() + '/' + (dt.getMonth() + 1) + '/' + dt.getFullYear()
		//  console.log("YESTERDAY", yesterdayStr, yesterday)
		//  console.log("GIVEN DATE", dtStr, dt);
		if (_.isEqual(todayStr, dtStr)) {
			return 'Today'
		} else if (_.isEqual(yesterdayStr, dtStr)) {
			return 'Yesterday'
		} else {
			return format(_.isString(dt) ? new Date(dt) : dt, 'dd/MM/yyyy')
		}
	}

	return (
		<>
			<Layout
				headers={headers}
				mobileheaders={mobileheaders}
				mobileHeaderKeys={mobileHeaderKeys}
				mobileContent={mobileContent}
				layoutTitle='Product'
				layoutTitleIcon='category'
				exportBtnTxt={'Export'}
				buttonText={TASK_BUTTON_TXT}
				buttonIcon={<Icon>sticky_note_2</Icon>}
				hideButton={!isTaskAddAllowed}
				handleSearchInputChange={handleSearchInputChange}
				initialTime={date_range}
				handleTimeChange={handleTimeChange}
				isExportAllowed={isExportAllowed}
				// {...(width === 'xs' ? {} : { initialTime: date_range, handleTimeChange: handleTimeChange })}
				page={page}
				limit={limit}
				pageType={'productx'}
				searchSuggestionsProductType='new'
				setPageLimit={setPageLimit}
				goToPage={goToPage}
				additionalTopFilter={[
					<FormControlLabel
						key='Switch to toggle Counterfeits only'
						checked={Boolean(isOnlyCounterfeits)}
						onChange={() => {
							setIsOnlyCounterfeits(!isOnlyCounterfeits)
							setIsFilterChanged(true)
							dispatch(setThisFilter(!isOnlyCounterfeits, FILTER_CONSTANTS.ONLY_COUNTERFEIT))
						}}
						className={classes.inputLabel}
						control={<Switch classes={switchClassesProp} />}
						label={`${_.startCase(_.isEmpty(labelAdjective) ? 'Counterfeit' : labelAdjective)} Only`}
						labelPlacement='end'
					/>,
				]}
				paginationFooterDetails={paginationFooterDetails}
				isLoading={isFetchingNewProducts}
				isCheckBoxNeeded={true}
				keyForCheckBoxSelectedList={keyForCheckBoxSelectedList}
				// keyForCheckBoxDisabling={keyForCheckBoxDisabling}
				onCheckBoxClick={onCheckBoxClick}
				handleCheckBoxClick={handleCheckBoxClick}
				handleAllSelectCheckBoxClick={handleAllSelectCheckBoxClick}
				handleSelectCurrentPage={handleSelectCurrentPage}
				handleSelectAllPageProducts={handleSelectAllPageProducts}
				setExportList={setExportList}
				setTableData={setTableData}
				setAllPageProduct={setAllPageProduct}
				allPageProduct={allPageProduct}
				setType={setType}
				selectedData={tableData}
				searchQuery={searchQuery}
				exportList={exportList}
				exportFxn={exportFxn}
				selectionMode={selectionMode}
				setSelectionMode={setSelectionMode}
				flagStatus={flagStatus}
				fixedLayout
				groupDataBy='crawled_date'
				fxnToFormatGroupByKey={(key) => formatDateForLabel(key)}
				// sortSettings={sortSettings}
				enableBorderBottom
				clearSelection={clearSelection}
				// handleSelectAllProducts={handleSelectAllProducts}
				newFilters={true}
				showTheseFilters={[
					// ...(width === 'xs' ? [FILTER_CONSTANTS.DATE_RANGE] : []),
					FILTER_CONSTANTS.DISCOUNT,
					FILTER_CONSTANTS.PRODUCT_NAME,
					FILTER_CONSTANTS.BRAND,
					FILTER_CONSTANTS.RETAILER,
					FILTER_CONSTANTS.STOCK,
					FILTER_CONSTANTS.STATUS,
					FILTER_CONSTANTS.PRODUCT_ID,
					FILTER_CONSTANTS.RESELLER,
				]}
				// showTheseFilters={['discount', 'product','retailer','stock', 'reseller']}
				data={newProductsData !== '-' && newProductsData}
				totalData={totalData}
			/>

			<ImagePopUp isOpen={isModalOpen} imgProps={dialogPropsState} />
			{/* <CustomDialog
                 open={open}
                 fullWidth={true}
                 maxWidth="lg"
                 disableEscapeKey={false}
                 disableBackdrop={false}
            >
              <CreateJobs 
               totalData={totalData}
               headers={dialogHeaders}
               wholeData={tableData}
               data={tableData}
               checkedProductsList={exportList}
               onCancelClick={onClose}
               product_list={productCode}
               closeWindowFxn={onClose}
              />
              </CustomDialog> */}
		</>
	)
}

export default withWidth()(withAddTaskDialog(NewProducts))
