import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Step, StepLabel, Stepper, Card, CardContent, makeStyles, Grid } from '@material-ui/core'
import GettingStarted from './GetStarted'
import TeamUnitsWrapper from './TeamUnitsWrapper'
import ProjectsWrapper from './ProjectsWrapper'
import GlassXBoard from './GlassXBoard'

WorkflowStepper.propTypes = {
	onFinish: PropTypes.func.isRequired,
}

function getSteps() {
	return ['Getting Started', 'Team units', 'Projects', 'Boards']
}

function getStepContent(step, onClickNext, onClickFinish) {
	switch (step) {
		case 0:
			return <GettingStarted onClickNext={onClickNext} />
		case 1:
			return <TeamUnitsWrapper onClickNext={onClickNext} />
		case 2:
			return <ProjectsWrapper onClickNext={onClickNext} />
		default:
			return <GlassXBoard onClickFinish={onClickFinish} />
	}
}

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2),
		paddingBottom: 60,
	},
	stepperRoot: {
		padding: theme.spacing(0),
	},
	stepperContent: {
		paddingTop: theme.spacing(4),
	},
}))

function WorkflowStepper({ onFinish }) {
	const [activeStep, setActiveStep] = useState(0)
	const classes = useStyles()

	const onClickNext = () => {
		setActiveStep(activeStep + 1)
	}

	const onClickFinish = () => {
		onFinish()
	}

	return (
		<Card className={classes.root} variant='outlined'>
			<CardContent>
				<Grid container>
					<Grid item xs={12}>
						<Stepper className={classes.stepperRoot} alternativeLabel nonLinear activeStep={activeStep}>
							{getSteps().map((stepLabel, index) => (
								<Step key={stepLabel}>
									<StepLabel
										//    onClick={handleStep(index)}
										completed={index < activeStep}
									>
										{stepLabel}
									</StepLabel>
								</Step>
							))}
						</Stepper>
					</Grid>
					<Grid item xs={12} className={classes.stepperContent}>
						{getStepContent(activeStep, onClickNext, onClickFinish)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	)
}

export default WorkflowStepper
