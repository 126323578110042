import React from 'react'
import PropTypes from 'prop-types'
import { ButtonBase, Icon, IconButton, makeStyles, Typography } from '@material-ui/core'
import _ from 'lodash'

LabelList.propTypes = {
	labels: PropTypes.arrayOf(
		PropTypes.shape({
			designation_id: PropTypes.string,
			designation_name: PropTypes.string,
			label_color: PropTypes.string,
		})
	),
	onEditLabelItem: PropTypes.func,
	onClickLabelItem: PropTypes.func,
	value: PropTypes.arrayOf(PropTypes.string),
	hideEditLabel: PropTypes.bool,
}

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(1),
	},
	title: {
		display: 'block',
		marginBottom: theme.spacing(1),
		fontSize: '10px',
		fontWeight: 'bold',
	},
	labelItem: {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
		marginBottom: theme.spacing(0.8),
	},
	itemTextWrap: {
		flex: '1',
		display: 'flex',
		flexDirection: 'row',
		borderRadius: '2px',
		background: '#e0e0e0',
	},
	labelItemTxt: {
		width: '100%',
		flex: '1',
		padding: theme.spacing(0.5, 1),
		textAlign: 'left',
		overflow: 'hidden',
		display: '-webkit-box',
		'-webkit-line-clamp': '1',
		'-webkit-box-orient': 'vertical',
		color: 'rgba(0, 0, 0, 0.87)',
	},
	itemCheckedIco: {
		padding: theme.spacing(0.5, 1),
		color: 'rgba(0, 0, 0, 0.87)',
	},
	loadingTxt: {
		padding: theme.spacing(1.5),
	},
}))

function LabelList({ labels, value, onEditLabelItem, onClickLabelItem, hideEditLabel, hideDeleteLabel, onDeleteLabel, loading }) {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<Typography variant='caption' color='textSecondary' className={classes.title} gutterBottom>
			DESIGNATIONS
			</Typography>
			{_.map(labels, (designation) => (
				<LabelListItem
					key={_.get(designation, 'designation_id')}
					isChecked={_.isEqual(_.get(value, 'designation_id'), _.get(designation, 'designation_id'))}
					classes={classes}
					labelObj={designation}
					onClickLabelItem={onClickLabelItem}
					onEditLabelItem={onEditLabelItem}
					hideEditLabel={hideEditLabel}
					hideDeleteLabel={hideDeleteLabel}
					onDeleteLabel={onDeleteLabel}
				/>
			))}
			{loading && (
				<Typography align='center' className={classes.loadingTxt} component='div' variant='caption' gutterBottom>
					Loading...
				</Typography>
			)}
			{_.isEmpty(labels) && !loading && (
				<Typography component='div' variant='caption' gutterBottom>
					No designations found.
				</Typography>
			)}
		</div>
	)
}

const LabelListItem = ({ classes, labelObj, isChecked, onClickLabelItem, onEditLabelItem, hideEditLabel, hideDeleteLabel, onDeleteLabel }) => {
	return (
		<div className={classes.labelItem}>
			<ButtonBase onClick={(e) => onClickLabelItem(labelObj)} /* style={{ background: _.get(labelObj, 'label_color') }} */ className={classes.itemTextWrap}>
				<Typography variant='caption' component='div' className={classes.labelItemTxt}>
					{_.get(labelObj, 'designation_name')}
				</Typography>
				{isChecked && (
					<Icon fontSize='inherit' className={classes.itemCheckedIco}>
						check
					</Icon>
				)}
			</ButtonBase>
			{(!hideEditLabel && !_.get(labelObj, 'is_default')) &&
				<div>
					<IconButton size='small' onClick={(e) => onEditLabelItem(labelObj)}>
						<Icon fontSize='inherit'>edit</Icon>
					</IconButton>
				</div>
			}
			{(hideEditLabel && !hideDeleteLabel && !_.get(labelObj, 'is_default')) &&
				<div>
					<IconButton size='small' onClick={(e) => onDeleteLabel(labelObj?.designation_id)}>
						<Icon fontSize='inherit'>delete</Icon>
					</IconButton>
				</div>
			}
		</div>
	)
}

export default LabelList
