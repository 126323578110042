import React, { useState, useRef } from 'react'
import { 
    makeStyles, 
    TextField, 
    Avatar, 
    Typography, 
    Icon, 
    Grid, 
    FormHelperText, 
    InputAdornment, 
    IconButton, 
    Slide, 
    Button, 
    Dialog, 
    DialogContent, 
    DialogActions, 
    DialogTitle, 
    LinearProgress,
	Divider,
	Chip,
} from '@material-ui/core'
import classnames from 'classnames'
import RepeatForm from './RepeatForm'
import { useFormik } from 'formik'
import GlassApi from '../../../../../../services/glass/api'
import Axios from 'axios'
import { useDispatch } from 'react-redux'
import { hideLoader, showErrorMessage } from 'services/loader/actions'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { getErrMsg, getSuccessMsg } from 'utils'
import _ from 'lodash'
import moment from 'moment'
import { convertLocalTimeToGmtStr, convertGmtToLocalTime } from 'utils'
import * as Yup from 'yup'

const useStyles = makeStyles((theme) => ({
    detailsRoot: {
		padding: theme.spacing(2, '20px'),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1, 2),
		},
	},
	titleSec: {
		fontSize: 14,
	},
    noneSection: {
		backgroundColor: theme.palette.grey[100],
		padding: 5,
		fontSize: 14,
		color: theme.palette.grey[600],
		marginTop: theme.spacing(1),
	},
    detailsContent: {
		padding: theme.spacing(1, 0),
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: theme.spacing(0.5, 0),
		},
	},
	pr2: {
		paddingRight: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			paddingRight: theme.spacing(0),
			width: '100%',
		},
	},
	editBtn: {
		paddingRight: theme.spacing(2),
		marginTop: '27px',
		[theme.breakpoints.down('sm')]: {
			marginTop: '0px',
			paddingRight: theme.spacing(0),
			width: '100%',
		},
	},
    changeButton: {
		background: theme.palette.almostBlack[300],
		color: theme.palette.almostBlack[700],
		textTransform: 'capitalize',
		'&:hover': {
			background: theme.palette.almostBlack[200],
		},
	},
	dialog: {
		[theme.breakpoints.up('md')]: {
			position: 'absolute',
		},
		maxWidth: '350px',
		maxHeight: 'calc(100% - 36px)',
	},
	disableOnUpdate: {
		opacity: (props) => (props.isLoading ? 0.8 : 1),
		pointerEvents: (props) => (props.isLoading ? 'none' : 'unset'),
	},
	dialogContent: {
		'& .MuiFormControl-marginDense': {
			marginTop: '4px',
		},
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			background: `${theme.palette.primary.main}`,
		},
	},
	dialogFooter: {
		padding: '12px 8px',
		borderTop: `1px solid ${theme.palette.grey[300]}`,
		'& .MuiButton-label': {
			textTransform: 'capitalize',
		},
	},
	detailsTitle: {
		fontSize: 'small',
		color: theme.palette.almostBlack[700],
	},
	detailsValue: {
		fontSize: 'small',
		fontWeight: 400,
	},
	repeatContent: {
		marginBottom: '10px',
	},
}))

const FormSchema = Yup.object().shape({
	type: Yup.string(),
	minutes: Yup.string().when('type', { is: (type) => type === 'Minute wise', then: Yup.string().required('Please select Minutes').nullable(), otherwise: Yup.string().notRequired().nullable()}),
	hours: Yup.string().when('type', { is: (type) => type === 'Hourly', then: Yup.string().required('Please select Hours').nullable(), otherwise: Yup.string().notRequired().nullable()}),
	day: Yup.array().when('type', { is: (type) => type === 'Weekly', then: Yup.array().min(1, 'Please select day').required('Please select day')}),
	date: Yup.array().when('type', { is: (type) => type === 'Monthly' || type === 'Yearly', then: Yup.array().min(1, 'Please select date').required('Please select date')}),
	month: Yup.array().when('type', { is: (type) => type === 'Yearly', then: Yup.array().min(1, 'Please select month').required('Please select month')}),
})

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const RepeatTask = ({ glassCode, stickyCode, sessionId, stickyDetails, updateStickyDetails, disabled }) => {

    const classes = useStyles()
	const dispatch = useDispatch()

	const autoSaveCancelExec = useRef()

	const [isLoading, setLoading] = useState(false)
    const [openRepeatForm, setRepeatForm] = useState(false)

	const onSubmit = (data) => {
		const repeatTaskData = {
			is_repeat_task: !_.isEqual(_.get(data, 'type'), 'None') ? true : false,
			repeat_type: _.get(data, 'type', ''),
			repeat_task: {
				type: _.get(data, 'type', ''),
				minutes: (_.isEqual(_.get(data, 'type', ''), 'Minute wise') || _.isEqual(_.get(data, 'type', ''), 'Hourly')) ? _.get(data, 'minutes', '') : '',
				hours: _.isEqual(_.get(data, 'type', ''), 'Hourly') ? _.get(data, 'hours', '') : '',
				month: _.isEqual(_.get(data, 'type', ''), 'Yearly') ? _.get(data, 'month', []) : [],
				day: _.isEqual(_.get(data, 'type', ''), 'Weekly') ? _.get(data, 'day', []) : [],
				date: (_.isEqual(_.get(data, 'type', ''), 'Monthly') || _.isEqual(_.get(data, 'type', ''), 'Yearly')) ? _.get(data, 'date', []) : [],
				time: convertLocalTimeToGmtStr(data?.time.format('YYYY-MM-DD HH:mm:ss')),
			    custom_date: convertLocalTimeToGmtStr(data?.custom_date.format('YYYY-MM-DD HH:mm:ss')),
			},
		}
		const onSuccess = (res) => {
			setRepeatForm(false)
			dispatch(showSnackbarWithTimeout(getSuccessMsg(res), 1500))
			updateStickyDetails(res?.data?.data)
		}
		const onFailure = (err) => {
			setRepeatForm(false)
			if (!Axios.isCancel(err)) dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		if (autoSaveCancelExec.current) {
			autoSaveCancelExec.current()
		}
		GlassApi.updateStickyRepeatTaskDetails(glassCode, stickyCode, sessionId, repeatTaskData, autoSaveCancelExec).then(onSuccess, onFailure)
	}

	const formik = useFormik({
		initialValues: {
			type: _.get(stickyDetails, 'repeat_task.type', 'None'),
			hours: _.get(stickyDetails, 'repeat_task.hours', ''),
			minutes: _.get(stickyDetails, 'repeat_task.minutes', ''),
			month: _.get(stickyDetails, 'repeat_task.month', []),
			day: _.get(stickyDetails, 'repeat_task.day', []),
			date: _.get(stickyDetails, 'repeat_task.date', []),
			time: moment(_.get(stickyDetails, 'repeat_task.time')),
			custom_date: moment(_.get(stickyDetails, 'repeat_task.custom_date')),
		},
		onSubmit: (e) => {
			onSubmit(e)
		},
		validationSchema: FormSchema,
	})

	const OpenRepeatDialog = () => {
		setRepeatForm(true)
	}

    const handleClose = () => {
		if (!isLoading) {
			setRepeatForm(false)
			formik.resetForm()
		}
	}

    return (
        <div>
            <Dialog
				open={openRepeatForm}
				classes={{ paper: classes.dialog }}
				TransitionComponent={Transition}
				keepMounted
				fullWidth
				onClose={handleClose}
				aria-labelledby='clone-sticky-form'
			>
				{isLoading && <LinearProgress variant='indeterminate' />}
				<DialogTitle>Repeat Task</DialogTitle>
				<Divider />
				<DialogContent className={classnames(classes.dialogContent, classes.disableOnUpdate)}>
					<RepeatForm formik={formik} />
				</DialogContent>
				<DialogActions className={classnames(classes.dialogFooter, classes.disableOnUpdate)}>
					<Button
						variant='outlined'
						color='primary'
						size='medium'
						fullWidth
						disableElevation
						onClick={() => {
							handleClose()
							// formik.handleReset()
						}}
					>
						Cancel
					</Button>
					<Button size='medium' fullWidth variant='contained' color='primary' type='submit' disableElevation onClick={formik?.handleSubmit}>
						Save
					</Button>
				</DialogActions>
			</Dialog>
			{(_.isEqual(_.get(stickyDetails, 'repeat_task.type'), 'None') || _.isEmpty(_.get(stickyDetails, 'repeat_task.type'))) ? (
            <Grid container>
				<Grid item lg={6} md={6} className={classnames(classes.detailsContent, classes.pr2)}>
					<Typography variant='subtitle2' component='p' className={classes.titleSec}>
						{'Repeat Task'}
					</Typography>
						<div role='button'>
							<p className={classes.noneSection}>None</p>
						</div>
				</Grid>
				<Grid item lg={6} md={6} className={classnames(classes.detailsContent, classes.editBtn)}>
				    <Typography variant='subtitle2' component='p' className={classes.titleSec}>
						{''}
					</Typography>
					<Button size='small' disabled={disabled} className={classes.changeButton} onClick={() =>OpenRepeatDialog()}>Edit</Button>
				</Grid>
            </Grid>)
			: (
			<>
				<Grid container>
					<Grid item lg={6} md={6} className={classnames(classes.detailsContent, classes.pr2)}>
						<Typography variant='subtitle2' component='p' className={classes.titleSec}>
							{'Repeat Task'}
						</Typography>
					</Grid>
                </Grid>
				<Grid container className={classes.repeatContent}>
					<Grid item xs={12} sm={2}>
						<Typography className={classes.detailsTitle} gutterBottom>
							Type
						</Typography>
					</Grid>
					<Grid item xs={12} sm={10}>
						<Typography className={classes.detailsValue} gutterBottom>
							{_.get(stickyDetails, 'repeat_task.type')}
						</Typography>
					</Grid>
				</Grid>
				{_.isEqual(_.get(stickyDetails, 'repeat_task.type'), 'Minute wise') &&
				<Grid container className={classes.repeatContent}>
					<Grid item xs={12} sm={2}>
						<Typography className={classes.detailsTitle} gutterBottom>
							Minutes
						</Typography>
					</Grid>
					<Grid item xs={12} sm={10}>
						<Typography className={classes.detailsValue} gutterBottom>
							{_.get(stickyDetails, 'repeat_task.minutes')} Minutes
						</Typography>
					</Grid>
				</Grid>}
				{!_.isEmpty(_.get(stickyDetails, 'repeat_task.hours')) && _.isEmpty(_.get(stickyDetails, 'repeat_task.minutes')) &&
				<Grid container className={classes.repeatContent}>
					<Grid item xs={12} sm={2}>
						<Typography className={classes.detailsTitle} gutterBottom>
							Hours
						</Typography>
					</Grid>
					<Grid item xs={12} sm={10}>
						<Typography className={classes.detailsValue} gutterBottom>
							{_.get(stickyDetails, 'repeat_task.hours')} Hours
						</Typography>
					</Grid>
				</Grid>}
				{!_.isEmpty(_.get(stickyDetails, 'repeat_task.hours')) && !_.isEmpty(_.get(stickyDetails, 'repeat_task.minutes')) &&
				<Grid container className={classes.repeatContent}>
					<Grid item xs={12} sm={2}>
						<Typography className={classes.detailsTitle} gutterBottom>
							Hours
						</Typography>
					</Grid>
					<Grid item xs={12} sm={10}>
						<Typography className={classes.detailsValue} gutterBottom>
							{_.get(stickyDetails, 'repeat_task.hours')} Hours {_.get(stickyDetails, 'repeat_task.minutes')} Minutes
						</Typography>
					</Grid>
				</Grid>}
				{!_.isEmpty(_.get(stickyDetails, 'repeat_task.month')) &&
				<Grid container className={classes.repeatContent}>
					<Grid item xs={12} sm={2}>
						<Typography className={classes.detailsTitle} gutterBottom>
							Months
						</Typography>
					</Grid>
					<Grid item xs={12} sm={10} style={{ display: 'flex', gap: '4px' }}>
						{_.map(_.get(stickyDetails, 'repeat_task.month'), (month, index)=>(
							<Chip size='small' label={month}/>
						))}
					</Grid>
				</Grid>}
				{!_.isEmpty(_.get(stickyDetails, 'repeat_task.day')) &&
				<Grid container className={classes.repeatContent}>
					<Grid item xs={12} sm={2}>
						<Typography className={classes.detailsTitle} gutterBottom>
							Days
						</Typography>
					</Grid>
					<Grid item xs={12} sm={10} style={{ display: 'flex', gap: '4px' }}>
						{_.map(_.get(stickyDetails, 'repeat_task.day'), (day, index)=>(
							<Chip size='small' label={day}/>
						))}
					</Grid>
				</Grid>}
				{!_.isEmpty(_.get(stickyDetails, 'repeat_task.date')) &&
				<Grid container className={classes.repeatContent}>
					<Grid item xs={12} sm={2}>
						<Typography className={classes.detailsTitle} gutterBottom>
							Date
						</Typography>
					</Grid>
					<Grid item xs={12} sm={10} style={{ display: 'flex', gap: '4px' }}>
						{_.map(_.get(stickyDetails, 'repeat_task.date'), (date, index)=>(
							<Chip size='small' label={date}/>
						))}
					</Grid>
				</Grid>}
				{(_.isEqual(_.get(stickyDetails, 'repeat_task.type'), 'Daily') || _.isEqual(_.get(stickyDetails, 'repeat_task.type'), 'Weekly')  || _.isEqual(_.get(stickyDetails, 'repeat_task.type'), 'Monthly') || _.isEqual(_.get(stickyDetails, 'repeat_task.type'), 'Yearly')) &&
				<Grid container className={classes.repeatContent}>
					<Grid item xs={12} sm={2}>
						<Typography className={classes.detailsTitle} gutterBottom>
							Time
						</Typography>
					</Grid>
					<Grid item xs={12} sm={10}>
						<Typography className={classes.detailsValue} gutterBottom>
							{convertGmtToLocalTime(_.get(stickyDetails, 'repeat_task.time'), 'hh:mm a')}
						</Typography>
					</Grid>
				</Grid>}
				{_.isEqual(_.get(stickyDetails, 'repeat_task.type'), 'Custom') &&
				<Grid container className={classes.repeatContent}>
					<Grid item xs={12} sm={2}>
						<Typography className={classes.detailsTitle} gutterBottom>
							Custom Date
						</Typography>
					</Grid>
					<Grid item xs={12} sm={10}>
						<Typography className={classes.detailsValue} gutterBottom>
							{convertGmtToLocalTime(_.get(stickyDetails, 'repeat_task.custom_date'), 'DD MMM YYYY hh:mm a')}
						</Typography>
					</Grid>
				</Grid>}
				<Grid container>
					<Grid item lg={6} md={6} className={classnames(classes.detailsContent, classes.pr2)}>
					    <Button size='small' disabled={disabled} className={classes.changeButton} onClick={() =>OpenRepeatDialog()}>Edit</Button>
					</Grid>
				</Grid>
			</>
			)
			}
        </div>
    )
}

export default RepeatTask