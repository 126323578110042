import { Drawer, makeStyles } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import Header from './Header'
import GlassApi from '../../../services/glass/api'
import Axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { convertGmtToLocalTime, convertLocalTimeToGmtStr, getErrMsg } from 'utils'
import { hideLoader, showConfirmMessage, showErrorMessage, showLoader } from 'services/loader/actions'
import PropTypes from 'prop-types'
import { updateGlassSticky } from 'services/glass/actions'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import session from 'services/session/reducers'
import SliderTab from './SliderTab'
import StickyLoader from './stickyloader'
import { getModelxDetails } from '../../../services/modelx/actions'
import projectApi from '../../../services/projects_creation/api'

const useStyles = makeStyles((theme) => ({
	drawer: {
		'& .MuiDrawer-paperAnchorRight': {
			width: '45%',
			maxWidth: '600px',
			// padding: '10px 20px',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				padding: 0,
			},
		},
		'& .MuiBackdrop-root': {
			opacity: 0,
		},
	},
	loaderContainer: {
		padding: '10px 20px',
	},
}))

const stcikySchema = Yup.object().shape({
	sticky_title: Yup.string().required('Please enter the Task Title'),
	// due_date: Yup.date().nullable().min(new Date(), 'Due date cannot be in the past.'),
	is_approval: Yup.boolean(),
	reporting_to: Yup.object()
		.nullable()
		.when('is_approval', {
			is: true,
			then: Yup.object().nullable().required('Please select reporting to'),
			otherwise: null,
		}),
})

const Slider = ({
	match,
	onClose,
	glassCode,
	glassLabels,
	sessionId,
	glassMembers,
	glassStickyGroups,
	isOwner,
	isMember,
	activityTypes,
	socketRef,
	isSocketConnected,
	authToken,
	history,
	isGuest,
	lanesData,
    slider,
	onClickGlassX,
	onClickDashX,
	onClickEdit,
	hideDeleteBtn,
	hideEditBtn,
	disableDashxBtn,
	onClickDeleteMenu,
}) => {
	const classes = useStyles()

    const dispatch = useDispatch()

    const cancelExecutor = useRef()

    const basicDetails = useSelector((state) => state.modelxTable?.modelx_details)

	const [projectDetail, setProjectDetail] = useState({ loading: false, project: {} })

	const { loading, project } = projectDetail

	const getSingleUser = (idx) => {
		setProjectDetail({ loading: true, project: {} })
		projectApi.fetchSingleProject(
			idx,
			(res) => {
				let value = res.data.data
				setProjectDetail({ loading: false, project: value })
			},
			(err) => {
				setProjectDetail({ loading: false, project: {} })
				dispatch(
					showErrorMessage(_.get(err, 'response.data.more_info', 'Something went wrong. Please try again'), 'Close', () => {
						dispatch(hideLoader())
					})
				)
			}
		)
	}

    useEffect(() => {
        // if (cancelExecutor.current !== undefined) {
        //     cancelExecutor.current()
        // }
        {!_.isEmpty(slider?.id) && getSingleUser(slider?.id)}
    }, [slider?.id])

	return (
		<Drawer anchor='right' open={slider?.open} onClose={onClose} className={classes.drawer}>
			{loading ? (
				<div className={classes.loaderContainer}>
					<StickyLoader />
				</div>
			) : (
				<React.Fragment>
					<Header
						onClose={onClose}
						history={history}
                        slider={slider}
						onClickDeleteMenu={onClickDeleteMenu}
						onClickEdit={onClickEdit}
						hideDeleteBtn={hideDeleteBtn}
						hideEditBtn={hideEditBtn}
					/>
                    <SliderTab 
                        slider={slider}
                        basicDetails={basicDetails}
						project={project}
						onClickGlassX={onClickGlassX}
						onClickDashX={onClickDashX}
						disableDashxBtn={disableDashxBtn}
                    />
				</React.Fragment>
			)} 
		</Drawer>
	)
}

export default Slider
