import moment from 'moment'
import { ACTION_TYPES, FILTER_CONSTANTS } from '../../../constants'

const initialState = {
	[FILTER_CONSTANTS.REDIRECT_URL]: false,
	[FILTER_CONSTANTS.SORT_BY]: null,
	[FILTER_CONSTANTS.SORT_BY_ORDER]: 'asc',
	[FILTER_CONSTANTS.CREATED_DATE]: {},
	[FILTER_CONSTANTS.MODIFIED_DATE]: {},
	[FILTER_CONSTANTS.CREATED_BY]: [],
	[FILTER_CONSTANTS.MODIFIED_BY]: [],
	[FILTER_CONSTANTS.START_DATE]: {},
	[FILTER_CONSTANTS.END_DATE]: {},
	[FILTER_CONSTANTS.STATUS]: [],
	[FILTER_CONSTANTS.RATING]: [0, 5],
	[FILTER_CONSTANTS.LABELS]: [],
}

const filtersNew = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.SET_THIS_FILTER: {
			return {
				...state,
				[action.payload.filterKey]: action.payload.filter,
			}
		}

		case ACTION_TYPES.SET_PROJECT_FILTER: {
			return {
				...state,
				...action.payload,
			}
		}

		// case ACTION_TYPES.SET_STYLE_CODE_FILTER: {
		// 	return {
		// 		...state,
		// 		objectID: [...action.payload],
		// 	}
		// }
		// case ACTION_TYPES.SET_RETAILER_FILTER: {
		// 	return {
		// 		...state,
		// 		retailer: [...action.payload],
		// 	}
		// }
		// case ACTION_TYPES.SET_PRODUCT_FILTER: {
		// 	return {
		// 		...state,
		// 		product: [...action.payload],
		// 	}
		// }
		// case ACTION_TYPES.SET_RESELLER_FILTER: {
		// 	return {
		// 		...state,
		// 		reseller: [...action.payload],
		// 	}
		// }
		// case ACTION_TYPES.SET_STOCK_FILTER: {
		// 	return {
		// 		...state,
		// 		stock: [...action.payload],
		// 	}
		// }
		// case ACTION_TYPES.SET_PRICE_FILTER: {
		// 	return {
		// 		...state,
		// 		price: [...action.payload],
		// 	}
		// }
		// case ACTION_TYPES.SET_SCRAPPER_FILTER: {
		// 	return {
		// 		...state,
		// 		scrapper: action.payload,
		// 		manual_update:
		// 			action.payload === 'all' ? [true, false] : action.payload === 'on' ? [false] : action.payload === 'off' ? [true] : [true, false],
		// 	}
		// }
		case ACTION_TYPES.CLEAR_ALL_FILTERS: {
			return Object.assign({}, initialState)
		}
		case ACTION_TYPES.CLEAR_THIS_FILTER: {
			if (action.payload === 'scrapper') {
				return {
					...state,
					[action.payload]: initialState[action.payload],
					manual_update: initialState['manual_update'],
				}
			} else {
				return {
					...state,
					[action.payload]: initialState[action.payload],
				}
			}
		}
		default: {
			return state
		}
	}
}

export default filtersNew
