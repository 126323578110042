import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { getImgUrl } from 'utils'
// import {APP_CONFIGS} from '../../constants';

const useStyles = makeStyles((theme) => ({
	root: {
		border: `1px dashed ${theme.palette.text.secondary}`,
		position: 'relative',
		'&.error': {
			border: `1px dashed ${theme.palette.error.main}`,
		},
		'&:hover': {
			'& $delBtn': {
				display: 'flex',
				'&:hover': {
					color: theme.palette.error.main,
					backgroundColor: theme.palette.primary.contrastText,
				},
			},
		},
	},
	input: {
		display: 'none',
	},
	iconBtn: {
		width: '100%',
		height: '100%',
		borderRadius: 0,
		boxSizing: 'border-box',
	},
	prevImg: {
		width: '100%',
		height: '100%',
	},
	delBtn: {
		display: 'none',
		position: 'absolute',
		right: theme.spacing(0.5),
		top: theme.spacing(0.5),
		background: theme.palette.common.white,
		'& i': {
			fontSize: 20,
		},
	},
}))

const File = (props) => {
	const { field, form, imgRatioClass, placeholder, disabled, maxHeight } = props

	const classes = useStyles()

	let fileInput = React.createRef()
	const token = useSelector((state) => _.get(state, 'session.authToken', ''))

	const choosenFile = form.values[field.name]
	const errMsg = form.errors[field.name] && String(form.errors[field.name])

	const onFileChange = (event) => {
		const files = fileInput.current.files
		if (!_.isEmpty(files)) {
			field.onChange({ target: { name: field.name, value: files[0] } })
		}
		//Allow to choose same file (Keep this statement at very last)
		event.target.value = null
	}

	//    const getImageUrl = (path) => {
	//         return `${API_ENDPOINTS.VIEW_IMAGE}${path}&Authorization=Bearer ${token}`
	//    }

	const getImage = (value) => {
		return typeof value === 'string' ? getImgUrl(token, value) : URL.createObjectURL(value)
	}

	const deleteImg = () => {
		field.onChange({ target: { name: field.name, value: '' } })
	}

	return (
		<>
			<div
				className={classnames(classes.root, imgRatioClass, {
					error: !_.isEmpty(errMsg),
				})}
			>
				{!choosenFile || !_.isEmpty(errMsg) ? (
					<>
						<input
							name={field.name}
							disabled={disabled}
							ref={fileInput}
							accept='image/*'
							onChange={onFileChange}
							className={classes.input}
							id={field.name}
							type='file'
						/>
						<label htmlFor={field.name}>
							<IconButton disabled={disabled} className={classes.iconBtn} color='primary' aria-label='upload picture' component='span'>
								<div>
									<i className='material-icons'>add_photo_alternate</i>
								</div>
								<Typography variant='body1'>{placeholder}</Typography>
							</IconButton>
						</label>
					</>
				) : (
					<>
						{!disabled && (
							<IconButton onClick={deleteImg} size='small' className={classes.delBtn}>
								<i className='material-icons'>delete</i>
							</IconButton>
						)}
						<img alt={field.name} className={classes.prevImg} src={getImage(choosenFile)} style={{ maxHeight: maxHeight ? maxHeight : 'none'}}/>
					</>
				)}
			</div>
			{errMsg && (
				<Typography variant='caption' color='error' component='div'>
					{errMsg}
				</Typography>
			)}
		</>
	)
}

File.propTypes = {
	imgRatioClass: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
}

export default File
