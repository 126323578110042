import React, { useMemo } from 'react'
import { Grid, Tab, Tabs, Typography, makeStyles } from '@material-ui/core'
import _ from 'lodash'
import TabPanel from 'components/TabPanel'
import { useUrlSearchParams } from 'hooks/use-url-search-params'
import PropTypes from 'prop-types'
import TaskInfo from './TaskInfo'
import TaskUpdates from './TaskUpdates'
import SubTask from './SubTask'

const defaultTabId = 'info'

const useStyles = makeStyles((theme) => ({
	tabs: {
		minHeight: 'unset',
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	tab: {
		minHeight: 'unset',
		textTransform: 'unset',
	},
}))

function StickyTabs({
	isOwner,
	glassCode,
	glassMembers,
	glassLabels,
	sessionId,
	formik,
	onAddAttachments,
	updateStickyDetails,
	stickyDetails,
	loading,
	isEditEnabled,
	isGuest,
	attachmentEditEnabled,
	stickyId,
	attachmentLoading,
	onDeleteAttachment,
	onDownloadAttachment,
	activityTypes,
	panes,
	sticky_group,
	socketRef,
	isSocketConnected,
	isOwnerOrReporter,
	isTimerEnabled,
	onSubmitLinkData,
	onCreateSubtask,
	isMember,
	history,
	handleCustomLabel,
}) {
	const classes = useStyles()
	const [pageQuery, setPageQuery] = useUrlSearchParams({}, { tabId: String })
	const { parent_sticky_code } = stickyDetails
	const stickyTabs = useMemo(
		() =>
			_.compact([
				{
					id: 'info',
					label: 'Task Info',
					tabPanel: TaskInfo,
				},
				{
					id: 'update',
					label: 'Task Updates',
					tabPanel: TaskUpdates,
				},
				// _.isEmpty(parent_sticky_code) && {
				// 	id: 'task',
				// 	label: 'Subtask',
				// 	tabPanel: SubTask,
				// },
			]),
		[parent_sticky_code]
	)

	const activeTabIdx = useMemo(() => _.findIndex(stickyTabs, { id: _.get(pageQuery, 'tabId') || defaultTabId }), [pageQuery, stickyTabs])

	const onChangeTab = (e, tabIdx) => {
		setPageQuery({ tabId: _.get(stickyTabs, `[${tabIdx}].id`) }, true)
	}

	const membersSuggestions = useMemo(
		() => _.map(glassMembers, (member) => ({ text: _.join([member?.first_name, member?.last_name], ' '), value: member?.email, url: '' })),
		[glassMembers]
	)

	return (
		<Grid container>
			<Grid item xs={12}>
				<Tabs
					variant='scrollable'
					className={classes.tabs}
					size='small'
					value={activeTabIdx}
					indicatorColor='primary'
					textColor='primary'
					onChange={onChangeTab}
					aria-label='disabled tabs example'
				>
					{_.map(stickyTabs, (tab, index) => (
						<Tab key={tab.id} className={classes.tab} label={<Typography variant='body2'>{tab.label}</Typography>} />
					))}
				</Tabs>
			</Grid>
			<Grid item xs={12}>
				{_.map(stickyTabs, (tab, index) => (
					<TabPanel key={tab.id} label={tab.label} value={activeTabIdx} index={index}>
						{React.createElement(tab.tabPanel, {
							isOwner,
							glassCode,
							glassMembers,
							isMember,
							glassLabels,
							sessionId,
							formik,
							stickyDetails,
							isEditEnabled,
							isGuest,
							attachmentEditEnabled,
							loading,
							stickyId,
							onAddAttachments,
							attachmentLoading,
							onDeleteAttachment,
							onDownloadAttachment,
							updateStickyDetails,
							activityTypes,
							panes,
							sticky_group,
							socketRef,
							isSocketConnected,
							membersSuggestions,
							isOwnerOrReporter,
							isTimerEnabled,
							onSubmitLinkData,
							onCreateSubtask,
							history,
							handleCustomLabel,
						})}
					</TabPanel>
				))}
			</Grid>
		</Grid>
	)
}

StickyTabs.propTypes = {
	isOwner: PropTypes.bool,
	glassCode: PropTypes.string,
	glassMembers: PropTypes.array,
	glassLabels: PropTypes.array,
	sessionId: PropTypes.string,
	formik: PropTypes.object,
	stickyDetails: PropTypes.object,
	loading: PropTypes.bool,
	isEditEnabled: PropTypes.bool,
	stickyId: PropTypes.string,
	isTimerEnabled: PropTypes.bool,
}

export default StickyTabs
