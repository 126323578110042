import React, { useCallback, useEffect, useState } from 'react'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { Button, makeStyles, TextField, Grid, Typography, Chip } from '@material-ui/core'
import _ from 'lodash'
import glassApi from '../../../../../../../services/glass/api'
import { useDispatch } from 'react-redux'
import { getErrMsg } from 'utils'
import { hideLoader, showErrorMessage } from 'services/loader/actions'

const useStyles = makeStyles((theme) => ({
	addBtn: { marginTop: theme.spacing(1) },
}))

const LinkStickyForm = ({ glassCode, stickyCode, formik, isOwnerOrReporter, glassEmpty, stickyOptions, stickyLoading, onInputChange }) => {
	const classes = useStyles()
	const [searchData, setSearchData] = useState({ query: '', loading: false, stickyList: [] })
	const { query, loading, stickyList } = searchData
	const dispatch = useDispatch()

	const filterOptions = createFilterOptions({
		stringify: ({ sticky_code, sticky_title }) => `${sticky_code} ${sticky_title} `,
	})

	// const getOptions = useCallback(() => {
	// 	setSearchData((prevState) => ({ ...prevState, loading: true }))
	// 	const onSuccess = (res) => {
	// 		setSearchData((prevState) => ({ ...prevState, loading: false, stickyList: res?.data?.data?.stickies || [] }))
	// 	}
	// 	const onFailure = (err) => {
	// 		setSearchData((prevState) => ({ ...prevState, loading: false, stickyList: [] }))
	// 		dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
	// 	}
	// 	let params = {
	// 		search_text: query ? query : '',
	// 		offset: '0',
	// 		limit: '5',
	// 	}
	// 	glassApi.getUnlinedStickies(glassCode, stickyCode, params).then(onSuccess, onFailure)
	// }, [dispatch, glassCode, query, stickyCode])

	// useEffect(() => {
	// 	getOptions()
	// }, [dispatch, glassCode, stickyCode, query, getOptions])

	// const onInputChange = useCallback(
	// 	_.debounce((e) => {
	// 		setSearchData((prevState) => ({ ...prevState, query: e?.target?.value }))
	// 	}, 500),
	// 	[]
	// )

	const onChangeSticky = (e, value) => {
		formik?.setFieldValue('sticky', value)
		setSearchData((prevState) => ({ ...prevState, query: value?.sticky_title }))
	}

	return (
		<form onSubmit={formik?.handleSubmit}>
			<Autocomplete
				id='select-sticky'
				options={stickyOptions}
				multiple
				getOptionLabel={(option) => option.sticky_title}
				fullWidth={true}
				disabled={!isOwnerOrReporter || glassEmpty}
				loading={stickyLoading}
				onInputChange={onInputChange}
				filterSelectedOptions={true}
				name='sticky'
				filterOptions={filterOptions}
				value={formik?.values?.sticky}
				onChange={onChangeSticky}
				getOptionSelected={(option, value) => option.sticky_code === value.sticky_code}
				renderTags={(value, getTagProps) =>
					value.map((option, index) => (
						<Chip
							variant='default'
							size='small'
							color='primary'
							key={option.sticky_code}
							label={option.sticky_title}
							onDelete={() => console.log('test')}
							{...getTagProps({ index })}
						/>
					))
				}
				renderOption={(option) => {
					return (
						<Grid container alignItems='center'>
							<Grid item xs>
								<span>{option?.sticky_title}</span>

								<Typography variant='body2' color='textSecondary'>
									{option.sticky_code}
								</Typography>
							</Grid>
						</Grid>
					)
				}}
				renderInput={(params) => (
					<TextField
						error={Boolean(formik?.errors?.sticky)}
						helperText={Boolean(formik?.errors?.sticky) && formik?.errors?.sticky}
						margin='dense'
						{...params}
						placeholder='Select task to add'
						variant='outlined'
					/>
				)}
			/>
			<Button
				variant='contained'
				disabled={!isOwnerOrReporter}
				color='primary'
				type='submit'
				size='small'
				className={classes.addBtn}
				disableElevation
			>
				Add
			</Button>
		</form>
	)
}

export default LinkStickyForm
