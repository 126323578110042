import { TablePagination, Button } from '@material-ui/core'
import cloneDeep from 'lodash/cloneDeep'
import React from 'react'
import { TableHead, TableRow, TableCell, Table, TableBody, Tooltip, IconButton, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { data } from './data'
// import './styles.css'
import FormDialog from './FormDialog'
import SearchBox from '../../../../components/SearchBox'
import _ from 'lodash'
import classnames from 'classnames'
import DeleteIcon from '@material-ui/icons/Delete'

const tableHead = {
	img: 'PROFILE',
	label: 'NAME',

	rating: 'RATING',
	budget: 'BUDGET',
	skill: 'SKILLS',
	location: 'LOCATION',
	// action: 'Action',
}

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.others.tableHeaderBg.main,
	},
	header: {
		fontWeight: '700',
		textTransform: 'uppercase',
		padding: '4px 14px',
		fontSize: theme.typography.pxToRem(10),
		borderBottom: 'none',
		letterSpacing: '2px',
	},
	overflowTextDots: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
}))

const Tableview = (props) => {
	// const countPerPage = 10
	const classes = useStyles()
	const { values, errors, touched, handleBlur, handleChange, disabled } = props
	const [countPerPage, setcountPerPage] = React.useState(10)
	const [value, setValue] = React.useState('')
	const [people, setPeople] = React.useState(data)
	const [currentPage, setCurrentPage] = React.useState(0)
	const [openFormDialog, setopenFormDialog] = React.useState(false)
	const [collection, setCollection] = React.useState(cloneDeep(data.slice(0, countPerPage)))

	React.useEffect(() => {
		if (!value) {
			updatePage(0, 0)
		} else {
			// searchData(value)
			// throttle((val) => {
			const query = value.toLowerCase()
			setCurrentPage(0)
			const data1 = cloneDeep(data.filter((item) => item.name.toLowerCase().indexOf(query) > -1).slice(0, countPerPage))
			setCollection(data1)
			// }, 400)
		}
	}, [value, data])

	React.useEffect(() => {
		setCollection(cloneDeep(data.slice(0, countPerPage)))
	}, [data])

	const updatePage = (p, pageNo) => {
		setCurrentPage(pageNo)
		const from = countPerPage * pageNo
		const to = from + countPerPage
		setCollection(cloneDeep(data.slice(from, to)))
	}

	const handleChangeRowsPerPage = (event) => {
		setcountPerPage(parseInt(event.target.value, 10))
		setCurrentPage(0)
		setCollection(cloneDeep(data.slice(0, parseInt(event.target.value, 10))))
	}

	const deletepeople = (key) => {
		setCollection(collection.filter((s) => s.id !== key['id']))
		setPeople(people.filter((s) => s.id !== key['id']))
		handleChange({
			target: { name: 'people', value: collection.filter((s) => s.id !== key['id']) },
		})
	}

	const tableRows = (rowData) => {
		const { key, index } = rowData
		const tableCell = Object.keys(tableHead)
		const columnData = tableCell.map((keyD, i) => {
			function validURL(str) {
				var pattern = new RegExp(
					'^(https?:\\/\\/)?' + // protocol
						'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
						'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
						'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
						'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
						'(\\#[-a-z\\d_]*)?$',
					'i'
				) // fragment locator
				return !!pattern.test(str)
			}
			return validURL(key[keyD]) === true ? (
				<TableCell key={i}>
					<img src={key[keyD]} style={{ width: 60 }}>
						{/* <td key={i}>{key[keyD]}</td> */}
					</img>
				</TableCell>
			) : keyD === 'action' ? (
				<TableCell key={i}>
					<Tooltip title='Delete'>
						<IconButton key='Delete' onClick={(e) => deletepeople(key)} className={classes.actionBtn} aria-label='Delete'>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				</TableCell>
			) : (
				<TableCell key={i}>{key[keyD]}</TableCell>
			)
		})

		return <TableRow key={index}>{columnData}</TableRow>
	}

	const tableData = () => {
		// console.log(collection, 'list1')
		return collection.map((key, index) => tableRows({ key, index }))
	}

	const headRow = () => {
		return Object.values(tableHead).map((title, index) => <td key={index}>{title}</td>)
	}

	const handleOpenFormDialog = (e, isRefreshList) => {
		setopenFormDialog(true)
		console.log(openFormDialog, 'open')
		// history.replace({
		// 	isRefreshList: true,
		// })
	}

	const handleCloseFormDialog = (e, isRefreshList) => {
		setopenFormDialog(false)
		// history.replace({
		// 	isRefreshList: true,
		// })
	}

	const onSkipClick = (notes) => {
		// console.log(notes, 'note')
		setPeople(notes)
		setCollection(notes)
		handleChange({
			target: { name: 'people', value: notes },
		})
		setopenFormDialog(false)
	}

	return (
		<>
			<div class='search' style={{ display: 'flex', flexBasis: 'column', justifyContent: 'end', marginBottom: 10 }}>
				{/* <input placeholder='Search people...' value={value} onChange={(e) => setValue(e.target.value)} style={{ marginRight: 20 }} /> */}
				<SearchBox
					value={value}
					placeholder='Search'
					// searchBarClassName={classes.searchBarWidth}
					// containerClassName={classes.searchBarContainer}
					onChange={(e) => setValue(e.target.value)}
				></SearchBox>
				<Button
					onClick={handleOpenFormDialog}
					color='primary'
					variant='contained'
					size='medium'
					// startIcon={<i className='material-icons'> memory </i>}
					disableElevation
				>
					Add
				</Button>
			</div>
			<Table>
				<TableHead className={classes.root}>
					<TableRow>
						{Object.values(tableHead).map((header, idx) => {
							return (
								<TableCell
									key={idx}
									className={classes.header}
									style={_.isNil(header.width) ? {} : { width: header.width }}
									align={header.align}
									{...(header.isImage ? { padding: 'none' } : {})}
								>
									<div
										className={classnames({
											[classes.overflowTextDots]: !_.isNil(header.width),
										})}
										style={_.isNil(header.width) ? {} : { width: '100%' }}
									>
										{header}
									</div>
								</TableCell>
							)
						})}
					</TableRow>
				</TableHead>
				<TableBody>{tableData()}</TableBody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 25, 50]}
				component='div'
				rowsPerPage={countPerPage}
				page={currentPage}
				count={data.length}
				onChangePage={updatePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
			<FormDialog open={openFormDialog} streams={people} onSkipClick={onSkipClick} handleClose={handleCloseFormDialog}></FormDialog>
		</>
	)
}
export default Tableview
