import React, { useState, useEffect } from 'react'
import {
	makeStyles,
	Accordion,
	AccordionSummary,
	Icon,
	Grid,
	TextField,
	Typography,
	AccordionDetails,
	ListItem,
	ListItemIcon,
	List,
	Checkbox,
	ListItemText,
	Button,
	CircularProgress,
	RadioGroup,
	FormControlLabel,
	Radio,
	IconButton,
	Chip,
	Badge,
} from '@material-ui/core'
import SearchBox from './SearchBox'
// import { useDispatch } from 'react-redux';
import _ from 'lodash'
import classnames from 'classnames'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles((theme) => ({
	expansionPanelRoot: {
		borderBottom: 0,
		background: 'transparent',
		boxShadow: 'none',
		zIndex: '0',
		'&.Mui-expanded': {
			margin: theme.spacing(0.5, 0),
		},
		'&:not(:last-child)': {
			// borderBottom: '1px solid rgba(0, 0, 0, .125)',
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 0,
		},
		'& .MuiAccordionSummary-expandIcon': {
			color: theme.palette.almostBlack[600],
		},
	},
	expanded: {},
	filterPanelTitleContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	filterTitleWithChip: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	resetSpan: {
		padding: theme.spacing(0.5),
		color: theme.palette.almostBlack[800],
		borderRadius: '5px',
		transition: 'background 0.3s',
		cursor: 'pointer',
		// fontFamily: '"Poppins"',
		fontSize: theme.typography.pxToRem(10),
		'&:hover': {
			background: `${theme.palette.primary.main}11`,
			transition: 'background 0.3s',
		},
	},
	root: {
		padding: theme.spacing(0),
		'&.Mui-expanded': {
			minHeight: 'unset',
			// margin: theme.spacing(0.5,0)
		},
		'& .MuiIconButton-colorPrimary:hover': {
			backgroundColor: 'transparent',
		},
		'& .MuiSvgIcon-root': {
			// color: `${theme.palette.primary.dark}`
		},
		'& .MuiTypography-body1': {
			// lineHeight: 0,
			fontWeight: 400,
		},
		'& .MuiFormGroup-row': {
			width: '100%',
			justifyContent: 'space-between',
		},
	},
	muiPanelSummaryRoot: {
		'&.Mui-expanded': {
			margin: theme.spacing(0.5, 0),
		},
	},
	iconRoot: {
		minWidth: 'auto',
	},
	listRoot: {},
	listItemRoot: {
		paddingTop: theme.spacing(0),
		paddingBottom: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		cursor: 'pointer',
		alignItems: 'center',
	},
	filterPanelHeading: {
		color: theme.palette.almostBlack[800],
		letterSpacing: theme.typography.pxToRem(0.8),
	},
	listText: {
		color: theme.palette.almostBlack[800],
		// textTransform: 'uppercase',
		'& .MuiTypography-root': {
			// lineHeight: 0,
			fontWeight: 400,
			width: '100%',
			whiteSpace: 'nowrap',
			// textOverflow: 'ellipsis',
			// overflow: 'hidden',
		},
	},
	searchContainer: {
		padding: theme.spacing(1, 0),
	},
	capitaliseText: {
		textTransform: 'capitalize !important',
	},
	searchIcon: {
		color: theme.palette.almostBlack[400],
	},
	styleOriginTopRightRectangle: {
		top: '50%',
		right: '-20px',
		'&.MuiBadge-dot': {
			top: '-22px',
			right: '-4px',
		},
	},
	anchorOriginTopRightRectangle: {
		top: '50%',
		right: '-20px',
		'&.MuiBadge-dot': {
			top: '10%',
			right: '-4px',
		},
		[theme.breakpoints.down('lg')]: {
			// top: '2px',
			// right: '-6px',
			// fontWeight: '700',
			// fontSize: theme.typography.pxToRem(10),
			// width: 14,
			// height: 14,
			// minWidth: 14,
			// borderRadius: 14,
		},
	},
	positionRelativeContainer: {
		position: 'relative',
	},
	positionAbsoluteContainer: {
		position: 'absolute',
		top: '45px',
		backgroundColor: 'white',
		width: 'calc(100% - 2px)',
		left: '1px',
	},
}))

const FilterPanel = (props) => {
	const {
		title,
		handleClearThisFilter,
		capitaliseText,
		isPreLoaded,
		noSort,
		isSearchNeeded,
		isRadio,
		isLoading,
		options,
		optionKey,
		textKey,
		handleSearch,
		handleCheckBoxClick,
		selectedOptions,
		loadMoreFxn,
		hasExtraHeight,
		filterDesc,
		renderAsChips,
		clearText,
		style
	} = props

	const classes = useStyles()
	const [isExpanded, setIsExpanded] = useState(false)

	let optionKeyHere = optionKey
	let textKeyHere = textKey

	const [radioValue, setRadioValue] = useState(selectedOptions)
	const [searchQuery, setSearchQuery] = React.useState('')
	const [allOptions, setAllOptions] = React.useState(options)

	const handleRadioChange = (e) => {
		setRadioValue(e.target.value)
		handleCheckBoxClick(e.target.value)
	}

	useEffect(() => setRadioValue(selectedOptions), [selectedOptions])

	const isDataChecked = (optionName) => {
		return !_.isEmpty(selectedOptions) && selectedOptions.indexOf(optionName) > -1
	}

	const handleSearchInput = (keyValue) => {
		setSearchQuery(keyValue)
		// handleSearch(keyValue.replace(/[^\w\s]/gi, ''))
		// handleSearch(keyValue)

		const data = _.cloneDeep(options.filter((item) => item.toLowerCase().indexOf(keyValue.toLowerCase()) > -1).slice(0, options.length))
		_.isEmpty(keyValue) ? setAllOptions(options) : setAllOptions(data)
	}

	const handleClearInputBtn = () => {
		setSearchQuery('')
		// handleSearch('', true)
		setAllOptions(options)
	}

	const handleResetClick = (e) => {
		e.stopPropagation()
		handleClearThisFilter()
		// setSearchQuery('')
		setAllOptions(options)
	}

	useEffect(() => {
		setSearchQuery('')
		// if (handleSearch) {
		// 	handleSearch('', true)
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clearText])

	const emptyOption = { [optionKeyHere]: '', [textKeyHere]: '-N/A-' }

	const properizeOptions = _.isNil(optionKeyHere)
		? allOptions.map((option) => {
				optionKeyHere = 'value'
				textKeyHere = 'text'
				return { [optionKeyHere]: option, [textKeyHere]: option }
		  })
		: allOptions
	const removeEmptyOptions = properizeOptions.map((option) => (_.isNil(option[optionKeyHere]) ? emptyOption : option))
	const unOrderedList = removeEmptyOptions.map((option) => ({
		...option,
		isChecked: isDataChecked(_.get(option, optionKeyHere, '-')),
	}))
	const orderedList = _.orderBy(unOrderedList, ['isChecked', optionKeyHere], ['desc', 'asc'])
	const optionsList = noSort && noSort === true ? unOrderedList : _.sortedUniqBy(_.orderBy(orderedList), optionKeyHere)

	const filteredList =
		_.isEmpty(searchQuery) || !isPreLoaded
			? optionsList
			: // : optionsList.filter((dataObj) => dataObj[textKeyHere].toLowerCase().match(searchQuery.replace(/[^\w\s]/gi, '').toLowerCase()))
			  optionsList.filter((dataObj) => dataObj[textKeyHere].toLowerCase().match(searchQuery.toLowerCase()))

	return (
		<>
			<Accordion
				expanded={isExpanded}
				classes={{
					root: classes.expansionPanelRoot,
					expanded: classes.expanded,
				}}
				onChange={() => setIsExpanded(!isExpanded)}
				elevation={0}
			>
				<AccordionSummary classes={{ root: classes.root, content: classes.muiPanelSummaryRoot }} expandIcon={<Icon>keyboard_arrow_down</Icon>}>
					<div className={classes.filterPanelTitleContainer}>
						<div className={classes.filterTitleWithChip}>
						{(_.gt(selectedOptions.length, 0)
						) ? (
							<>
							{style ?
							<>
							<Typography className={classes.filterPanelHeading} variant='subtitle2' title={filterDesc}>
								{title}
							</Typography>
							<Badge
								badgeContent={isRadio ? (selectedOptions === allOptions[0][optionKeyHere] ? 0 : selectedOptions) : selectedOptions.length}
								max={9}
								variant={'dot'}
								color='error'
								classes={{
									anchorOriginTopRightRectangle: classes.styleOriginTopRightRectangle,
								}}
							>
							</Badge>
							</>
							:
							<Badge
								badgeContent={isRadio ? (selectedOptions === allOptions[0][optionKeyHere] ? 0 : selectedOptions) : selectedOptions.length}
								max={9}
								variant={'dot'}
								color='error'
								classes={{
									anchorOriginTopRightRectangle: classes.anchorOriginTopRightRectangle,
								}}
							>
								<Typography className={classes.filterPanelHeading} variant='subtitle2' title={filterDesc}>
									{title}
								</Typography>
							</Badge>
							}
							</>
							) : (
								<Typography className={classes.filterPanelHeading} variant='subtitle2'>
									{title}
								</Typography>
							)}
							{/* <Chip size='small' clickable={false} color='primary' label='1' /> */}
						</div>
						{/* {
                        handleClearThisFilter && <Typography variant='caption' className={classes.resetSpan} component='span' onClick={handleResetClick}>RESET</Typography>
                    } */}
						{
							handleClearThisFilter ? (
								isRadio && selectedOptions !== allOptions[0][optionKeyHere] ? (
									<Typography variant='caption' className={classes.resetSpan} component='span' onClick={handleResetClick}>
										RESET
									</Typography>
								) : !isRadio && selectedOptions.length !== 0 ? (
									<Typography variant='caption' className={classes.resetSpan} component='span' onClick={handleResetClick}>
										RESET
									</Typography>
								) : null
							) : null
							// :null
						}
					</div>
				</AccordionSummary>
				<AccordionDetails classes={{ root: classes.root }}>
					<Grid container className={classes.positionRelativeContainer}>
						{isSearchNeeded && isSearchNeeded ? (
							<Grid item xs={12} className={classes.searchContainer}>
								{renderAsChips && renderAsChips === true ? (
									<Autocomplete
										multiple
										freeSolo
										options={allOptions}
										size='small'
										getOptionLabel={(option) => option}
										onChange={(e, v) => handleCheckBoxClick(v /* _.isObject(v[0]) ? v[0][optionKeyHere] : v[0] */ /* e.target.value */)}
										defaultValue={selectedOptions}
										onInputChange={(e, v) => handleSearch(v.replace(/[^\w\s]/gi, ''))}
										renderTags={(value, getTagProps) =>
											value.map((option, index) => <Chip variant='outlined' size='small' label={option} {...getTagProps({ index })} />)
										}
										renderInput={(params) => (
											<TextField
												{...params}
												// adornment={
												//     isLoading
												//     ?   <CircularProgress style={{marginRight: '4px'}} size={20} />
												//     :   searchQuery===''
												//             ?   <Icon className={classes.searchIcon}>search</Icon>
												//             :   <IconButton onClick={()=>setSearchQuery('')} size='small' title='Clear Search'><Icon className={classes.searchIcon}>close</Icon></IconButton>}
												variant='outlined'
												label={title}
												size='small' /* placeholder='Type to search values' */
											/>
										)}
									/>
								) : (
									<SearchBox
										onChange={(e) => handleSearchInput(e.target.value)}
										value={searchQuery}
										adornment={
											isLoading ? (
												<CircularProgress style={{ marginRight: '4px' }} size={20} />
											) : searchQuery === '' ? (
												<Icon className={classes.searchIcon}>search</Icon>
											) : (
												<IconButton onClick={handleClearInputBtn} size='small' title='Clear Search'>
													<Icon className={classes.searchIcon}>close</Icon>
												</IconButton>
											)
										}
									/>
								)}
							</Grid>
						) : null}
						{/* {
                        renderAsChips && renderAsChips===true
                            ?   <Grid item xs={12}>
                                    {selectedOptions && selectedOptions.map((option,idx) => {
                                        return (
                                            <Chip key={option+idx} onDelete={()=>handleCheckBoxClick(option)} label={_.isNil(option) ? 'N/A' : option} size='small' variant='default' />
                                        )
                                    })}
                                </Grid> 
                            : null
                    } */}
						{isRadio && isRadio === true ? (
							<>
								<RadioGroup value={radioValue} onChange={handleRadioChange} row>
									{filteredList &&
										filteredList.map((option, idx) => {
											return (
												<FormControlLabel
													key={option + idx}
													value={_.get(option, optionKeyHere)}
													control={<Radio color='secondary' />}
													label={_.get(option, textKeyHere)}
													labelPlacement='end'
												/>
											)
										})}
								</RadioGroup>
							</>
						) : (
							<>
								{_.isNil(renderAsChips) || renderAsChips !== true ? (
									<Grid item xs={12} style={hasExtraHeight ? { maxHeight: '380px', overflowY: 'auto' } : { maxHeight: '200px', overflowY: 'auto' }}>
										<List className={classes.listRoot}>
											{filteredList && _.isEmpty(filteredList) && !isLoading && searchQuery !== '' ? (
												<Grid item container justifyContent='center' alignItems='baseline'>
													<Typography variant='subtitle1' component='span'>
														No Match Found
													</Typography>
													<Button color='primary' onClick={handleClearInputBtn}>
														Clear Search
													</Button>
												</Grid>
											) : (
												filteredList &&
												filteredList.map((option, idx) => {
													if (_.isNil(_.get(option, textKeyHere))) {
														return null
													} else {
														return (
															<ListItem
																key={idx}
																onClick={() => handleCheckBoxClick(_.get(option, optionKeyHere, idx))}
																disableGutters
																classes={{ root: classes.listItemRoot }}
															>
																<ListItemIcon classes={{ root: classes.iconRoot }}>
																	<Checkbox edge='start' color='secondary' checked={_.get(option, 'isChecked', false)} disableRipple size='small' />
																</ListItemIcon>
																<ListItemText
																	primary={_.isNil(_.get(option, optionKeyHere, idx)) ? 'N/A' : _.get(option, optionKeyHere, idx)}
																	title={_.isNil(_.get(option, optionKeyHere, idx)) ? 'N/A' : _.get(option, optionKeyHere, idx)}
																	primaryTypographyProps={{ variant: 'body2' }}
																	className={classnames(classes.listText, {
																		[classes.capitaliseText]: capitaliseText,
																	})}
																/>
															</ListItem>
														)
													}
												})
											)}
										</List>
										{loadMoreFxn && isPreLoaded === false && searchQuery === '' && !isLoading ? (
											<Grid item container justifyContent='center'>
												<Button color='primary' onClick={loadMoreFxn}>
													Load More
												</Button>
											</Grid>
										) : null}
									</Grid>
								) : null}
							</>
						)}
					</Grid>
				</AccordionDetails>
			</Accordion>
		</>
	)
}

export default FilterPanel
