import { put, call, all, takeLatest } from 'redux-saga/effects'
import { ACTION_TYPES, SAGA_ACTIONS, API_ENDPOINTS } from '../../constants'
import Axios from 'axios'
import { watchGetRetailerSuggestions, watchGetProductSuggessions, watchGetResellerSuggessions } from '../searchSuggestions/sagas'
import productApi from '../products/api'
import peoplexApi from '../peoplex/api'
import modelxApi from '../modelx/api'
import { watchFetchJobs, watchFetchJobByJobId } from '../jobs/sagas'
import { watchGetSuggestions } from '../filterSuggestions/sagas'
import { watchGetPeoplexSuggestions } from '../peoplexFilterSuggestions/sagas'
import { watchGetModelxSuggestions } from '../modelxFilterSuggestions/sagas'
import { watchGetProjectSuggestions } from '../projectFilterSuggestions/sagas'
import { watchFetchActivitiesForJob, watchFetchProductsForJob } from '../viewJobData/sagas'
import { watchFetchAppConfigs } from '../appsettings/sagas'
import UserSagas from '../users/sagas'
import SessionSagas from '../session/sagas'
import RoleSagas from '../roles/sagas'
import TeamSagas from '../teams/sagas'
import ShareSagas from '../share/sagas'
import GlassSagas from '../glass/sagas'
import UserConfigSagas from '../user_config/sagas'
import MytaskSagas from '../myTask/sagas'
import ModelxSagas from '../modelx/sagas'
import PeoplexSagas from '../peoplex/sagas'

function* workGetProductsTable(action) {
	yield put({ type: ACTION_TYPES.GET_PRODUCTS_TABLE_PENDING })

	try {
		const products = yield call(productApi.getProductsTableData, action.query, action.search, action.cancelExecutor)
		yield put({
			type: ACTION_TYPES.GET_PRODUCTS_TABLE_FULFILLED,
			payload: products,
		})
	} catch (err) {
		yield put({ type: ACTION_TYPES.GET_PRODUCTS_TABLE_REJECTED, payload: err })
		console.log(err)
	}
}

function* workGetPeoplexTable(action) {
	yield put({ type: ACTION_TYPES.GET_PEOPLEX_TABLE_PENDING })

	try {
		const products = yield call(peoplexApi.getProductsTableData, action.query, action.search, action.cancelExecutor)
		yield put({
			type: ACTION_TYPES.GET_PEOPLEX_TABLE_FULFILLED,
			payload: products,
		})
	} catch (err) {
		yield put({ type: ACTION_TYPES.GET_PEOPLEX_TABLE_REJECTED, payload: err })
		console.log(err)
	}
}

// function* workGetModelxTable(action) {
// 	yield put({ type: ACTION_TYPES.GET_MODELX_TABLE_PENDING })

// 	try {
// 		const products = yield call(modelxApi.getModelxTableData, action.query, action.search, action.redirect, action.cancelExecutor)
// 		yield put({
// 			type: ACTION_TYPES.GET_MODELX_TABLE_FULFILLED,
// 			payload: products,
// 		})
// 	} catch (err) {
// 		yield put({ type: ACTION_TYPES.GET_MODELX_TABLE_REJECTED, payload: err })
// 		console.log(err)
// 	}
// }

// function* workGetModelxDetails(action) {
// 	yield put({ type: ACTION_TYPES.GET_MODELX_DETAILS_PENDING })

// 	try {
// 		const modelx = yield call(modelxApi.getModelxDataDetails, action.modelxId, action.cancelExecutor)
// 		yield put({
// 			type: ACTION_TYPES.GET_MODELX_DETAILS_FULFILLED,
// 			payload: modelx,
// 		})
// 	} catch (err) {
// 		yield put({ type: ACTION_TYPES.GET_MODELX_DETAILS_REJECTED, payload: err })
// 		console.log(err)
// 	}
// }

// function* workGetModelxLogs(action) {
// 	yield put({ type: ACTION_TYPES.GET_MODELX_LOGS_PENDING })

// 	try {
// 		const modelx_logs = yield call(modelxApi.getModelxLogs, action.modelxId, action.cancelExecutor)
// 		yield put({
// 			type: ACTION_TYPES.GET_MODELX_LOGS_FULFILLED,
// 			payload: modelx_logs,
// 		})
// 	} catch (err) {
// 		yield put({ type: ACTION_TYPES.GET_MODELX_LOGS_REJECTED, payload: err })
// 		console.log(err)
// 	}
// }

function* workGetClientId() {
	yield put({ type: ACTION_TYPES.GET_CLIENT_ID_PENDING })
	try {
		const resposeData = yield Axios.get(API_ENDPOINTS.GET_CLIENT_ID, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
		// console.log(resposeData);
		yield put({
			type: ACTION_TYPES.GET_CLIENT_ID_FULFILLED,
			payload: resposeData.data,
		})
	} catch (error) {
		console.log(error)
	}
}

function* workGetAssignTo(action) {
	yield put({ type: ACTION_TYPES.GET_ASSIGN_TO_PENDING })
	try {
		const resposeData = yield Axios.get(`${API_ENDPOINTS.GET_ASSIGN_TO}/${action.clientId}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
		// console.log(resposeData);
		yield put({
			type: ACTION_TYPES.GET_ASSIGN_TO_FULFILLED,
			payload: resposeData.data,
		})
	} catch (error) {
		console.log(error)
	}
}

function* workGetBpId() {
	yield put({ type: ACTION_TYPES.GET_CLIENT_ID_PENDING })
	try {
		const resposeData = yield Axios.get(API_ENDPOINTS.GET_BP_ID, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
		// console.log(resposeData);
		yield put({
			type: ACTION_TYPES.GET_BP_ID_FULFILLED,
			payload: resposeData.data,
		})
	} catch (error) {
		console.log(error)
	}
}

function* workCreateJobs(action) {
	yield put({ type: ACTION_TYPES.CREATE_JOB_PENDING })
	try {
		const resposeData = yield Axios.post(`${API_ENDPOINTS.CREATE_JOB}`, action.payload, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
		// console.log(resposeData);
		yield put({
			type: ACTION_TYPES.CREATE_JOB_FULFILLED,
			payload: resposeData.data,
		})
	} catch (error) {
		console.log(error)
		yield put({
			type: ACTION_TYPES.CREATE_JOB_REJECTED,
			payload: error,
		})
	}
}

function* watchGetProductsTable() {
	yield takeLatest(SAGA_ACTIONS.GET_PRODUCTS_TABLE, workGetProductsTable)
}
function* watchGetPeoplexTable() {
	yield takeLatest(SAGA_ACTIONS.GET_PEOPLEX_TABLE, workGetPeoplexTable)
}
// function* watchGetModelxTable() {
// 	yield takeLatest(SAGA_ACTIONS.GET_MODELX_TABLE, workGetModelxTable)
// }
// function* watchGetModelxDetails() {
// 	yield takeLatest(SAGA_ACTIONS.GET_MODELX_DETAILS, workGetModelxDetails)
// }
// function* watchGetModelxLogs() {
// 	yield takeLatest(SAGA_ACTIONS.GET_MODELX_LOGS, workGetModelxLogs)
// }
function* watchGetClientId() {
	yield takeLatest(SAGA_ACTIONS.GET_CLIENT_ID, workGetClientId)
}
function* watchGetAssignTo() {
	yield takeLatest(SAGA_ACTIONS.GET_ASSIGN_TO, workGetAssignTo)
}
function* watchGetBpId() {
	yield takeLatest(SAGA_ACTIONS.GET_BP_ID, workGetBpId)
}

function* watchCreateJobs() {
	yield takeLatest(SAGA_ACTIONS.CREATE_JOB, workCreateJobs)
}

export default function* rootSaga() {
	yield all([
		watchGetRetailerSuggestions(),
		watchGetProductsTable(),
		watchGetPeoplexTable(),
		// watchGetModelxTable(),
		// watchGetModelxDetails(),
		// watchGetModelxLogs(),
		watchGetProductSuggessions(),
		watchGetResellerSuggessions(),
		watchFetchJobs(),
		watchGetSuggestions(),
		watchGetPeoplexSuggestions(),
		watchGetModelxSuggestions(),
		watchGetProjectSuggestions(),
		watchGetClientId(),
		watchGetAssignTo(),
		watchFetchActivitiesForJob(),
		watchFetchProductsForJob(),
		watchGetBpId(),
		watchCreateJobs(),
		watchFetchJobByJobId(),
		watchFetchAppConfigs(),
		...SessionSagas,
		...UserSagas,
		...RoleSagas,
		...TeamSagas,
		...ShareSagas,
		...GlassSagas,
		...UserConfigSagas,
		...MytaskSagas,
		...ModelxSagas,
		...PeoplexSagas,
	])
}
