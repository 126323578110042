import React, { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import {
	makeStyles,
	Avatar,
	Badge,
	Grid,
	Typography,
	Icon,
	Button,
	IconButton,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	FormControlLabel,
	Radio,
	Checkbox,
	CircularProgress,
	ButtonGroup,
	// useMediaQuery,
	// useTheme,
	Dialog,
	TextField,
	Tabs,
	Tab,
	Box,
	Chip,
	FormHelperText,
	Switch,
} from '@material-ui/core'
import classnames from 'classnames'
import { escapeRegExp, formTheName, getImgUrl, stringToHslColor } from 'utils'
import { useSelector } from 'react-redux'
import SearchBox from 'components/PainSearchBox'
import Axios from 'axios'
import DateInput from 'components/DateInput'
// import ca from 'date-fns/esm/locale/ca/index.js'
import AutoComplete from '@material-ui/lab/Autocomplete'

export const MAIN_HEADER_HEIGHT = 64
export const BOARD_HEADER_HEIGHT = 48
export const MENU_WIDTH = 320

export const MAIN_HEADER_HEIGHT_MOBILE = 57
export const BOARD_HEADER_HEIGHT_MOBILE = 48
export const MENU_WIDTH_MOBILE = 320

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		// marginLeft: 8, marginRight: 8
	},
	avatar: {
		width: `100%`,
		height: `100%`,
		fontSize: 16,
		lineHeight: 'unset',
		// position: 'absolute',
		// top: 0, left: 0,
		boxSizing: 'border-box',
		// transition: 'top 150ms',
		// '&:hover': {
		// top: `-4px`
		// }
	},
	avatarBorderDiv: {
		width: `100%`,
		height: `100%`,
		// fontSize: 16,
		// lineHeight: 'unset',
		position: 'absolute',
		top: 0,
		left: 0,
		boxSizing: 'border-box',
		transition: 'top 150ms',
		borderRadius: '50%',
		border: `0px solid blue`,
		'&:hover:not($otherAvatar)': {
			top: `-4px`,
		},
	},
	otherAvatar: {},
	otherAvatarOpened: {
		zIndex: 9999,
	},
	selectedAvatar: {
		border: `2px solid #0052cc`,
	},
	avatarContainer: (props) => ({
		width: 36,
		height: 36,
		position: 'relative',
		marginLeft: -8,
		cursor: 'pointer',
		'&:hover': {
			// top: `-4px`,
			zIndex: `${props?.maxZ || 9999} !important`,
			transition: `z-index 150ms`,
		},
	}),
	popoverRoot: {
		// marginTop: theme.spacing(1),
		// padding: theme.spacing(2)
	},
	content: {
		width: '300px',
		border: `1px solid ${theme.palette.grey[300]}`,
		padding: theme.spacing(0),
		// maxHeight: `calc(100vh - (${MAIN_HEADER_HEIGHT}px + ${BOARD_HEADER_HEIGHT}px))`,
		height: 'auto',
	},
	dialogPaper: {
		height: 'auto',
		maxHeight: `calc(100vh - 58px)`,
	},
	dialogScrollPaper: {
		alignItems: 'flex-end',
	},
	filterContent: {
		width: 0,
	},
	menuHeader: {
		width: '100%',
		height: 40,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: `1px solid ${theme.palette.almostBlack[200]}`,
		boxSizing: 'border-box',
		// boxShadow: `0px 1px 8px 0px ${theme.palette.almostBlack[200]}`,
		[theme.breakpoints.down('sm')]: {
			minHeight: BOARD_HEADER_HEIGHT_MOBILE,
		},
	},
	menuHeaderAndResetBtn: {
		flex: 1,
		display: 'flex',
		gap: `8px`,
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: 12,
	},
	menuTitle: {
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 500,
		letterSpacing: `1.125px`,
	},
	menuHeaderIconDiv: {
		width: 32,
		height: 32,
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& .MuiIconButton-root': {
			padding: theme.spacing(0.75),
			fontSize: theme.typography.pxToRem(20),
			'& .MuiIcon-root': {
				fontSize: theme.typography.pxToRem(20),
			},
		},
	},
	listContainer: {
		overflowX: 'hidden',
		overflowY: 'auto', //height: 'auto',
		// marginTop: 1,
		padding: theme.spacing(0),
		// maxHeight: `calc(100vh - (${MAIN_HEADER_HEIGHT}px + (1 * ${BOARD_HEADER_HEIGHT}px) + 40px))`,
		flex: 1,
		height: '100%',
		[theme.breakpoints.down('xs')]: {
			maxHeight: 'unset',
			paddingBottom: BOARD_HEADER_HEIGHT,
			display: 'inline-block',
			maxWidth: '100%',
		},
		[theme.breakpoints.down('md')]: {
			display: 'unset',
		},
		'&::-webkit-scrollbar,::-webkit-scrollbar-track': {
			width: '8px',
			height: '8px',
			'-webkit-appearance': 'none',
			backgroundColor: 'transparent',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.palette.almostBlack[400],
			height: '20px',
			borderRadius: '4px',
		},
	},
	listItemContainer: {
		padding: theme.spacing(0.5),
		margin: theme.spacing(0.5, 0, 0.5, 0),
		borderRadius: 4,
		border: `1px solid transparent`,
		cursor: 'pointer',
		// '&:hover': {
		//     background: theme.palette.almostBlack[100]
		// }
	},
	selectedListItem: {
		background: `${theme.palette.success.light}25`,
		borderColor: `${theme.palette.success.light}`,
	},
	userPic: {
		width: 36,
		height: 36,
		marginRight: theme.spacing(1),
		fontSize: 17,
	},
	optionTextEllipsis: {
		width: `100%`,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	filterPanelTitleContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	filterTitleWithChip: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		// '& .MuiBadge-anchorOriginTopRightRectangle': {
		//     top: `4px`, right: `-4px`
		// }
	},
	anchorOriginTopRightRectangle: {
		top: `4px`,
		right: `-4px`,
	},
	filterPanelHeading: {
		fontSize: theme.typography.pxToRem(14),
		color: theme.palette.almostBlack[800],
		fontWeight: 'bold',
		letterSpacing: theme.typography.pxToRem(0.8),
	},
	resetSpan: {
		padding: theme.spacing(0.5),
		color: theme.palette.almostBlack[800],
		borderRadius: '5px',
		transition: 'background 0.3s',
		fontFamily: '"Poppins"',
		fontSize: theme.typography.pxToRem(10),
		'&:hover': {
			background: `${theme.palette.primary.main}11`,
			transition: 'background 0.3s',
		},
	},
	muiExpansionPanelRoot: {
		width: '100%',
		'&:before': {
			height: 2,
			background: 'white',
		},
		'&.Mui-expanded': {
			margin: 0,
			'& + .MuiAccordion-root:before': {
				display: 'block',
			},
			'&:before': {
				opacity: 1,
			},
		},
		'& .MuiAccordionSummary-expandIcon': {
			padding: theme.spacing(1),
		},
	},
	muiExpanded: {
		// margin: 0,
		'&:first-child': {
			// marginTop: 0
		},
		'&:last-child': {
			// marginBottom: 0
		},
	},
	muiExpansionPanelSummaryRoot: {
		minHeight: 40,
		background: theme.palette.almostBlack[200],
		'&$muiExpanded': {
			minHeight: 40,
		},
	},
	muiExpansionPanelSummaryContent: {
		margin: [[`0px 0px`], '!important'],
		'&$muiExpanded': {
			margin: `12px 0px`,
		},
	},
	muiExpansionPanelDetailsRoot: {
		padding: theme.spacing(0.5, 0, 0.5),
		flexDirection: 'column',
		'& $optionListItemContainer:last-child': {
			borderBottom: `none`,
		},
	},
	optionsListContainer: {
		maxHeight: 200,
		overflowY: 'auto',
		marginTop: 4,
		'&::-webkit-scrollbar,::-webkit-scrollbar-track': {
			width: '8px',
			height: '8px',
			'-webkit-appearance': 'none',
			backgroundColor: 'transparent',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.palette.almostBlack[400],
			height: '20px',
			borderRadius: '4px',
		},
	},
	optionListItemContainer: {
		width: '100%',
		borderBottom: `1px solid ${theme.palette.almostBlack[200]}`,
		boxSizing: 'border-box',
		padding: theme.spacing(0, 2),
		cursor: 'pointer',
		'&:hover': {
			background: theme.palette.almostBlack[100],
		},
	},
	optionItemRadio: {
		padding: 6,
		'& .MuiSvgIcon-root': {
			fontSize: theme.typography.pxToRem(18),
		},
	},
	optionListLabelContainer: {
		display: 'flex',
		gap: `4px`,
		alignItems: 'center',
	},
	optionListLabelColorDiv: {
		width: 16,
		height: 16,
		borderRadius: 4,
	},
	optionItemLabel: {
		fontSize: theme.typography.pxToRem(14),
	},
	searchContainer: {
		padding: theme.spacing(0, 1),
		// border: `1px solid red`
	},
	searchInput: {
		// background: 'red'
		borderRadius: 4,
	},
	resetBtn: {
		padding: theme.spacing(0.25, 0.75),
		fontSize: theme.typography.pxToRem(11),
		minWidth: 48,
	},
	filterFabBtn: {
		position: 'fixed',
		bottom: theme.spacing(1.5),
		right: theme.spacing(1.5),
	},
	bottomButtonContainer: {
		// border: `1px solid blue`,
		background: 'white',
		width: '100%',
		height: 48,
		margin: 0,
		marginTop: 2,
		boxShadow: `0px -1px 1px 0px rgb(100 100 100 / 20%)`,
	},
	tabs: {
		'& .Mui-selected': {
			backgroundColor: theme.palette.primary.main,
			color: 'white',
		},
	},
	tabSeperateor: {
		borderRight: `1px solid ${theme.palette.grey[300]}`,
		padding: 3,
	},
}))

const getFiltersWithValuesCount = (data) => _.reduce(data || {}, (acc, o) => (_.isEmpty(o) ? acc : acc + 1), 0)

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			style={{ width: '100%' }}
			role='tabpanel'
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box style={{ padding: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	}
}

const FilterPopup = (props) => {
	const classes = useStyles()
	// const theme = useTheme()
	const { activeGlassDetails } = useSelector((state) => state?.glass)
	// const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'))
	const [hasChanges, setHasChanges] = useState(false)
	const { filterStructure } = props
	const groupedData = _.groupBy(filterStructure, 'category')

	const [currentActiveFilters, setCurrentActiveFilters] = useState(props?.activeFilters)
	const [appliedFiltersCount] = useState(() => getFiltersWithValuesCount(props?.activeFilters))
	const { isMobileView } = props

	const [value, setValue] = React.useState(2)
	const [cancelled, setCancelled] = useState(false)
	const [toggle, setToggle] = useState(false)

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	const eliminateCommon = _.size(currentActiveFilters) === 1 && currentActiveFilters && 'common' in currentActiveFilters ? false : true

	const toggleChange = (event) => {
		setToggle(!toggle)
		if (event.target.checked === true) {
			setValue(1)
		} else {
			setValue(2)
		}
	}

	useEffect(() => {
		let tempHasChanges
		if (!_.isEqual(currentActiveFilters, props?.activeFilters)) {
			tempHasChanges = true
		} else if (appliedFiltersCount !== getFiltersWithValuesCount(currentActiveFilters) && getFiltersWithValuesCount(currentActiveFilters) !== 0) {
			tempHasChanges = true
		} else {
			tempHasChanges = false
		}
		setHasChanges(tempHasChanges)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentActiveFilters, props?.activeFilters])

	const updateFilter = (path, value, isSub, isMulti, category, filterId) => {
		let temp = _.cloneDeep(currentActiveFilters || {})
		let tempValue = value
		let existingPathValue = _.cloneDeep(_.get(temp, path))
		let existingPathState = _.isArray(value)
			? _.isEmpty(_.xor(existingPathValue, value))
			: _.isPlainObject(value)
			? isMulti
				? !!_.find(existingPathValue, value)
				: _.isEqual(existingPathValue, value)
			: _.includes(existingPathValue, value)

		let commonValue = []
		let isCommonValueAvailable = _.indexOf(commonValue, value) !== -1

		if (!isCommonValueAvailable) {
			commonValue.push(value)
		} else {
			_.remove(commonValue, (key) => key === value)
		}

		if (existingPathState && !_.isEmpty(existingPathValue)) {
			if (_.isObject(existingPathValue)) {
				if (_.isArray(value)) {
					tempValue = []
				} else if (_.isPlainObject(value)) {
					if (isMulti) {
						_.remove(existingPathValue, value)
						tempValue = existingPathValue
					} else {
						tempValue = {}
					}
				} else {
					_.remove(existingPathValue, (o) => _.isEqual(value, o))
					tempValue = existingPathValue
				}
			} else {
				tempValue = ''
			}
		} else if (!existingPathState && !_.isEmpty(existingPathValue)) {
			if (_.isArray(existingPathValue)) {
				tempValue = _.isString(value) || _.isNumber(value) ? [...existingPathValue, value] : _.uniq(_.concat(existingPathValue, value))
			} else if (_.isPlainObject(value) && isMulti) {
				tempValue = [...existingPathValue, value]
			}
		} else if (!existingPathState && _.isEmpty(existingPathValue) && (_.isPlainObject(value) || _.isString(value)) && isMulti) {
			tempValue = [value]
		}
		_.set(
			temp,
			path,
			(isSub && _.isString(tempValue)) || _.isNumber(tempValue) ? [tempValue] : _.isNull(tempValue) && isMulti ? [tempValue] : tempValue
		)

		if (_.isEmpty(_.get(temp, path))) {
			_.unset(temp, path)
			temp = _.reduce(
				temp,
				(acc, o, k) => {
					if (!_.isEmpty(o)) {
						acc[k] = o
					}
					return acc
				},
				{}
			)
		}
		setCurrentActiveFilters(temp)
		setHasChanges(true)
	}

	const resetFunc = (path, value) => {
		let tempState = _.cloneDeep(currentActiveFilters || {})
		_.set(tempState, path, value)
		if (_.isEmpty(_.get(tempState, path))) {
			_.unset(tempState, path)
			tempState = _.reduce(
				tempState,
				(acc, o, k) => {
					if (!_.isEmpty(o)) {
						acc[k] = o
					}
					return acc
				},
				{}
			)
		}
		setCurrentActiveFilters(tempState)
	}

	const resetAllFunc = () => {
		if (currentActiveFilters && 'common' in currentActiveFilters) {
			setCurrentActiveFilters({ common: currentActiveFilters.common })
		} else {
			setCurrentActiveFilters({})
		}
	}

	useEffect(() => {
		setCurrentActiveFilters(props?.activeFilters || {})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.activeFilters])

	const onCancelClicked = () => {
		setCurrentActiveFilters(props?.activeFilters)
		setCancelled(!cancelled)
	}

	return (
		<FilterWrapper
			isSmallDevice={isMobileView}
			isOpen={!!props?.isOpen}
			handleClose={() => {
				if (props?.handleClose) props.handleClose()
			}}
			classes={classes}
		>
			<div className={classes.menuHeader}>
				{/* <div className={classes.menuHeaderIconDiv}>
                        <Icon>filter_list</Icon>
                    </div> */}
				<div className={classes.menuHeaderAndResetBtn}>
					<Typography variant='h5' className={classes.menuTitle}>
						Filters
					</Typography>

					{/* <FormControlLabel
						value='start'
						control={<Switch color='primary' value={toggle} checked={toggle} onChange={toggleChange} />}
						label={
							toggle === true ? (
								<Typography variant='h6' className={classes.menuTitle}>
									NER
								</Typography>
							) : (
								<Typography variant='h6' className={classes.menuTitle}>
									Custom
								</Typography>
							)
						}
						labelPlacement='start'
					/> */}

					{(!!appliedFiltersCount || hasChanges) && eliminateCommon && (
						<Button
							size='small'
							className={classes.resetBtn}
							onClick={(e) => {
								e.stopPropagation()
								resetAllFunc()
							}}
						>
							Reset All
						</Button>
					)}
				</div>
				{!!props?.secondaryKey?.icon && !!props?.secondaryKey?.onClick && (
					<div className={classes.menuHeaderIconDiv}>
						<IconButton onClick={props?.secondaryKey?.onClick} title='Filter Settings'>
							<Icon fontSize='small'>{props?.secondaryKey?.icon}</Icon>
						</IconButton>
					</div>
				)}
				<div className={classes.menuHeaderIconDiv}>
					<IconButton
						onClick={() => {
							setCurrentActiveFilters(props?.activeFilters)
							setCancelled(!cancelled)
							if (props?.handleClose) props.handleClose()
						}}
						title='Close'
					>
						<Icon fontSize='small'>close</Icon>
					</IconButton>
				</div>
			</div>
			<Grid container className={classes.listContainer}>
				{props?.isLoading ? (
					<Grid container justifyContent='center' alignItems='center' style={{ padding: 12 }}>
						<CircularProgress size={16} />{' '}
						<Typography
							style={{
								marginLeft: 8,
								fontSize: 14,
								fontWeight: 500,
								color: '#808080',
							}}
						>
							Loading...
						</Typography>
					</Grid>
				) : _.isEmpty(filterStructure) ? (
					<Grid container style={{ padding: 12 }}>
						No Filters Applicable
					</Grid>
				) : (
					<>
						{/* <Grid item lg={4} sm={12} md={12} className={classes.tabSeperateor}> */}
						{/* <Tabs
								orientation={isMobileView ? 'horizontal' : 'vertical'}
								variant='scrollable'
								value={value}
								onChange={handleChange}
								aria-label='Filter tabs '
								className={classes.tabs}
							>
								<Tab label='Common Filters' {...a11yProps(0)} />
								<Tab label='NER Filters' {...a11yProps(1)} />
								<Tab label='Custom Filters' {...a11yProps(2)} />
							</Tabs> */}
						{/* </Grid> */}
						<Grid item lg={12} md={12} sm={12}>
							{/* <TabPanel value={value} index={0}> */}
							{/* {_.isEmpty(groupedData?.common) ? ( */}
							{/* <Grid container style={{ padding: 12 }}>
									No Filters Applicable
								</Grid> */}
							{/* ) : (
									groupedData?.common.map((o, i) => {
										return (
											<FilterPanel
												key={`filter_${i}`}
												{...o}
												glassId={_.get(activeGlassDetails, 'glass_id')}
												classes={classes}
												selected={_.get(currentActiveFilters, o?.path)}
												resetFunc={resetFunc}
												updateFunc={updateFilter}
												cancelled={cancelled}
											/>
										)
									}) */}
							{/* </TabPanel> */}
							<TabPanel value={value} index={1}>
								{_.isEmpty(groupedData?.ner) ? (
									<Grid container style={{ padding: 12 }}>
										No Filters Applicable
									</Grid>
								) : (
									groupedData?.ner.map((o, i) => (
										<FilterPanel
											key={`filter_${i}`}
											{...o}
											glassId={_.get(activeGlassDetails, 'glass_id')}
											classes={classes}
											selected={_.get(currentActiveFilters, o?.path)}
											resetFunc={resetFunc}
											updateFunc={updateFilter}
											cancelled={cancelled}
										/>
									))
								)}
							</TabPanel>
							<TabPanel value={value} index={2}>
								{_.isEmpty(groupedData?.custom) ? (
									<Grid container style={{ padding: 12 }}>
										No Filters Applicable
									</Grid>
								) : (
									groupedData?.custom.map((o, i) => (
										<FilterPanel
											key={`filter_${i}`}
											{...o}
											glassId={_.get(activeGlassDetails, 'glass_id')}
											classes={classes}
											selected={_.get(currentActiveFilters, o?.path)}
											resetFunc={resetFunc}
											updateFunc={updateFilter}
											cancelled={cancelled}
										/>
									))
								)}
							</TabPanel>
						</Grid>
					</>
				)}
			</Grid>
			{!props?.isLoading && !_.isEqual(currentActiveFilters, props?.activeFilters) && (
				<Grid container className={classes.bottomButtonContainer} spacing={2}>
					<Grid item container xs={6} justifyContent='center' alignItems='center'>
						<Button size='small' disableElevation fullWidth variant='outlined' onClick={onCancelClicked}>
							Cancel
						</Button>
					</Grid>
					<Grid item container xs={6} justifyContent='center' alignItems='center'>
						<Button
							size='small'
							disableElevation
							fullWidth
							variant='contained'
							color='primary'
							onClick={() => {
								if (props?.updateFunc) {
									props.updateFunc(currentActiveFilters)
								}
							}}
						>
							Apply
						</Button>
					</Grid>
				</Grid>
			)}
		</FilterWrapper>
	)
}

export default FilterPopup

const FilterWrapper = (props) => {
	if (props?.isSmallDevice) {
		return (
			<Dialog
				open={!!props?.isOpen}
				fullScreen
				maxWidth='sm'
				fullWidth
				onClose={props?.handleClose}
				classes={{
					paper: props?.classes.dialogPaper,
					scrollPaper: props?.classes.dialogScrollPaper,
				}}
			>
				{props?.children}
			</Dialog>
		)
	}
	return props.children
}

const useAxios = (endpoint, method = 'post', params, bodyData, cancelExec, isAuthRequired = true) => {
	if (cancelExec.current) {
		cancelExec.current()
	}
	return Axios[method](endpoint, bodyData, {
		cancelToken: new Axios.CancelToken(function executor(c) {
			cancelExec.current = c
		}),
		headers: {
			'Content-Type': 'application/json',
			isAuthRequired,
			path: params,
		},
	})
}

const dataFormatter = (rawData, idKey = 'id', labelKey = 'name') => {
	let singleData = _.get(rawData, '[0]')
	let isDataIsObj = _.isPlainObject(singleData)
	let tempOptions = rawData
	if (!isDataIsObj) {
		tempOptions = _.map(rawData, (o) => ({ [idKey]: o, [labelKey]: o }))
	}
	return tempOptions
}

const FilterPanel = (props) => {
	const [isLoading, setIsLoading] = useState(false)
	const cancelExecutor = useRef()
	const {
		label,
		classes = {},
		updateFunc = () => null,
		glassId,
		resetFunc = () => null,
		category,
		filterId,
		path,
		idKey,
		selected,
		cancelled,
	} = props
	const [searchQuery, setSearchQuery] = useState('')
	const [options, setOptions] = useState(() =>
		dataFormatter([...(props?.defaultOptions || []), ...((_.isArray(props?.selected) && props?.selected) || [])], props?.idKey, props?.labelKey)
	)

	const [selectedValue, setSelectedValue] = useState(selected)
	const [prevSelectedValue, setPrevSelectedValue] = useState(selected)

	const onTagsChange = (event, values) => {
		setSelectedValue(values)
	}

	useEffect(() => {
		setSelectedValue(selected)
	}, [cancelled])

	useEffect(() => {
		if (_.isNil(selected)) {
			setSelectedValue(props?.defaultEmptyValue || [])
		}
	}, [selected])

	useEffect(() => {
		if (selectedValue !== selected && !_.isEmpty(selectedValue)) {
			const changes = _.xor(prevSelectedValue, selectedValue)
			changes.forEach((o) => {
				updateFunc(path, o, false, true, category, filterId)
			})
			setPrevSelectedValue(selectedValue)
		}

		if (_.isEmpty(selectedValue)) {
			resetFunc(props?.path, props?.defaultEmptyValue || [])
		}
	}, [selectedValue])

	const getData = useAxios
	// const autoLabel = _.map(options, 'name')
	// console.log(selectedValue)
	const autoLabel = props?.defaultOptions || []

	useEffect(() => {
		if (!!props?.isSearchable) {
			setIsLoading(true)
			let bodyData = { search_text: searchQuery, offset: 0, limit: 10 }
			if (props?.bodyDataAdapterFunction && _.isFunction(props?.bodyDataAdapterFunction)) {
				bodyData = props.bodyDataAdapterFunction(bodyData)
			}
			getData(props?.endpoint, 'post', { glassId }, bodyData, cancelExecutor)
				.then((resp) => {
					let rawData = _.get(resp, 'data.data.data', _.get(resp, 'data.data.result', []))
					let tempOptionsState = _.uniqBy(
						[
							...dataFormatter(rawData, props?.idKey, props?.labelKey),
							...dataFormatter(props?.defaultOptions || [], props?.idKey, props?.labelKey),
							...dataFormatter((_.isArray(props?.selected) && props?.selected) || [], props?.idKey, props?.labelKey),
						],
						props?.idKey
					)
					let tempFilteredOptions = _.filter(tempOptionsState, (o) =>
						new RegExp(`\\b(\\w*${escapeRegExp(searchQuery)}\\w*)\\b`, 'gi').test(`${o[props?.labelKey]}`)
					)
					setOptions(tempFilteredOptions)
					setIsLoading(false)
				})
				.catch((err) => {
					if (!!searchQuery) {
						setOptions(
							_.filter(dataFormatter(props?.defaultOptions || [], props?.idKey, props?.labelKey), (o) =>
								new RegExp(`\\b(\\w*${escapeRegExp(searchQuery)}\\w*)\\b`, 'gi').test(`${o[props?.labelKey]}`)
							)
						)
					} else if (props?.defaultTotalCount !== options.length) {
						setOptions(dataFormatter(props?.defaultOptions || [], props?.idKey, props?.labelKey))
					}
					if (Axios.isCancel(err)) {
						return true
					}
					setIsLoading(false)
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery])

	return (
		<Accordion
			classes={{
				root: classes.muiExpansionPanelRoot,
				expanded: classes.muiExpanded,
			}}
			/* onChange={() => setIsExpanded(!isExpanded)} */ elevation={0}
		>
			<AccordionSummary
				classes={{
					root: classes.muiExpansionPanelSummaryRoot,
					content: classes.muiExpansionPanelSummaryContent,
					expanded: classes.muiExpanded,
				}}
				expandIcon={<Icon>keyboard_arrow_down</Icon>}
			>
				<div className={classes.filterPanelTitleContainer}>
					<div className={classes.filterTitleWithChip}>
						<Badge
							color='error'
							badgeContent={_.isPlainObject(props?.selected) ? (_.isEmpty(props?.selected) ? 0 : 1) : _.toLength(props?.selected?.length)}
							variant='dot'
							classes={{
								anchorOriginTopRightRectangle: classes.anchorOriginTopRightRectangle,
							}}
						>
							<Typography className={classes.filterPanelHeading} variant='h6'>
								{label || 'Filter'}
							</Typography>
						</Badge>
					</div>
					{!!(_.isPlainObject(props?.selected) ? (_.isEmpty(props?.selected) ? 0 : 1) : _.toLength(props?.selected?.length)) && (
						<Button
							size='small'
							className={classes.resetBtn}
							onClick={(e) => {
								e.stopPropagation()
								resetFunc(props?.path, props?.defaultEmptyValue || [])
								setSelectedValue(props?.defaultEmptyValue || [])
							}}
						>
							Reset
						</Button>
					)}
				</div>
			</AccordionSummary>
			<AccordionDetails classes={{ root: classes.muiExpansionPanelDetailsRoot }}>
				{!!props?.isSearchable ? (
					<Grid item xs={12} className={classes.searchContainer}>
						<SearchBox
							onChange={(e) => setSearchQuery(e.target.value)}
							value={searchQuery}
							className={classes.searchInput}
							adornment={
								isLoading ? (
									<CircularProgress style={{ marginRight: '4px' }} size={20} />
								) : searchQuery === '' ? (
									<Icon className={classes.searchIcon}>search</Icon>
								) : (
									<IconButton onClick={() => setSearchQuery('')} size='small' title='Clear Search'>
										<Icon className={classes.searchIcon}>close</Icon>
									</IconButton>
								)
							}
						/>
					</Grid>
				) : (
					_.isEqual(props?.type, 'text') && (
						<Grid item xs={12} className={classes.searchContainer}>
							<AutoComplete
								multiple
								disableCloseOnSelect
								options={autoLabel}
								value={selectedValue}
								// defaultValue={selectedValue}
								size='small'
								getOptionLabel={(option) => (_.isEmpty(option) ? '' : option)}
								// getOptionSelected={(option, value) => option === value}
								// filterOptions={(options) => {
								// 	return options?.filter((item) => !selected?.includes(item))
								// }}
								onChange={(event, newValue) => {
									onTagsChange(event, newValue)
								}}
								renderOption={(option) => <>{_.toString(option)}</>}
								// onInputChange={(e) => setSearchQuery(e.target.value)}
								// limitTags={2}
								filterSelectedOptions
								margin='dense'
								renderTags={(value, getTagProps) =>
									(_.isArray(value) ? value : [value]).map((option, index) => (
										<Chip variant='outlined' size='small' label={option} {...getTagProps({ index })} />
									))
								}
								renderInput={(params) => (
									<TextField
										{...params}
										// {...(isNumber && isNumber === true ? { type: 'number' } : {})}
										margin='dense'
										variant='outlined'
										// label={filterCondition ? filterCondition : title}
										size='small' /* placeholder='Type to search values' */
									/>
								)}
							/>
						</Grid>
					)
				)}

				<div className={classes.optionsListContainer}>
					<RenderFilterOptions
						{...props}
						{...(props?.isSearchable || props?.type !== 'date'
							? {
									defaultOptions: _.sortBy(options, (o) => _.isUndefined(_.find(props?.selected, (j) => j === o[props?.idKey]))),
							  }
							: {})}
						updateFunc={updateFunc}
						classes={classes}
					/>
				</div>

				{/* <Grid container style={{ height: 150, border: `1px solid blue`, overflow: 'hidden' }}>
                    <Typography variant='body1'>
                        Contents of Filter
                    </Typography>
                </Grid> */}
			</AccordionDetails>
		</Accordion>
	)
}

const RenderFilterOptions = (props) => {
	const { classes = {} } = props
	switch (props?.type) {
		case 'date': {
			const { options = [], selected, updateFunc, path } = props
			let isCustom = _.isEmpty(_.filter(props.options, (o) => _.isEqual(o.key, selected))) && !_.isEmpty(selected?.from)

			if (_.get(options, '[0].key') === 'custom_dates') {
				return (
					<DateRangePicker
						ifOnlyCustom
						key={`option_date_custom_picker_only`}
						classes={classes}
						isSelected={!_.isEmpty(selected?.key) || isCustom}
						selectedKey={_.get(options, '[0].key')}
						selected={/* _.isEmpty(selected?.startDate) ? {startDate: selected?.from, endDate: selected?.to} :  */ selected}
						path={path}
						updateFunc={updateFunc}
					/>
				)
			}

			if (!!options?.length) {
				return _.map(props.options, (o, i) => {
					if (o?.key === 'custom_dates') {
						return (
							<DateRangePicker
								key={`option_date_custom_picker`}
								classes={classes}
								isSelected={!_.isEmpty(selected?.key) || isCustom}
								selectedKey={o?.key}
								selected={_.isEmpty(selected?.startDate) ? { startDate: selected?.from, endDate: selected?.to } : selected}
								path={path}
								updateFunc={updateFunc}
							/>
						)
					} else {
						return (
							<div
								key={`option_date_${path}_${i}`}
								className={classes.optionListItemContainer}
								onClick={(e) => {
									e.stopPropagation()
									updateFunc(path, o?.key, false, false)
								}}
							>
								<FormControlLabel
									classes={{ label: classes.optionItemLabel }}
									checked={_.isEqual(selected, o?.key)}
									/* onChange={(e) =>{e.preventDefault(); updateFunc(path, o?.key, false, false)}} */ value='end'
									control={<Radio className={classes.optionItemRadio} color='primary' />}
									label={<ListItemLabel classes={classes} text={o?.label} />}
								/>
								{/* <Typography variant='h6'>{o?.label}</Typography> */}
							</div>
						)
					}
				})
			}
			return <Typography variant='body2'>No Options in Date</Typography>
		}
		case 'check': {
			const {
				defaultOptions = [],
				selected,
				updateFunc,
				path,
				idKey,
				labelKey,
				firstNameKey,
				lastNameKey,
				emailKey,
				imageKey,
				isAvatar,
				token,
				colorKey,
				iconKey,
				category,
				filterId,
			} = props

			// const defaultOptions = useSelector(state => _.get(state, defaultOptionsReduxPath, []));
			if (!!defaultOptions?.length && !!idKey && (!!labelKey || (!!firstNameKey && !!lastNameKey && !!emailKey))) {
				return _.map(defaultOptions, (o, i) => {
					if (o?.key === 'custom_dates') {
						return (
							<Typography key={`option_check_custom_dates_${path}_${i}`} variant='h6'>
								Custom Dates
							</Typography>
						)
					} else {
						// const isSelected =
						if (isAvatar) {
							let imgProps = !_.isEmpty(_.get(o, imageKey)) ? { src: getImgUrl(token, _.get(o, imageKey)) } : { src: 'fake_path' }
							return (
								<div
									key={`option_check_avatar_${path}_${o[idKey]}`}
									className={classes.optionListItemContainer}
									role='button'
									onClick={() => {
										updateFunc(path, o[idKey], false, true, category, filterId)
									}}
								>
									<FormControlLabel
										classes={{ label: classes.optionItemLabel }}
										checked={_.includes(selected, o[idKey])}
										/* onChange={(e) =>{e.stopPropagation(); updateFunc(path, o[idKey], false, true)}} */ value='end'
										control={<Checkbox className={classes.optionItemRadio} color='primary' />}
										label={
											<ListItem
												{...imgProps}
												classes={classes}
												name={formTheName(o[firstNameKey], o[lastNameKey])}
												email={o[emailKey]}
												// onClick={() => updateFunc(path, o[idKey], false, true, category, filterId)}
											/>
										}
									/>
									{/* <Typography variant='h6'>{o?.label}</Typography> */}
								</div>
							)
						}
						return (
							<div
								key={`option_check_${path}_${o[idKey]}_${i}`}
								className={classes.optionListItemContainer}
								role='button'
								onClick={() => {
									updateFunc(path, o[idKey], false, true, category, filterId)
								}}
							>
								<FormControlLabel
									classes={{ label: classes.optionItemLabel }}
									checked={_.includes(selected, o[idKey])}
									/* onChange={(e)=>{e.stopPropagation(); updateFunc(path, o[idKey], false, true);}} */ value='end'
									control={<Checkbox className={classes.optionItemRadio} color='primary' />}
									/* label={o[labelKey]} */ label={
										<ListItemLabel classes={classes} icon={o[iconKey]} color={o[colorKey]} text={o[labelKey]} />
									} /* label={<>{!!o[colorKey] ? <span style={{width: 16, height: 16, borderRadius: 4, background: o[colorKey]}}></span> : null}<Typography variant='body1' className={classes.optionItemLabel}>{o[labelKey]}</Typography></>} */
								/>
								{/* <Typography variant='h6'>{o?.label}</Typography> */}
							</div>
						)
					}
				})
			}
			return (
				<div key={`option_check_${path}_no_option`} className={classes.optionListItemContainer} style={{ cursor: 'auto', padding: 8 }}>
					<ListItemLabel classes={classes} text='No Options' />
				</div>
			)
		}
		case 'text': {
			return <></>
		}
		case 'numberRange': {
			return (
				<div className={classes.optionListItemContainer} style={{ cursor: 'auto', padding: 8 }}>
					<NumberRangePicker {...props} />
				</div>
			)
		}
		default: {
			return (
				<div className={classes.optionListItemContainer} style={{ cursor: 'auto', padding: 8 }}>
					<ListItemLabel classes={classes} text='No Options' />
				</div>
			)
		}
	}
}

const NumberRangePicker = (props) => {
	// const [state, setState] = useState(!_.isEmpty(props?.selected)  ? {from: props?.selected?.from || '', to: props?.selected?.to || ''} : {from: '', to: ''});
	const [state, setState] = useState(() => {})
	const isInitial = React.useRef(true)
	const [rangeErr, setRangeErr] = useState(false)

	const handleChange = (d, key) => {
		if (key === 'from' && !!state?.to) {
			setState({ ...state, [key]: d })
		} else if (key === 'to' && !!state?.from) {
			setState({ ...state, [key]: d })
		} else {
			setState({ from: '', to: '', [key]: d })
		}
	}

	const handleBlur = (d, key) => {
		if (key === 'from') {
			if (_.isEmpty(state?.to) && !_.isEmpty(d)) {
				setState({ from: d, to: d })
			} else if (!_.isEmpty(state?.to) && !_.isEmpty(d)) {
				if (_.toNumber(state?.to) <= d) {
					setState({ from: d, to: d })
				} else {
					setState({ from: d, to: state?.to })
				}
			} else if (!_.isEmpty(state?.to) && _.isEmpty(d)) {
				// setState({undefined, state[1]])
				setState({})
			} else if (_.isEmpty(state?.to) && _.isEmpty(d)) {
				setState({})
			}
		} else if (key === 'to') {
			if (_.isEmpty(state?.from) && !_.isEmpty(d)) {
				setState({ from: d, to: d })
			} else if (!_.isEmpty(state?.from) && !_.isEmpty(d)) {
				if (_.toNumber(state?.from) >= d) {
					setState({ from: d, to: d })
				} else {
					setState({ from: state?.from, to: d })
				}
			} else if (!_.isEmpty(state?.from) && _.isEmpty(d)) {
				// setState([state?.from, undefined])
				setState({})
			} else if (_.isEmpty(state?.from) && _.isEmpty(d)) {
				setState({})
			}
		}
	}

	useEffect(() => {
		if (!isInitial.current) {
			if (_.isEqual(props?.selected, state)) {
				return
			}
			props.updateFunc(props.path, !!!state?.from && !!!state?.to ? {} : state, false, false)
		}
		isInitial.current = false
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state])

	useEffect(() => {
		if (!isInitial.current) {
			if (_.isEqual(props?.selected, state)) {
				return
			}
			if (_.isEmpty(props?.selected)) {
				setState({})
				return
			}
			setState({
				from: props?.selected?.from || '',
				to: props?.selected?.to || '',
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.selected])

	return (
		<Grid container spacing={1}>
			<Grid item container xs={6}>
				<TextField
					size='small'
					margin='dense'
					variant='outlined'
					label='From'
					placeholder='Min'
					fullWidth
					type='number'
					value={state?.from || ''}
					onChange={(e) => handleChange(e.target?.value, 'from')}
					onBlur={(e) => handleBlur(e.target?.value, 'from')}
					{...(state?.to !== '' ? { inputProps: { max: _.toNumber(state?.to || '') } } : {})}
				/>
			</Grid>
			<Grid item container xs={6}>
				<TextField
					size='small'
					margin='dense'
					variant='outlined'
					label='To'
					placeholder='Max'
					fullWidth
					type='number'
					value={state?.to || ''}
					onChange={(e) => handleChange(e.target?.value, 'to')}
					onBlur={(e) => handleBlur(e.target?.value, 'to')}
					{...(state?.from !== '' ? { inputProps: { min: _.toNumber(state?.from || '') } } : {})}
				/>
			</Grid>
			{rangeErr && (
				<Grid item xs={12}>
					<FormHelperText error>Invalid Max value</FormHelperText>
				</Grid>
			)}
		</Grid>
	)
}

const DateRangePicker = ({ updateFunc = () => null, classes, isSelected = false, selected, selectedKey = '', path = '', ifOnlyCustom = false }) => {
	const [dates, setDates] = useState(() => ({
		startDate: _.get(selected, 'startDate', _.get(selected, 'from', undefined)),
		endDate: _.get(selected, 'endDate', _.get(selected, 'to', undefined)),
		key: 'custom_dates_start',
		clickedTimes: 0,
	}))
	const [isPickerOpen, setIsPickerOpen] = useState(false)

	const handleClick = (e) => {
		if (isPickerOpen) {
			setIsPickerOpen(false)
		} else {
			setIsPickerOpen(true)
		}
	}

	useEffect(() => {
		if (!_.isEqual(selected, dates) && !_.isEmpty(dates) && dates?.key !== 'custom_dates_start' && dates?.clickedTimes !== 1) {
			updateFunc(path, dates, false, false)
			setIsPickerOpen(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates])

	useEffect(() => {
		if (_.isEmpty(selected))
			setDates({
				startDate: _.get(selected, 'startDate', _.get(selected, 'from', undefined)),
				endDate: _.get(selected, 'endDate', _.get(selected, 'to', undefined)),
				key: 'custom_dates_start',
				clickedTimes: 0,
			})
	}, [selected])

	if (ifOnlyCustom) {
		return (
			<div style={{ flex: 1, display: 'flex' }}>
				<ButtonGroup variant='outlined' size='small' color='primary' style={{ margin: `4px 16px`, flex: 1 }}>
					<Button onClick={handleClick} style={{ textTransform: 'none', flex: 1 }}>
						<DateInput
							noDateText='No Date Selected'
							initialDates={dates}
							textStyle={{ justifyContent: 'center' }}
							isOpen={Boolean(isPickerOpen)}
							handleDateChange={(d) => {
								setDates(d)
							}}
							handleCalendarClose={(flag) => {
								setIsPickerOpen(flag)
							}}
						/>
					</Button>
					<Button onClick={handleClick} size='small'>
						<Icon fontSize='small'>{isPickerOpen ? 'close' : 'date_range'}</Icon>
					</Button>
				</ButtonGroup>
			</div>
		)
	}

	return (
		<>
			<div key={`custom_date_picker_in_filter_panel`} className={classes.optionListItemContainer} role='button' onClick={handleClick}>
				<FormControlLabel
					classes={{ label: classes.optionItemLabel }}
					checked={isSelected}
					value='end'
					control={<Radio className={classes.optionItemRadio} color='primary' />}
					label={<ListItemLabel classes={classes} text='Custom' />}
				/>
				<DateInput
					initialDates={dates}
					isOpen={Boolean(isPickerOpen)}
					handleDateChange={(d) => {
						setDates(d)
					}}
					handleCalendarClose={(flag) => {
						setIsPickerOpen(flag)
					}}
				/>
			</div>
		</>
	)
}

const ListItemLabel = ({ text, icon, color, classes }) => {
	if (!!text) {
		if (!!color) {
			if (!!icon) {
				return (
					<div
						className={classes.optionListLabelContainer}
						style={{
							padding: `2px 8px 2px 4px`,
							background: `${color}25`,
							borderRadius: 4,
						}}
						onClick={(e) => e.preventDefault()}
					>
						<Icon style={{ color: color, fontSize: 18 }} onClick={(e) => e.preventDefault()}>
							{icon}
						</Icon>
						<Typography
							variant='caption'
							style={{
								color: `#000000DE`,
								lineHeight: '19px',
								letterSpacing: '0px',
								fontSize: 14,
							}}
							onClick={(e) => e.preventDefault()}
						>
							{text}
						</Typography>
					</div>
				)
			}
			return (
				<div className={classes.optionListLabelContainer} onClick={(e) => e.preventDefault()}>
					<div className={classes.optionListLabelColorDiv} style={{ background: color }} onClick={(e) => e.preventDefault()} />
					<Typography variant='body1' className={classes.optionItemLabel} onClick={(e) => e.preventDefault()}>
						{text}
					</Typography>
				</div>
			)
		}
		return (
			<Typography variant='body1' className={classes.optionItemLabel} onClick={(e) => e.preventDefault()}>
				{text}
			</Typography>
		)
	}
	return null
}

const ListItem = ({ name = '', email = '', isSelected = false, onClick = () => null, src = 'fake_path', classes, ...others }) => {
	return (
		<Grid
			container
			alignItems='center'
			style={{ flexWrap: 'nowrap' }}
			onClick={(e) => {
				e.preventDefault()
				onClick()
			}}
			className={classnames(classes.listItemContainer, {
				[classes.selectedListItem]: isSelected,
			})}
		>
			<Avatar alt={name} src={src} size='small' className={classes.userPic} style={{ backgroundColor: stringToHslColor(name) }} />
			<Grid item xs>
				<Typography variant='body2' className={classes.optionTextEllipsis} title={name}>
					{name}
				</Typography>
				<Typography variant='body2' color='textSecondary' title={email} className={classes.optionTextEllipsis}>
					{email}
				</Typography>
			</Grid>
		</Grid>
	)
}
