import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Tabs, Tab, Typography, Badge } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { PAGE_MODES } from '../../../../../constants'
import Validator from './validator'
import { Form } from 'formik'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { hideLoader } from '../../../../../services/loader/actions'
import { createNewUser, fetchUserViaSaga, updateUser } from '../../../../../services/users/actions'
import BasicDetails from './BasicDetails'
import Slide from '@material-ui/core/Slide'
import classnames from 'classnames'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const getDialogTitle = (action) => {
	switch (action) {
		case PAGE_MODES.CREATE:
			return 'Add User'
		case PAGE_MODES.EDIT:
			return 'Edit User'
		case PAGE_MODES.VIEW:
			return 'View User'
		default:
			return ''
	}
}

const useStyles = makeStyles((theme) => ({
	dialog: {
		[theme.breakpoints.up('md')]: {
			position: 'absolute',
			top: 20,
		},
	},
	tab: {
		textTransform: 'none',
		[theme.breakpoints.down('xs')]: {
			fontSize: 13,
		},
	},
	customTabTitle: {
		fontSize: 14,
		fontWeight: '500',
		[theme.breakpoints.down('xs')]: {
			fontSize: 13,
		},
	},
	dialogTitle: {
		paddingBottom: 0,
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		'& h6': {
			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},
		},
	},
	dialogContent: {
		// paddingTop: theme.spacing(2),
		width: '549px',
	},
	dialogFooter: {
		padding: theme.spacing(2),
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},
	badge: {
		marginLeft: theme.spacing(1),
	},
	hideBtn: {
		display: 'none',
	},
}))

const FormDialog = ({
	open,
	userId,
	action,
	handleClose,
	distributors,
	products,
	isRoleListLoading,
	roleList,
	isEditAllowed,
	onSkipClick,
	changeToEditMode,
	streams,
}) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const dialogTitle = getDialogTitle(action)
	const classes = useStyles()
	const [activeTabIdx, setActiveTabIdx] = useState(0)

	const [extUsrState, setExtUsrState] = useState({
		isFetchingUsr: false,
		userDetails: {},
		stream: [],
	})

	const formRef = useRef()
	const dispatch = useDispatch()

	const { isFetchingUsr, userDetails, stream } = extUsrState

	const isEditMode = _.isEqual(action, PAGE_MODES.EDIT)
	const isCreateMode = _.isEqual(action, PAGE_MODES.CREATE)
	const isViewMode = _.isEqual(action, PAGE_MODES.VIEW)

	const onChangeTab = (e, tabIdx) => {
		setActiveTabIdx(tabIdx)
	}

	const onSubmitForm = (values) => {
		onSkipClick(values.stream)
	}

	const onClickEditBtn = () => {
		if (action === PAGE_MODES.VIEW) {
			changeToEditMode(userId)
		}
	}

	const onFormSubmitErr = () => {
		if (!_.isEmpty(formRef.current, 'state.errors')) {
			if (activeTabIdx > 0) {
				setActiveTabIdx(0)
			}
		}
	}

	console.log(stream, streams, 'stre')

	const onClickActionBtn = () => {
		formRef.current && formRef.current.submitForm()
	}

	//reset exsting user details on form dialog close event
	useEffect(() => {
		if (!open) {
			setExtUsrState((prevState) => ({
				...prevState,
				isFetchingUsr: false,
				userDetails: {},
				stream: streams,
			}))
			setActiveTabIdx(0)
		}
	}, [open])

	const openDialog = open && !isFetchingUsr
	return (
		<Dialog
			classes={{
				paper: classes.dialog,
			}}
			TransitionComponent={Transition}
			fullScreen={fullScreen}
			maxWidth={'sm'}
			open={openDialog}
			onClose={handleClose}
			aria-labelledby='user-form-title'
		>
			{!isFetchingUsr ? (
				<Validator formMode={action} initialFormData={stream} onSubmit={onSubmitForm} ref={formRef}>
					{(formik) => {
						return (
							<>
								<DialogTitle className={classes.dialogTitle} disableTypography={true} id='user-dialog-title'>
									<Typography variant='h6'>Add people</Typography>
									{/* <Tabs
										orientation='horizontal'
										value={activeTabIdx}
										indicatorColor='primary'
										onChange={onChangeTab}
										variant='fullWidth'
										aria-label='User form tabs'
									>
										<Tab className={classes.tab} label={'Basic Details'}></Tab>
									</Tabs> */}
								</DialogTitle>
								<DialogContent className={classes.dialogContent}>
									<Form id='user-form' autoComplete='new-password'>
										<Effect formik={formik} onSubmissionError={onFormSubmitErr} />
										<div hidden={activeTabIdx !== 0} className={classes.tabPanel}>
											<BasicDetails
												isViewMode={isViewMode}
												distributors={distributors}
												products={products}
												isEditMode={isEditMode}
												isCreateMode={isCreateMode}
												formik={formik}
												streams={streams}
											/>
										</div>
									</Form>
								</DialogContent>
								<DialogActions className={classes.dialogFooter}>
									<Button
										style={{ fontWeight: 'bold' }}
										variant='outlined'
										size={fullScreen ? 'small' : 'medium'}
										onClick={handleClose}
										color='primary'
									>
										{isViewMode ? 'Close' : 'Cancel'}
									</Button>

									<Button
										variant='contained'
										onClick={isViewMode ? onClickEditBtn : onClickActionBtn}
										size={fullScreen ? 'small' : 'medium'}
										className={classnames({
											[classes.hideBtn]: isViewMode && !isEditAllowed,
										})}
										color='primary'
										style={{ fontWeight: 'bold' }}
										disableElevation
									>
										{isViewMode ? 'Edit' : 'Submit'}
									</Button>
								</DialogActions>
							</>
						)
					}}
				</Validator>
			) : (
				<></>
			)}
		</Dialog>
	)
}

function Effect(props) {
	const effect = () => {
		if (props.formik.submitCount > 0 && !props.formik.isValid) {
			props.onSubmissionError()
		}
	}
	useEffect(effect, [props.formik.submitCount])
	return null
}

FormDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	changeToEditMode: PropTypes.func.isRequired,
	isRoleListLoading: PropTypes.bool.isRequired,
	roleList: PropTypes.array,
	userId: PropTypes.string,
	action: PropTypes.string,
	isEditAllowed: PropTypes.bool,
}

export default FormDialog
