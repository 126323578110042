import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Icon, IconButton, makeStyles, Popover, TextField, Typography } from '@material-ui/core'
import Validator from './validator.js'
import { Form, Field as FormikField } from 'formik'
import InputField from 'components/TextField'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(1),
		padding: theme.spacing(2),
	},
	content: {
		width: '260px',
		border: `1px solid ${theme.palette.grey[300]}`,
		padding: theme.spacing(0),
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(1, 1.5),
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
	},
	headerText: {
		flex: '1',
	},
	contentWrapper: {
		padding: theme.spacing(1.5),
	},
	searchBox: {
		fontSize: theme.typography.pxToRem(14),
	},
	loadingTxt: {
		padding: theme.spacing(1.5),
	},
	AddBtn: {
		float: 'right',
		marginTop: '8px',
	},
	CreateBtn: {
		marginTop: '8px',
	},
	labelInput: {
		fontSize: theme.typography.pxToRem(14),
	},
	rootInput: {
		marginBottom: theme.spacing(1),
	},
	colorLbl: {
		display: 'block',
	},
}))

const Field = (props) => {
	return (
		<FormikField type={props?.type} name={props?.name}>
			{props?.render}
		</FormikField>
	)
}

const PopoverHeader = ({ classes, title, showBackBtn, onClickBackBtn, onClickCloseBtn }) => {
	return (
		<div className={classes.header}>
			{showBackBtn && (
				<IconButton size='small' className={classes.backBtn} onClick={onClickBackBtn}>
					<Icon fontSize='inherit'>arrow_back</Icon>
				</IconButton>
			)}
			<Typography className={classes.headerText} variant='body2' align='center'>
				{title}
			</Typography>
			<IconButton size='small' onClick={onClickCloseBtn} className={classes.backBtn}>
				<Icon fontSize='inherit'>close</Icon>
			</IconButton>
		</div>
	)
}

const UrlPopover = ({
	open,
	onClose,
	anchorEl,
	openLblForm,
	onSubmitLblForm,
	onClickAddLblBtn,
	onClickBackBtn,
	labels,
	onEditLabel,
	onClickLabel,
	value,
	searchText,
	onChangeSearchBox,
	formLabelId,
	formLabelName,
	formLabelColor,
	onDeleteLabel,
	loading,
	hideAddLabel,
	hideEditLabel,
	hideDeleteLabel,
	title,
	urlValue,
	onSubmitForm,
}) => {
	const classes = useStyles()
	const popoverTitle = !_.isEmpty(title) ? title : 'Add URL'
	const FormRef = useRef()

	const initialFormData = {
		file_name: '',
		url: '',
	}

	const onClickSubmit = (values, actions) => {
		onSubmitForm(values, actions)
	}

	return (
		<Popover
			className={classes.root}
			anchorPosition={{ top: 20, left: 0 }}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			anchorEl={anchorEl}
			open={open}
			onClose={onClose}
			classes={{ paper: classes.content }}
		>
			<PopoverHeader title={popoverTitle} onClickBackBtn={onClickBackBtn} onClickCloseBtn={onClose} showBackBtn={openLblForm} classes={classes} />
			<div className={classes.contentWrapper}>
				<Validator initialFormData={initialFormData} onSubmit={onClickSubmit} ref={FormRef} doesNeedValidate={false}>
					{({ values }) => (
						<Form id='Form' className='form' autoComplete='off'>
							<Field
								type='text'
								name='file_name'
								render={({ field, form }) => {
									return (
										<InputField type='text' size='small' fullWidth={true} label='Name *' field={field} form={form} disabled={false} margin='dense' />
									)
								}}
							/>
							<Field
								type='text'
								name='url'
								render={({ field, form }) => {
									return (
										<InputField type='text' size='small' fullWidth={true} label='URL *' field={field} form={form} disabled={false} margin='dense' />
									)
								}}
							/>
							<Button onClick={onClose} color='default' size='small' disableElevation variant='outlined' className={classes.CreateBtn}>
								Cancel
							</Button>
							<Button disableElevation size='small' type='submit' variant='contained' color='primary' className={classes.AddBtn}>
								Add
							</Button>
						</Form>
					)}
				</Validator>
			</div>
		</Popover>
	)
}

export default UrlPopover
