import React, { useState, useImperativeHandle } from 'react'
import _ from 'lodash'
import { Avatar, Typography, Badge, Icon, IconButton } from '@material-ui/core'
import { getImgUrl, stringToHslColor } from 'utils'

export const withCellRenderState = (CellRenderer) => {
	return React.forwardRef((props, ref) => {
		const [value, setValue] = useState(props.value)
		useImperativeHandle(ref, () => {
			return {
				refresh: (params) => {
					if (params.value !== value) {
						setValue(params.value)
					}
					return true
				},
			}
		})
		return <CellRenderer {...props}></CellRenderer>
	})
}

export const ActionCellRenderer = (props) => {
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	const onClickMoreOptions = _.get(props, 'colDef.cellRendererParams.onClickMoreOptions')

	const onClickAnchorEl = (event) => {
		onClickMoreOptions(event, _.get(props, 'data', {}))
	}

	return (
		<div className={containerClassName}>
			<IconButton size='small' onClick={onClickAnchorEl} title='Show Actions'>
				<i className='material-icons'>more_horiz</i>
			</IconButton>
		</div>
	)
}

export const ProfileUrlCellRenderer = (props) => {
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')

	function formatURL(url) {
		if (!url.match(/^https?:\/\//i)) {
			url = 'https://' + url
		}
		return url
	}

	const onClickLink = (event) => {
		if (window !== 'undefined') {
			window.open(formatURL(_.get(props, 'data.profile_url', {})), '_blank')
		}
	}

	return (
		<div className={containerClassName}>
			{!_.isEmpty(_.get(props, 'data.profile_url', {})) &&
			<IconButton size='small' onClick={onClickLink} title='Show Actions'>
				<i className='material-icons' fontSize='small'>launch</i>
			</IconButton>}
		</div>
	)
}

export const RoleCellRenderer = (props) => {
	const { data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	let displayName = _.join(
		_.map(_.get(data, 'roles', []), (role) => _.startCase(_.get(role, 'role_name', ''))),
		', '
	)
	return (
		<div className={containerClassName} title={displayName}>
			{displayName}
		</div>
	)
}

export const StatusCellRenderer = (props) => {
	const { value } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	return (
		<div className={containerClassName}>
			<span style={{ backgroundColor: value ? '#82c43c' : '#fc5a5a' }}></span>
			{value ? 'Active' : 'Inactive'}
		</div>
	)
}

export const EmailCellRenderer = (props) => {
	const { value, data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	const isEmailVerified = _.get(data, 'is_email_verified')
	return (
		<div className={containerClassName}>
			<div>
				{value}
			</div>
			<Icon
				style={{ color: isEmailVerified ? 'green' : '#FFAD36' }}
				title={isEmailVerified ? 'Email address is verified.' : 'Email address is not verified!'}
				fontSize='small'
			>
				{isEmailVerified ? 'verified' : 'new_releases'}
			</Icon>
		</div>
	)
}

export const NameCellRenderer = (props) => {
	const { value, data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	const isRenderMobView = _.get(props, 'colDef.cellRendererParams.isRenderMobView')
	const authToken = _.get(props, 'colDef.cellRendererParams.authToken')
	const fullName = _.join([value, _.get(data, 'last_name')], ' ')
	const profilePic = _.get(data, 'profile_pic_url')
	const getPicUrl = (profilePic) => getImgUrl(authToken, profilePic, 50)
	const profilePicUrl = profilePic && getPicUrl(profilePic)
	const isActive = _.get(data, 'is_active', false)
	const avatarStyle = isActive ? { backgroundColor: stringToHslColor(`${fullName}`) } : {}

	if (isRenderMobView) {
		const isEmailVerified = _.get(data, 'is_email_verified')
		return (
			<div className={containerClassName}>
				<Badge
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					color={_.get(data, 'is_active') ? 'secondary' : 'error'}
					overlap='circular'
					variant='dot'
				>
					<Avatar size='small' style={avatarStyle} className='avatar-span' component='span' src={profilePic && profilePicUrl}>
						{value && _.isEmpty(profilePic) && _.upperCase(value.substring(0, 1))}
					</Avatar>
				</Badge>
				<div className='m-details'>
					<Typography variant='body2' component='div'>
						{fullName}
					</Typography>
					<Typography variant='caption' component='div'>
						{_.get(data, 'email')}
						<Icon
							style={{
								color: isEmailVerified ? 'green' : '#FFAD36',
								fontSize: '16px',
							}}
							title={isEmailVerified ? 'Email address is verified.' : 'Email address is not verified!'}
							fontSize='small'
						>
							{isEmailVerified ? 'verified' : 'new_releases'}
						</Icon>
					</Typography>
				</div>
			</div>
		)
	} else
		return (
			<div className={containerClassName}>
				<Avatar size='small' style={avatarStyle} className='avatar-span' component='span' src={profilePic && profilePicUrl}>
					{value && _.isEmpty(profilePic) && _.upperCase(value.substring(0, 1))}
				</Avatar>
				<span className='details-span'>{_.startCase(fullName)}</span>
			</div>
		)
}
