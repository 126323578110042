import React, { useState, useRef } from 'react'
import {
	Grid,
	Card,
	CardActions,
	CardContent,
	CardActionArea,
	Collapse,
	Icon,
	IconButton,
	Button,
	Divider,
	Typography,
	makeStyles,
	CircularProgress,
	Avatar,
	CardMedia,
	Chip,
} from '@material-ui/core'
import TableCell from '../CustomTableCell'
import TableRow from '../CustomTableRow'
import { NoRecordsIllustration } from '../../../illustrators'
import _ from 'lodash'
import classnames from 'classnames'
import { isModuleAccessGranted, getImgUrl, stringToHslColor, addHttp, userHasAdminRole } from 'utils'
import { useSelector } from 'react-redux'
import File from 'components/File'
import SelectCardList from '../../../components/SelectCardList'
import defaultImg from '../../../images/default_avatar.png'
// import CardList from '../CardList'
import CustomTableBody from '../TableBody'

const useStyles = makeStyles((theme) => ({
	infoContainer: {
		textAlign: 'center',
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		'& h6': {
			paddingTop: theme.spacing(2),
		},
	},
	loadingIndicatorContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		paddingTop: theme.spacing(6),
		paddingBottom: theme.spacing(6),
	},
	noRecordsIllustration: {
		width: '100px',
		height: '100px',
	},
	NoRecordsFound: {
		display: 'flex',
		justifyContent: 'center',
	},
	CardContainer: {
		// desktop view removed

		// display: 'flex',
		// flexDirection: 'column',
		// flexWrap: 'nowrap',

		// desktop view added
		display: 'flex',
		flexDirection: 'row',
		gap: '12px',
		flexWrap: 'wrap',
		marginBottom: '12px',
	},
	Card: {
		marginBottom: '0px',

		// desktop view added
		[theme.breakpoints.between(1390, 1515)]: {
			maxWidth: '32.6%',
			flexBasis: '32.6%',
		},
		[theme.breakpoints.between(1280, 1390)]: {
			maxWidth: '32.5%',
			flexBasis: '32.5%',
		},
		[theme.breakpoints.between(1125, 1280)]: {
			maxWidth: '32.6%',
			flexBasis: '32.6%',
		},
		[theme.breakpoints.between(995, 1125)]: {
			maxWidth: '32.5%',
			flexBasis: '32.5%',
		},
		[theme.breakpoints.between(960, 995)]: {
			maxWidth: '32.45%',
			flexBasis: '32.45%',
		},
		[theme.breakpoints.between(897, 960)]: {
			maxWidth: '49.3%',
			flexBasis: '49.3%',
		},
		[theme.breakpoints.between(791, 897)]: {
			maxWidth: '49.2%',
			flexBasis: '49.2%',
		},
		[theme.breakpoints.between(707, 791)]: {
			maxWidth: '49.1%',
			flexBasis: '49.1%',
		},
		[theme.breakpoints.between(660, 707)]: {
			maxWidth: '49%',
			flexBasis: '49%',
		},
		[theme.breakpoints.down(660)]: {
			maxWidth: '100%',
			flexBasis: '100%',
		},
		flexGrow: '0',
		maxWidth: '24.25%',
		flexBasis: '24.25%',
	},
	cardAction: {
		// background: '#fafafa',
	},
	mainCard: {
		padding: '8px !important',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		maxWidth: '100%',
	},
	media: {
		width: '100%',
		height: '140px',
		marginBottom: theme.spacing(1),
	},
	id: {
		fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
		fontWeight: '400',
		lineHeight: '1.75',
		letterSpacing: '0.00938em',
		fontSize: '0.9rem',
	},
	name: {
		fontSize: '18px',
	},
	title: {
		fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
		fontWeight: '400',
		lineHeight: '1.334',
		letterSpacing: '0em',
		fontSize: '1.1rem',
	},
	description: {
		fontSize: '16px',
	},
	locationIcn: {
		height: '23px',
		width: '23px',
		marginLeft: '-5px',
		color: '#65b17f',
	},
	location: {
		fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
		fontWeight: '400',
		lineHeight: '1.75',
		letterSpacing: '0.00938em',
		fontSize: '0.9rem',
		flexDirection: 'row',
		margin: '4px 0px',
	},
	subtitle1: {
		fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
		fontWeight: '400',
		lineHeight: '1.75',
		letterSpacing: '0.00938em',
		fontSize: '0.9rem',
	},
	subtitle2: {
		fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
		fontWeight: '400',
		lineHeight: '1.75',
		letterSpacing: '0.00938em',
		fontSize: '0.9rem',
	},
	success: {
		color: '#65b17f',
	},
	failed: {
		color: 'rgb(244, 67, 54)',
	},
	likeIcon: {
		fontSize: '14px',
		padding: '0px',
		color: '#65b17f',
		margin: '-2px 4px',
	},
	dislikeIcon: {
		fontSize: '14px',
		padding: '0px',
		color: 'rgb(244, 67, 54)',
		margin: '-2px 4px',
		transform: 'scaleX(-1)',
	},
	expandContainer: {
		margin: '0px',
	},
	cardExpanded: {
		padding: '1px 8px 8px 8px !important',
	},
	projectTitle: {
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
	},
	glassCode: {
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		marginTop: '0.35em',
	},
	glassTitle: {
		fontSize: theme.typography.pxToRem(18),
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		[theme.breakpoints.down('xs')]: {
			fontSize: theme.typography.pxToRem(16),
		},
	},
	selectedSummaryInfoRow: {
		'& .MuiTableCell-body': {
			height: 54,
			paddingLeft: `0 !important`,
			paddingRight: `0 !important`,
		},
		'& .MuiTableCell-root': {
			padding: '0px 0px 16px 0px !important',
		},
	},
	selectedSummaryInfoRowCustom: {
		'& .MuiTableCell-body': {
			height: 54,
			paddingLeft: `0 !important`,
			paddingRight: `0 !important`,
		},
		'& .MuiTableCell-root': {
			padding: '0px 0px 16px 0px !important',
		},
		[theme.breakpoints.up(600)]: {
			display: 'grid',
		},
	},
	selectionContainer: {
		[theme.breakpoints.down(535)]: {
			display: 'flex',
			flexDirection: 'column',
			textAlign: 'center',
		},
	},
	selectionInfoContainer: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: theme.palette.almostBlack[100],
		padding: '4px 0px',
		// borderRadius: 5
	},
	selectionSummarySpan: {
		marginLeft: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		padding: theme.spacing(1),
		color: theme.palette.primary.main,
		cursor: 'pointer',
		// borderRadius: 5,
		'&:hover': {
			background: `${theme.palette.primary.main}11`,
		},
	},
	selectionSummary: {
		marginLeft: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		padding: theme.spacing(1),
		color: 'black',
		cursor: 'pointer',
	},
	price: {
		display: 'flex',
		flexDirection: 'column',
	},
	mobTableTitle: {
		color: '#9cadad',
		textTransform: 'uppercase',
		fontSize: '10px',
		fontWeight: '500',
		letterSpacing: '1px',
	},
	flagContainer: {
		display: 'flex',
		flexFlow: 'row',
		alignItems: 'center',
		maxWidth: 'min-content',
	},
	flagText: {
		'& .MuiTypography-body2': {
			padding: '2px 3px',
		},
	},
	flagCircle: {
		width: theme.spacing(1),
		height: theme.spacing(1),
		display: 'inline-block',
		marginRight: theme.spacing(1),
		borderRadius: '50%',
		background: theme.palette.warning.light,
	},
	avatarIcon: {
		'& .MuiAvatar-root': {
			width: '35px',
			height: '35px',
		},
	},
	mobLinkIcon: {
		'& .MuiIconButton-root': {
			padding: '0px 5px',
		},
		'& .material-icons': {
			fontSize: '20px',
		},
	},
	tableBody2: {
		color: theme.palette.almostBlack[800],
		letterSpacing: theme.typography.pxToRem(0.1),
	},
	sellingPriceText: {
		fontSize: theme.typography.pxToRem(14),
		fontWeight: 500,
		color: theme.palette.info.main,
		paddingRight: theme.spacing(0.5),
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	marketPriceText: {
		fontSize: theme.typography.pxToRem(14),
		fontWeight: 500,
		paddingRight: theme.spacing(0.5),
		color: theme.palette.success.main,
	},
	dataList: {
		marginRight: '5px',
		'& .MuiChip-sizeSmall': {
			height: '18px',
		},
	},
	mobImageIcon: {
		justifyContent: 'center',
		'& .MuiIconButton-root': {
			padding: '0px',
			'& .MuiIcon-root': {
				fontSize: '23px',
			},
		},
	},
	mobImage: {
		width: '100px',
		height: '60px',
	},
	rating: {
		fontWeight: '500',
		marginRight: theme.spacing(0.5),
		display: 'flex',
		flexDirection: 'row',
		'& .MuiIcon-root': {
			fontSize: '0.95rem',
			marginLeft: '2px',
			color: '#ffb400',
		},
	},
	dashX: {
		textAlignLast: 'start',
		padding: '18px 0px 12px 0px',
	},
	onlyDashX: {
		textAlignLast: 'center',
		padding: '18px 0px 12px 0px',
	},
	actionBtn: {
		'& .MuiGrid-item': {
			padding: '18px 12px 4px 12px',
		},
	},
	iconTxt: {
		fontSize: '17px',
		marginLeft: '4px',
	},
	glassX: {
		textAlignLast: 'start',
		padding: '18px 0px 12px 0px',
	},
	dashboardIcon: {
		'& .material-icons': {
			fontSize: '20px',
		},
	},
	viewColumnIcon: {
		'& .material-icons': {
			fontSize: '26px',
		},
	},
	profilePic: {
		width: 170,
		height: 170,
		[theme.breakpoints.down('xs')]: {
			width: 140,
			height: 140,
			margin: '0 auto',
		},
	},
	root: {
		border: `1px dashed ${theme.palette.text.secondary}`,
		position: 'relative',
		'&.error': {
			border: `1px dashed ${theme.palette.error.main}`,
		},
		'&:hover': {
			'& $delBtn': {
				display: 'flex',
				'&:hover': {
					color: theme.palette.error.main,
					backgroundColor: theme.palette.primary.contrastText,
				},
			},
		},
	},
	prevImg: {
		width: '100%',
		height: '100%',
	},
}))

const TableDeskBody = (props) => {
	const {
		isPickerMode,
		isSelectMode,
		handlePicked,
		handleCancel,
		pickButtonLabel,
		cancelButtonLabel,
		setAddPickedList,
		addPickedList,
		data,
		isLoading,
		handleCheckBoxClick,
		exportList,
		setExportList,
		renderSelectionSummary,
		pickerSelectionSummary,
		keyForCheckBoxSelectedList,
		paginationFooterDetails,
		handleSelectAllProducts,
		clearSelection,
		type,
		pageType,
		handleSelectAllPageProducts,
		allPageProduct,
		selectionMode,
		setSelectionMode,
		onClickDashX,
		onClickGlassX,
		disableDashxBtn = false,
		mobileHeaderKeys,
		mobileContent,
		flagStatus,
		handleSelectCurrentPage,
		history,
		handleSliderOpen,
		actionHandler,
	} = props

	const classes = useStyles()

	const [expanded, setExpanded] = useState([])

	const timerId = useRef()
	const avatarAchorRef = useRef(null)
	const token = useSelector((state) => _.get(state, 'session.authToken'))

	const getPicUrl = (profilePic) => getImgUrl(token, profilePic, 650)

	const handleSelect = (record) => {
		const valueIn = _.some(addPickedList, (obj) => _.isEqual(obj.id, record[keyForCheckBoxSelectedList]))
		handleCheckBoxClick(record)
		if (!valueIn) {
			setAddPickedList(_.concat(addPickedList, record))
		} else {
			setAddPickedList(_.without(addPickedList, record))
		}
	}

	const handleMouseDown = (e, record) => {
		timerId.current = setTimeout(() => {
			setSelectionMode(true)
			setExportList(_.concat(exportList, record[keyForCheckBoxSelectedList]))
			isPickerMode && handleSelect(e, record)
			handleCheckBoxClick(e, record)
		}, 300)
	}

	const handleMouseUp = () => {
		clearTimeout(timerId.current)
		if (selectionMode) {
			setSelectionMode(false)
		}
	}

	const handleExpandClick = (e, record) => {
		const valueIn = _.includes(expanded, record[keyForCheckBoxSelectedList])
		if (!valueIn) {
			setExpanded(_.concat(expanded, record[keyForCheckBoxSelectedList]))
		} else {
			setExpanded(_.without(expanded, record[keyForCheckBoxSelectedList]))
		}

		{
			;(_.isEqual(pageType, 'modelx') || _.isEqual(pageType, 'peoplex')) && handleSliderOpen(e, record)
		}
	}

	const handleSliderClick = (record) => {
		handleSliderOpen(record)
	}

	const handleSelectData = (record) => {
		handleCheckBoxClick(record)
	}

	const handleSelectClick = (e, record) => {
		const valueIn = isPickerMode
			? _.includes(addPickedList, record[keyForCheckBoxSelectedList])
			: _.includes(exportList, record[keyForCheckBoxSelectedList])
		handleCheckBoxClick(e, record)
		if (!valueIn) {
			setExportList(_.concat(exportList, record[keyForCheckBoxSelectedList]))
			setAddPickedList(_.concat(addPickedList, record))
		} else {
			setExportList(_.without(exportList, record[keyForCheckBoxSelectedList]))
			setAddPickedList(_.without(addPickedList, record))
		}

		if (_.size(exportList) <= 1 && selectionMode && valueIn) {
			handleMouseUp()
		}
	}

	const pickCurrentPage = () => {
		if (data !== '-') {
			const tempArr = _.uniqBy(_.concat(addPickedList, data), 'id')
			setAddPickedList(tempArr)
		}
	}

	const clearPickerSelection = () => {
		setAddPickedList([])
	}

	function handleClickDashX(e, record) {
		e.stopPropagation()
		onClickDashX(e, record)
	}

	function handleClickGlassX(e, record) {
		e.stopPropagation()
		if (!_.isNil(record?.glass_code) && !_.isNil(record[keyForCheckBoxSelectedList])) {
			onClickGlassX(e, record)
		}
	}

	const onClickFxn = (e, actionObj, record) => {
		e.stopPropagation()
		actionObj.onClick(e, record)
	}

	const NoRecordsFound = () => {
		return (
			<TableRow>
				<TableCell>
					<div className={classes.infoContainer}>
						<NoRecordsIllustration className={classes.noRecordsIllustration} />
						<Typography variant='body1'>No Records Found</Typography>
					</div>
				</TableCell>
			</TableRow>
		)
	}

	const LoadingIndicator = () => {
		return (
			<TableRow>
				<TableCell>
					<div className={classes.loadingIndicatorContainer}>
						<CircularProgress size={30} />
					</div>
				</TableCell>
			</TableRow>
		)
	}

	const flag = (data, idx, record) => {
		const checkFlagStatus = (record, flagConditions) => {
			if (_.lowerCase(_.toString(record)) === _.lowerCase(_.toString(flagConditions.yes)) || record === true) {
				return true
			} else if (_.lowerCase(_.toString(record)) === _.lowerCase(_.toString(flagConditions.no)) || record === false) {
				return false
			} else {
				return flagConditions.indeterminate
			}
		}
		const flagStatus = !_.isEmpty(_.get(data, 'flagCondition', {})) ? checkFlagStatus(record[data?.dataKey], data.flagCondition) : record
		const renderFlagComponentFxn = (componentKey) => {
			const isObject = _.isObject(_.get(data, componentKey))
			const isFunction = _.isFunction(_.get(data, componentKey))

			if (isObject && isFunction) {
				return _.get(data, componentKey)(_.get(data, 'textKey', '-') !== '-' ? { text: _.get(record, _.get(data, 'textKey')) } : {})
			} else if (isObject && !isFunction) {
				return _.get(data, componentKey)
			}
		}
		return (
			<Grid container key={idx} xs={data?.size ? data?.size : 12} className={classes.flagContainer}>
				<Grid item container style={{ marginLeft: '0px' }}>
					<div style={_.isNil(data?.style) ? {} : data?.style} className={classes.flagText}>
						{_.get(data, 'flagComponents', '-') !== '-' ? (
							flagStatus === true ? (
								renderFlagComponentFxn('flagComponents.yes')
							) : flagStatus === false ? (
								renderFlagComponentFxn('flagComponents.no')
							) : (
								renderFlagComponentFxn('flagComponents.indeterminate')
							)
						) : (
							<>
								<span
									className={classnames(classes.flagCircle, {
										[classes.active]: flagStatus === true,
										[classes.deActive]: flagStatus === false,
									})}
								></span>
								<Typography variant='body2' component='span' className={classes.tableBody2}>
									{flagStatus === true
										? _.get(data, 'flagCondition.yes', 'Yes')
										: flagStatus === false
										? _.get(data, 'flagCondition.no', 'No')
										: _.get(data, 'flagCondition.indeterminate')}
								</Typography>
							</>
						)}
					</div>
				</Grid>
			</Grid>
		)
	}

	const getExpandContent = (data, idx, record) => {
		function _getPriceDetails({ sellingPrice, marketPrice, discount }) {
			if ((!_.isNil(discount) && _.toNumber(discount) === 0) || (_.toNumber(sellingPrice) && _.toNumber(marketPrice))) {
				return {
					sellingPrice: !_.isNil(sellingPrice) ? _.toNumber(sellingPrice) : _.toNumber(marketPrice),
				}
			} else if (!_.isNil(sellingPrice) && !_.isNil(marketPrice) && !_.isNil(discount)) {
				return { sellingPrice, marketPrice, discount }
			} else if (!_.isNil(sellingPrice) && !_.isNil(marketPrice) && _.isNil(discount)) {
				return {
					sellingPrice,
					marketPrice,
					discount: ((sellingPrice - marketPrice) / marketPrice) * 100,
				}
			} else if (!_.isNil(sellingPrice) && _.isNil(marketPrice) && !_.isNil(discount)) {
				return {
					sellingPrice,
					marketPrice: (100 * sellingPrice) / (100 - discount),
					discount,
				}
			} else if (_.isNil(sellingPrice) && !_.isNil(marketPrice) && !_.isNil(discount)) {
				return {
					sellingPrice: marketPrice - (marketPrice * discount) / 100,
					marketPrice,
					discount,
				}
			} else {
				return null
			}
		}

		const price = _getPriceDetails({
			sellingPrice: _.isNumber(_.get(record, 'selling_price')) ? _.get(record, 'selling_price').toFixed(2) : _.get(record, 'selling_price'),
			marketPrice: _.isNumber(_.get(record, 'market_price')) ? _.get(record, 'market_price').toFixed(2) : _.get(record, 'market_price'),
			discount: _.get(record, 'product_discount'),
		})

		switch (_.get(data, 'type', '')) {
			case 'flag':
				const checkFlagStatus = (record, flagConditions) => {
					if (_.lowerCase(_.toString(record)) === _.lowerCase(_.toString(flagConditions.yes)) || record === true) {
						return true
					} else if (_.lowerCase(_.toString(record)) === _.lowerCase(_.toString(flagConditions.no)) || record === false) {
						return false
					} else {
						return flagConditions.indeterminate
					}
				}
				const flagStatus = !_.isEmpty(_.get(data, 'flagCondition', {})) ? checkFlagStatus(record[data?.dataKey], data.flagCondition) : record
				const renderFlagComponentFxn = (componentKey) => {
					const isObject = _.isObject(_.get(data, componentKey))
					const isFunction = _.isFunction(_.get(data, componentKey))

					if (isObject && isFunction) {
						return _.get(data, componentKey)(_.get(data, 'textKey', '-') !== '-' ? { text: _.get(record, _.get(data, 'textKey')) } : {})
					} else if (isObject && !isFunction) {
						return _.get(data, componentKey)
					}
				}
				return (
					<Grid container key={idx} xs={data?.size ? data?.size : 4} className={classes.flagContainer}>
						<Grid item container>
							<Typography variant='caption' color='textSecondary'>
								{data?.name}:
							</Typography>
						</Grid>
						<Grid item container style={{ marginLeft: '5px' }}>
							<div style={_.isNil(data?.style) ? {} : data?.style} className={classes.flagText}>
								{_.get(data, 'flagComponents', '-') !== '-' ? (
									flagStatus === true ? (
										renderFlagComponentFxn('flagComponents.yes')
									) : flagStatus === false ? (
										renderFlagComponentFxn('flagComponents.no')
									) : (
										renderFlagComponentFxn('flagComponents.indeterminate')
									)
								) : (
									<>
										<span
											className={classnames(classes.flagCircle, {
												[classes.active]: flagStatus === true,
												[classes.deActive]: flagStatus === false,
											})}
										></span>
										<Typography variant='body2' component='span' className={classes.tableBody2}>
											{flagStatus === true
												? _.get(data, 'flagCondition.yes', 'Yes')
												: flagStatus === false
												? _.get(data, 'flagCondition.no', 'No')
												: _.get(data, 'flagCondition.indeterminate')}
										</Typography>
									</>
								)}
							</div>
						</Grid>
					</Grid>
				)
			case 'link':
				const onClickFxn = (e, actionObj) => {
					actionObj.onClick(e, record)
				}
				return (
					<Grid item container className={classes.mobLinkIcon} key={idx} xs={data?.size ? data?.size : 4}>
						<IconButton size={'size'} onClick={(e) => onClickFxn(e, data?.actions)} title={'Link'} aria-label={'Link'}>
							<Icon style={data?.styleIcon ? data?.styleIcon : {}}>{data?.actions?.icon}</Icon>
						</IconButton>
					</Grid>
				)
			case 'marketPrice':
				return (
					<>
						{!_.isNil(price) && !_.isEmpty(price) ? (
							<>
								<Grid container item xs={data?.size ? data?.size : 4} key={idx}>
									{price.marketPrice ? (
										<Typography variant='h5' title={`Market Price: RM${price.marketPrice}`} component='span' className={classes.marketPriceText}>
											{_.get(data, 'name')}: {_.get(record, 'market_price')}
										</Typography>
									) : null}
								</Grid>
							</>
						) : null}
					</>
				)
			case 'sellingPrice':
				return (
					<>
						{!_.isNil(price) && !_.isEmpty(price) ? (
							<>
								<Grid container item xs={data?.size ? data?.size : 4} key={idx}>
									{price.sellingPrice ? (
										<Typography variant='h5' title={`Selling Price: RM${price.sellingPrice}`} component='span' className={classes.sellingPriceText}>
											{_.get(data, 'name')}: {_.get(record, 'selling_price')}
										</Typography>
									) : null}
								</Grid>
							</>
						) : null}
					</>
				)
			case 'image':
				let imgSrc = _.get(record, data?.dataKey, '-')
				const secImgSrc = _.get(record, data?.secImgKey, '-')
				let secImgSrcCount = secImgSrc !== '-' ? secImgSrc.length - 1 : -1
				const loadSecondarySrc = (e) => {
					if (secImgSrcCount !== -1) {
						e.target.src = secImgSrc[secImgSrcCount]
						secImgSrcCount -= 1
					}
				}
				const popUpFxn = _.get(data, 'imgPopUpFxn', () => null)
				function handlePopUpFxn(e) {
					e.stopPropagation()
					popUpFxn(record)
				}
				return (
					<Grid item container className={classes.mobImageIcon} xs={data?.size ? data?.size : 4} key={idx}>
						{imgSrc !== '-' ? (
							<img className={classes.mobImage} onClick={handlePopUpFxn} onError={loadSecondarySrc} src={imgSrc} alt={record.name} />
						) : (
							<Typography variant='subtitle1'>Image</Typography>
						)}
					</Grid>
				)
			case 'list':
				return (
					<Grid container item xs={data?.size ? data?.size : 4} key={idx} style={{ padding: '0px 1px 8px 0px' }}>
						<Grid item>
							<Typography variant='caption' color='textSecondary' gutterBottom>
								{_.get(data, 'name')}:&nbsp;
							</Typography>
						</Grid>
						{record[data.dataKey].map((data, key) => (
							<Grid item id={key} className={classes.dataList}>
								<Chip size='small' label={data} />
							</Grid>
						))}
					</Grid>
				)
			default:
				return (
					<Grid container item xs={data?.size ? data?.size : 4} key={idx} style={{ padding: '0px 1px 8px 0px' }}>
						<Grid item>
							<Typography variant='caption' color='textSecondary' gutterBottom>
								{_.get(data, 'name')}:&nbsp;
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant='caption' color='textSecondary' gutterBottom>
								{record[data.dataKey]}
							</Typography>
							{!_.isNil(_.get(data, 'subTitleRenderFxn')) ? _.get(data, 'subTitleRenderFxn')(record) : null}
						</Grid>
					</Grid>
				)
		}
	}

	return (
		<>
			{isLoading ? (
				<div className={classes.NoRecordsFound}>
					<LoadingIndicator />
				</div>
			) : _.isEmpty(data) && !isLoading ? (
				<div className={classes.NoRecordsFound}>
					<NoRecordsFound />
				</div>
			) : (
				<>
					{data && data.length > 0 && exportList && exportList.length > 0 && _.isEqual(pageType, 'modelx') ? (
						<Grid item xs={12}>
							<TableRow className={classes.selectedSummaryInfoRow} style={{ display: 'grid' }}>
								<TableCell>
									<div className={classes.selectionInfoContainer}>
										<Typography variant='subtitle1' className={classes.selectionContainer}>
											<span className={classes.selectionSummary}>{renderSelectionSummary()}</span>
											{_.isEqual(data.map((product) => product[keyForCheckBoxSelectedList]).sort(), exportList.sort()) ? (
												_.get(paginationFooterDetails, 'total', 0) > 12 ? (
													_.isNil(handleSelectAllProducts) ? (
														<>
															<span className={classes.selectionSummarySpan} onClick={clearSelection}>
																Clear Selection
															</span>
															{_.isEqual(type, 'EXPORT SELECTED') && (
																<span className={classes.selectionSummarySpan} onClick={handleSelectAllPageProducts}>
																	{_.isEqual(allPageProduct, false) && `Select results of all pages`}
																</span>
															)}
														</>
													) : (
														<span className={classes.selectionSummarySpan} onClick={handleSelectAllProducts}>
															Select all {_.get(paginationFooterDetails, 'total', 0)} products.
														</span>
													)
												) : (
													<span className={classes.selectionSummarySpan} onClick={clearSelection}>
														Clear Selection
													</span>
												)
											) : (
												<>
													<span className={classes.selectionSummarySpan} onClick={clearSelection}>
														Clear Selection
													</span>
													{exportList.length < 12 ? (
														<span className={classes.selectionSummarySpan} onClick={handleSelectCurrentPage}>
															Select all
														</span>
													) : (
														<>
															{_.isEqual(type, 'EXPORT SELECTED') && (
																<span className={classes.selectionSummarySpan} onClick={handleSelectAllPageProducts}>
																	{_.isEqual(allPageProduct, false) && `Select results of all pages`}
																</span>
															)}
														</>
													)}
												</>
											)}
										</Typography>
									</div>
								</TableCell>
							</TableRow>
						</Grid>
					) : null}

					{data && data.length > 0 && exportList && exportList.length > 0 && !_.isEqual(pageType, 'modelx') && !isPickerMode ? (
						<TableRow className={classes.selectedSummaryInfoRowCustom}>
							<TableCell>
								<div className={classes.selectionInfoContainer}>
									<Typography variant='subtitle1' className={classes.selectionContainer}>
										<span className={classes.selectionSummary}>{renderSelectionSummary()}</span>
										{_.isEqual(data.map((product) => product[keyForCheckBoxSelectedList]).sort(), exportList.sort()) ? (
											_.get(paginationFooterDetails, 'total', 0) > 12 ? (
												_.isNil(handleSelectAllProducts) ? (
													<>
														<span className={classes.selectionSummarySpan} onClick={clearSelection}>
															Clear Selection
														</span>
														{_.isEqual(type, 'EXPORT SELECTED') && (
															<span className={classes.selectionSummarySpan} onClick={handleSelectAllPageProducts}>
																{_.isEqual(allPageProduct, false) && `Select results of all pages`}
															</span>
														)}
													</>
												) : (
													<span className={classes.selectionSummarySpan} onClick={handleSelectAllProducts}>
														Select all {_.get(paginationFooterDetails, 'total', 0)} products.
													</span>
												)
											) : (
												<span className={classes.selectionSummarySpan} onClick={clearSelection}>
													Clear Selection
												</span>
											)
										) : (
											<>
												<span className={classes.selectionSummarySpan} onClick={clearSelection}>
													Clear Selection
												</span>
												{exportList.length < 12 ? (
													<span className={classes.selectionSummarySpan} onClick={handleSelectCurrentPage}>
														Select all
													</span>
												) : (
													<>
														{_.isEqual(type, 'EXPORT SELECTED') && (
															<span className={classes.selectionSummarySpan} onClick={handleSelectAllPageProducts}>
																{_.isEqual(allPageProduct, false) && `Select results of all pages`}
															</span>
														)}
													</>
												)}
											</>
										)}
									</Typography>
								</div>
							</TableCell>
						</TableRow>
					) : null}

					{data && data.length > 0 && addPickedList && addPickedList.length > 0 && !_.isEqual(pageType, 'modelx') && isPickerMode ? (
						<TableRow className={classes.selectedSummaryInfoRowCustom}>
							<TableCell>
								<div className={classes.selectionInfoContainer}>
									<Typography variant='subtitle1' className={classes.selectionContainer}>
										<span className={classes.selectionSummary}>{pickerSelectionSummary()}</span>
										<span className={classes.selectionSummarySpan} onClick={clearPickerSelection}>
											Clear Selection
										</span>
										{!_.every(data, (item) => _.includes(addPickedList, item)) && (
											<span className={classes.selectionSummarySpan} onClick={pickCurrentPage}>
												Select all
											</span>
										)}
									</Typography>
								</div>
							</TableCell>
						</TableRow>
					) : null}

					{_.isEqual(pageType, 'modelx') && (
						<SelectCardList
							pagination={true}
							data={data}
							mobileHeaderKeys={mobileHeaderKeys}
							onClickDashX={onClickDashX}
							onClickGlassX={onClickGlassX}
							disableDashxBtn={disableDashxBtn}
							keyForCheckBoxSelectedList={keyForCheckBoxSelectedList}
							handleClickDashX={handleClickDashX}
							handleClickGlassX={handleClickGlassX}
							handleExpandClick={handleExpandClick}
							handleSelectClick={handleSelectClick}
							handleMouseDown={handleMouseDown}
							handleMouseUp={handleMouseUp}
							selectionMode={selectionMode}
							exportList={exportList}
							handleSliderClick={handleSliderClick}
							handleSelectData={handleSelectData}
							isSelectMode={isSelectMode}
						/>
					)}

					{!_.isEqual(pageType, 'modelx') && (
						<Grid container className={classes.CardContainer}>
							{data.map((record, idx) => (
								// <CardList
								//     record={record}
								//     idx={idx}
								//     keyForCheckBoxSelectedList={keyForCheckBoxSelectedList}
								//     expanded={expanded}
								//     isPickerMode={isPickerMode}
								//     addPickedList={addPickedList}
								//     exportList={exportList}
								//     mobileHeaderKeys={mobileHeaderKeys}
								//     flagStatus={flagStatus}
								//     flag={flag}
								//     onClickFxn={onClickFxn}
								//     onClickDashX={onClickDashX}
								//     pageType={pageType}
								//     disableDashxBtn={disableDashxBtn}
								//     onClickGlassX={onClickGlassX}
								//     handleClickDashX={handleClickDashX}
								//     handleClickGlassX={handleClickGlassX}
								//     mobileContent={mobileContent}
								//     getExpandContent={getExpandContent}
								//     handleExpandClick={handleExpandClick}
								//     setExportList={setExportList}
								//     handleSelect={handleSelect}
								//     handleCheckBoxClick={handleCheckBoxClick}
								// />
								<CustomTableBody
									record={record}
									keyForCheckBoxSelectedList={keyForCheckBoxSelectedList}
									handleSliderOpen={handleSliderOpen}
									mobileHeaderKeys={mobileHeaderKeys}
									isSelectMode={isSelectMode}
									handleSelectData={handleSelectData}
									setExportList={setExportList}
									exportList={exportList}
									handleSelect={handleSelect}
									handleCheckBoxClick={handleCheckBoxClick}
									isPickerMode={isPickerMode}
									addPickedList={addPickedList}
									flagStatus={flagStatus}
									flag={flag}
									idx={idx}
									onClickFxn={onClickFxn}
									onClickDashX={onClickDashX}
									pageType={pageType}
									handleClickDashX={handleClickDashX}
									disableDashxBtn={disableDashxBtn}
									onClickGlassX={onClickGlassX}
									handleClickGlassX={handleClickGlassX}
									mobileContent={mobileContent}
									getExpandContent={getExpandContent}
									setExpanded={setExpanded}
									expanded={expanded}
								/>
							))}
						</Grid>
					)}
				</>
			)}
		</>
	)
}

export default TableDeskBody
