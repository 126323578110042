import { ACTION_TYPES } from 'constants/index'
import _ from 'lodash'
import { hideLoader, showErrorMessage } from 'services/loader/actions'
const { showSnackbarWithTimeout } = require('services/snackbar/actions')

const socketEventsWatcher = (socketIORef, dispatch, history) => {
	socketIORef.current.on('join_glass_status', (resp) => {
		if (resp?.status === 'failed') {
			dispatch(showSnackbarWithTimeout(`Socket Error : ${resp?.message}`, 3000))
		}
	})

	//LABEL SOCKET EVENTS

	socketIORef.current.on('glass_label_create', (resp) => {
		dispatch({
			type: ACTION_TYPES.ADD_GLASS_LABEL,
			payload: _.get(resp, 'data'),
		})
	})

	socketIORef.current.on('glass_label_update', (resp) => {
		dispatch({
			type: ACTION_TYPES.UPDATE_GLASS_LABEL,
			payload: { labelId: _.get(resp, 'data.label_id'), label: _.get(resp, 'data') },
		})
	})

	socketIORef.current.on('glass_label_delete', (resp) => {
		dispatch({
			type: ACTION_TYPES.REMOVE_GLASS_LABEL,
			payload: { labelId: _.get(resp, 'data.label_id') },
		})
	})

	//ACTIVITY TYPE SOCKET EVENTS
	socketIORef.current.on('glass_activity_type_create', (resp) => {
		dispatch({
			type: ACTION_TYPES.ADD_GLASS_ACTIVITY_TYPE,
			payload: _.get(resp, 'data'),
		})
	})

	socketIORef.current.on('glass_activity_type_update', (resp) => {
		dispatch({
			type: ACTION_TYPES.UPDATE_GLASS_ACTIVITY_TYPE,
			payload: { actTypeId: _.get(resp, 'data.activity_id'), activity: _.get(resp, 'data') },
		})
	})

	socketIORef.current.on('glass_activity_type_delete', (resp) => {
		dispatch({
			type: ACTION_TYPES.REMOVE_GLASS_ACTIVITY_TYPE,
			payload: { actTypeId: _.get(resp, 'data.activity_id') },
		})
	})

	//STICKY ESC UPDATE EVENT
	socketIORef.current.on('glass_sticky_escalation_update', (resp) => {
		dispatch({
			type: ACTION_TYPES.UPDATE_GLASS_STICKY_ESC,
			payload: _.get(resp, 'data'),
		})
	})

	// ADD STICKY SOCKET EVENT
	socketIORef.current.on('sticky_create', (resp) => {
		dispatch({
			type: ACTION_TYPES.ADD_GLASS_STICKY,
			payload: _.get(resp, 'data'),
		})
	})

	socketIORef.current.on('sticky_update', (resp) => {
		dispatch({
			type: ACTION_TYPES.UPDATE_GLASS_STICKY,
			payload: _.get(resp, 'data'),
		})
	})

	socketIORef.current.on('sticky_delete', (resp) => {
		dispatch({
			type: ACTION_TYPES.REMOVE_GLASS_STICKY,
			payload: _.get(resp, 'data'),
		})
	})

	socketIORef.current.on('sticky_delete', (resp) => {
		dispatch({
			type: ACTION_TYPES.REMOVE_GLASS_MULTI_STICKY,
			payload: _.get(resp, 'data'),
		})
	})

	//GLASS ADD MEMBERS SOCKET EVENTS
	socketIORef.current.on('glass_members_create', (resp) => {
		dispatch({
			type: ACTION_TYPES.ADD_GLASS_MEMBERS,
			payload: _.get(resp, 'data.glass_members', []),
		})
	})

	socketIORef.current.on('glass_members_update', (resp) => {
		dispatch({
			type: ACTION_TYPES.UPDATE_GLASS_MEMBER_FULFILLED,
			payload: { userId: _.get(resp, 'data.glass_members[0].user_id'), data: _.get(resp, 'data.glass_members[0]') },
		})
	})

	socketIORef.current.on('glass_members_delete', (resp) => {
		dispatch({
			type: ACTION_TYPES.REMOVE_GLASS_MEMBER,
			payload: { userId: _.get(resp, 'data.user_id[0]') },
		})
	})

	//GLASS EDIT AND DELETE EVENT WATCHER
	socketIORef.current.on('glass_update', (resp) => {
		dispatch({
			type: ACTION_TYPES.UPDATE_GLASS_DETAILS_AFTER_EDIT,
			payload: _.get(resp, 'data'),
		})
	})

	socketIORef.current.on('glass_delete', (resp) => {
		dispatch(
			showErrorMessage('This Board has been deleted!', 'Close', () => {
				history.push('/glassx')
				dispatch(hideLoader())
			})
		)
	})

	socketIORef.current.on('glass_pane_create', (resp) => {
		dispatch({
			type: ACTION_TYPES.ADD_GLASS_PANE,
			payload: _.get(resp, 'data'),
		})
	})

	socketIORef.current.on('glass_pane_update', (resp) => {
		dispatch({
			type: ACTION_TYPES.UPDATE_GLASS_PANE,
			payload: {
				paneId: _.get(resp, 'data.pane_id'),
				pane: _.get(resp, 'data'),
			},
		})
	})

	socketIORef.current.on('glass_pane_delete', (resp) => {
		dispatch({
			type: ACTION_TYPES.REMOVE_GLASS_PANE,
			payload: {
				paneId: _.get(resp, 'data.pane_id'),
			},
		})
	})

	socketIORef.current.on('left_room', (resp) => {
		console.log('Leaving glass...', resp)
	})
}

export default socketEventsWatcher
