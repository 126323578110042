import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import update from 'immutability-helper'
import _ from 'lodash'

export const initialState = {
	fetchingSharedList: true,
	tabIdx: 0,
	selectedUsers: [],
	selectedTeams: [],
	permission: PERMISSION_TYPE.EDIT,
	message: '',
	sharedUsersList: [],
	sharedTeamsList: [],
	owner: {},
	updateSharedUsers: [],
	updateSharedTeams: [],
	deletedUsers: [],
	deletedTeams: [],
}

export const formModules = [ROLE_MODULES.USER_MANAGEMENT, ROLE_MODULES.TEAM_MANAGEMENT]

export const permissions = [
	{
		name: PERMISSION_TYPE.EDIT,
		label: 'Edit',
		icon: 'edit',
	},
	{
		name: PERMISSION_TYPE.VIEW_WITH_FILTER,
		label: 'View with Filter',
		reactIcon: 'TbFilterCheck',
	},
	{
		name: PERMISSION_TYPE.VIEW,
		label: 'View',
		icon: 'visibility',
	},
	{
		name: PERMISSION_TYPE.DELETE,
		label: 'Remove',
		icon: 'close',
	},
]

const getUpdtShareListAction = (action, sharedList, idKey, exstUpdateList) => {
	const { id, resource_id, permission } = action.payload
	const shdPushUpdtList = _.some(sharedList, (sharedItem) => _.isEqual(sharedItem[idKey], id) && !_.isEqual(sharedItem.permission, permission))
	return shdPushUpdtList
		? { $push: [{ [idKey]: id, resource_id, permission }] }
		: {
				$splice: [[_.findIndex(exstUpdateList, (updateItem) => _.isEqual(updateItem[idKey], id)), 1]],
		  }
}

export function reducer(state, action) {
	switch (action.type) {
		case 'setSharedList':
			return update(state, {
				fetchingSharedList: { $set: false },
				owner: { $set: _.get(action.payload, 'owner[0]') },
				sharedTeamsList: { $set: action.payload.team_units },
				sharedUsersList: { $set: action.payload.users },
			})
		case 'updateSharedUsersList':
			return update(state, {
				updateSharedUsers: getUpdtShareListAction(action, state.sharedUsersList, 'user_id', state.updateSharedUsersList),
			})
		case 'updateSharedTeamsList':
			return update(state, {
				updateSharedTeams: getUpdtShareListAction(action, state.sharedTeamsList, 'tu_code', state.updateSharedTeams),
			})
		case 'deleteSharedUser':
			return update(state, { deletedUsers: { $push: [{ user_id: action.payload.id, resource_id: action.payload.resource_id }] } })
		case 'deleteSharedTeam':
			return update(state, { deletedTeams: { $push: [{ tu_code: action.payload.id, resource_id: action.payload.resource_id }] } })
		case 'changeTab':
			return {
				...state,
				tabIdx: action.payload.tabIdx,
				selectedUsers: [],
				selectedTeams: [],
				permission: PERMISSION_TYPE.EDIT,
				message: '',
			}
		case 'changeOnAutoComplete':
			let changeKey = action.payload.formModule === ROLE_MODULES.USER_MANAGEMENT ? 'selectedUsers' : 'selectedTeams'
			let resetKey = changeKey === 'selectedUsers' ? 'selectedTeams' : 'selectedUsers'
			let actionToPerform = {
				deletedUsers: { $set: [] },
				deletedTeams: { $set: [] },
				updateSharedTeams: { $set: [] },
				updateSharedUsers: { $set: [] },
				fetchingSharedList: { $set: action.payload.refreshList },
				[changeKey]: { $set: action.payload.data },
				[resetKey]: { $set: [] },
			}
			return update(state, actionToPerform)
		case 'resetForm':
			const retainVals = action.payload || {}
			return { ...initialState, ...retainVals }
		case 'changePermSelBox':
			return update(state, { permission: { $set: action.payload.value } })
		case 'changeMsgInput':
			return update(state, { message: { $set: action.payload.value } })
		default:
			throw new Error()
	}
}
