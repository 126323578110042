import { put, call, takeLatest, cancelled, select, takeEvery, all } from 'redux-saga/effects'
import Axios from 'axios'
import { ACTION_TYPES, SAGA_ACTIONS } from '../../../constants'
import userApi from '../api'
import _ from 'lodash'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { getErrMsg } from 'utils'
import { updateStickList } from '../actions'

function* watchUserGetLabel() {
	yield takeEvery(SAGA_ACTIONS.GET_USER_LABEL, userGetLabel)
}

function* userGetLabel(action) {
	yield put({ type: ACTION_TYPES.GET_USER_LABEL_PENDING })

	try {
		const products = yield call(userApi.fetchUserLabels, action.query, action.search, action.redirect, action.module, action.cancelExecutor)
		yield put({
			type: ACTION_TYPES.GET_USER_LABEL_FULFILLED,
			payload: products,
		})
	} catch (err) {
		yield put({ type: ACTION_TYPES.GET_USER_LABEL_REJECTED, payload: err })
		console.log(err)
	}
}

function* watchUserGetLabelCategory() {
	yield takeEvery(SAGA_ACTIONS.GET_LABEL_CATEGORY, userGetLabelCategory)
}

function* userGetLabelCategory(action) {
	yield put({ type: ACTION_TYPES.GET_LABEL_CATEGORY_PENDING })

	try {
		const category = yield call(userApi.fetchLabelsCategory, action.cancelExecutor)
		yield put({
			type: ACTION_TYPES.GET_LABEL_CATEGORY_FULFILLED,
			payload: category,
		})
	} catch (err) {
		yield put({ type: ACTION_TYPES.GET_LABEL_CATEGORY_REJECTED, payload: err })
		console.log(err)
	}
}

function* watchUserAddLabel() {
	yield takeEvery(SAGA_ACTIONS.ADD_USER_LABEL, userAddLabel)
}

function* userAddLabel(action) {
	const { sessionId, labelName, module, successCallback, errCallback } = action
	try {
		const resp = yield call(userApi.userCreateLabel, labelName, module, sessionId)
		yield put({
			type: ACTION_TYPES.ADD_USER_LABEL,
			payload: _.get(resp, 'data.data'),
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

function* watchUserUpdateLabel() {
	yield takeEvery(SAGA_ACTIONS.UPDATE_USER_LABEL, userUpdateLabel)
}

function* userUpdateLabel(action) {
	const { sessionId, labelId, labelName, module, successCallback, errCallback } = action
	try {
		const resp = yield call(userApi.updateUserLabel, labelId, labelName, module, sessionId)
		yield put({
			type: ACTION_TYPES.UPDATE_USER_LABEL,
			payload: { labelId, label: _.get(resp, 'data.data') },
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

function* watchUserRemoveLabel() {
	yield takeEvery(SAGA_ACTIONS.REMOVE_USER_LABEL, userRemoveLabel)
}

function* userRemoveLabel(action) {
	const { sessionId, labelId, module, successCallback, errCallback } = action
	try {
		const resp = yield call(userApi.deleteLabel, labelId, module, sessionId)
		yield put({
			type: ACTION_TYPES.REMOVE_USER_LABEL,
			payload: { labelId },
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

function* watchUserGetDepartment() {
	yield takeEvery(SAGA_ACTIONS.GET_USER_DEPARTMENT, userGetDepartment)
}

function* userGetDepartment(action) {
	yield put({ type: ACTION_TYPES.GET_USER_DEPARTMENT_PENDING })

	try {
		const products = yield call(userApi.fetchUserDepartments, action.query, action.search, action.redirect, action.module, action.cancelExecutor)
		yield put({
			type: ACTION_TYPES.GET_USER_DEPARTMENT_FULFILLED,
			payload: products,
		})
	} catch (err) {
		yield put({ type: ACTION_TYPES.GET_USER_DEPARTMENT_REJECTED, payload: err })
		console.log(err)
	}
}

function* watchUserAddDepartment() {
	yield takeEvery(SAGA_ACTIONS.ADD_USER_DEPARTMENT, userAddDepartment)
}

function* userAddDepartment(action) {
	const { sessionId, departmentName, module, successCallback, errCallback } = action
	try {
		const resp = yield call(userApi.userCreateDepartment, departmentName, module, sessionId)
		yield put({
			type: ACTION_TYPES.ADD_USER_DEPARTMENT,
			payload: _.get(resp, 'data.data'),
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

function* watchUserUpdateDepartment() {
	yield takeEvery(SAGA_ACTIONS.UPDATE_USER_DEPARTMENT, userUpdateDepartment)
}

function* userUpdateDepartment(action) {
	const { sessionId, departmentId, departmentName, module, successCallback, errCallback } = action
	try {
		const resp = yield call(userApi.updateUserDepartment, departmentId, departmentName, module, sessionId)
		yield put({
			type: ACTION_TYPES.UPDATE_USER_DEPARTMENT,
			payload: { departmentId, department: _.get(resp, 'data.data') },
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

function* watchUserRemoveDepartment() {
	yield takeEvery(SAGA_ACTIONS.REMOVE_USER_DEPARTMENT, userRemoveDepartment)
}

function* userRemoveDepartment(action) {
	const { sessionId, departmentId, module, successCallback, errCallback } = action
	try {
		const resp = yield call(userApi.deleteDepartment, departmentId, module, sessionId)
		yield put({
			type: ACTION_TYPES.REMOVE_USER_DEPARTMENT,
			payload: { departmentId },
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

function* watchUserGetDesignation() {
	yield takeEvery(SAGA_ACTIONS.GET_USER_DESIGNATION, userGetDesignation)
}

function* userGetDesignation(action) {
	yield put({ type: ACTION_TYPES.GET_USER_DESIGNATION_PENDING })

	try {
		const products = yield call(userApi.fetchUserDesignations, action.query, action.search, action.redirect, action.module, action.cancelExecutor)
		yield put({
			type: ACTION_TYPES.GET_USER_DESIGNATION_FULFILLED,
			payload: products,
		})
	} catch (err) {
		yield put({ type: ACTION_TYPES.GET_USER_DESIGNATION_REJECTED, payload: err })
		console.log(err)
	}
}

function* watchUserAddDesignation() {
	yield takeEvery(SAGA_ACTIONS.ADD_USER_DESIGNATION, userAddDesignation)
}

function* userAddDesignation(action) {
	const { sessionId, designationName, module, successCallback, errCallback } = action
	try {
		const resp = yield call(userApi.userCreateDesignation, designationName, module, sessionId)
		yield put({
			type: ACTION_TYPES.ADD_USER_DESIGNATION,
			payload: _.get(resp, 'data.data'),
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

function* watchUserUpdateDesignation() {
	yield takeEvery(SAGA_ACTIONS.UPDATE_USER_DESIGNATION, userUpdateDesignation)
}

function* userUpdateDesignation(action) {
	const { sessionId, designationId, designationName, module, successCallback, errCallback } = action
	try {
		const resp = yield call(userApi.updateUserDesignation, designationId, designationName, module, sessionId)
		yield put({
			type: ACTION_TYPES.UPDATE_USER_DESIGNATION,
			payload: { designationId, designation: _.get(resp, 'data.data') },
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

function* watchUserRemoveDesignation() {
	yield takeEvery(SAGA_ACTIONS.REMOVE_USER_DESIGNATION, userRemoveDesignation)
}

function* userRemoveDesignation(action) {
	const { sessionId, designationId, module, successCallback, errCallback } = action
	try {
		const resp = yield call(userApi.deleteDesignation, designationId, module, sessionId)
		yield put({
			type: ACTION_TYPES.REMOVE_USER_DESIGNATION,
			payload: { designationId },
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

export default [
    watchUserGetLabel(),
	watchUserGetLabelCategory(),
	watchUserAddLabel(),
    watchUserUpdateLabel(),
    watchUserRemoveLabel(),
	watchUserGetDepartment(),
	watchUserAddDepartment(),
	watchUserUpdateDepartment(),
	watchUserRemoveDepartment(),
	watchUserGetDesignation(),
	watchUserAddDesignation(),
	watchUserUpdateDesignation(),
	watchUserRemoveDesignation(),
]