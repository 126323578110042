import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'
const CancelToken = Axios.CancelToken

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	createProjects: (data, successCallback, errorCallback) => {
		Axios.post(`${API_ENDPOINTS.CREATE_PROJECTS}`, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	updateProjects: (data, projectId, successCallback, errorCallback) => {
		Axios.put(`${API_ENDPOINTS.SINGLE_PROJECTS}`, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { projectId }, } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	createProjectsSuggestion: (data, successCallback, errorCallback) => {
		Axios.post(`${API_ENDPOINTS.CREATE_PROJECTS_SUGGESTION}`, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	projectsPeopleSuggestion: (data, successCallback, errorCallback) => {
		Axios.post(`${API_ENDPOINTS.PROJECTS_PEOPLE_SUGGESTION}`, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	projectsBudgetCalculation: (data, successCallback, errorCallback) => {
		Axios.post(`${API_ENDPOINTS.PROJECTS_BUDGET_CALCULATION}`, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	projectTitleSugestion: (query, limit, cancelExecutor, successCallback, errorCallback) => {
		if (cancelExecutor.current) {
			cancelExecutor.current()
		}
		Axios.get(`${API_ENDPOINTS.PROJECT_TITLE_SUGGESTION}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { query, limit },
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	thirdPartyStreamSugestion: (query, limit, cancelExecutor, successCallback, errorCallback) => {
		if (cancelExecutor.current) {
			cancelExecutor.current()
		}
		Axios.get(`${API_ENDPOINTS.THIRD_PARTY_STEAM_SUGGESTION}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { query, limit },
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchAllProjects: (search, offset, limit, cancelExecutor, successCallback, errorCallback) => {
		if (cancelExecutor.current) cancelExecutor.current()
		Axios.get(`${API_ENDPOINTS.GET_PROJECTS}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { search, offset, limit },
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchSingleProject: (projectId, successCallback, errorCallback) => {
		Axios.get(`${API_ENDPOINTS.SINGLE_PROJECTS}`, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { projectId },
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	deleteProject: (projectId, successCallback, errorCallback) => {
		Axios.delete(`${API_ENDPOINTS.SINGLE_PROJECTS}`, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { projectId },
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
}
