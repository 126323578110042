import { useRef } from 'react'
import { useEffect } from 'react'

export default function useLazyPaginate(bottomDivRef, callback) {
	const observer = useRef()

	useEffect(() => {
		observer.current = new IntersectionObserver((entries) => {
			entries.forEach((en) => {
				// if (en.intersectionRatio >= 0.75) {
				if (en.isIntersecting) {
					callback()
				}
			})
		})

		if (bottomDivRef.current) {
			observer.current.observe(bottomDivRef.current)
		}

		return () => {
			observer.current.disconnect()
		}
	}, [callback, bottomDivRef])
}
