import { createChatBotMessage } from 'react-chatbot-kit';

const config = { 
  botName: "Wersel",
  initialMessages: [createChatBotMessage("Welcome! Let's create a new data science project. To begin, please provide a brief description of your project goal.")],
  customStyles: {
    botMessageBox: {
      backgroundColor: "#65b17f",
    },
    chatButton: {
      backgroundColor: "#65b17f",
    },
  },
}

export default config