import React, { useState, useEffect, useCallback, useRef } from 'react'
// import TaskStatus from './TaskStatus'
import { Grid, makeStyles } from '@material-ui/core'
import Activities from 'components/Activities'
import TimeLogger from 'components/TimeLogger'
import Axios from 'axios'
import { convertLocalTimeToGmtStr, getErrMsg, getSuccessMsg } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import GlassApi from 'services/glass/api'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { usePrevious } from 'hooks'
import update from 'immutability-helper'
import { hideLoader, showConfirmMessage, showLoader } from 'services/loader/actions'
import moment from 'moment'
import { updateGlassSticky, updateStickyData } from 'services/glass/actions'
import MyTaskApi from 'services/myTask/api'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '0 20px',
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0),
		},
	},
	verticalSpace: {
		padding: theme.spacing(0, 0, 2, 0),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0, 2),
		},
	},
	timeLogger: {
		padding: '12px 16px 16px 16px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: theme.spacing(2),
			flex: 'inherit',
		},
	},
	timer: {
		'& .MuiBox-root': {
			padding: '2px 6px',
		},
	},
}))

const Timer = ({record, setReload}) => {
	const classes = useStyles()
	const dispatch = useDispatch()
    const [isTimerLoading, setTimerLoading] = useState(false)
    const { time_logs, glass_code, sticky_code } = record
	const token = useSelector((state) => _.get(state, 'session.authToken'))

	const [stickyData, setStickyData] = useState({ stickyDetails: {}, loading: true })

	const stickyDetailsSynced = useRef(record)


	useEffect(() => {
		stickyDetailsSynced.current = record
	}, [record])


	const updateStickyDetails = useCallback(
		(record, skipReduxUpdate = false) => {
			//skip redux update flag is true then don't update on redux
			if (!skipReduxUpdate) dispatch(updateStickyData(record))
			setStickyData((prevState) => ({ ...prevState, stickyDetails: record }))
		},
		[dispatch]
	)

	const onErrorTimeLogs = (err) => {
		if (!Axios.isCancel(err)) {
			dispatch(hideLoader())
			dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
			setTimerLoading(false)
		}
	}

	const onSuccessTimeLogs = useCallback(
		(opToPerform, hideLoading = true) => {
			hideLoading && setTimerLoading(false)
			updateStickyDetails(update(stickyDetailsSynced.current, { time_logs: opToPerform }, true))
		},
		[updateStickyDetails]
	)

	const pushDummyLog = useCallback(
		(from) => {
			onSuccessTimeLogs({ $push: [{ time_log_id: 1, from, to: null }] }, false)
		},
		[onSuccessTimeLogs]
	)

	const getDummyLogIdx = useCallback(() => {
		return _.findIndex(stickyDetailsSynced.current?.time_logs, { time_log_id: 1 })
	}, [])

	const replaceDummyLog = useCallback(
		(logToReplace) => {
			onSuccessTimeLogs({ [getDummyLogIdx()]: { $set: logToReplace } })
		},
		[onSuccessTimeLogs, getDummyLogIdx]
	)

    const onStartTimer = (e) => {
		e.stopPropagation()
		dispatch(showLoader('Please wait...'))
		setReload(true)
		const from = convertLocalTimeToGmtStr(moment().format('YYYY-MM-DD HH:mm:ss'))
		pushDummyLog(from)
		setTimerLoading(true)
		const onSuccess = (resp) => {
			setTimeout(() => {
				dispatch(hideLoader())
			}, 480)
			replaceDummyLog(resp?.data?.data)
		}
		const onError = (err) => {
			setTimeout(() => {
				dispatch(hideLoader())
				dispatch(showSnackbarWithTimeout(getSuccessMsg(err), 1500))
			}, 480)
			//remove dummy log idx
			// onSuccessTimeLogs({ $splice: [[getDummyLogIdx(), 1]] })
			// onErrorTimeLogs(err)
		}
		GlassApi.startStickyTimer(glass_code, sticky_code, from).then(onSuccess, onError)
	}

    const onStopTimer = (e) => {
		e.stopPropagation()
		dispatch(showLoader('Please wait...'))
		setReload(true)
		const to = convertLocalTimeToGmtStr(moment().format('YYYY-MM-DD HH:mm:ss'))

		// const replaceTo = (to) => {
		// 	const exstLogIdx = _.findIndex(stickyDetailsSynced.current?.time_logs, { to: null })
		// 	onSuccessTimeLogs({ [exstLogIdx]: { $set: { ...stickyDetailsSynced.current?.time_logs[exstLogIdx], to } } }, false)
		// }
		// replaceTo(to)

		// delayOnApi(to, (to) => {
		// 	setTimerLoading(true)
			const onSuccess = (resp) => {
				setTimeout(() => {
					dispatch(hideLoader())
				}, 480)
				// const exstLogIdx = _.findIndex(stickyDetailsSynced.current?.time_logs, { time_log_id: resp.data?.data?.time_log_id })
				// onSuccessTimeLogs({ [exstLogIdx]: { $set: resp.data?.data } })
			}
			GlassApi.stopStickyTimer(glass_code, sticky_code, to).then(onSuccess, onErrorTimeLogs)
		// })
	}

	return (
		<div className={classes.timer}>
			<TimeLogger
                isLoading={isTimerLoading}
                // disabled={!isTimerEnabled}
                value={time_logs || []}
                // estimatedTime={formik.values?.estimation_time}
                onStartTimer={(e)=>onStartTimer(e)}
                onStopTimer={(e)=>onStopTimer(e)}
				isFrom='myTasks'
                // onAddTimeLog={onAddTimeLog}
                // onUpdateTimeLog={onUpdateTimeLog}
                // onRemoveTimeLog={onRemoveTimeLog}
                // onClearTimeLogs={onClearTimeLogs}
			/>
		</div>
	)
}

export default Timer
