import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { TablePagination, useMediaQuery, useTheme, Grow, Fab, Icon, Badge } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FormDialog from './FormDialog'
import _ from 'lodash'
import { PAGE_MODES } from '../../constants'
import projectApi from '../../services/projects_creation/api'
import ProjectTableList from './ProjectListTable'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import withDashboardModal from 'hoc/withDashboardModal'
import { hideLoader, showConfirmMessage, showErrorMessage, showLoader } from 'services/loader/actions'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import { usePermission } from 'hooks/usePermission'
import Axios from 'axios'
import moment from 'moment'
import { getDetailedPaginationProperties } from '../../utils'
import CardList from '../../components/CardList'
import ProjectFilters from '../../components/ProjectFilters'
import { FILTER_CONSTANTS } from '../../constants'
import Slider from './Slider'
import FilterApi from '../../services/projectFilters/api'
import { setProjectFilter } from '../../services/projectFilters/actions'
import { useHistory } from 'react-router'
import ModelDialog from './ModelDialog'

const MENU_WIDTH = 320

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			paddingBottom: 80,
		},
	},
	container: {
		// display: 'flex',
		// overflow: 'hidden',
		// maxWidth: '1200px',
		// [theme.breakpoints.between(1000, 1280)]: {
		// 	maxWidth: '94%',
		// },
	},
	fab: {
		position: 'fixed',
		zIndex: 100,
		bottom: '54px',
		right: '24px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	menuContainer: {
		width: 0,
		opacity: 0,
		height: '100%',
		boxSizing: 'border-box',
		transition: 'width 200ms',
		display: 'table-column-group',
		[theme.breakpoints.down('xs')]: {
			width: `0.00001vw`,
		},
	},
	openMenu: {
		width: MENU_WIDTH,
		opacity: 1,
		zIndex: 1,
		height: '100%',
		boxShadow: `0px 0px 4px 0px ${theme.palette.almostBlack[400]}`,
		background: 'white',
		position: 'fixed',
		right: '0',
		top: '64px',
		[theme.breakpoints.down(600)]: {
			top: '57px',
		},
		[theme.breakpoints.down(330)]: {
			width: 'auto',
		},
	},
}))
const Projects = ({ match, isEmbedded = false, changeEmbededState, openDashboard, closeDashboard, ...otherProps }) => {
	const dispatch = useDispatch()
	// states
	const classes = useStyles()
	const history = useHistory()
	const [params, setParams] = useState({ search: '', page: 1, offset: 0, limit: 12 })
	const [state, setState] = useState({ loading: false, projectList: [], totalCount: 0 })
	const [paginationFooterDetails, setPaginationFooterDetails] = useState({})
	const action = isEmbedded ? otherProps?.action : _.get(match, 'params.action', '')
	const dataId = isEmbedded ? otherProps?.id : _.get(match, 'params.id', '')
	const cancelExecutor = useRef()
	const appSettingsData = useSelector((state) => _.get(state, 'appSettings.data'))

	const [openModel, setOpenModel] = useState(false)
	const [record, setRecord] = useState(null)

	const { loading, projectList, totalCount } = state
	const { search, offset, page, limit } = params

	const projectPerms = usePermission(ROLE_MODULES.PROJECTS)
	const modelPerms = usePermission(ROLE_MODULES.MODELX)
	const isEditAllowed = useMemo(() => _.get(projectPerms, PERMISSION_TYPE.EDIT, false), [projectPerms])

	const filtersState = useSelector((state) => state.projectFilters)

	const theme = useTheme()
	const smallScreen = useMediaQuery(theme.breakpoints.down('1098'))
	const isSmallDevice = useMediaQuery(theme.breakpoints.down('550'))

	const [slider, setSlider] = useState({ open: false, id: '', title: '' })
	const [sortingDetails, setSortingDetails] = useState({ sort_by: !_.isEmpty(filtersState?.sort_by) ? filtersState?.sort_by : null, sort_by_order: filtersState?.sort_by_order })
	const sortableFields = [
		{id: 'project_title', name:'Project Name'},
		{id: 'created_date', name:'Created Date'},
		{id: 'modified_date', name:'Modified Date'},
		// {id: 'budget', name:'Budget'},
		{id: 'status', name:'Status'}
	]

	useEffect(() => {
		setSortingDetails({ sort_by: !_.isEmpty(filtersState?.sort_by) ? filtersState?.sort_by : null, sort_by_order: filtersState?.sort_by_order })
	}, [filtersState])

	const [filterMenuProps, setMenuProps] = useState({ isOpen: false, page: 'menulist', isButtonVisible: true })


	const CardContent = {
		// id_2: 'model_id',
		// rating: 'rating',
		id: 'project_code',
		title: 'project_title',
		status: 'status',
		start_date: 'project_start_date',
		end_date: 'project_end_date',
		completed: 'completed_percentage',
		// subtitle1: 'created_by',
		// subtitle2: 'status',
	}

	const handleSearchChange = (e) => {
		setParams({ ...params, search: e.target.value, offset: 0 })
	}

	const handleCloseFormDialog = (e, isRefreshList) => {
		// history.goBack()
		if (!isEmbedded)
			history.replace({
				pathname: `/projects`,
				isRefreshList: _.isEqual(isRefreshList, true),
			})
		else changeEmbededState({ action: '', id: null, isRefreshList: _.isEqual(isRefreshList, true) })
	}

	const changetoEditMode = (idx, record) => {
		if (!isEmbedded) history.replace({ pathname: `/projects/${PAGE_MODES.EDIT}/${idx}` })
		else changeEmbededState({ action: PAGE_MODES.EDIT, id: idx })
	}

	const editModeByBtn = (idx, record) => {
		if (!isEmbedded) history.replace({ pathname: `/projects/${PAGE_MODES.EDIT}/${dataId}` })
		else changeEmbededState({ action: PAGE_MODES.EDIT, id: dataId })
	}

	const cardList = projectList.map((project) => {
		const sharedPerm = _.get(project, 'permission')
		const systemGenerated = _.get(project, 'is_system_generated', false)
		return {
			projectId: _.get(project, 'project_code'),
			title: _.isEmpty(project.project_name) || project.project_name.trim() === '' ? 'No Title' : project.project_name,
			subtitle: `Created on ${moment(project.created_date).fromNow()}`,
			createdBy: _.get(project, 'created_by.user_name'),
			indicatorTxt: sharedPerm && 'Shared',
			// actions: actionsToShow(systemGenerated, sharedPerm).filter(Boolean),
			disabledActions: _.isEqual(sharedPerm, PERMISSION_TYPE.VIEW) ? ['Edit', 'Delete', 'Share'] : [],
			is_glassX: systemGenerated,
		}
	})

	const getProjectList = (search, offset, limit) => {
		setState({ loading: true, projectList: [], totalCount: 0 })
		projectApi.fetchAllProjects(
			search,
			offset,
			limit,
			// 'project_name',
			cancelExecutor,
			(res) => {
				setState({ loading: false, projectList: _.get(res, 'data.data.projects', []), totalCount: _.get(res, 'data.data.total_count', 0) })
				// setParams({ ...params, page: page, offset: offset })
				// setState({ loading: false, projectList: card, totalCount: _.get(res, 'data.data.total_count', 0) })
				isEmbedded && changeEmbededState({ projectsLength: _.get(res, 'data.data.total_count', 0) })
			},
			(err) => {
				if (!Axios.isCancel(err)) setState({ loading: false, projectList: [], totalCount: 0 })
			}
		)
	}

	const handleCloseSlider = () => {
		setSlider({ open: false, id: '', title: '' })
	}

	const onClickEdit = (id) => {
		history.push(`/projects/edit/${id}`)
	}

	const onClickDeleteMenu = (idx) => {
		dispatch(
			showConfirmMessage(
				`Are you sure, you want to delete this Project?`,
				'',
				'Confirm',
				(e) => {
					dispatch(showLoader('Please wait...'))
					projectApi.deleteProject(
						idx,
						(res) => {
							dispatch(hideLoader())
							handleCloseSlider()
							getProjectList('', 0, 12)
							setParams((prevState) => ({
								...prevState,
								limit: 12,
							}))
							dispatch(showSnackbarWithTimeout(_.get(res, 'data.more_info', 'Something went wrong. Please try again!'), 2000))
						},
						(err) => {
							dispatch(hideLoader())
							handleCloseSlider()
							dispatch(
								showErrorMessage(_.get(err, 'response.data.more_info', 'Something went wrong. Please try again!'), 'Close', () => {
									dispatch(hideLoader())
								})
							)
						}
					)
				},
				'Cancel',
				(e) => {
					dispatch(hideLoader())
				}
			)
		)
	}

	useEffect(() => {
		getProjectList(params.search, params.offset, params.limit, page)
	}, [params, filtersState])

	const sortFilter = useCallback((field, type) => {
		let filters = { ...filtersState, sort_by: field, sort_by_order: type }
		FilterApi.updateFilter(filters)
			.then((res) => {
				dispatch(setProjectFilter(res.data.data))
			})
			.catch((err) => {
				// console.log(err, 'err')
			})
	}, [filtersState])

	const paginationProperties = (pageNo, limit, totalCount, selectedList) => {
		return getDetailedPaginationProperties(limit, totalCount, pageNo, selectedList)
	}

	useEffect(() => {
		if (projectList !== 'undefined') {
			setPaginationFooterDetails({
				...paginationProperties(page, limit, totalCount),
			})
		}
	}, [totalCount, limit, page])

	const goToPage = (pageNo) => {
		setParams({ ...params, page: pageNo, offset: (pageNo - 1) * limit })
	}

	const setPageLimit = (selectedLimit) => {
		setParams({ ...params, limit: selectedLimit, page: 1, offset: 0 })
	}

	const handleSliderOpen = (e, record) => {
		setSlider({ open: true, id: record?.project_code, title: record?.project_title })
	}

	const closeFilterDialog = () => {
		setMenuProps(false)
	}

	const filterInitialState = {
		[FILTER_CONSTANTS.REDIRECT_URL]: false,
		[FILTER_CONSTANTS.SORT_BY]: null,
		[FILTER_CONSTANTS.SORT_BY_ORDER]: 'asc',
		[FILTER_CONSTANTS.CREATED_DATE]: {},
		[FILTER_CONSTANTS.MODIFIED_DATE]: {},
		[FILTER_CONSTANTS.CREATED_BY]: [],
		[FILTER_CONSTANTS.MODIFIED_BY]: [],
		[FILTER_CONSTANTS.START_DATE]: {},
		[FILTER_CONSTANTS.END_DATE]: {},
		[FILTER_CONSTANTS.STATUS]: [],
		[FILTER_CONSTANTS.RATING]: [0, 5],
		[FILTER_CONSTANTS.LABELS]: [],
	}

	const isFilterActive = useMemo(() => {
		const initialFilter = _.omit(filterInitialState, ['sort_by', 'sort_by_order', 'redirect_url'])
		const filter = _.omit(filtersState, ['sort_by', 'sort_by_order', 'redirect_url'])
		const value = !_.isEqual(initialFilter, filter)
		return value
	}, [filtersState])

	const refreshData = () => {
		getProjectList('', 0, 10)
		setParams((prevState) => ({
			...prevState,
			limit: 10,
		}))
	}

	// const onChangePage = (e, pageNo) => {
	// 	setParams({ ...params, offset: pageNo * params.limit })
	// }
	const onChangePage = (e, pageNo) => {
		setParams({ ...params, page: e.page, offset: e.page * limit })
	}

	const changeRowPerPage = (e) => {
		setParams({ ...params, limit: e.target.value, offset: 0 })
	}

	const projectDashxEnabled = _.isEqual(_.get(appSettingsData, 'project_dashboard_config.enable_dashboard'), true)

	const onClickDashX = (e, record, recordIdx) => {
		projectDashxEnabled && (
			openDashboard(
				_.get(appSettingsData, 'project_dashboard_config.dashboard_id.dashboard_id', ''),
				{
					title: _.get(record, `${_.get(appSettingsData, 'project_dashboard_config.field_name', '')}`),
					filterColumn: _.get(appSettingsData, 'project_dashboard_config.field_name', ''),
					filterValue: _.toString(_.get(record, `${_.get(appSettingsData, 'project_dashboard_config.field_name', '')}`)),
					dsId: _.get(appSettingsData, 'project_dashboard_config.ds_id.ds_id', ''),
					history: history,
					subtitle: _.get(appSettingsData, 'project_dashboard_config.ds_id.ds_name', ''),
					tag: "Project",
					viewLink: null,
				},
				null,
				false,
				() => {
					closeDashboard()
				}
			)
		)
	}

	const onClickGlassX = (e, record, recordIdx) => {
		const glassCode = record?.glass_code
		const stickyCode = record?.model_id

		!_.isEmpty(glassCode) && history.push(`/glassx/view/${glassCode}`)
	}

	const openModelScreen = (record) => {
		setOpenModel(true)
		setRecord(record)
	}

	const closeModelScreen = () => {
		setOpenModel(false)
		// setTimeout(() => {
		// 	removeQueryParams()
		// }, 800)
	}

	// const onRenderCellVal = (cellProperties, value, record) => {
	// 	return value
	// }
	return (
		<div className={classes.root}>
			<div className={classnames({ 'container-no-fluid page-content': !isEmbedded }, classes.container)}>
				<ProjectTableList
					idKey='project_code'
					isEmbedded={isEmbedded}
					changeEmbededState={changeEmbededState}
					data={projectList}
					title='Projects'
					titleIcon='folder'
					pageType='projects'
					onChangeSearch={handleSearchChange}
					history={history}
					baseUrl='/projects'
					showLoading={loading}
					onClickEditMenu={changetoEditMode}
					onClickDeleteMenu={onClickDeleteMenu}
					onClickEdit={onClickEdit}
					onClickModel={openModelScreen}
					projectPerms={projectPerms}
					hideAddBtn={_.get(projectPerms, PERMISSION_TYPE.CREATE, false) === true ? false : true}
					hideDeleteBtn={_.get(projectPerms, PERMISSION_TYPE.DELETE, false) === true ? false : true}
					hideEditBtn={_.get(projectPerms, PERMISSION_TYPE.EDIT, false) === true ? false : true}
					hideModelBtn={_.get(modelPerms, PERMISSION_TYPE.VIEW, false) === true ? false : true}
					// changePage={onChangePage}
					cardList={cardList}
					page={page}
					totalCount={totalCount}
					CardContent={CardContent}
					paginationFooterDetails={paginationFooterDetails}
					limit={limit}
					smallScreen={smallScreen}
					goToPage={goToPage}
					setPageLimit={setPageLimit}
					sortingDetails={sortingDetails}
					sortableFields={sortableFields}
					setSortingDetails={setSortingDetails}
					handleSliderOpen={handleSliderOpen}
					onClickGlassX={onClickGlassX}
					onClickDashX={onClickDashX}
					disableDashxBtn={!projectDashxEnabled}
					sortFilter={sortFilter}
				/>
				<Slider
					match={match}
					slider={slider}
					onClose={handleCloseSlider}
					history={history}
					onClickGlassX={onClickGlassX}
					onClickDeleteMenu={onClickDeleteMenu}
					onClickEdit={onClickEdit}
					hideDeleteBtn={_.get(projectPerms, PERMISSION_TYPE.DELETE, false) === true ? false : true}
					hideEditBtn={_.get(projectPerms, PERMISSION_TYPE.EDIT, false) === true ? false : true}
					onClickDashX={onClickDashX}
					disableDashxBtn={!projectDashxEnabled}
				/>

				<Grow in={!filterMenuProps.isOpen} unmountOnExit>
					<Fab
						className={classes.fab}
						color='primary'
						size='small'
						onClick={() => {
							setMenuProps((prev) => ({
								...prev,
								isOpen: true,
							}))
						}}
					>
						{isFilterActive ? (
							<Badge color='error' badgeContent={''} variant='dot'>
								<Icon>filter_list</Icon>
							</Badge>
						) : (
							<Icon>filter_list</Icon>
						)}
					</Fab>
				</Grow>

				<div className={classnames(classes.menuContainer, { [classes.openMenu]: filterMenuProps.isOpen })}>

					<ProjectFilters
						onCloseDialog={closeFilterDialog}
						isSmallDevice={isSmallDevice}
						noScrapperFilter={''}
						filterOpen={filterMenuProps.isOpen}
						showTheseFilters={[
							FILTER_CONSTANTS.CREATED_DATE,
							FILTER_CONSTANTS.MODIFIED_DATE,
							FILTER_CONSTANTS.CREATED_BY,
							FILTER_CONSTANTS.MODIFIED_BY,
							FILTER_CONSTANTS.START_DATE,
							FILTER_CONSTANTS.END_DATE,
							FILTER_CONSTANTS.STATUS,
							FILTER_CONSTANTS.RATING,
							FILTER_CONSTANTS.LABELS,
						]}
						extraFilterProps={''}
						// setExportList={setExportList}
						// setTableData={setTableData}
						// setAllPageProduct={setAllPageProduct}
						searchSuggestionsProductType='new'
						filterInitialState={filterInitialState}
					/>
				</div>

				{/* {!loading && <CardList page={1} changePage={onChangePage} pagination={true} data={cardList}></CardList>} */}
				{/* {!loading && (
					<TablePagination
						component='div'
						count={totalCount}
						page={params.offset / params.limit}
						onChangePage={onChangePage}
						rowsPerPage={params.limit}
						onChangeRowsPerPage={changeRowPerPage}
					/>
				)} */}
			</div>
			{/* <FormDialog
				open={!_.isEmpty(action)}
				action={action}
				isEditAllowed={isEditAllowed}
				handleClose={handleCloseFormDialog}
				history={history}
				dataId={dataId}
				changeEditMode={editModeByBtn}
				refreshData={() => {
					refreshData()
				}}
			/> */}
			<ModelDialog
				dialogTitle='Choose People'
				open={openModel}
				onClose={closeModelScreen}
				// onPickedPeoplex={onPickedPeoplex}
				projectData={record}
				isPickFrom='Projects'
			></ModelDialog>
		</div>
	)
}

export default compose(withDashboardModal)(Projects)

