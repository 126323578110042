import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'
import _ from 'lodash'
import { convertLocalTimeToGmtStr, jsonToFormData } from 'utils'
const CancelToken = Axios.CancelToken

export default {
	fetchUserDashboard: (cancelExecutor, successCallback, errorCallback) => {
		if (cancelExecutor.current) {
			cancelExecutor.current()
		}
		Axios.get(`${API_ENDPOINTS.USER_DASHBOARD}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},

	fetchUserDataSource: (dashboardId, module_name, cancelExecutor, successCallback, errorCallback) => {
		if (cancelExecutor.current) {
			cancelExecutor.current()
		}
		Axios.get(`${API_ENDPOINTS.USER_DATASOURCE}`, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { dashboardId },
			},
			params: { module_name },
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchUserFields: (dsId, module_name, cancelExecutor, successCallback, errorCallback) => {
		if (cancelExecutor.current) {
			cancelExecutor.current()
		}
		Axios.get(`${API_ENDPOINTS.USER_FIELDS}`, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { dsId },
			},
			params: { module_name },
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchUserLabels: (search, offset, limit, module, cancelExecutor) => {
		if (cancelExecutor.current) {
			cancelExecutor.current()
		}
		return Axios.get(`${API_ENDPOINTS.GET_USER_LABELS}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { search, offset, limit, module },
			},
		})
	},
	fetchLabelsCategory: (cancelExecutor) => {
		if (cancelExecutor.current) {
			cancelExecutor.current()
		}
		return Axios.get(`${API_ENDPOINTS.LABELS_CATEGORY}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
			},
		})
	},
	userCreateLabel: (labelName, module, sessionId) => {
		return Axios.post(
			API_ENDPOINTS.USER_LABELS,
			{
				label_name: labelName,
				module: module,
			},
			{
				headers: { isAuthRequired: true, sid: sessionId, 'Content-Type': 'application/json' },
			}
		)
	},
	updateUserLabel: (labelId, labelName, module, sessionId) => {
		return Axios.put(
			API_ENDPOINTS.SINGLE_USER_LABELS,
			{
				label_id: labelId,
				label_name: labelName,
				module: module,
			},
			{
				headers: { isAuthRequired: true, sid: sessionId, 'Content-Type': 'application/json', path: { labelId } },
			}
		)
	},
	deleteLabel: (labelId, module, sessionId) => {
		return Axios.delete(API_ENDPOINTS.SINGLE_USER_LABELS, {
			data: { label_id: labelId, module: module },
			headers: { isAuthRequired: true, sid: sessionId, 'Content-Type': 'application/json', path: { labelId } },
		})
	},
	fetchUserDepartments: (search, offset, limit, module, cancelExecutor) => {
		if (cancelExecutor.current) {
			cancelExecutor.current()
		}
		return Axios.get(`${API_ENDPOINTS.GET_USER_DEPARTMENTS}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { search, offset, limit, module },
			},
		})
	},
	userCreateDepartment: (departmentName, module, sessionId) => {
		return Axios.post(
			API_ENDPOINTS.USER_DEPARTMENTS,
			{
				department_name: departmentName,
				module: module,
			},
			{
				headers: { isAuthRequired: true, sid: sessionId, 'Content-Type': 'application/json' },
			}
		)
	},
	updateUserDepartment: (departmentId, departmentName, module, sessionId) => {
		return Axios.put(
			API_ENDPOINTS.SINGLE_USER_DEPARTMENTS,
			{
				department_id: departmentId,
				department_name: departmentName,
				module: module,
			},
			{
				headers: { isAuthRequired: true, sid: sessionId, 'Content-Type': 'application/json', path: { departmentId } },
			}
		)
	},
	deleteDepartment: (departmentId, module, sessionId) => {
		return Axios.delete(API_ENDPOINTS.SINGLE_USER_DEPARTMENTS, {
			data: { department_id: departmentId, module: module },
			headers: { isAuthRequired: true, sid: sessionId, 'Content-Type': 'application/json', path: { departmentId } },
		})
	},
	fetchUserDesignations: (search, offset, limit, module, cancelExecutor) => {
		if (cancelExecutor.current) {
			cancelExecutor.current()
		}
		return Axios.get(`${API_ENDPOINTS.GET_USER_DESIGNATIONS}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { search, offset, limit, module },
			},
		})
	},
	userCreateDesignation: (designationName, module, sessionId) => {
		return Axios.post(
			API_ENDPOINTS.USER_DESIGNATIONS,
			{
				designation_name: designationName,
				module: module,
			},
			{
				headers: { isAuthRequired: true, sid: sessionId, 'Content-Type': 'application/json' },
			}
		)
	},
	updateUserDesignation: (designationId, designationName, module, sessionId) => {
		return Axios.put(
			API_ENDPOINTS.SINGLE_USER_DESIGNATIONS,
			{
				designation_id: designationId,
				designation_name: designationName,
				module: module,
			},
			{
				headers: { isAuthRequired: true, sid: sessionId, 'Content-Type': 'application/json', path: { designationId } },
			}
		)
	},
	deleteDesignation: (designationId, module, sessionId) => {
		return Axios.delete(API_ENDPOINTS.SINGLE_USER_DESIGNATIONS, {
			data: { designation_id: designationId, module: module },
			headers: { isAuthRequired: true, sid: sessionId, 'Content-Type': 'application/json', path: { designationId } },
		})
	},
}
