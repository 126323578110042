import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'
import _ from 'lodash'
import { format } from 'date-fns'

const CancelToken = Axios.CancelToken

const params = (param, search, redirect) => {
	// let startDate = format(
	// 	_.isString(param?.date_range?.startDate) ? new Date(param?.date_range?.startDate) : param?.date_range?.startDate,
	// 	'yyyy-MM-dd'
	// )
	// let endDate = format(_.isString(param?.date_range?.endDate) ? new Date(param?.date_range?.endDate) : param?.date_range?.endDate, 'yyyy-MM-dd')

	let query = {
		page_number: parseInt(param.page),
		page_row_count: parseInt(param.limit),
		// date: {
		// 	from: `${new Date(startDate).toUTCString()}`,
		// 	to: `${new Date(endDate).toUTCString()}`,
		// },
		// counterfeit: _.get(param, 'isOnlyCounterfeits', false),
		search_text: search ? search : '',
		redirect_url: redirect,
	}
	return query
}

export default {
	getProductsTableData: (query, search, redirect, cancelExecutor) => {
		if (cancelExecutor.current) cancelExecutor.current()
		return Axios.post(API_ENDPOINTS.PEOPLEX_LIST, params(query, search, redirect), {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
		})
	},
	getPeoplexDataDetails: (peoplexId, cancelExecutor) => {
		console.log('peoplex details api', peoplexId, cancelExecutor)
		if (cancelExecutor.current) cancelExecutor.current()
		return Axios.get(API_ENDPOINTS.PEOPLEX_DETAILS, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { peoplexId } },
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
		})
	},
	getExportView: (cancelExecutor) => {
		if (cancelExecutor.current) cancelExecutor.current()
		return Axios.get(API_ENDPOINTS.PEOPLEX_EXPORT_VIEW, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
		})
	},
	changeExportView: function (searchParams, cancelExecutor) {
		if (cancelExecutor.current) cancelExecutor.current()
		return Axios.put(
			API_ENDPOINTS.PEOPLEX_EXPORT_VIEW,
			{
				...searchParams,
			},
			{
				headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
				cancelToken: new CancelToken(function executor(c) {
					cancelExecutor.current = c
				}),
			}
		)
	},
	getExportResults: function (searchParams, cancelExecutor) {
		if (cancelExecutor.current) cancelExecutor.current()
		return Axios.post(
			API_ENDPOINTS.PEOPLEX_EXPORT_RESULTS,
			{
				...searchParams,
			},
			{
				headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
				cancelToken: new CancelToken(function executor(c) {
					cancelExecutor.current = c
				}),
			}
		)
	},
	getExport: function (searchParams, cancelExecutor) {
		if (cancelExecutor.current) cancelExecutor.current()
		return Axios.post(
			API_ENDPOINTS.PEOPLEX_EXPORT,
			{
				...searchParams,
			},
			{
				headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
				cancelToken: new CancelToken(function executor(c) {
					cancelExecutor.current = c
				}),
			}
		)
	},
	fetchPeoplexDashboard: (search, offset, limit, cancelExecutor, successCallback, errorCallback) => {
		if (cancelExecutor.current) {
			cancelExecutor.current()
		}
		Axios.get(`${API_ENDPOINTS.PEOPLEX_DASHBOARD}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { search, offset, limit },
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchPeoplexDataSource: (dashboardId, search, offset, limit, cancelExecutor, successCallback, errorCallback) => {
		if (cancelExecutor.current) {
			cancelExecutor.current()
		}
		Axios.get(`${API_ENDPOINTS.PEOPLEX_DATASOURCE}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { dashboardId, search, offset, limit },
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchPeoplexFields: (dsId, search, offset, limit, cancelExecutor, successCallback, errorCallback) => {
		if (cancelExecutor.current) {
			cancelExecutor.current()
		}
		Axios.get(`${API_ENDPOINTS.PEOPLEX_FIELDS}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { dsId, search, offset, limit },
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchPeoplexSettings: (cancelExecutor, successCallback, errorCallback) => {
		if (cancelExecutor.current) {
			cancelExecutor.current()
		}
		Axios.get(`${API_ENDPOINTS.PEOPLEX_SETTINGS}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	changePeoplexSettings: (data, successCallback, errorCallback) => {
		return Axios.put(
			`${API_ENDPOINTS.PEOPLEX_SETTINGS}`,
			{ ...data },
			{
				headers: {
					'Content-Type': 'application/json',
					isAuthRequired: true,
				},
			}
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
}
