import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Grid, TextField, Typography, Switch, FormControlLabel } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Field } from 'formik'
// import { REASON_TO_SKIP } from '../../../../../../constants'
import classnames from 'classnames'
import SelectBox from '../../../../../../components/SelectBox'
import MultiSelect from '../../../../../../components/MultiSelect'
import CardList from '../../CardList'

const useStyles = makeStyles((theme) => ({
	profilePic: {
		width: 170,
		height: 170,
		[theme.breakpoints.down('xs')]: {
			width: 140,
			height: 140,
			margin: '0 auto',
		},
	},
	flexRootResponsive: {
		display: 'flex',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
	},
	basicHeaderRight: {
		flex: 1,
		// paddingLeft: theme.spacing(2),
		marginTop: theme.spacing(0.8),
		[theme.breakpoints.down('xs')]: {
			flex: 'unset',
			paddingLeft: 0,
		},
	},
	basicHeaderLeft: {
		maxWidth: 170,
		[theme.breakpoints.down('xs')]: {
			flex: 1,
			maxWidth: '100%',
			paddingBottom: theme.spacing(2),
			textAlign: 'center',
		},
	},
	textField: {
		marginBottom: theme.spacing(2.2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
		},
	},
	parallelInputRoot: {
		width: 'calc(50% - 8px)',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	parallelInputEnd: {
		marginLeft: 16,
		[theme.breakpoints.down('xs')]: {
			marginLeft: 0,
		},
	},
	basicHeader: {
		marginBottom: theme.spacing(0.5),
		[theme.breakpoints.down('xs')]: {
			marginBottom: 0,
		},
	},
	pwdTitle: {
		textTransform: 'none',
	},
	statusIndicator: {
		marginLeft: theme.spacing(2),
		borderRadius: '50%',
		width: '10px',
		height: '10px',
		display: 'inline-block',
		marginRight: theme.spacing(1),
	},
}))

const Apps = ({ isEditMode, isCreateMode, isViewMode, formik, streams, data, cardImageStyle, cardBreakups, onClickcard, stream }) => {
	const classes = useStyles()

	const [searchkey, setSearchkey] = useState('')
	const searchBoxTimeout = useRef(undefined)

	const showFieldValue = (value) => {
		return isViewMode ? (_.isEmpty(_.trim(value)) ? '-' : value) : value
	}

	const marketPlace = [
		{
			id: 'Amazon',
			name: 'Amazon',
			url: 'https://1000logos.net/wp-content/uploads/2016/10/Amazon-logo-meaning.jpg',
		},
		{
			id: 'eBay',
			name: 'eBay',
			url: 'https://cdn-icons-png.flaticon.com/512/888/888848.png',
		},
		{
			id: 'Walmart',
			name: 'Walmart',
		},
		{
			id: 'Flipkart',
			name: 'Flipkart',
		},
		{
			id: 'LinkedIn',
			name: 'LinkedIn',
		},
		{
			id: 'Glassdoor',
			name: 'Glassdoor',
		},
		{
			id: 'Others',
			name: 'Others',
		},
	]

	const onInputSRChange = (e) => {
		if (e !== null) {
			if (e.target.value !== 0) {
				if (searchBoxTimeout.current) clearTimeout(searchBoxTimeout.current)
				searchBoxTimeout.current = setTimeout(() => {
					// let product_id = e.target.value
					// setProdSearch(e.target.value)
					setSearchkey(e.target.value)
				}, 1500)
			}
		}
	}

	return (
		<Grid container>
			<Grid item xs={12} className={classnames(classes.flexRootResponsive, classes.basicHeader)}>
				<div className={classes.basicHeaderRight}>
                    {!_.isEmpty(data) &&
                    <CardList
                        limit={20}
                        data={data}
                        selection={true}
                        cardImageStyle={cardImageStyle}
                        pagination={false}
                        stream={stream}
                        cardBreakups={cardBreakups}
                        actionHandler={onClickcard}
                    />}
				</div>
			</Grid>
		</Grid>
	)
}

Apps.propTypes = {
	isCreateMode: PropTypes.bool.isRequired,
	isEditMode: PropTypes.bool.isRequired,
	isViewMode: PropTypes.bool.isRequired,
	formik: PropTypes.object.isRequired,
}

export default Apps
