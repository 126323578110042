import * as Yup from 'yup'
import moment from 'moment'

const URL =
	/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

export const initialData = {
	project_desc: '',
	data_stream: {
		apps: [],
		data_base: [],
		third_party_stream: [],
	},
	project_title: '',
	project_scope: '',
	category: [],
	labels: [],
	// infrastructure_details: [
	// 	{
	// 		infrastructure_type: "wersel_x",
	// 		core: 0,
	// 		node: 0,
	// 		details: {
	// 			cpu: 0,
	// 			gpu: 0,
	// 			memory: 0,
	// 			storage: 0,
	// 		}
	// 	}
	// ],
	infrastructure_type: 'wersel_xl',
	timeline: [
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'dates',
		},
	],
	wersel_x: {
		infrastructure_type: 'wersel_x',
		infra_select_label: 'Select Wersel X',
		core: 1,
		node: 1,
		details: {
			cpu: '8 core',
			gpu: '8 GB',
			memory: '32 GB',
			storage: '2 x SSD 480GB',
		},
		checked: false,
	},
	wersel_xl: {
		infrastructure_type: 'wersel_xl',
		infra_select_label: 'Select Wersel XL',
		core: 1,
		node: 1,
		details: {
			cpu: '32 core',
			gpu: '32 GB',
			memory: '128 GB',
			storage: '4 x SSD 480GB',
		},
		checked: false,
	},
	wersel_platinum: {
		infrastructure_type: 'wersel_platinum',
		infra_select_label: 'Select Wersel Platinum',
		core: 1,
		node: 1,
		details: {
			cpu: '256 core',
			gpu: '4 x Tesla V100s 256GB',
			memory: '8 TB',
			storage: '16 x SSD 2TB',
		},
		checked: false,
	},
	project_cost: 1,
	project_members: [],
	created_by: null,
}

export const validationSchema = [
	Yup.object().shape({
		project_desc: Yup.string().trim().nullable().required('Please enter the description.'),
		// data_streams: Yup.array().min(1, 'Please select the data stream.').required('Please select the data stream.'),
		// match_type: Yup.string().trim().required('Please select the match type.'),
		// prj_title: Yup.string().trim().required('Please enter the project title'),
		// scope: Yup.string().trim().required('Please enter the scope'),
	}),
	Yup.object().shape({
		project_title: Yup.string().trim().required('Please enter the title.'),
		project_scope: Yup.string().trim().required('Please enter the scope.'),
		labels: Yup.array().min(1, 'Please select labels.').required('Please select labels.'),
		// infra: Yup.string().trim().required('Please select the infrastructure'),
		// date: Yup.date().required('Start time is required').typeError('Invalid Start Time'),
		// budget: Yup.number().required('Please select the budget').min(0, 'Min value 0.').max(100, 'Max value 100.'),
	}),
	Yup.object().shape({
		// project_members: Yup.array().min(1, 'Please select people.').required('Please select people.'),
	}),
	Yup.object().shape({
		// people: Yup.array().min(1, 'Please select people.').required('Please select people.'),
	}),
]
