import React, { useRef, useEffect } from 'react'
import { Divider, Grid, makeStyles, Typography, Chip, TextField, Avatar, Box, Fab, Icon, Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { formTheName, getImgUrl, stringToHslColor } from 'utils'
import { getModelxDetails } from '../../../../../services/modelx/actions'
import _ from 'lodash'
import { Rating } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
    headerRoot: {
		'& .MuiFormControl-fullWidth': {
			marginTop: 0,
		},
		'& .MuiOutlinedInput-inputMarginDense': {
			padding: theme.spacing(1),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1.2, 2, 0, 2),
		},
		padding: '16px 20px',
	},
    titleSec: {
		overflow: 'hidden',
		cursor: 'text',
        '& .MuiTypography-h6': {
            fontSize: '14px',
            fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: '500',
            lineHeight: '1.57',
            letterSpacing: '0.00714em',
            },
		'& h5': {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
    content: {
        margin: '8px 0px',
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 16px 8px 0px',
    },
    contentKey: {
        fontSize: '14px',
        fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
        fontWeight: '500',
        lineHeight: '1.57',
        letterSpacing: '0.00714em',
        color: '#171725',
    },
    contentValue: {
        marginTop: '8px',
        alignItems: 'center',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
        fontSize: '0.875rem',
        fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
        fontWeight: '400',
        lineHeight: '1.43',
        letterSpacing: '0.01071em',
        color: '#171725',
    },
    assigneeDiv: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flex: 1,
		alignItems: 'center',
		marginTop: theme.spacing(1),
	},
	text: {
		fontSize: 14,
		flex: 1,
	},
    userPicSmall: {
		width: 24,
		height: 24,
		fontSize: 13,
	},
    chipContent: {
        margin: '8px 0px',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiChip-sizeSmall': {
            height: '18px',
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
            fontWeight: '400',
            lineHeight: '1.43',
            letterSpacing: '0.03333em',
            color: '#171725',
            fontSize: '0.875rem',
            boxSizing: 'borderBox',
            alignItems: 'center',
        },
    },
    chip: {
        marginTop: '8px',
        display: 'flex',
        gap: '5px',
    },
    multiTextField: {
        minWidth: '100%',
        maxWidth: '100%',
        '& .MuiOutlinedInput-multiline': {
            color: 'black',
        },
    },
    noneSection: {
		backgroundColor: theme.palette.grey[100],
		padding: 5,
		fontSize: 14,
		color: theme.palette.grey[600],
		marginTop: theme.spacing(1),
	},
    footerRoot: {
		// borderTop: `1px solid ${theme.palette.grey[300]}`,
		position: 'sticky',
		bottom: 0,
		zIndex: 999,
		background: theme.palette.common.white,
	},
	centerAlign: {
		textAlign: 'center',
		// borderRight: `1px solid ${theme.palette.divider}`,
		'& button, & a': {
			padding: theme.spacing(1.5),
			width: '100%',
		},
	},
}))


const BasicDetails = ({ slider, basicDetails, onClickDashX, disableDashxBtn }) => {
 
    const classes = useStyles()

    const token = useSelector((state) => _.get(state, 'session.authToken'))


    // const dispatch = useDispatch()

    // const cancelExecutor = useRef()

    // const basicDetails = useSelector((state) => state.modelxTable?.modelx_details)

    // useEffect(() => {
    //     // if (cancelExecutor.current !== undefined) {
    //     //     cancelExecutor.current()
    //     // }
    //     dispatch(getModelxDetails(slider?.id, cancelExecutor))
    // }, [])

    return (
        <div>
            <Grid container justifyContent='space-between' alignItems='flex-start' className={classes.headerRoot}>
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'Category'}
                    </Typography>
                    {!_.isEmpty(basicDetails?.category) ? (
                    <Typography variant='caption' color='textSecondary' className={classes.contentValue}>
                        {basicDetails?.category}
                    </Typography>
                    ) : (
                        <p className={classes.noneSection}>None</p>
                    )}
                </Grid>
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'Talent Type'}
                    </Typography>
                    {!_.isEmpty(basicDetails?.talent_type) ? (
                    <Typography variant='caption' color='textSecondary' className={classes.contentValue}>
                        {basicDetails?.talent_type}
                    </Typography>
                    ) : (
                        <p className={classes.noneSection}>None</p>
                    )}
                </Grid>
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'Hours Billed'}
                    </Typography>
                    {_.gt(basicDetails?.hours_billed, 0) ? (
                    <Typography variant='caption' color='textSecondary' className={classes.contentValue}>
                        {basicDetails?.hours_billed}
                    </Typography>
                    ) : (
                        <p className={classes.noneSection}>None</p>
                    )}
                </Grid>
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'Hourly Rate'}
                    </Typography>
                    {_.gt(basicDetails?.hourly_rate, 0) ? (
                    <Typography variant='caption' color='textSecondary' className={classes.contentValue}>
                        {'$'}{basicDetails?.hourly_rate}
                    </Typography>
                    ) : (
                        <p className={classes.noneSection}>None</p>
                    )}
                </Grid>
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'Earned Amount'}
                    </Typography>
                    {_.gt(basicDetails?.earned_amount, 0) ? (
                    <Typography variant='caption' color='textSecondary' className={classes.contentValue}>
                        {'$'}{basicDetails?.earned_amount}
                    </Typography>
                    ) : (
                        <p className={classes.noneSection}>None</p>
                    )}
                </Grid>
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'Projects Worked'}
                    </Typography>
                    {_.gt(basicDetails?.project_worked, 0) ? (
                    <Typography variant='caption' color='textSecondary' className={classes.contentValue}>
                        {basicDetails?.project_worked}
                    </Typography>
                    ) : (
                        <p className={classes.noneSection}>None</p>
                    )}
                </Grid>
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'Weekly Commitment'}
                    </Typography>
                    {_.gt(basicDetails?.weekly_commitment, 0) ? (
                    <Typography variant='caption' color='textSecondary' className={classes.contentValue}>
                        {basicDetails?.weekly_commitment}
                    </Typography>
                    ) : (
                        <p className={classes.noneSection}>None</p>
                    )}
                </Grid>
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'Total Hours Worked'}
                    </Typography>
                    {_.gt(basicDetails?.total_hours_worked, 0) ? (
                    <Typography variant='caption' color='textSecondary' className={classes.contentValue}>
                        {basicDetails?.total_hours_worked}
                    </Typography>
                    ) : (
                        <p className={classes.noneSection}>None</p>
                    )}
                </Grid>
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'Job Success'}
                    </Typography>
                    {_.gt(basicDetails?.job_success, 0) ? (
                    <Typography variant='caption' color='textSecondary' className={classes.contentValue}>
                        {basicDetails?.job_success}{'%'}
                    </Typography>
                    ) : (
                        <p className={classes.noneSection}>None</p>
                    )}
                </Grid>
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'Success Score'}
                    </Typography>
                    {_.gt(basicDetails?.success_score, 0) ? (
                    <div role='button'>
                        <Grid style={{ marginTop: 8 }} className={classes.priorityItem} container alignItems='center'>
                            <Grid item xs style={{display: 'flex', flexDirection: 'row'}}>
                            <p style={{ marginTop: '0px', fontSize: '15px' }}>{basicDetails?.success_score}</p>&nbsp;<Rating name="unique-rating" precision={0.1} value={basicDetails?.success_score} readOnly size="small" />							
                            </Grid>
                        </Grid>
                    </div>
                    ) : (
                        <p className={classes.noneSection}>None</p>
                    )}
                </Grid>
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'Code Quality'}
                    </Typography>
                    {_.gt(basicDetails?.code_quality, 0) ? (
                    <div role='button'>
                        <Grid style={{ marginTop: 8 }} className={classes.priorityItem} container alignItems='center'>
                            <Grid item xs style={{display: 'flex', flexDirection: 'row'}}>
                            <p style={{ marginTop: '0px', fontSize: '15px' }}>{basicDetails?.code_quality}</p>&nbsp;<Rating name="unique-rating" precision={0.1} value={basicDetails?.code_quality} readOnly size="small" />							
                            </Grid>
                        </Grid>
                    </div>
                    ) : (
                        <p className={classes.noneSection}>None</p>
                    )}
                </Grid>
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'Status Ratings'}
                    </Typography>
                    {_.gt(basicDetails?.status_ratings, 0) ? (
                    <div role='button'>
                        <Grid style={{ marginTop: 8 }} className={classes.priorityItem} container alignItems='center'>
                            <Grid item xs style={{display: 'flex', flexDirection: 'row'}}>
                            <p style={{ marginTop: '0px', fontSize: '15px' }}>{basicDetails?.status_ratings}</p>&nbsp;<Rating name="unique-rating" precision={0.1} value={basicDetails?.status_ratings} readOnly size="small" />							
                            </Grid>
                        </Grid>
                    </div>
                    ) : (
                        <p className={classes.noneSection}>None</p>
                    )}
                </Grid>
                
                {/* <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'Created By'}
                    </Typography>
                    <div className={classes.assigneeDiv}>
                        <Avatar
                            {...(!_.isEmpty(basicDetails?.created_by?.profile_img) && {
                                src: getImgUrl(token, basicDetails?.created_by?.profile_img),
                            })}
                            alt={formTheName(basicDetails?.created_by?.first_name, basicDetails?.created_by?.last_name)}
                            className={classes.userPicSmall}
                            style={{
                                backgroundColor: stringToHslColor(formTheName(basicDetails?.created_by?.first_name, basicDetails?.created_by?.last_name)),
                            }}
                        >
                            {_.slice(_.words(formTheName(basicDetails?.created_by?.first_name, basicDetails?.created_by?.last_name)), 0, 1).reduce((a, t) => (a += t[0]), '')}
                        </Avatar>
                        <Typography variant='body1' className={classes.text} style={{ marginLeft: 4 }}>
                            {formTheName(basicDetails?.created_by?.first_name, basicDetails?.created_by?.last_name)}
                        </Typography>
                    </div>
                </Grid> */}
                {/* <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                        <Typography variant='caption' className={classes.contentKey}>
                            {'Modified By'}
                        </Typography>
                        <div className={classes.assigneeDiv}>
                        <Avatar
                            {...(!_.isEmpty(basicDetails?.modified_by?.profile_img) && {
                                src: getImgUrl(token, basicDetails?.modified_by?.profile_img),
                            })}
                            alt={formTheName(basicDetails?.modified_by?.first_name, basicDetails?.modified_by?.last_name)}
                            className={classes.userPicSmall}
                            style={{
                                backgroundColor: stringToHslColor(formTheName(basicDetails?.modified_by?.first_name, basicDetails?.modified_by?.last_name)),
                            }}
                        >
                            {_.slice(_.words(formTheName(basicDetails?.modified_by?.first_name, basicDetails?.modified_by?.last_name)), 0, 1).reduce((a, t) => (a += t[0]), '')}
                        </Avatar>
                        <Typography variant='body1' className={classes.text} style={{ marginLeft: 4 }}>
                            {formTheName(basicDetails?.modified_by?.first_name, basicDetails?.modified_by?.last_name)}
                        </Typography>
                    </div>
                </Grid> */}

                
                
                
                <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'Communication'}
                    </Typography>
                    {!_.isEmpty(basicDetails?.communication) ? (
                    <Typography variant='caption' color='textSecondary' className={classes.contentValue}>
                        {basicDetails?.communication}
                    </Typography>
                    ) : (
                        <p className={classes.noneSection}>None</p>
                    )}
                </Grid>
                {/* <Grid item lg={6} sm={6} xs={12} className={classes.content}>
                        <Typography variant='caption' className={classes.contentKey}>
                            {'Port'}
                        </Typography>
                        {!_.isEqual(basicDetails?.node_port, null) ?
                        <Typography variant='caption' color='textSecondary' className={classes.contentValue}>
                            {basicDetails?.node_port}
                        </Typography> : 
                        <p className={classes.noneSection}>None</p>}
                </Grid> */}
                {/* <Grid item lg={12} sm={12} xs={12} className={classes.content}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'Tags'}
                    </Typography>
                    <Typography variant='caption' color='textSecondary' className={classes.contentValue}>
                        {basicDetails?.tags}
                    </Typography>
                </Grid> */}
                <Grid item lg={12} sm={12} xs={12} className={classes.chipContent}>
                    <Typography variant='caption' className={classes.contentKey}>
                        {'Tags'}
                    </Typography>
                    {!_.isEmpty(basicDetails?.tags) ? (
                    <div className={classes.chip}>
                        {basicDetails?.tags?.map((data, id) => (
                            <Chip size='small' key={id} label={data}/>
                        ))}
                    </div>
                    ) : (
                    <p className={classes.noneSection}>None</p>
                    )}
                </Grid>
            </Grid>
            <Grid container className={classes.footerRoot}>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={12} className={classes.centerAlign}>
                    <Button size='small' color='primary' disabled={disableDashxBtn} onClick={(e) => onClickDashX(e, basicDetails)}>
                        Dashboard
                    </Button>
                </Grid>
                {/* <Grid item xs={12} sm={6} className={classes.centerAlign}>
                    <Button target='_blank' size='small' color='primary' onClick={(e) => onClickGlassX(e, project)} disabled={_.isEmpty(project.glass_code)}>
                        Boards
                    </Button>
                </Grid> */}
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
        </div>
    )
}

export default BasicDetails
