import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	suggestions: (query) => {
		return Axios.post(API_ENDPOINTS.FILTER_SUGGESTIONS, query, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
	searchSuggestionsInProjectSearchApi: (query) => {
		return Axios.post(API_ENDPOINTS.PROJECT_FILTER_SUGGESTIONS, query, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
}
