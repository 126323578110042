import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	loadingContainer: {
		maxWidth: 80,
		margin: '0 auto',
		paddingTop: theme.spacing(20),
		paddingBottom: theme.spacing(2),
		textAlign: 'center',
		'& span': {
			paddingTop: theme.spacing(2),
			display: 'block',
		},
	},
}))

const LoadingIndicator = () => {
	const classes = useStyles()
	return (
		<div className={classes.loadingContainer}>
			<LinearProgress color='primary' />
			<span>Loading...</span>
		</div>
	)
}

export default LoadingIndicator
