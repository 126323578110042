import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { makeStyles } from '@material-ui/core'
// import { stateToHTML } from 'draft-js-export-html'
// import { stateFromHTML } from 'draft-js-import-html'
import { useState } from 'react'
import classnames from 'classnames'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

const useStyles = makeStyles((theme) => ({
	root: {
		border: `1px solid ${theme.palette.divider}`,
		overflow: 'auto',
		'&::-webkit-scrollbar, ::-webkit-scrollbar-track': {
			width: '10px',
			height: '10px',
			'-webkit-appearance': 'none',
			backgroundColor: 'transparent',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.palette.almostBlack[400],
			height: '80px',
			borderRadius: '5px',
		},
		'& .rdw-suggestion-dropdown': {
			width: '200px',
			boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
			position: 'relative',
		},
		'& .rdw-colorpicker-modal-options': {
			'&::-webkit-scrollbar, ::-webkit-scrollbar-track': {
				width: '10px',
				height: '10px',
				'-webkit-appearance': 'none',
				backgroundColor: 'transparent',
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.almostBlack[400],
				height: '80px',
				borderRadius: '5px',
			},
		},
		'& .rdw-editor-toolbar': {
			fontSize: '13px',
			fontFamily: 'inherit',
			'& .rdw-option-wrapper': {
				minWidth: 20,
				padding: '1px 5px',
				margin: '0 2px',
				border: 'none',
				boxShadow: 'none',
				'&.rdw-option-active': {
					color: theme.palette.common.white,
					backgroundColor: theme.palette.text.secondary,
					fontWeight: 'bold',
				},
				'& .rdw-colorpicker-option': {
					'&.rdw-option-active': {
						border: '3px solid grey',
					},
				},
			},
		},
	},
	editorClass: {
		fontSize: '14px',
		padding: theme.spacing(1, 2),
		minHeight: ({ showMinHeight }) => (showMinHeight ? '100px' : 'unset'),
		'& span': {
			lineHeight: '130%',
			// wordBreak: 'break-word',
		},
		'& h1, h2, h3': {
			'& > div': {
				margin: 0,
				fontWeight: '500',
			},
		},
		'& .public-DraftStyleDefault-block': {
			margin: '0.5em 0',
		},
	},
}))

RichTextEditor.propTypes = {
	name: PropTypes.string,
	readOnly: PropTypes.bool,
	initialFocus: PropTypes.bool,
	onChange: PropTypes.func,
	value: PropTypes.any,
	placeholder: PropTypes.string,
	renderKey: PropTypes.string,
	suggestions: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			value: PropTypes.string,
			url: PropTypes.string,
		})
	),
}

const convertHtmlToEditorState = (value) => {
	return _.isEmpty(value)
		? EditorState.createEmpty()
		: EditorState.moveFocusToEnd(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(value))))
}

function RichTextEditor({
	renderKey,
	name,
	value,
	placeholder,
	suggestions,
	onChange,
	onBlur,
	readOnly,
	rootClass,
	initialFocus = false,
	hideToolbarOnFocus,
	delayedUpdate = 0,
}) {
	const [isFocused, setFocused] = useState(false)
	const [refreshKey, setRefreshKey] = useState(renderKey)
	const [editorState, setEditorState] = useState(convertHtmlToEditorState(value))

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedCallback = useCallback(
		_.debounce((q, callback) => {
			callback(q)
		}, delayedUpdate),
		[delayedUpdate]
	)

	const classes = useStyles({ showMinHeight: !hideToolbarOnFocus || isFocused })

	useEffect(() => {
		const exstState = draftToHtml(convertToRaw(editorState.getCurrentContent()))
		if (!_.isEqual(value, exstState) && !isFocused) {
			const newState = _.isEmpty(value)
				? EditorState.createEmpty()
				: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(value)))
			setEditorState(newState)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])

	const defaultToolbarSettings = {
		options: ['blockType', 'inline', 'list', 'textAlign', 'colorPicker', 'link', 'image'],
		blockType: {
			inDropdown: false,
			options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote'],
		},
		inline: {
			inDropdown: true,
			options: ['bold', 'italic', 'underline'],
		},
		image: {
			urlEnabled: true,
			alignmentEnabled: true,
			defaultSize: {
				height: 'auto',
				width: '100%',
			},
		},
		// list: { inDropdown: true },
		list: {
			inDropdown: true,
		},
		textAlign: { inDropdown: true },
		link: {
			inDropdown: true,
			linkCallback: null,
		},
	}

	const onEditorStateChange = (newEditorState) => {
		setEditorState(newEditorState)
		delayedCallback(newEditorState, (newEditorState) => {
			callOnChangeMethod(newEditorState)
		})
	}

	useEffect(() => {
		if (!isFocused) setRefreshKey(renderKey)
	}, [isFocused, renderKey])

	const onFocus = () => setFocused(true)

	const onBlurEditor = (e, newEditorState) => {
		onBlur &&
			onBlur({
				target: {
					name,
					value: draftToHtml(convertToRaw(newEditorState.getCurrentContent())),
				},
			})
		setFocused(false)
	}

	const callOnChangeMethod = (newEditorState) => {
		onChange &&
			onChange({
				target: {
					name,
					value: draftToHtml(convertToRaw(newEditorState.getCurrentContent())),
				},
			})
	}

	const setEditorReference = (ref) => {
		if (ref?.focus) {
			// const newEditorState = EditorState.moveSelectionToEnd(ref?.editorState)
			// EditorState.forceSelection(newEditorState, newEditorState.getSelection())
			ref.focus()
		}
	}

	return (
		<div className={rootClass ? classnames(rootClass, classes.root) : classes.root}>
			<Editor
				key={refreshKey}
				editorRef={initialFocus ? setEditorReference : null}
				toolbarHidden={!hideToolbarOnFocus ? hideToolbarOnFocus : !isFocused}
				editorState={editorState}
				readOnly={readOnly}
				onFocus={onFocus}
				onBlur={onBlurEditor}
				placeholder={placeholder}
				editorClassName={classes.editorClass}
				// toolbarClassName={classes.toolbarWrapper}
				toolbar={defaultToolbarSettings}
				onEditorStateChange={onEditorStateChange}
				mention={{
					separator: ' ',
					trigger: '@',
					suggestions: suggestions || [],
				}}
			/>
		</div>
	)
}

export default RichTextEditor
