import React, { useState, useMemo } from 'react'
import { Grid, TextField, Typography, Switch, FormControlLabel, RadioGroup, Radio, InputAdornment, IconButton, Icon } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Field } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import ChipInput from 'material-ui-chip-input'
import _ from 'lodash'
import InputField from '../../../../../components/TextField'
import SelectBox from '../../../../../components/SelectBox'
import UserLabels from 'components/UserLabels'
import UserDepartment from 'components/UserDepartment'
import UserDesignation from 'components/UserDesignation'
import { DateTimePicker, DatePicker } from '@material-ui/pickers'
import { convertGmtToLocalTime } from 'utils'
import { USER_TALENT_TYPE, USER_TALENT_RANKING, USER_CATEGORY, USER_COMMUNICATION } from '../../../../../constants'
import classnames from 'classnames'

const useStyles = makeStyles((theme) => ({
	textField: {
		marginBottom: theme.spacing(2.2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
		},
	},
	chipField: {
		marginBottom: theme.spacing(2.2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
		},
		'& .MuiChip-root': {
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
	},
	chipFieldOne: {
		marginBottom: theme.spacing(2.2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
		},
		'& .MuiChip-root': {
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
		'& legend': {
			width: '45px !important',
		},
	},
	chipFieldTwo: {
		marginBottom: theme.spacing(2.2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
		},
		'& .MuiChip-root': {
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
		'& legend': {
			width: '62px !important',
		},
	},
	chipFieldThree: {
		marginBottom: theme.spacing(2.2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
		},
		'& .MuiChip-root': {
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
		'& legend': {
			width: '95px !important',
		},
	},
	chipFieldFour: {
		marginBottom: theme.spacing(2.2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
		},
		'& .MuiChip-root': {
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
		'& legend': {
			width: '56px !important',
		},
	},
	description: {
		marginBottom: theme.spacing(2.2),
		'& .MuiFormControl-root': {
			minWidth: '100%',
		},
	},
	dateInput: {
		// marginTop: '0px',
		// marginBottom: '0px',
		marginBottom: theme.spacing(2.2),
		'& .MuiInputBase-root.Mui-disabled': {
			color: '#171725',
		},
		'& .MuiFormLabel-root.Mui-disabled': {
			color: 'inherit',
		},
		'& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
	},
}))

const ProfileDetails = ({ isEditMode, isCreateMode, isViewMode, formik, isEditAllowed, isCreateAllowed, isDeleteAllowed }) => {
	const classes = useStyles()

	const [openSkills, setOpenSkills] = useState(false)
	const [skillChange, setSkillChange] = useState(false)

	const [openDepartment, setOpenDepartment] = useState(false)

	const [openDesignation, setOpenDesignation] = useState(false)

	const skillValue = useMemo(() => {
		let dafaultValue = formik?.values?.skill_sets
		const value = !_.isEmpty(dafaultValue) ? _.map(dafaultValue, (skill) => _.get(skill, 'label_name')) : []
		setSkillChange(false)
		return value
	}, [formik?.values?.skill_sets, skillChange])

	const skillId = useMemo(() => {
		const value = !_.isEmpty(formik?.values?.skill_sets) ? _.map(formik?.values?.skill_sets, (skill) => _.get(skill, 'label_id')) : []
		setSkillChange(false)
		return value
	}, [formik?.values?.skill_sets, skillChange])

	const handleAddChip = (chip) => {
		console.log('handleAddChip', chip)
	}

	const handleDeleteChip = (chip, index) => {
		let array = formik?.values?.skill_sets
		_.remove(array, { label_name: chip })
		setSkillChange(true)
	}

	return (
		<>
			<Grid container spacing={1}>
				<Grid item lg={6} md={6} xs={12}>
					<Field type='text' name='education_qualification'>
						{({ field, form }) => {
							return (
								<TextField
									variant='outlined'
									margin='none'
									size='small'
									fullWidth
									name={field.name}
									label={'Education Qualification'}
									type='text'
									autoComplete='off'
									onChange={field.onChange}
									onBlur={field.onBlur}
									value={field.value || ''}
									disabled={isViewMode}
									className={classes.textField}
								/>
							)
						}}
					</Field>
				</Grid>
				<Grid item lg={6} md={6} xs={12}>
					<Field type='text' name='course'>
						{({ field, form }) => (
							<TextField
								variant='outlined'
								margin='none'
								size='small'
								fullWidth
								name={field.name}
								label={'Course'}
								type='text'
								autoComplete='off'
								onChange={field.onChange}
								onBlur={field.onBlur}
								value={field.value || ''}
								disabled={isViewMode}
								className={classes.textField}
							/>
						)}
					</Field>
				</Grid>
				<Grid item lg={6} md={6} xs={12}>
					<Field type='text' name='experience'>
						{({ field, form }) => (
							<TextField
								variant='outlined'
								margin='none'
								size='small'
								fullWidth
								name={field.name}
								label={'Experience'}
								type='text'
								autoComplete='off'
								onChange={field.onChange}
								onBlur={field.onBlur}
								value={field.value || ''}
								disabled={isViewMode}
								className={classes.textField}
							/>
						)}
					</Field>
				</Grid>
				<Grid item lg={6} md={6} xs={12}>
					<Field type='text' name='college_name'>
						{({ field, form }) => (
							<TextField
								variant='outlined'
								margin='none'
								size='small'
								fullWidth
								name={field.name}
								label={'College Name'}
								type='text'
								autoComplete='off'
								onChange={field.onChange}
								onBlur={field.onBlur}
								value={field.value || ''}
								disabled={isViewMode}
								className={classes.textField}
							/>
						)}
					</Field>
				</Grid>
				<Grid item lg={6} md={6} xs={12}>
					<Field type='text' name='talent_type'>
						{({ field, form }) => (
							<SelectBox
								label='Talent Type*'
								options={USER_TALENT_TYPE}
								disabled={isViewMode}
								value={field.value || ''}
								margin='none'
								inputClass={classes.textField}
								freeSolo={isViewMode ? true : false}
								helperText={form.touched[field.name] && form.errors[field.name]}
								isError={Boolean(form.errors[field.name] && form.touched[field.name])}
								onChange={(value, e) => {
									form.handleChange({
										target: {
											name: 'talent_type',
											value,
										},
									})
								}}
							/>
						)}
					</Field>
				</Grid>
				<Grid item lg={6} md={6} xs={12}>
					<Field type='text' name='talent_ranking'>
						{({ field, form }) => (
							<SelectBox
								label='Talent Ranking*'
								options={USER_TALENT_RANKING}
								disabled={isViewMode}
								value={field.value || ''}
								margin='none'
								inputClass={classes.textField}
								freeSolo={isViewMode ? true : false}
								helperText={form.touched[field.name] && form.errors[field.name]}
								isError={Boolean(form.errors[field.name] && form.touched[field.name])}
								onChange={(value, e) => {
									form.handleChange({
										target: {
											name: 'talent_ranking',
											value,
										},
									})
								}}
							/>
						)}
					</Field>
				</Grid>
				<Grid item lg={6} md={6} xs={12}>
					<Field type='text' name='category'>
						{({ field, form }) => (
							<Autocomplete
								options={USER_CATEGORY}
								groupBy={(option) => option.category}
								getOptionLabel={(option) => option['subCategory'] || ''}
								getOptionSelected={(option, value) => option['id'] === value.id}
								size='small'
								disabled={isViewMode}
								value={
									_.find(USER_CATEGORY, function (data) {
										if (data.id === field.value) return true
									}) || ''
								}
								className={classes.textField}
								freeSolo={isViewMode ? true : false}
								onChange={(value, e) => {
									const data = e?.id || ''
									console.log('category', value, e, data)
									formik.setFieldValue('category', data)
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Category*'
										variant='outlined'
										margin='none'
										error={Boolean(form.errors[field.name] && form.touched[field.name])}
										size={'small'}
										name={''}
										helperText={form.touched[field.name] && form.errors[field.name]}
									/>
								)}
							/>
						)}
					</Field>
				</Grid>
				<Grid item lg={6} md={6} xs={12}>
					<Field type='text' name='designation'>
						{({ field, form }) => (
							<TextField
								variant='outlined'
								margin='none'
								size='small'
								fullWidth
								name={field.name}
								label={'Designation*'}
								type='text'
								autoComplete='off'
								// onChange={field.onChange}
								// onBlur={field.onBlur}
								value={field.value?.designation_name || ''}
								disabled={isViewMode}
								className={classes.textField}
								onClick={(event) => {
									!isViewMode && setOpenDesignation(true)
								}}
								onKeyPress={(e) => {
									e.preventDefault()
								}}
								helperText={form.touched[field.name] && form.errors[field.name]}
								error={Boolean(form.errors[field.name] && form.touched[field.name])}
							/>
						)}
					</Field>
				</Grid>
				<UserDesignation
					// disabled={!isEditEnabled}
					title='Designations'
					name='Designation'
					smallName='Designation'
					module='user_management'
					hideAddLabel={!isCreateAllowed}
					hideEditLabel={!isEditAllowed}
					hideDeleteLabel={!isDeleteAllowed}
					onChange={formik.handleChange}
					value={skillId}
					// labels={glassLabels}
					// glassCode={glassCode}
					// sessionId={sessionId}
					isDescription={true}
					open={openDesignation}
					setOpenSkills={setOpenDesignation}
					formik={formik}
					setSkillChange={setSkillChange}
				/>
				<Grid item lg={6} md={6} xs={12}>
					<Field type='text' name='department'>
						{({ field, form }) => (
							<TextField
								variant='outlined'
								margin='none'
								size='small'
								fullWidth
								name={field.name}
								label={'Department*'}
								type='text'
								autoComplete='off'
								// onChange={field.onChange}
								// onBlur={field.onBlur}
								value={field.value?.department_name || ''}
								disabled={isViewMode}
								className={classes.textField}
								onClick={(event) => {
									!isViewMode && setOpenDepartment(true)
								}}
								onKeyPress={(e) => {
									e.preventDefault()
								}}
								helperText={form.touched[field.name] && form.errors[field.name]}
								error={Boolean(form.errors[field.name] && form.touched[field.name])}
							/>
						)}
					</Field>
				</Grid>
				{/* <Grid item lg={12} md={12} xs={12}> */}
				<UserDepartment
					// disabled={!isEditEnabled}
					title='Departments'
					name='Department'
					smallName='Department'
					module='user_management'
					hideAddLabel={!isCreateAllowed}
					hideEditLabel={!isEditAllowed}
					hideDeleteLabel={!isDeleteAllowed}
					onChange={formik.handleChange}
					value={skillId}
					// labels={glassLabels}
					// glassCode={glassCode}
					// sessionId={sessionId}
					isDescription={true}
					open={openDepartment}
					setOpenSkills={setOpenDepartment}
					formik={formik}
					setSkillChange={setSkillChange}
				/>
				{/* </Grid> */}
				<Grid item lg={6} md={6} xs={12}>
					<Field type='text' name='communication'>
						{({ field, form }) => (
							<SelectBox
								label='Communication*'
								options={USER_COMMUNICATION}
								disabled={isViewMode}
								value={field.value || ''}
								margin='none'
								inputClass={classes.textField}
								freeSolo={isViewMode ? true : false}
								helperText={form.touched[field.name] && form.errors[field.name]}
								isError={Boolean(form.errors[field.name] && form.touched[field.name])}
								onChange={(value, e) => {
									form.handleChange({
										target: {
											name: 'communication',
											value,
										},
									})
								}}
							/>
						)}
					</Field>
				</Grid>
				<Grid item lg={12} md={12} xs={12}>
					<Field
						type='text'
						name='goals'
						render={({ field, form }) => {
							return (
								<ChipInput
									label='Goals'
									variant='outlined'
									className={classnames(!_.isEmpty(form?.values?.goals) ? classes.chipFieldOne : classes.chipField)}
									defaultValue={_.get(form.values, 'goals', [])}
									onChange={(chips) => {
										form.handleChange({
											target: {
												name: 'goals',
												value: chips,
											},
										})
									}}
									fullWidth
									disabled={isViewMode}
									error={form?.touched?.goals && !_.isEmpty(_.get(form.errors, 'goals', ''))}
									helperText={form?.touched?.goals && _.get(form.errors, 'goals', '')}
								/>
							)
						}}
					/>
				</Grid>
				<Grid item lg={12} md={12} xs={12}>
					<Field
						type='text'
						name='skill_sets'
						render={({ field, form }) => {
							return (
								<ChipInput
									label='Skill Sets'
									variant='outlined'
									className={classnames(!_.isEmpty(form?.values?.skill_sets) ? classes.chipFieldTwo : classes.chipField)}
									value={skillValue}
									// onChange={(e, chips) => handleSkillChange(e, chips, form)}
									onAdd={(chip) => handleAddChip(chip)}
									onDelete={(chip, index) => handleDeleteChip(chip, index)}
									fullWidth
									disabled={isViewMode}
									onClick={(event) => {
										!isViewMode && setOpenSkills(true)
									}}
								/>
							)
						}}
					/>
				</Grid>
				{/* <Grid item lg={12} md={12} xs={12}> */}
				<UserLabels
					// disabled={!isEditEnabled}
					title='Skill Sets'
					name='Skill Set'
					smallName='skill set'
					module='user_management'
					hideAddLabel={!isCreateAllowed}
					hideEditLabel={!isEditAllowed}
					hideDeleteLabel={!isDeleteAllowed}
					onChange={formik.handleChange}
					value={skillId}
					// labels={glassLabels}
					// glassCode={glassCode}
					// sessionId={sessionId}
					isDescription={true}
					open={openSkills}
					setOpenSkills={setOpenSkills}
					formik={formik}
					setSkillChange={setSkillChange}
				/>
				{/* </Grid> */}
				<Grid item lg={6} md={6} xs={12}>
					<Field
						type='text'
						name='area_of_interest'
						render={({ field, form }) => {
							return (
								<ChipInput
									label='Area of Interest'
									variant='outlined'
									className={classnames(!_.isEmpty(form?.values?.area_of_interest) ? classes.chipFieldThree : classes.chipField)}
									defaultValue={_.get(form.values, 'area_of_interest', [])}
									onChange={(chips) => {
										form.handleChange({
											target: {
												name: 'area_of_interest',
												value: chips,
											},
										})
									}}
									fullWidth
									disabled={isViewMode}
								/>
							)
						}}
					/>
				</Grid>
				<Grid item lg={6} md={6} xs={12}>
					<Field
						type='text'
						name='hobbies'
						render={({ field, form }) => {
							return (
								<ChipInput
									label='Hobbies'
									variant='outlined'
									className={classnames(!_.isEmpty(form?.values?.hobbies) ? classes.chipFieldFour : classes.chipField)}
									defaultValue={_.get(form.values, 'hobbies', [])}
									onChange={(chips) => {
										form.handleChange({
											target: {
												name: 'hobbies',
												value: chips,
											},
										})
									}}
									fullWidth
									disabled={isViewMode}
								/>
							)
						}}
					/>
				</Grid>
				<Grid item lg={6} md={6} xs={12}>
					<Field type='text' name='dob'>
						{({ field, form }) => (
							<DatePicker
								name={field.name}
								id={field.name}
								className={classes.dateInput}
								autoOk
								size='small'
								variant='inline'
								inputVariant='outlined'
								label='Date of Birth'
								format='DD MMM YYYY'
								value={field.value || null}
								fullWidth
								minDateMessage={<></>}
								error={Boolean(form.errors[field.name] && form.touched[field.name])}
								helperText={form.touched[field.name] && form.errors[field.name]}
								margin='none'
								clearable
								// autoFocus
								disabled={isViewMode}
								disableFuture={true}
								InputProps={{
									endAdornment: (
										<React.Fragment>
											{!isViewMode ? (
												<InputAdornment position='end'>
													<IconButton size='small'>
														<Icon>date_range</Icon>
													</IconButton>
												</InputAdornment>
											) : null}
										</React.Fragment>
									),
								}}
								onChange={(date) => {
									formik.setFieldValue('dob', date)
								}}
							/>
						)}
					</Field>
				</Grid>
				<Grid item lg={6} md={6} xs={12}>
					<Field type='text' name='location'>
						{({ field, form }) => (
							<TextField
								variant='outlined'
								margin='none'
								size='small'
								fullWidth
								name={field.name}
								label={'Location*'}
								type='text'
								autoComplete='off'
								onChange={field.onChange}
								onBlur={field.onBlur}
								value={field.value || ''}
								disabled={isViewMode}
								className={classes.textField}
								helperText={form.touched[field.name] && form.errors[field.name]}
								error={Boolean(form.errors[field.name] && form.touched[field.name])}
							/>
						)}
					</Field>
				</Grid>
				<Grid item lg={6} md={6} xs={12} className={classes.description}>
					<Field
						type='text'
						name='about'
						render={({ field, form }) => {
							return (
								<InputField type='text' label='About' field={field} form={form} fullWidth multiple rows={4} disabled={isViewMode} margin='dense' />
							)
						}}
					/>
				</Grid>
				<Grid item lg={6} md={6} xs={12} className={classes.description}>
					<Field
						type='text'
						name='address'
						render={({ field, form }) => {
							return (
								<InputField type='text' label='Address' field={field} form={form} fullWidth multiple rows={4} disabled={isViewMode} margin='dense' />
							)
						}}
					/>
				</Grid>
			</Grid>
		</>
	)
}

export default ProfileDetails
