import React, { useEffect } from 'react'
import { makeStyles, Grid, Typography, IconButton, Icon, Button } from '@material-ui/core'
import FilterPanel from './FilterPanel'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { clearAllFilters } from '../../services/filtersNew/actions'
import RangeFilter from './RangeFilter'
import { FILTER_CONSTANTS } from '../../constants'
import { setThisFilter, clearThisFilter, setFilter } from '../../services/filtersNew/actions'
import { getSuggestions } from '../../services/filterSuggestions/actions'
import DateFilter from './DateRangeFilter'
import FilterApi from '../../services/filtersNew/api'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		[theme.breakpoints.down('1280')]: {
			paddingTop: 38,
		},
		background: theme.palette.almostBlack[0],
		'& ::-webkit-scrollbar, ::-webkit-scrollbar-track': {
			width: '10px',
			background: 'transparent',
		},
		'& ::-webkit-scrollbar-thumb': {
			background: theme.palette.almostBlack[400],
			height: '10px',
		},
	},
	heading: {
		// letterSpacing: theme.typography.pxToRem(1),
		// color: theme.palette.almostBlack[600],
		// padding: `${theme.spacing(1)}px 0px`,
		fontSize: theme.typography.pxToRem(18),
		letterSpacing: 1,
		color: theme.palette.almostBlack[700],
	},
	resetSpan: {
		padding: theme.spacing(0.5),
		color: theme.palette.almostBlack[800],
		borderRadius: '5px',
		transition: 'background 0.3s',
		cursor: 'pointer',
		// fontFamily: '"Poppins"',
		fontSize: theme.typography.pxToRem(10),
		'&:hover': {
			background: `${theme.palette.primary.main}11`,
			transition: 'background 0.3s',
		},
	},
	header: {
		[theme.breakpoints.down('1280')]: {
			position: 'absolute',
			top: 0,
			left: 0,
			padding: theme.spacing(2, 3),
			backgroundColor: theme.palette.common.white,
			zIndex: 100,
			borderBottom: `1px solid ${theme.palette.grey[300]}`,
		},
	},
	footer: {
		display: 'none',
		[theme.breakpoints.down('1280')]: {
			position: 'absolute',
			display: 'block',
			bottom: 0,
			left: 0,
			padding: theme.spacing(2, 3),
			backgroundColor: theme.palette.common.white,
			zIndex: 100,
			borderTop: `1px solid ${theme.palette.grey[300]}`,
			textAlign: 'right',
		},
	},
	content: {
		[theme.breakpoints.down('1280')]: {
			paddingBottom: 64,
		},
	},
	mLeft8: {
		marginLeft: theme.spacing(1),
	},
}))

const Filters = ({ extraFilterProps, showTheseFilters, isSmallDevice, onCloseDialog, searchSuggestionsProductType = 'old', setExportList, setTableData, setAllPageProduct }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const suggestions = useSelector((state) => state.filterSuggestions)
	const filters = useSelector((state) => state.filtersNew)
	const dataSourceId = useSelector((state) => _.get(state, 'appSettings.data.ds_id'))
	const [trigger, setTrigger] = React.useState(false)
	const params = _.split(window?.location?.search, '&')

	// const [filters, setFilters] = React.useState(filter)



	const fetchSuggestions = (filterKey, keyValue = '', isClearOffset = false) => {
		const offset = 0
		dispatch(getSuggestions(filterKey, offset, keyValue, dataSourceId))
	}

	const fetchLoadMoreSuggestions = (filterKey, keyValue = '', isClearOffset = false) => {
		const offset = keyValue.length === 0 && !isClearOffset ? suggestions[filterKey].suggestions.length : 0
		dispatch(getSuggestions(filterKey, offset, keyValue, dataSourceId))
	}

	// useEffect(() => {
	// 	if (!_.isEmpty(showTheseFilters)) {
	// 		showTheseFilters.forEach((key) => {
	// 			if (
	// 				[
	// 					FILTER_CONSTANTS.JOB_NAME,
	// 					FILTER_CONSTANTS.ASSIGNED_TO,
	// 					FILTER_CONSTANTS.RETAILER,
	// 					FILTER_CONSTANTS.RESELLER,
	// 					FILTER_CONSTANTS.PRODUCT_NAME,
	// 					FILTER_CONSTANTS.BRAND,
	// 					FILTER_CONSTANTS.STATUS,
	// 					FILTER_CONSTANTS.PRODUCT_ID,
	// 				].includes(key) &&
	// 				_.get(suggestions, `${key}.isInitialState`, false)
	// 			) {
	// 				console.log('fetch 2', key, _.get(suggestions, `${key}.isInitialState`, false), suggestions)
	// 				fetchSuggestions(key)
	// 			}
	// 		})
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [])
	// }, [suggestions, showTheseFilters])

	useEffect(() => {
		let split = _.split(params[5], '=')
		let decode = params[5] !== undefined ? JSON.parse(decodeURIComponent(split[1]).replace(/\+/g, ' ')) : []
		let reloaded = window.performance.getEntriesByType('navigation')[0].type
		let filter = {
			filter_param: !_.isEmpty(decode) && reloaded !== 'reload' ? decode : {},
			redirect_url: !_.isEmpty(decode) && reloaded === 'navigate' ? true : false,
		}
		localStorage.setItem('redirect_url', filter.redirect_url)
		// let filter_param = { filter_param: {} }
		FilterApi.filter(filter)
			.then((res) => {
				dispatch(setFilter(res.data.data.filter))
				
				// setFilters({ ...filters, ...res.data.data.filter })
			})
			.catch((err) => {
				// console.log(err, 'err')
			})
	}, [])

	// useEffect(() => {}, [])

	const handleChange = (filterKey, value, isNotCheckBoxType) => {
		if (_.isNil(isNotCheckBoxType) || isNotCheckBoxType === false) {
			setTimeout(() => {
			if (filters[filterKey].indexOf(value) !== -1) {
				let tempArray = [...filters[filterKey]]
				_.remove(tempArray, (key) => key === value)
				dispatch(setThisFilter(tempArray, filterKey))
				// console.log('calling..1', tempArray)
				setTrigger(true)
				// setTimeout(() => {
					setTrigger(false)
				// }, 1000)
			} else {
				// console.log('calling..2')
				dispatch(setThisFilter([...filters[filterKey], value], filterKey))
				setTrigger(true)
				// setTimeout(() => {
					setTrigger(false)
				// }, 1000)
			}
		 }, 1000)
		} else {
			setTimeout(() => {
			// console.log('calling..3')
			dispatch(setThisFilter(value, filterKey))
			setTrigger(true)
			// setTimeout(() => {
				setTrigger(false)
			// }, 1000)
		}, 1000)
		}
	}

	useEffect(() => {
		if (trigger === true) {
			// console.log('calling..4', filters, suggestions, showTheseFilters)
			FilterApi.updateFilter(filters)
				.then((res) => {
					// console.log(res, 'res')
					dispatch(setFilter(res.data.data.filter))
					setExportList([])
					setTableData([])
					setAllPageProduct(false)
					// setInit(!init)
					// setFilters({ ...filters, ...res.data.data.filter })
				})
				.catch((err) => {
					// console.log(err, 'err')
					setExportList([])
					setTableData([])
					setAllPageProduct(false)
				})
		}
	}, [trigger])

	const [clearText, setClearText] = React.useState(false)

	const handleClearFilter = () => {
		dispatch(clearAllFilters())
		setClearText(!clearText)
		setTrigger(true)
		setTimeout(() => {
			setTrigger(false)
		}, 1000)
	}

	const handleClearThisFilter = (filterKey) => {
		dispatch(clearThisFilter(filterKey))
		setTrigger(true)
		setTimeout(() => {
			setTrigger(false)
		}, 1000)
	}

	const restBtnClickOnDialog = () => {
		handleClearFilter()
		onCloseDialog()
	}

	// const stockOptions = [
	//     {value: true, text: 'In Stock'},
	//     {value: false, text: 'Sold Out'},
	//     // {value: 'coming-soon', text: 'Coming Soon'}
	// ]
	// const priorityOptions = [
	//     {value: 'high', text: 'High'},
	//     {value: 'medium', text: 'Medium'},
	//     {value: 'low', text: 'Low'},
	//     // {value: 'coming-soon', text: 'Coming Soon'}
	// ]

	// const scrapperOptions = [
	//     {value: 'all', text: 'All'},
	//     {value: 'on', text: 'On'},
	//     {value: 'off', text: 'Off'}
	// ]

	const renderFilters = () => {
		return (
			<>
				{/* {renderProductFilter()} */}
				{/* {renderRetailerFilter()} */}
				{/* {renderStockFilter()} */}
				{/* {renderScrapperFilter()} */}
				{/* {renderDiscountFilter()} */}
				{/* {renderResellerFilter()} */}
				{renderTargetFilter()}
				{renderDiscountFilter()}
				{/* {renderJobFilter()} */}
				{/* {renderAssignedFilter()} */}
				{/* {renderPriorityFilter()} */}
			</>
		)
	}

	const renderFiltersOnCondition = (filterKey) => {
		switch (filterKey) {
			case FILTER_CONSTANTS.JOB_STATUS:
				return renderJobStatusFilter()
			case FILTER_CONSTANTS.TARGET_DAYS:
				return renderTargetFilter()
			case FILTER_CONSTANTS.DATE_RANGE:
				return renderDateRangeFilter()
			case FILTER_CONSTANTS.JOB_NAME:
				return renderJobNameFilter()
			case FILTER_CONSTANTS.ASSIGNED_TO:
				return renderAssignedFilter()
			case FILTER_CONSTANTS.PRIORITY:
				return renderPriorityFilter()

			case FILTER_CONSTANTS.DISCOUNT:
				return renderDiscountFilter()
			case FILTER_CONSTANTS.PRODUCT_NAME:
				return renderProductFilter()
			case FILTER_CONSTANTS.RETAILER:
				return renderMarketplaceFilter()
			// case FILTER_CONSTANTS.STOCK:
			// 	return renderStockFilter()
			case FILTER_CONSTANTS.STATUS:
				return renderStatusFilter()
			case FILTER_CONSTANTS.PRODUCT_ID:
				return renderProductidFilter()
			case FILTER_CONSTANTS.RESELLER:
				return renderResellerFilter()
			case FILTER_CONSTANTS.BRAND:
				return renderBrandFilter()
			default:
				return null
		}
	}

	const renderJobStatusFilter = (filterKey = FILTER_CONSTANTS.JOB_STATUS) => (
		<FilterPanel
			noSort={true}
			key={filterKey}
			isSearchNeeded={false}
			title='Job Status'
			options={suggestions[filterKey].suggestions}
			selectedOptions={filters[filterKey]}
			handleCheckBoxClick={(val) => handleChange(filterKey, val)}
			handleClearThisFilter={() => handleClearThisFilter(filterKey)}
		/>
	)
	// const renderJobStatusFilter = (filterKey=FILTER_CONSTANTS.JOB_STATUS) => <FilterPanel key={filterKey} isFetching={suggestions[filterKey].isLoading} isPreLoaded={suggestions[filterKey].isAllLoaded} isSearchNeeded={true} title='Job Status' handleSearch={(keyValue, isClearOffset)=>fetchSuggestions(filterKey, keyValue, isClearOffset)} clearFilter={handleClearFilter} loadMoreFxn={()=>fetchSuggestions(filterKey)} options={suggestions[filterKey].suggestions} selectedOptions={filters[filterKey]} handleCheckBoxClick={(val)=>handleChange(filterKey, val)} handleClearThisFilter={()=>handleClearThisFilter(filterKey)} isLoading={suggestions[filterKey].isLoading}  />
	// const renderTargetFilter = (filterKey=FILTER_CONSTANTS.TARGET_DAYS) => <RangeFilter key={filterKey} title='Target Days' min={suggestions[filterKey].suggestions[0]} max={suggestions[filterKey].suggestions[1]} step={1} minTitle={'From'} maxTitle={'To'} rangePrefix={'%'} rangeSelected={filters[filterKey]} onChangeCommitted={(val)=>handleChange(filterKey, val, true)} handleClearThisFilter={()=>handleClearThisFilter(filterKey)} />

	const renderTargetFilter = (filterKey = FILTER_CONSTANTS.TARGET_DAYS) => (
		<DateFilter
			key={filterKey}
			title='Target Days'
			initialDates={suggestions[filterKey].suggestions}
			rangeSelected={filters[filterKey]}
			onChangeCommitted={(val) => handleChange(filterKey, val, true)}
			handleClearThisFilter={() => handleClearThisFilter(filterKey)}
		/>
	)
	const renderDateRangeFilter = (filterKey = FILTER_CONSTANTS.DATE_RANGE) => (
		<DateFilter
			key={filterKey}
			title='Date Range'
			initialDates={suggestions[filterKey].suggestions}
			rangeSelected={filters[filterKey]}
			onChangeCommitted={(val) => handleChange(filterKey, val, true)}
			handleClearThisFilter={() => handleClearThisFilter(filterKey)}
			inputProps={suggestions[filterKey]?.additionalProps || {}}
		/>
	)

	const renderJobNameFilter = (filterKey = FILTER_CONSTANTS.JOB_NAME) => (
		<FilterPanel
			key={filterKey}
			isFetching={suggestions[filterKey].isLoading}
			isPreLoaded={suggestions[filterKey].isAllLoaded}
			isSearchNeeded={true}
			title='Job Name'
			handleSearch={(keyValue, isClearOffset) => fetchSuggestions(filterKey, keyValue, isClearOffset)}
			clearFilter={handleClearFilter}
			loadMoreFxn={() => fetchLoadMoreSuggestions(filterKey)}
			options={suggestions[filterKey].suggestions}
			selectedOptions={filters[filterKey]}
			handleCheckBoxClick={(val) => handleChange(filterKey, val)}
			handleClearThisFilter={() => handleClearThisFilter(filterKey)}
			isLoading={suggestions[filterKey].isLoading}
			clearText={clearText}
			setClearText={setClearText}
		/>
	)
	const renderAssignedFilter = (filterKey = FILTER_CONSTANTS.ASSIGNED_TO) => (
		<FilterPanel
			key={filterKey}
			isFetching={suggestions[filterKey].isLoading}
			isPreLoaded={suggestions[filterKey].isAllLoaded}
			isSearchNeeded={true}
			title='Assigned To'
			handleSearch={(keyValue, isClearOffset) => fetchSuggestions(filterKey, keyValue, isClearOffset)}
			clearFilter={handleClearFilter}
			loadMoreFxn={() => fetchLoadMoreSuggestions(filterKey)}
			options={suggestions[filterKey].suggestions}
			selectedOptions={filters[filterKey]}
			handleCheckBoxClick={(val) => handleChange(filterKey, val)}
			handleClearThisFilter={() => handleClearThisFilter(filterKey)}
			isLoading={suggestions[filterKey].isLoading}
			clearText={clearText}
			setClearText={setClearText}
		/>
	)
	const renderPriorityFilter = (filterKey = FILTER_CONSTANTS.PRIORITY) => (
		<FilterPanel
			key={filterKey}
			isSearchNeeded={false}
			title='Priority'
			noSort={true}
			options={suggestions[filterKey].suggestions}
			selectedOptions={filters[filterKey]}
			handleCheckBoxClick={(val) => handleChange(filterKey, val)}
			handleClearThisFilter={() => handleClearThisFilter(filterKey)}
			clearText={clearText}
			setClearText={setClearText}
		/>
	)

	const renderDiscountFilter = (filterKey = FILTER_CONSTANTS.DISCOUNT) => (
		<RangeFilter
			key={filterKey}
			title='Discount'
			min={suggestions[filterKey].suggestions[0]}
			max={suggestions[filterKey].suggestions[1]}
			step={1}
			minTitle={'From'}
			maxTitle={'To'}
			rangeSuffix={'%'}
			rangeSelected={filters[filterKey]}
			onChangeCommitted={(val) => handleChange(filterKey, val, true)}
			handleClearThisFilter={() => handleClearThisFilter(filterKey)}
		/>
	)
	const renderProductFilter = (filterKey = FILTER_CONSTANTS.PRODUCT_NAME) => (
		<FilterPanel
			noSort={true}
			key={filterKey}
			isFetching={suggestions[filterKey].isLoading}
			isPreLoaded={suggestions[filterKey].isAllLoaded}
			isSearchNeeded={true}
			title='Product Name'
			handleSearch={(keyValue, isClearOffset) => fetchSuggestions(filterKey, keyValue, isClearOffset)}
			optionKey='column_data'
			textKey='column_data'
			clearFilter={handleClearFilter}
			loadMoreFxn={() => fetchLoadMoreSuggestions(filterKey)}
			options={suggestions[filterKey].suggestions}
			selectedOptions={filters[filterKey]}
			handleCheckBoxClick={(val) => handleChange(filterKey, val)}
			handleClearThisFilter={() => handleClearThisFilter(filterKey)}
			isLoading={suggestions[filterKey].isLoading}
			clearText={clearText}
			setClearText={setClearText}
		/>
	)
	const renderMarketplaceFilter = (filterKey = FILTER_CONSTANTS.RETAILER) => (
		<FilterPanel
			noSort={true}
			key={filterKey}
			isFetching={suggestions[filterKey].isLoading}
			isPreLoaded={suggestions[filterKey].isAllLoaded}
			optionKey='column_data'
			textKey='column_data'
			isSearchNeeded={true}
			title='MarketPlace'
			handleSearch={(keyValue, isClearOffset) => fetchSuggestions(filterKey, keyValue, isClearOffset)}
			clearFilter={handleClearFilter}
			loadMoreFxn={() => fetchLoadMoreSuggestions(filterKey)}
			options={suggestions[filterKey].suggestions}
			selectedOptions={filters[filterKey]}
			handleCheckBoxClick={(val) => handleChange(filterKey, val)}
			handleClearThisFilter={() => handleClearThisFilter(filterKey)}
			isLoading={suggestions[filterKey].isLoading}
			clearText={clearText}
			setClearText={setClearText}
		/>
	)
	const renderStockFilter = (filterKey = FILTER_CONSTANTS.STOCK) => (
		<FilterPanel
			noSort={true}
			key={filterKey}
			isSearchNeeded={false}
			title='Stock'
			options={suggestions[filterKey].suggestions}
			selectedOptions={filters[filterKey]}
			handleCheckBoxClick={(val) => handleChange(filterKey, val)}
			handleClearThisFilter={() => handleClearThisFilter(filterKey)}
			clearText={clearText}
			setClearText={setClearText}
		/>
	)
	const renderStatusFilter = (filterKey = FILTER_CONSTANTS.STATUS) => (
		<FilterPanel
			noSort={true}
			key={filterKey}
			isFetching={suggestions[filterKey].isLoading}
			isPreLoaded={suggestions[filterKey].isAllLoaded}
			optionKey='column_data'
			textKey='column_data'
			isSearchNeeded={true}
			title='Status'
			handleSearch={(keyValue, isClearOffset) => fetchSuggestions(filterKey, keyValue, isClearOffset)}
			clearFilter={handleClearFilter}
			loadMoreFxn={() => fetchLoadMoreSuggestions(filterKey)}
			options={suggestions[filterKey].suggestions}
			selectedOptions={filters[filterKey]}
			handleCheckBoxClick={(val) => handleChange(filterKey, val)}
			handleClearThisFilter={() => handleClearThisFilter(filterKey)}
			isLoading={suggestions[filterKey].isLoading}
			clearText={clearText}
			setClearText={setClearText}
		/>
	)
	const renderProductidFilter = (filterKey = FILTER_CONSTANTS.PRODUCT_ID) => (
		<FilterPanel
			noSort={true}
			key={filterKey}
			isFetching={suggestions[filterKey].isLoading}
			isPreLoaded={suggestions[filterKey].isAllLoaded}
			optionKey='column_data'
			textKey='column_data'
			isSearchNeeded={true}
			title='Product Id'
			handleSearch={(keyValue, isClearOffset) => fetchSuggestions(filterKey, keyValue, isClearOffset)}
			clearFilter={handleClearFilter}
			loadMoreFxn={() => fetchLoadMoreSuggestions(filterKey)}
			options={suggestions[filterKey].suggestions}
			selectedOptions={filters[filterKey]}
			handleCheckBoxClick={(val) => handleChange(filterKey, val)}
			handleClearThisFilter={() => handleClearThisFilter(filterKey)}
			isLoading={suggestions[filterKey].isLoading}
			clearText={clearText}
			setClearText={setClearText}
		/>
	)
	const renderResellerFilter = (filterKey = FILTER_CONSTANTS.RESELLER) => (
		<FilterPanel
			noSort={true}
			key={filterKey}
			isFetching={suggestions[filterKey].isLoading}
			optionKey='column_data'
			textKey='column_data'
			isPreLoaded={suggestions[filterKey].isAllLoaded}
			isSearchNeeded={true}
			title='Reseller'
			handleSearch={(keyValue, isClearOffset) => fetchSuggestions(filterKey, keyValue, isClearOffset)}
			clearFilter={handleClearFilter}
			loadMoreFxn={() => fetchLoadMoreSuggestions(filterKey)}
			options={suggestions[filterKey].suggestions}
			selectedOptions={filters[filterKey]}
			handleCheckBoxClick={(val) => handleChange(filterKey, val)}
			handleClearThisFilter={() => handleClearThisFilter(filterKey)}
			isLoading={suggestions[filterKey].isLoading}
			clearText={clearText}
			setClearText={setClearText}
		/>
	)
	const renderBrandFilter = (filterKey = FILTER_CONSTANTS.BRAND) => (
		<FilterPanel
			noSort={true}
			key={filterKey}
			isFetching={suggestions[filterKey].isLoading}
			optionKey='column_data'
			textKey='column_data'
			isPreLoaded={suggestions[filterKey].isAllLoaded}
			isSearchNeeded={true}
			title='Brand'
			handleSearch={(keyValue, isClearOffset) => fetchSuggestions(filterKey, keyValue, isClearOffset)}
			clearFilter={handleClearFilter}
			loadMoreFxn={() => fetchLoadMoreSuggestions(filterKey)}
			options={suggestions[filterKey].suggestions}
			selectedOptions={filters[filterKey]}
			handleCheckBoxClick={(val) => handleChange(filterKey, val)}
			handleClearThisFilter={() => handleClearThisFilter(filterKey)}
			isLoading={suggestions[filterKey].isLoading}
			clearText={clearText}
			setClearText={setClearText}
		/>
	)

	return (
		<>
			<Grid container className={classes.root} alignContent='flex-start'>
				<Grid item xs={12} className={classes.header} container justifyContent='space-between' alignItems='center'>
					<Typography variant='h6' className={classes.heading}>
						Filters
					</Typography>
					{!isSmallDevice && (
						<Typography variant='caption' className={classes.resetSpan} component='span' onClick={handleClearFilter}>
							RESET ALL
						</Typography>
					)}
					{isSmallDevice && (
						<IconButton onClick={onCloseDialog} size='small'>
							<Icon fontSize='small'>close</Icon>
						</IconButton>
					)}
				</Grid>
				<Grid item xs={12} className={classes.content}>
					{showTheseFilters && !_.isEmpty(showTheseFilters) ? showTheseFilters.map((key) => renderFiltersOnCondition(key)) : renderFilters()}
					{extraFilterProps && !_.isEmpty(extraFilterProps) ? <FilterPanel {...extraFilterProps} /> : null}
				</Grid>
				<Grid item xs={12} className={classes.footer} container justifyContent='flex-end' alignItems='flex-end'>
					<Button onClick={restBtnClickOnDialog} color='default' variant='outlined' size='small' disableElevation>
						Reset All
					</Button>
					<Button onClick={onCloseDialog} className={classes.mLeft8} color='primary' variant='contained' size='small' disableElevation>
						Done
					</Button>
				</Grid>
			</Grid>
		</>
	)
}

export default Filters
