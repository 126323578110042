import { Icon, makeStyles, Grid, IconButton, Box, Typography, Button } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import _ from 'lodash'

// Import Swiper styles
import 'swiper/swiper.scss'
import ReactPlayer from 'react-player'
import { getImgUrl } from 'utils'
import VideocamIcon from '@material-ui/icons/Videocam'
import YouTubeIcon from '@material-ui/icons/YouTube'
import classnames from 'classnames'

const useStyles = makeStyles((theme) => ({
	swiperRoot: {
		height: '100%',
		[theme.breakpoints.up(600)]: {
			height: '85vh',
		},
		[theme.breakpoints.between(600, 730)]: {
			minWidth: '52vh',
		},
		[theme.breakpoints.between(730, 790)]: {
			minWidth: '64vh',
		},
		[theme.breakpoints.between(790, 920)]: {
			minWidth: '100%',
		},
		[theme.breakpoints.between(920, 1000)]: {
			minWidth: '64vh',
		},
		[theme.breakpoints.up(1000)]: {
			minWidth: '70vh',
		},
		'& .MuiIcon-fontSizeSmall': {
			fontSize: '2.25rem',
		},
	},
	swiperContainer: {
		[theme.breakpoints.up(600)]: {
			height: '78vh !important',
			objectFit: 'cover',
			display: 'flex',
			alignItems: 'center',
		},
		[theme.breakpoints.down(600)]: {
			'& .swiper-wrapper': {
				minHeight: '500px !important',
			},
		},
	},
	slideContent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		// height vary while play video from tumbnail to player
		// height: 'inherit !important',
		height: '100%',
		padding: '5px',
		boxSizing: 'border-box',
		marginRight: (fileList) => !_.gt(fileList.length, 1) && '0px !important',
		// popup maximum height
		// '& .swiper-slide': {
		// 	height: '100%',
		// },

		// make the slider content in center
		alignSelf: 'center',
	},
	slideImage: {
		// popup maximum height
		// maxHeight: '100%',
		maxHeight: '90vh',
		[theme.breakpoints.up(600)]: {
			maxHeight: '80vh',
		},
		maxWidth: '100%',
	},
	mobileStepper: {
		width: '100%',
		borderRadius: '10px',
	},
	navBtn: {
		color: theme.palette.common.white,
	},
	noPrevRoot: {
		backgroundColor: '#4c494c',
		color: theme.palette.common.white,
		borderRadius: '10px',
		textAlign: 'center',
	},
	downloadBtn: {
		marginTop: theme.spacing(2),
	},
	thumbnailImage: {
		height: 'calc(100% - 2px)',
		width: 'calc(100% - 2px)',
	},
	noThumbnails: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		top: '1px',
		left: '1px',
		height: 'calc(100% - 2px)',
		width: 'calc(100% - 2px)',
		backgroundImage: `linear-gradient(rgb(49 46 46), rgb(12 12 12))`,
	},
	videoContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		top: '1px',
		left: '1px',
		height: 'calc(100% - 2px)',
		width: 'calc(100% - 2px)',
		backgroundImage: `linear-gradient(rgb(255 255 255 / 0%), rgb(255 249 249 / 0%))`,
	},
	emptyThumbnail: {
		backgroundImage: `linear-gradient(rgb(53 47 47), rgb(47 32 32))`,
	},
	videocamIcon: {
		fontSize: '40px',
		color: '#fafafb',
		backgroundColor: '#ff5722',
		borderRadius: '8px',
	},
	youtubeIcon: {
		color: '#ff0000',
		fontSize: '65px',
	},
	emptyContainer: {
		height: '500px',
		display: 'contents',
	},
	emptyBoxContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '500px', // Adjust the height as needed
		[theme.breakpoints.down(530)]: {
			height: '230px',
		},
		[theme.breakpoints.between(530, 830)]: {
			height: '310px',
		},
		[theme.breakpoints.between(830, 930)]: {
			height: '400px',
		},
		[theme.breakpoints.down(430)]: {
			width: '270px',
		},
		[theme.breakpoints.between(430, 530)]: {
			width: '300px',
		},
		[theme.breakpoints.between(530, 600)]: {
			width: '400px',
		},
		[theme.breakpoints.between(600, 800)]: {
			width: '445px',
		},
		[theme.breakpoints.between(800, 930)]: {
			width: '590px',
		},
		[theme.breakpoints.between(930, 1150)]: {
			width: '600px',
		},
		[theme.breakpoints.between(930, 1150)]: {
			width: '600px',
		},
		[theme.breakpoints.up(1150)]: {
			width: '700px',
		},
		width: '200px', // Adjust the width as needed
		backgroundColor: '#e0e0e0', // Background color
		borderRadius: '8px', // Optional: Add border-radius for rounded corners
	},
}))

const isImage = (mediaType) => {
	return ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'].indexOf(mediaType) > -1
}

const isPlayerSupport = (mediaType) => {
	return (
		[
			'video/mp4',
			'video/3gpp',
			'video/wmv',
			'video/mkv',
			'video/webm',
			'video/avi',
			'video/avchd',
			'video/flv',
			'video/swf',
			'video/f4v',
			'video/mpeg-2',
			'video/x-ms-wmv',
			'application/octet-stream',
		].indexOf(mediaType) > -1
	)
}

const isAudioSupport = (mediaType) => {
	return ['audio/mpeg', 'audio/mp3', 'audio/3gp', 'audio/flac', 'audio/cda', 'audio/wav'].indexOf(mediaType) > -1
}

const isYoutubeVideo = (mediaType) => {
	return ['url'].indexOf(mediaType) > -1
}

const SwiperCarousel = ({ fileList, accessToken, activeIdx, setActiveIdx }) => {
	const classes = useStyles(fileList)
	const [swiper, setSwiper] = useState(null)

	const [showVideo, setShowVideo] = useState(false)
	const [currentVideo, setCurrentVideo] = useState('')

	const onClickCapture = (url) => {
		setCurrentVideo(url)
		setShowVideo(true)
	}

	const params = {
		onSwiper: setSwiper,
		autoHeight: true,
		slidesPerView: 1,
		spaceBetween: 50,
	}

	const handleNext = () => {
		if (!_.isEmpty(swiper)) {
			setShowVideo(false)
			setCurrentVideo('')
			swiper.slideNext()
			setActiveIdx(activeIdx + 1)
		}
	}

	const handleBack = () => {
		if (!_.isEmpty(swiper)) {
			setShowVideo(false)
			setCurrentVideo('')
			swiper.slidePrev()
			setActiveIdx(activeIdx - 1)
		}
	}

	const onClickDownload = (filePath) => {
		window.open(filePath, '_blank')
	}

	useEffect(() => {
		if (swiper && swiper.activeIndex !== activeIdx) {
			swiper.slideTo(activeIdx, 0)
		}
	}, [activeIdx, swiper])

	const filesToShow = useMemo(
		() =>
			_.map(fileList, (file) => ({ ...file, url: _.isEqual(file?.file_type, 'url') ? file?.file_path : getImgUrl(accessToken, file?.file_path) })),
		[fileList, accessToken]
	)

	return (
		<Grid className={classes.swiperRoot} container alignItems='center' justifyContent='center'>
			{_.gt(fileList?.length, 1) && (
				<Grid item lg={1} xs={1} container justifyContent='center'>
					<IconButton className={classes.navBtn} disabled={activeIdx === 0} onClick={handleBack} size='small'>
						<Icon fontSize='small'>chevron_left</Icon>
					</IconButton>
				</Grid>
			)}
			<Grid item lg={_.gt(fileList?.length, 1) ? 10 : 12} xs={_.gt(fileList?.length, 1) ? 10 : 12}>
				<Swiper className={classes.swiperContainer} {...params}>
					{_.map(fileList, (file, index) => (
						<SwiperSlide key={file?.attachment_id} className={classes.slideContent}>
							{isImage(file?.file_type) ? (
								<img className={classes.slideImage} alt={''} src={file?.url} />
							) : isPlayerSupport(file?.file_type) ? (
								!showVideo ? (
									<div onClick={() => onClickCapture(file?.url)} className={classnames(_.isEmpty(file?.thumbnail_path) && classes.emptyContainer)}>
										{!_.isEmpty(file?.thumbnail_path) ? (
											<>
												<img className={classes.thumbnailImage} alt={'Video Loading...'} src={file?.src} />
												<div className={classnames(classes.videoContainer)}>
													<VideocamIcon className={classes.videocamIcon} />
												</div>
											</>
										) : (
											<>
												<Box className={classes.emptyBoxContainer}>
													<VideocamIcon className={classes.videocamIcon} />
												</Box>
											</>
										)}
									</div>
								) : (
									<ReactPlayer
										className={classes.reactPlayer}
										url={file?.url}
										width={'100%'}
										height={'auto'}
										playing={showVideo && _.isEqual(index, activeIdx) && _.isEqual(file?.url, currentVideo)}
										controls={true}
									/>
								)
							) : isAudioSupport(file?.file_type) ? (
								<ReactPlayer
									className={classes.reactPlayer}
									url={file?.url}
									width={'100%'}
									height={'auto'}
									playing={showVideo && _.isEqual(index, activeIdx) && _.isEqual(file?.url, currentVideo)}
									controls={true}
								/>
							) : isYoutubeVideo(file?.file_type) ? (
								!showVideo ? (
									<div onClick={() => onClickCapture(file?.url)} className={classnames(_.isEmpty(file?.thumbnail_path) && classes.emptyContainer)}>
										{!_.isEmpty(file?.thumbnail_path) ? (
											<>
												<img className={classes.thumbnailImage} alt={'Video Loading...'} src={file?.src} />
												<div className={classnames(classes.videoContainer)}>
													<YouTubeIcon className={classes.youtubeIcon} />
												</div>
											</>
										) : (
											<>
												<Box className={classes.emptyBoxContainer}>
													<YouTubeIcon className={classes.youtubeIcon} />
												</Box>
											</>
										)}
									</div>
								) : (
									<ReactPlayer
										className={classes.reactPlayer}
										url={file?.url}
										width={'100%'}
										height={'500px'}
										playing={showVideo && _.isEqual(index, activeIdx) && _.isEqual(file?.url, currentVideo)}
										controls={true}
										preload={true}
										config={{
											youtube: {
												playerVars: { showinfo: 1 },
											},
										}}
										fallback={<img src={file?.url} alt='Fallback Thumbnail' />}
									/>
								)
							) : (
								<Box paddingTop={2} paddingLeft={3} paddingBottom={2} paddingRight={3} className={classes.noPrevRoot}>
									<Typography variant='body1'>No preview available</Typography>
									<Button
										startIcon={<Icon>get_app</Icon>}
										size='small'
										variant='contained'
										disableElevation
										color='primary'
										className={classes.downloadBtn}
										onClick={() => {
											onClickDownload(getImgUrl(accessToken, file?.file_path))
										}}
									>
										Download
									</Button>
								</Box>
							)}
						</SwiperSlide>
					))}
				</Swiper>
			</Grid>
			{_.gt(fileList?.length, 1) && (
				<Grid item lg={1} xs={1} container justifyContent='center'>
					<IconButton className={classes.navBtn} disabled={_.isEqual(activeIdx, fileList?.length - 1)} onClick={handleNext} size='small'>
						<Icon fontSize='small'>chevron_right</Icon>
					</IconButton>
				</Grid>
			)}
		</Grid>
	)
}

export default SwiperCarousel
