import React, { useMemo, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Button, Typography, ButtonBase, makeStyles } from '@material-ui/core'
import _ from 'lodash'
import update from 'immutability-helper'
import { useDispatch } from 'react-redux'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { getErrMsg, getSuccessMsg } from 'utils'
import { hideLoader, showConfirmMessage, showErrorMessage, showLoader } from 'services/loader/actions'
import { addGlassLabel, removeGlassLabel, updateGlassLabel } from 'services/glass/actions'

InputLabels.propTypes = {
	loading: PropTypes.bool,
	glassCode: PropTypes.string,
	name: PropTypes.string,
	sessionId: PropTypes.string,
	value: PropTypes.arrayOf(PropTypes.string),
	labels: PropTypes.arrayOf(
		PropTypes.shape({
			label_id: PropTypes.string,
			label_name: PropTypes.string,
			label_color: PropTypes.string,
		})
	),
	onChange: PropTypes.func,
	onUpdtLblListChanges: PropTypes.func,
	disabled: PropTypes.bool,
	hideAddLabel: PropTypes.bool,
	hideEditLabel: PropTypes.bool,
	hideDeleteLabel: PropTypes.bool,
	isCustom: PropTypes.bool,
	handleAddLabel: PropTypes.func,
	handleUpdateLabel: PropTypes.func,
	handleDeleteLabel: PropTypes.func,
}

const useStyles = makeStyles((theme) => ({
	labelsWrap: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: '5px',
	},
	labelBtnBase: {
		height: '28px',
		padding: '3px 9px',
		borderRadius: '2px',
		color: theme.palette.common.white,
	},
	labelElipsesCount: {
		height: '28px',
		padding: '3px 9px',
		borderRadius: '2px',
		backgroundColor: theme.palette.grey[300],
		color: theme.palette.text.primary,
	},
	title: {
		marginBottom: theme.spacing(1),
	},
	addLabelButton: {
		background: theme.palette.almostBlack[300],
		color: theme.palette.almostBlack[700],
		textTransform: 'capitalize',
		'&:hover': {
			background: theme.palette.almostBlack[200],
		},
	},
	addLabelDescButton: {
		minWidth: '85px',
		background: theme.palette.almostBlack[300],
		color: theme.palette.almostBlack[700],
		textTransform: 'capitalize',
		'&:hover': {
			background: theme.palette.almostBlack[200],
		},
	},
}))

function InputLabels({
	labels,
	value,
	disabled,
	isDescription,
}) {
	const classes = useStyles()

	const selectedLabels = useMemo(() => {
		return _.filter(labels, (label) => _.indexOf(value, _.get(label, 'label_id')) > -1)
	}, [labels, value])

	return (
		<div>
			{isDescription && (
				<Typography variant='subtitle2' component='p' className={classes.title}>
					Labels
				</Typography>
			)}
			<div className={classes.labelsWrap}>
                {_.map(selectedLabels, (label) => (
                    <ButtonBase
                        disabled={disabled}
                        style={{ background: _.get(label, 'label_color') }}
                        className={classes.labelBtnBase}
                        key={_.get(label, 'label_id')}
                    >
                        <Typography variant='caption'>{_.get(label, 'label_name')}</Typography>
                    </ButtonBase>
                ))}
			</div>
		</div>
	)
}

export default InputLabels
