import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import {
	Dialog,
	makeStyles,
	Button,
	TextField,
	Grid,
	DialogContent,
	DialogActions,
	DialogTitle,
	Slide,
	Divider,
	Typography,
	FormHelperText,
	FormControlLabel,
	Checkbox,
	InputAdornment,
	IconButton,
	Icon,
	ClickAwayListener,
	Avatar,
	LinearProgress,
} from '@material-ui/core'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { DateTimePicker } from '@material-ui/pickers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { formTheName, getErrMsg, getImgUrl, stringToHslColor, getSuccessMsg, convertLocalTimeToGmtStr } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import GlassApi from '../../../../services/glass/api'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import classnames from 'classnames'
import { addStickyData, refreshStickyList } from 'services/glass/actions'

const useStyles = makeStyles((theme) => ({
	disableOnUpdate: {
		opacity: (props) => (props.isLoading ? 0.8 : 1),
		pointerEvents: (props) => (props.isLoading ? 'none' : 'unset'),
	},
	dialog: {
		[theme.breakpoints.up('md')]: {
			position: 'absolute',
		},
		maxWidth: '350px',
		maxHeight: 'calc(100% - 36px)',
	},
	textField: {
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
			'& .MuiAutocomplete-endAdornment': {
				display: 'none',
			},
		},
		'& .MuiAutocomplete-root': {
			'& .MuiFormControl-root': {
				'& .Mui-disabled': {
					color: '#ceced2',
				},
			},
		},
	},
	includesContainer: {
		'& .MuiGrid-item': {
			padding: '0 !important',
		},
		'& .MuiFormControlLabel-label': {
			fontSize: 'small',
			color: theme.palette.almostBlack[700],
		},
	},
	dialogContent: {
		'& .MuiFormControl-marginDense': {
			marginTop: '4px',
		},
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			background: `${theme.palette.primary.main}`,
		},
	},
	fieldContainer: {
		'& .MuiGrid-item': {
			paddingTop: '8px',
		},
	},
	dialogFooter: {
		padding: '12px 8px',
		borderTop: `1px solid ${theme.palette.grey[300]}`,
		'& .MuiButton-label': {
			textTransform: 'capitalize',
		},
	},
	mainGrid: {
		flexWrap: 'nowrap',
	},
	userPic: {
		width: 36,
		height: 36,
		marginRight: theme.spacing(1),
		fontSize: 17,
	},
	userInfo: { maxWidth: `calc(100% - (36px + 8px))` },
	optionTextEllipsis: {
		width: `100%`,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	assigneeDiv: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flex: 1,
		alignItems: 'center',
		marginTop: '4px',
	},
	text: {
		fontSize: 14,
		flex: 1,
		'&:hover': {
			color: theme.palette.primary.main,
		},
	},
}))
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const FormSchema = Yup.object().shape({
	// sticky_title: Yup.string().trim().max(255, 'Sticky title should not be more than 255 characters.').required('Please enter the Sticky Title'),
	due_date: Yup.date().nullable().min(new Date(), 'Due date cannot be in the past.'),
	copy_to: Yup.object().nullable().required('Please select the pane to copy'),
})

const CloneIncludes = ({ formik }) => {
	const classes = useStyles()

	return (
		<Grid container className={classes.includesContainer}>
			<Grid item xs={6}>
				{' '}
				<FormControlLabel
					control={<Checkbox checked={formik.values.is_attachments} name='is_attachments' onChange={formik.handleChange} />}
					label='Attachment'
				/>
			</Grid>
			<Grid item xs={6}>
				{' '}
				<FormControlLabel control={<Checkbox checked={formik.values.is_labels} name='is_labels' onChange={formik.handleChange} />} label='Labels' />
			</Grid>
			{/* <Grid item xs={6}>
				{' '}
				<FormControlLabel
					control={<Checkbox checked={formik.values.is_pinned_posts} name='is_pinned_posts' onChange={formik.handleChange} />}
					label='Pinned Post'
				/>
			</Grid>
			<Grid item xs={6}>
				{' '}
				<FormControlLabel
					control={<Checkbox checked={formik.values.is_comments} name='is_comments' onChange={formik.handleChange} />}
					label='Comments'
				/>
			</Grid> */}
			<Grid item xs={6}>
				{' '}
				<FormControlLabel
					control={<Checkbox checked={formik.values.is_subtasks} name='is_subtasks' onChange={formik.handleChange} />}
					label='Subtask'
				/>
			</Grid>
		</Grid>
	)
}
const CloneForm = ({ glassMembers, lanesData, formik, stickyDetails, stickyCode }) => {
	const classes = useStyles()
	const filterOptions = createFilterOptions({
		stringify: ({ first_name, last_name, email }) => `${first_name} ${last_name} ${email}`,
	})

	const token = useSelector((state) => _.get(state, 'session.authToken', ''))
	const [isAssigneeListOpen, setIsAssigneeListOpen] = useState(false)

	const toggleAssigneeList = () => {
		setIsAssigneeListOpen(!isAssigneeListOpen)
	}

	return (
		<form onSubmit={formik.handleSubmit}>
			<Grid container className={classes.fieldContainer}>
			    <Grid item xs={12}>
					<Typography variant='subtitle2' className={classes.stickyCode}>
						{stickyCode && `Task Code : ${stickyCode || ''}`}
					</Typography>
				</Grid>
				{/* <Grid item xs={12}>
					<Typography variant='subtitle2'>Sticky Title*</Typography>
					<TextField
						fullWidth
						size='small'
						type='text'
						margin='dense'
						variant='outlined'
						name='sticky_title'
						id='sticky_title'
						className={classes.textField}
						value={formik.values.sticky_title}
						error={formik.errors.sticky_title}
						helperText={formik.errors.sticky_title && formik.errors.sticky_title}
						onChange={formik.handleChange}
					/>
				</Grid> */}
				<Grid item xs={12}>
					<Typography variant='subtitle2'>Copy To*</Typography>
					<Autocomplete
						id='copy_to'
						name='copy_to'
						fullWidth
						size='small'
						value={formik.values?.copy_to}
						getOptionSelected={(option, value) => _.isEqual(option?.pane_id, value?.pane_id)}
						options={lanesData}
						onChange={(event, newValue) => {
							formik.setFieldValue('copy_to', newValue)
						}}
						getOptionLabel={(option) => option.pane_name}
						renderInput={(params) => (
							<TextField {...params} variant='outlined' name='copy_to' margin='dense' size='small' placeholder='Select Pane' />
						)}
					/>
					{formik.errors.copy_to && <FormHelperText error>{formik.errors.copy_to}</FormHelperText>}
				</Grid>
				<Grid item xs={12}>
					<Typography variant='subtitle2'>{'Due Date & Time'}</Typography>
					<DateTimePicker
						name='due_date'
						id='due_date'
						className={classes.textField}
						autoOk
						fullWidth
						size='small'
						margin='dense'
						variant='dialog'
						inputVariant='outlined'
						format='DD MMM YYYY, h:mm A'
						value={_.get(formik.values, 'due_date', null)}
						disablePast
						clearable
						error={formik.errors.due_date}
						placeholder={'Choose Due Date & Time'}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton size='small'>
										<Icon>date_range</Icon>{' '}
									</IconButton>
								</InputAdornment>
							),
						}}
						onChange={(newDate) => {
							if (newDate) {
								newDate.set({ seconds: 0, milliseconds: 0 })
							}

							formik.handleChange({
								target: {
									name: 'due_date',
									value: newDate,
								},
							})
						}}
					/>
					{formik.errors.due_date && <FormHelperText error>{formik.errors.due_date}</FormHelperText>}
				</Grid>
				<Grid item xs={12}>
					<Typography variant='subtitle2'>Assignee</Typography>
					{_.isEmpty(formik.values.assigned_to) || isAssigneeListOpen ? (
						<ClickAwayListener onClickAway={toggleAssigneeList}>
							<Autocomplete
								fullWidth
								size='small'
								id='assigned_to'
								filterOptions={filterOptions}
								onChange={(event, newValue) => {
									formik.setFieldValue('assigned_to', newValue)
									toggleAssigneeList()
								}}
								openOnFocus
								value={formik.values.assigned_to}
								options={glassMembers}
								getOptionSelected={(option, value) => _.isEqual(option?.user_id, value?.user_id)}
								// eslint-disable-next-line react/jsx-no-duplicate-props
								getOptionLabel={(option) => (option?.first_name && formTheName(option?.first_name, option?.last_name)) || ''}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										variant='outlined'
										size='small'
										id='assigned_to'
										name='assigned_to'
										margin='dense'
										placeholder='Select Assignee'
									/>
								)}
								renderOption={(option) => {
									const picUrl = _.get(option, 'profile_pic_url', '')
									const full_name = _.join([_.get(option, 'first_name', ''), _.get(option, 'last_name', '')], ' ')
									return (
										<Grid key={option.user_id} container alignItems='center' className={classes.mainGrid}>
											<Grid item>
												<Avatar
													alt={full_name}
													src={picUrl ? getImgUrl(token, picUrl) : null}
													size='small'
													className={classes.userPic}
													style={{ backgroundColor: stringToHslColor(full_name) }}
												>
													{full_name && full_name.substring(0, 1)}
												</Avatar>
											</Grid>
											<Grid item xs className={classes.userInfo}>
												<Typography variant='body2' className={classes.optionTextEllipsis} title={full_name}>
													{full_name}
												</Typography>
												<Typography variant='body2' color='textSecondary' className={classes.optionTextEllipsis} title={option.email}>
													{option.email}
												</Typography>
											</Grid>
										</Grid>
									)
								}}
							/>
						</ClickAwayListener>
					) : (
						<div
							role='button'
							onClick={toggleAssigneeList}
							style={{
								display: 'flex',
								flex: 1,
								alignItems: 'center',
							}}
						>
							<div className={classes.assigneeDiv} onClick={toggleAssigneeList}>
								<Avatar
									{...(_.isEmpty(formik.values?.assigned_to?.profile_pic_url)
										? {}
										: {
												src: getImgUrl(token, formik.values?.assigned_to?.profile_pic_url),
										  })}
									alt={formTheName(formik.values?.assigned_to?.first_name, formik.values?.assigned_to?.last_name)}
									className={classes.userPic}
									style={{
										backgroundColor: stringToHslColor(formTheName(formik.values?.assigned_to?.first_name, formik.values?.assigned_to?.last_name)),
									}}
								>
									{_.slice(_.words(formTheName(formik.values?.assigned_to?.first_name, formik.values?.assigned_to?.last_name)), 0, 1).reduce(
										(a, t) => (a += t[0]),
										''
									)}
								</Avatar>
								<Typography variant='body1' className={classes.text} style={{ marginLeft: 4 }}>
									{formTheName(formik.values?.assigned_to?.first_name, formik.values?.assigned_to?.last_name)}
								</Typography>
							</div>
						</div>
					)}
				</Grid>
				<Grid item xs={12}>
					<Typography variant='subtitle2'>Includes</Typography>
					<CloneIncludes formik={formik} />
				</Grid>
			</Grid>
		</form>
	)
}
const FormDialog = ({ open, setForm, glassMembers, lanesData, stickyDetails, glassCode, stickyCode, history, sessionId, setSelectedRows }) => {

	const [isLoading, setLoading] = useState(false)
	const classes = useStyles({ isLoading })
	const dispatch = useDispatch()
	const formik = useFormik({
		initialValues: {
			// sticky_title: `CLONE - ${_.get(stickyDetails, 'sticky_title', '')}`,
			copy_to: null,
			assigned_to: null,
			due_date: null,
			is_attachments: false,
			is_labels: false,
			is_pinned_posts: false,
			is_comments: false,
			is_subtasks: false,
		},
		enableReinitialize: true,
		onSubmit: (e) => {
			submitForm(e)
		},
		validationSchema: FormSchema,
	})

	const handleClone = () => {
		formik.submitForm()
	}

	const submitForm = (vals) => {
		let formVals = {
			...vals,
			pane_id: _.get(vals, 'copy_to.pane_id', null),
			due_date: _.isNil(vals.due_date) ? null : convertLocalTimeToGmtStr(vals.due_date),
			assigned_to: _.get(vals, 'assigned_to.user_id', null),
			sticky_code: stickyCode,
			is_select_all: false
		}
		delete formVals.copy_to
		setLoading(true)
		formik.resetForm()
		setSelectedRows([])
		GlassApi.cloneMultiSticky(glassCode, formVals, sessionId)
			.then((res) => {
				// dispatch(addStickyData(_.get(res, 'data.data')))
				setLoading(false)
				// const cloneStickyCode = _.get(res, 'data.data.sticky_code', stickyCode)
				dispatch(refreshStickyList(glassCode, ''))
				handleClose()
				// history.push(`/glassx/view/${glassCode}/${cloneStickyCode}`)
				dispatch(showSnackbarWithTimeout(getSuccessMsg(res), 1500))
			})
			.catch((err) => {
				setLoading(false)
				dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
			})
	}

	useEffect(() => {
		// formik.setFieldValue('copy_to', _.isEmpty(stickyDetails.pane_id) ? null : _.find(lanesData, { pane_id: stickyDetails.pane_id }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, lanesData])

	const handleClose = () => {
		if (!isLoading) {
			setForm(false)
		}
	}

	return (
		<>
			<Dialog
				open={open}
				classes={{ paper: classes.dialog }}
				TransitionComponent={Transition}
				keepMounted
				fullWidth
				onClose={handleClose}
				aria-labelledby='clone-sticky-form'
			>
				{isLoading && <LinearProgress variant='indeterminate' />}
				<DialogTitle>Clone Task</DialogTitle>
				<Divider />
				<DialogContent className={classnames(classes.dialogContent, classes.disableOnUpdate)}>
					<CloneForm glassMembers={glassMembers} lanesData={lanesData} stickyCode={stickyCode}
					// stickyDetails={stickyDetails} 
					formik={formik} />
				</DialogContent>
				<DialogActions className={classnames(classes.dialogFooter, classes.disableOnUpdate)}>
					<Button
						variant='outlined'
						color='primary'
						size='medium'
						fullWidth
						disableElevation
						onClick={() => {
							handleClose()
							formik.handleReset()
						}}
					>
						Cancel
					</Button>
					<Button size='medium' fullWidth variant='contained' color='primary' type='submit' disableElevation onClick={handleClone}>
						Clone
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}
export default FormDialog
