import { ACTION_TYPES, SAGA_ACTIONS } from '../../../constants'

export const getMytaskStickies = (payload) => ({
	type: SAGA_ACTIONS.SAGA_GET_MY_TASK_STICKIES,
	payload,
})

export const getMytaskFilter = (sessionId) => ({
	type: SAGA_ACTIONS.SAGA_GET_MY_TASK_FILTER,
	sessionId,
})

export const setMytaskFilter = (sessionId, payload, succCallback, errCallback) => ({
	type: SAGA_ACTIONS.SAGA_SET_MY_TASK_FILTER,
	sessionId,
	payload,
	succCallback,
	errCallback,
})

export const updateMytaskLayout = (layout) => ({
	type: SAGA_ACTIONS.SAGA_UPDATE_MYTASK_LAYOUT,
	payload: { layout },
})
