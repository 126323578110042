import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import classnames from 'classnames'
import Grid from '@material-ui/core/Grid'
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import ProjectFilterList from './ProjectFilterList'
import GlassListComponent from './GlassListComponent'
import _ from 'lodash'
import Axios from 'axios'
import GlassApi from '../../services/glass/api'
import { usePermission } from 'hooks/usePermission'
import { PERMISSION_TYPE, ROLE_MODULES } from '../../constants/modules'
import { GLASS_ROLES } from '../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { hideLoader, showConfirmMessage, showErrorMessage, showLoader } from 'services/loader/actions'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { getSuccessMsg, getErrMsg } from 'utils'
import GlassFormDialog from 'components/GlassFormDialog'
import WorkflowStepper from './WorkflowStepper'

const useStyles = makeStyles((theme) => ({
	wrapper: {
		height: '100vh',
		boxSizing: 'border-box',
	},
	root: {
		height: '100%',
	},
	section: {
		position: 'relative',
		'&[disabled]': {
			pointerEvents: 'none',
			opacity: 0.3,
		},
	},
}))

function GlassList({ history }) {
	const classes = useStyles()
	const theme = useTheme()
	const isMounted = useRef(true)
	const cancelExecutor = useRef()
	const cancelDetailsExecutor = useRef()
	const isTab = useMediaQuery(theme.breakpoints.down('sm'))
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
	const [openAddDialog, setOpenAddDialog] = useState(false)
	const [showIntroStepper, setShowIntroStepper] = useState(false)
	const [glassState, setGlassState] = useState({
		loading: true,
		glassList: [],
		searchTxt: '',
		totalCount: 0,
		filters: {},
		offset: 0,
		limit: 12,
	})
	const { searchTxt, filters, glassList, offset, limit, totalCount, loading } = glassState
	const glassPerms = usePermission(ROLE_MODULES.GLASS)
	const dashXPerms = usePermission(ROLE_MODULES.DASHBOARDS)
	const isDashXViewAllowed = useMemo(() => _.get(dashXPerms, PERMISSION_TYPE.VIEW, false), [dashXPerms])
	const isCreateAllowed = useMemo(() => _.get(glassPerms, PERMISSION_TYPE.CREATE, false), [glassPerms])
	const isEditAllowed = useMemo(() => _.get(glassPerms, PERMISSION_TYPE.EDIT, false), [glassPerms])
	const isDeleteAllowed = useMemo(() => _.get(glassPerms, PERMISSION_TYPE.DELETE, false), [glassPerms])
	const currentUserId = useSelector((state) => _.get(state, 'session.user.userDetails.user_id'))
	const dispatch = useDispatch()

	const [selectedData, setSelectedData] = useState({})

	const onChngProjFilter = (projList) => {
		setGlassState((prevState) => ({
			...prevState,
			filters: { projects: projList },
			offset: 0,
			limit: 12,
		}))
	}

	useEffect(() => {
		history.index = 0
		return () => {
			isMounted.current = false
		}
	}, [])

	const safeUpdateGlassState = (objToUpdate) => {
		if (isMounted.current) setGlassState((prevState) => ({ ...prevState, ...objToUpdate }))
	}

	const projFilterVal = useMemo(() => _.get(filters, 'projects', []), [filters])

	//Take the team units and projects module permission to check the user has the permission or not
	const teamunitsPerms = usePermission(ROLE_MODULES.TEAM_MANAGEMENT)
	const projectPerms = usePermission(ROLE_MODULES.PROJECTS)

	//isIntroPopupAvail flag is true if user has teamunits and projects module permission
	const isIntroPopupAvail = useMemo(
		() =>
			_.get(teamunitsPerms, PERMISSION_TYPE.VIEW, false) &&
			_.get(teamunitsPerms, PERMISSION_TYPE.CREATE, false) &&
			_.get(projectPerms, PERMISSION_TYPE.VIEW, false) &&
			_.get(projectPerms, PERMISSION_TYPE.CREATE, false),
		[teamunitsPerms, projectPerms]
	)

	const onClickAddGlassBtn = () => {
		//show intro popup if user doesn't have any glassX
		// if (!loading && _.isEmpty(searchTxt) && _.isEmpty(glassList) && isIntroPopupAvail) {
		// 	setShowIntroStepper(true)
		// } else
		toggleDialog()
	}

	const onFinishIntroStepper = () => {
		setShowIntroStepper(false)
		toggleDialog()
	}

	const toggleDialog = () => {
		setOpenAddDialog(!openAddDialog)
	}
	const handleCloseDialog = () => {
		setSelectedData({})
		setOpenAddDialog(false)
	}

	const getGlassList = useCallback((searchTxt, filters, offset, limit) => {
		safeUpdateGlassState({ loading: true, glassList: [] })
		const projectCode = _.join(
			_.map(_.get(filters, 'projects', []), (project) => _.get(project, 'project_code')),
			','
		)
		GlassApi.fetchAllGlasses(
			searchTxt,
			offset,
			limit,
			`${projectCode}`,
			cancelExecutor,
			(res) => {
				const list = _.get(res, 'data.data.glass', [])
				safeUpdateGlassState({
					loading: false,
					glassList: list || [],
					totalCount: _.get(res, 'data.data.total_count', 0),
				})
			},
			(err) => {
				if (!Axios.isCancel(err)) safeUpdateGlassState({ loading: false, glassList: [] })
			}
		)
	}, [])
	useEffect(() => {
		getGlassList(searchTxt, filters, offset, limit)
	}, [searchTxt, filters, offset, limit, getGlassList])

	const delayedQuery = useCallback(
		_.debounce((q) => {
			safeUpdateGlassState({ searchTxt: q, offset: 0, totalCount: 0 })
		}, 300),
		[]
	)

	const onChngSearchBox = (e) => {
		delayedQuery(e.target.value)
	}

	const handleChangePage = (event, newPage) => {
		safeUpdateGlassState({ offset: newPage * limit })
	}

	const handleChangeRowsPerPage = (event) => {
		safeUpdateGlassState({ offset: 0, limit: parseInt(event.target.value) })
	}

	const onClickGlassItem = (glassCode) => {
		history.push(`/glassx/view/${glassCode}`)
	}
	const checkPermission = (data, isEditMode, callback) => {
		dispatch(showLoader('Please wait validating permissions...'))
		setSelectedData({})
		const onSuccess = (res) => {
			dispatch(hideLoader())
			let owner = _.findIndex(_.get(res, 'data.data.glass_members', []), { user_id: currentUserId, role: GLASS_ROLES.OWNER }) > -1
			if (!owner) {
				dispatch(
					showErrorMessage(
						isEditMode ? "You don't have permission to edit the Board" : "You don't have permission to delete the Board",
						'Close',
						() => dispatch(hideLoader())
					)
				)
			} else {
				callback(res)
			}
		}
		const onFailure = () => {
			dispatch(hideLoader())
		}

		GlassApi.fetchGlassDetails(data?.glass_code, cancelDetailsExecutor).then(onSuccess, onFailure)
	}

	const onClickEdit = (data) => {
		checkPermission(data, true, (res) => {
			setSelectedData(_.get(res, 'data.data', {}))
			setOpenAddDialog(!openAddDialog)
		})
	}

	const refreshList = () => {
		getGlassList(searchTxt, filters, offset, limit)
	}

	const deleteGlassFxn = (glassCode) => {
		dispatch(showLoader('Please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			setSelectedData({})
			getGlassList(searchTxt, filters, 0, 12)
			dispatch(showSnackbarWithTimeout(getSuccessMsg(res), 1500))
		}
		const onFailure = (err) => {
			setSelectedData({})
			dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
			dispatch(hideLoader())
		}

		GlassApi.deleteGlass(glassCode).then(onSuccess, onFailure)
	}

	const onClickDelete = (data) => {
		checkPermission(data, false, (res) => {
			setSelectedData(_.get(res, 'data.data', {}))
			dispatch(
				showConfirmMessage(
					['Are you sure, you want to delete the ', <b>{_.get(data, 'glass_name', '')}</b>, ' Board?'],
					'',
					'Confirm',
					(e) => {
						deleteGlassFxn(_.get(data, 'glass_code'))
					},
					'Cancel',
					(e) => {
						setSelectedData({})
						dispatch(hideLoader())
					}
				)
			)
		})
	}

	const pinGlassFxn = (glassCode, pin) => {
		dispatch(showLoader('Please wait...'))
		const data = { is_pinned: pin }
		const onSuccess = (res) => {
			dispatch(hideLoader())
			setSelectedData({})
			getGlassList(searchTxt, filters, offset, limit)
			dispatch(showSnackbarWithTimeout(getSuccessMsg(res), 1500))
		}
		const onFailure = (err) => {
			setSelectedData({})
			dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
			dispatch(hideLoader())
		}

		GlassApi.pinGlass(glassCode, data).then(onSuccess, onFailure)
	}

	const onClickPin = (data, pin) => {
		// checkPermission(data, false, (res) => {
		// 	console.log('check', res, data)
		// 	setSelectedData(_.get(res, 'data.data', {}))
		dispatch(
			showConfirmMessage(
				['Do you want to Pin the ', <b>{_.get(data, 'glass_name', '')}</b>, ' Board?'],
				'',
				'Confirm',
				(e) => {
					pinGlassFxn(_.get(data, 'glass_code'), pin)
				},
				'Cancel',
				(e) => {
					setSelectedData({})
					dispatch(hideLoader())
				}
			)
		)
		// })
	}

	const onClickUnpin = (data, pin) => {
		// checkPermission(data, false, (res) => {
		// 	setSelectedData(_.get(res, 'data.data', {}))
		dispatch(
			showConfirmMessage(
				['Are you sure, you want to Un-Pin the ', <b>{_.get(data, 'glass_name', '')}</b>, ' Board?'],
				'',
				'Confirm',
				(e) => {
					pinGlassFxn(_.get(data, 'glass_code'), pin)
				},
				'Cancel',
				(e) => {
					setSelectedData({})
					dispatch(hideLoader())
				}
			)
		)
		// })
	}

	return (
		<div className={classnames('container-no-fluid', 'page-content', classes.wrapper)}>
			<Grid container className={classes.root}>
				{!isTab && !isMobile && (
					<Grid item xs={12} md={3} className={classes.section} disabled={showIntroStepper}>
						<ProjectFilterList controlled value={projFilterVal} onChange={onChngProjFilter} />
					</Grid>
				)}
				<Grid item xs={12} md={9} className={classes.section}>
					{!showIntroStepper ? (
						<GlassListComponent
							key={showIntroStepper}
							isCreateAllowed={isCreateAllowed}
							isEditAllowed={isEditAllowed}
							isDeleteAllowed={isDeleteAllowed}
							isDashXViewAllowed={isDashXViewAllowed}
							loading={loading}
							limit={limit}
							offset={offset}
							searchTxt={searchTxt}
							totalCount={totalCount}
							listData={glassList}
							onClickListItem={onClickGlassItem}
							onChangeSearchBox={onChngSearchBox}
							onChangePage={handleChangePage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
							isSmallDevice={isTab || isMobile}
							projFilterVal={projFilterVal}
							onClickAddBtn={onClickAddGlassBtn}
							onChangeProjFilter={onChngProjFilter}
							onClickEdit={onClickEdit}
							onClickDelete={onClickDelete}
							onClickPin={onClickPin}
							onClickUnpin={onClickUnpin}
							history={history}
						/>
					) : (
						<WorkflowStepper onFinish={onFinishIntroStepper} />
					)}
				</Grid>
				<GlassFormDialog
					history={history}
					open={openAddDialog}
					selectedData={selectedData}
					handleClose={handleCloseDialog}
					refreshList={refreshList}
				/>
			</Grid>
		</div>
	)
}

export default GlassList
