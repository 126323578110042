import { Card, CardContent, Typography, makeStyles, Icon, Avatar, Grid, CardMedia } from '@material-ui/core'
import React, { useContext, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { CardActions } from '@material-ui/core'
import { convertGmtToLocalTime, formTheName, getImgUrl, stringToHslColor } from 'utils'
import AppEventBus from 'utils/event-bus'
import { STICKY_ESC_TIMER } from '../constants'
import { useMemo } from 'react'
import classnames from 'classnames'
import { GLASS_LAYOUT, PRIORITY_LIST, STICKY_STATUS } from 'constants/index'
import BoardContext from '../BoardContext'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(1),
		width: '254px',
		// maxWidth: '260px',
		margin: theme.spacing(0, 0.5, 1, 0.5),
		cursor: 'pointer',
	},
	cardContent: {
		padding: theme.spacing(0),
	},
	cardTitle: {
		fontSize: theme.typography.pxToRem(14),
		fontWeight: '400',
		marginBottom: theme.spacing(2),
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		whiteSpace: 'normal',
		maxWidth: '161px',
	},
	createDate: {
		fontSize: theme.typography.pxToRem(13),
		marginBottom: theme.spacing(1),
	},
	cardSubTitle: {
		fontSize: 'small',
		color: theme.palette.primary.main,
		// fontWeight: '400',
		marginTop: theme.spacing(-1),
		marginBottom: theme.spacing(1),
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		whiteSpace: 'normal',
		// maxWidth: '161px',
	},
	dateIcon: {
		fontSize: theme.typography.pxToRem(13),
		marginRight: theme.spacing(0.5),
		color: 'inherit',
	},
	cardHeader: {
		flexWrap: 'unset',
		marginBottom: theme.spacing(1),
	},
	chip: {
		width: 'auto',
		padding: theme.spacing(0.2, 0.8),
		backgroundColor: (props) => props.priorityColor || theme.palette.error.main,
		color: theme.palette.common.white,
		marginRight: theme.spacing(1),
		fontWeight: '500',
	},
	dueDate: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.text.secondary,
		'& span': {
			fontWeight: '400',
		},
	},
	cardFooter: {
		padding: theme.spacing(0),
	},
	cardNo: {
		fontWeight: '500',
		marginRight: theme.spacing(1),
	},
	cardIco: {
		fontSize: theme.typography.pxToRem(18),
		color: theme.palette.text.secondary,
	},
	rating: {
		fontWeight: '500',
		marginRight: theme.spacing(0.5),
		display: 'flex',
		flexDirection: 'row',
		'& .MuiIcon-root': {
			fontSize: '0.95rem',
			marginLeft: '2px',
			color: '#ffb400',
		},
	},
	assigneeSection: {
		marginTop: theme.spacing(1),
	},
	cardAvatar: {
		width: 22,
		height: 22,
		fontSize: theme.typography.pxToRem(14),
	},
	stickyGroupIcon: {
		width: 13,
		height: 13,
		fontSize: theme.typography.pxToRem(14),
		marginRight: '3px',
	},
	stickyGroupIconG: {
		width: 15,
		height: 15,
		fontSize: theme.typography.pxToRem(14),
		marginRight: '4px',
	},
	assigneeTxt: {
		marginLeft: theme.spacing(0.5),
		fontSize: theme.typography.pxToRem(14),
	},
	markBlue: {
		color: theme.palette.info.main,
		'& span': {
			fontWeight: '500',
		},
	},
	markYellow: {
		color: theme.palette.warning.main,
		'& span': {
			fontWeight: '500',
		},
	},
	markRed: {
		color: theme.palette.error.main,
		'& span': {
			fontWeight: '500',
		},
	},
	markGreen: {
		color: theme.palette.success.main,
		'& span': {
			fontWeight: '500',
		},
	},
	markOrange: {
		color: theme.palette.warning.main,
		'& span': {
			fontWeight: '500',
		},
	},
	attachment: {
		display: 'flex',
		alignItems: 'center',
		color: theme.palette.text.secondary,
		marginLeft: theme.spacing(1),
		gap: theme.spacing(0.4),
	},
	media: {
		width: '100%',
		height: '140px',
		marginBottom: theme.spacing(1),
	},
	labelSection: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	label: {
		width: 27,
		height: 4,
		borderRadius: 2,
		margin: theme.spacing(0.5, 0.5, 0.5, 0),
		backgroundColor: 'grey',
	},
	compactAvatarWrapper: {
		flex: '1',
		'& > div': {
			float: 'right',
		},
	},
	stickyImg: {
		margin: theme.spacing(0, 1, 0.5, 0),
		width: 40,
		height: 40,
		border: `1px solid ${theme.palette.grey[300]}`,
	},
	docType: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		padding: '2px 6px',
		fontWeight: '500',
		borderRadius: '4px',
		marginLeft: theme.spacing(0.5),
		fontSize: '12px',
	},
	progressbarContainer: {
		display: 'flex',
		flexDirection: 'row',
		gap: 10,
		alignItems: 'center',
		marginTop: '8px',
		minWidth: '100%',
	},
	progressbarTotal: {
		backgroundColor: theme.palette.grey[300],
		height: 7,
		width: '100%',
		borderRadius: 10,
		display: 'flex',
		flexDirection: 'row',
	},
	inProgressbar: {
		backgroundColor: theme.palette.progress.inprogress,
		height: 7,
		borderTopRightRadius: 10,
		borderBottomRightRadius: 10,
	},
	completedBar: {
		backgroundColor: theme.palette.progress.completed,
		height: 7,
		borderTopLeftRadius: 10,
		borderBottomLeftRadius: 10,
	},
	progressContent: {
		'& .MuiTypography-body1': {},
	},
	completedText: {
		fontSize: 12,
		whiteSpace: 'nowrap',
	},
}))

// const token = localStorage.getItem('authToken')

const DueDateInfo = React.memo(({ classes, dueDate, stickyStatus, isApproval }) => {
	const escTimerListener = useRef()
	const isRejected = useMemo(() => _.isEqual(stickyStatus, STICKY_STATUS.REJECTED), [stickyStatus])
	const isCompleted = useMemo(
		() => (isApproval ? _.isEqual(stickyStatus, STICKY_STATUS.APPROVED) : _.isEqual(stickyStatus, STICKY_STATUS.COMPLETED)),
		[isApproval, stickyStatus]
	)
	const isInprogress = useMemo(() => _.isEqual(stickyStatus, STICKY_STATUS.INPROGRESS), [stickyStatus])
	const isOpened = useMemo(() => _.isEqual(stickyStatus, STICKY_STATUS.OPEN), [stickyStatus])
	const isReopened = useMemo(() => _.isEqual(stickyStatus, STICKY_STATUS.REOPENED), [stickyStatus])

	const isWaiting = useMemo(() => isApproval && _.isEqual(stickyStatus, STICKY_STATUS.COMPLETED), [isApproval, stickyStatus])

	const [{ isEscalated, isReminder }, setState] = useState({
		isEscalated: false,
		isReminder: false,
	})

	const dueDateTime = useMemo(() => {
		setState({ isEscalated: false, isReminder: false })
		return dueDate ? moment(convertGmtToLocalTime(dueDate)) : null
	}, [dueDate])

	useEffect(() => {
		if (dueDateTime) {
			escTimerListener.current = (e) => {
				const { stickyEscValHrs, stickyRemindMins, currentDateTime, isFirstTrigger } = e.detail
				if (isEscalated && !isFirstTrigger) return
				else if (moment(dueDateTime).add(stickyEscValHrs, 'h') < currentDateTime) setState({ isReminder: false, isEscalated: true })
				else if (!_.isEmpty(stickyRemindMins) && moment(dueDateTime).subtract(_.toNumber(stickyRemindMins), 'm') < currentDateTime)
					setState((prev) => ({ ...prev, isReminder: true }))
				else if (isReminder) setState((prev) => ({ ...prev, isEscalated: false, isReminder: false }))
			}
			AppEventBus.on(STICKY_ESC_TIMER, escTimerListener.current)
		}
		return () => {
			AppEventBus.remove(STICKY_ESC_TIMER, escTimerListener.current)
		}
	}, [dueDateTime, isEscalated, isReminder])

	return (
		<Grid
			className={classnames(classes.dueDate, {
				[classes.markYellow]: isReminder,
				[classes.markRed]: isEscalated || isRejected,
				[classes.markBlue]: isInprogress,
				[classes.markGreen]: isCompleted,
				[classes.markOrange]: isWaiting,
			})}
			alignItems='center'
			justifycontent='center'
		>
			<Icon title={`Task Status: ${_.capitalize(stickyStatus)}`} className={classes.dateIcon} fontSize='small'>
				{isCompleted
					? 'verified'
					: isInprogress
					? 'hourglass_top'
					: isRejected
					? 'thumb_down'
					: isOpened
					? 'play_circle'
					: isReopened
					? 'replay_circle_filled'
					: dueDate
					? 'pending_actions'
					: ''}
			</Icon>
			{dueDate && (
				<Typography title={`Due date: ${dueDateTime.format('MMM DD, YYYY hh:mm a')}`} variant='caption' color='inherit'>
					{dueDateTime.format('MMM DD')}
				</Typography>
			)}
		</Grid>
	)
})

const CardSec = (props) => {
	const {
		onClick,
		sticky_title,
		created_date,
		priority,
		sticky_code,
		due_date,
		assigned_to,
		sticky_rating_score,
		sticky_status,
		attachments,
		labels = [],
		is_approval,
		cardDraggable,
		sticky_for,
		seller_name,
		sticky_group,
		completed_percentage,
		inprogress_percentage,
		child_sticky_code,
	} = props

	const { layout, token, glassLabels } = useContext(BoardContext)

	const priorityObj = useMemo(() => _.find(PRIORITY_LIST, { value: priority }), [priority])
	const classes = useStyles({ priorityColor: priorityObj?.color })
	const attachmentsLen = useMemo(() => attachments?.length || 0, [attachments])
	const imgAttachmentIdx = useMemo(() => _.findIndex(attachments, (o) => o?.file_type?.match(/(^image\/*)\w+/gi)), [attachments])

	const finalLabels = useMemo(() => {
		const cardLabelIds = _.map(labels, (label) => label.label_id)
		return _.filter(glassLabels, (label) => cardLabelIds.indexOf(label.label_id) > -1)
	}, [glassLabels, labels])

	const isCompact = useMemo(() => layout === GLASS_LAYOUT.COMPACT, [layout])
	const localCreatedDate = moment(created_date).local().format('DD MMM YYYY hh:mm a')
	// const localCreatedDate = moment(created_date).local().format('DD MMM YYYY')
	if (isCompact) {
		return (
			<Card className={classes.root} onClick={onClick} variant='outlined'>
				<header draggable={cardDraggable} onDragStart={(e) => e.preventDefault()}>
					<CardContent className={classes.cardContent}>
						<Grid container wrap='nowrap'>
							{imgAttachmentIdx > -1 && (
								<Grid item>
									{/* <img alt={sticky_title} className={classes.stickyImg} src={getImgUrl(token, attachments[imgAttachmentIdx]?.file_path, 500)} /> */}
									<CardMedia
										className={classes.stickyImg}
										image={getImgUrl(token, attachments[imgAttachmentIdx]?.file_path, 500)}
										title={attachments[imgAttachmentIdx]?.file_name}
									/>
								</Grid>
							)}
							<Grid container direction='column' justifyContent='center' item>
								{!_.isEmpty(finalLabels) && (
									<div className={classes.labelSection}>
										{finalLabels.map((o, i) => (
											<div
												key={`label_${i}_${o.label_id}`}
												title={o?.label_name}
												className={classes.label}
												style={{ backgroundColor: o?.label_color || 'transparent' }}
											/>
										))}
									</div>
								)}
								<Typography title={sticky_title} className={classes.cardTitle} variant='h5' component='h2'>
									{sticky_title}
								</Typography>

								{/* This Grid will align localCreatedDate and priorityObj in the same row */}

								{_.isEqual(sticky_for, 'products') && !_.isEmpty(seller_name) && (
									<Typography className={classes.cardSubTitle} variant='h5' component='h2'>
										{seller_name}
									</Typography>
								)}
							</Grid>

							{!_.isEmpty(sticky_rating_score) && (
								<Grid item className={classes.compactAvatarWrapper}>
									<Typography color='textSecondary' title='Task Code' className={classes.cardNo} variant='caption' component='h2'>
										{sticky_rating_score?.score}
									</Typography>
								</Grid>
							)}
							{!_.isEqual(sticky_status?.ratings, 0) && (
								<Grid item className={classes.compactAvatarWrapper}>
									<Typography color='textSecondary' title='Task Code' className={classes.rating} variant='caption' component='h2'>
										{sticky_status?.ratings}
										<Icon>star_icon</Icon>
									</Typography>
								</Grid>
							)}
						</Grid>
					</CardContent>
					<CardActions className={classes.cardFooter}>
						<Grid container>
							<Grid container direction='row' spacing={3}>
								<Grid item>
									<Typography className={classes.createDate} color='textSecondary' variant='h5' component='h2'>
										{localCreatedDate}
									</Typography>
								</Grid>
								{priorityObj && (
									<Grid item title='Priority'>
										<Typography className={classes.chip} variant='caption'>
											{priorityObj?.name}
										</Typography>
									</Grid>
								)}
							</Grid>

							<Grid xs={12} item container alignItems='center'>
								{!_.isEmpty(sticky_group) && (
									<Avatar
										className={classes.stickyGroupIcon}
										src={getImgUrl(token, sticky_group?.sticky_group_url, 50)}
										style={{ backgroundColor: stringToHslColor(`${sticky_group?.sticky_group}`) }}
									>
										{_.slice(sticky_group?.sticky_group, 0, 1)}
									</Avatar>
								)}
								<Typography color='textSecondary' title='Task Code' className={classes.cardNo} variant='caption' component='h2'>
									{sticky_code}
								</Typography>

								<DueDateInfo classes={classes} stickyStatus={sticky_status?.status} isApproval={is_approval} dueDate={due_date} />
								{attachmentsLen > 0 && (
									<Grid title='Attachments' alignItems='center' justifycontent='center'>
										<Typography className={classes.attachment} variant='caption' color='inherit'>
											<Icon fontSize='inherit'>insert_drive_file</Icon>
											<span>{attachmentsLen}</span>
										</Typography>
									</Grid>
								)}

								{!_.isEmpty(assigned_to) && (
									<Grid
										item
										className={classes.compactAvatarWrapper}
										title={`${assigned_to?.first_name || ''} ${assigned_to?.last_name || ''}\n${assigned_to?.email}`}
									>
										<Avatar
											className={classes.cardAvatar}
											src={assigned_to?.profile_img ? getImgUrl(token, assigned_to?.profile_img, 50) : null}
											style={{ backgroundColor: stringToHslColor(`${assigned_to?.first_name} ${assigned_to?.last_name}`) }}
										>
											{_.slice(assigned_to?.first_name, 0, 1)}
										</Avatar>
									</Grid>
								)}
							</Grid>
						</Grid>
					</CardActions>
					{!_.isEmpty(child_sticky_code) && (
						<CardActions className={classes.cardFooter}>
							<Grid container>
								<Grid xs={12} item container alignItems='center'>
									<div className={classes.progressbarContainer}>
										<div className={classes.progressbarTotal}>
											<div
												title={`Completed - ${completed_percentage}%`}
												style={{ width: `${completed_percentage}%` }}
												className={classes.completedBar}
											></div>
											<div
												title={`In Progress - ${inprogress_percentage}%`}
												style={{ width: `${inprogress_percentage}%` }}
												className={classes.inProgressbar}
											></div>
										</div>
										<div className={classes.progressContent}>
											<Typography variant='caption' color='textSecondary' className={classes.completedText}>
												{`${completed_percentage}% Completed`}
											</Typography>
										</div>
									</div>
								</Grid>
							</Grid>
						</CardActions>
					)}
				</header>
			</Card>
		)
	} else {
		return (
			<Card className={classes.root} onClick={onClick} variant='outlined'>
				<header draggable={cardDraggable} onDragStart={(e) => e.preventDefault()}>
					{imgAttachmentIdx > -1 && (
						// <img alt={sticky_title} className={classes.media} src={getImgUrl(token, attachments[imgAttachmentIdx]?.file_path, 150)} />
						<CardMedia
							className={classes.media}
							height='140'
							image={getImgUrl(token, attachments[imgAttachmentIdx]?.file_path, 500)}
							title={attachments[imgAttachmentIdx]?.file_name}
						/>
					)}
					<CardContent className={classes.cardContent}>
						<Grid container wrap='nowrap'>
							<Grid container direction='column' justifyContent='center' item>
								{!_.isEmpty(finalLabels) && (
									<div className={classes.labelSection}>
										{finalLabels.map((o, i) => (
											<div
												key={`label_${i}_${o.label_id}`}
												title={o?.label_name}
												className={classes.label}
												style={{ backgroundColor: o?.label_color || 'transparent' }}
											/>
										))}
									</div>
								)}
								<Typography title={sticky_title} className={classes.cardTitle} variant='h5' component='h2'>
									{sticky_title}
								</Typography>

								{_.isEqual(sticky_for, 'products') && !_.isEmpty(seller_name) && (
									<Typography className={classes.cardSubTitle} variant='h5' component='h2'>
										{seller_name}
									</Typography>
								)}
							</Grid>
							{!_.isEmpty(sticky_rating_score) && (
								<Grid item className={classes.compactAvatarWrapper}>
									<Typography color='textSecondary' title='Task Code' className={classes.cardNo} variant='caption' component='h2'>
										{sticky_rating_score?.score}
									</Typography>
								</Grid>
							)}
							{!_.isEqual(sticky_status?.ratings, 0) && (
								<Grid item className={classes.compactAvatarWrapper}>
									<Typography color='textSecondary' title='Task Code' className={classes.rating} variant='caption' component='h2'>
										{sticky_status?.ratings}
										<Icon>star_icon</Icon>
									</Typography>
								</Grid>
							)}
						</Grid>
					</CardContent>
					<CardActions className={classes.cardFooter}>
						<Grid container>
							<Grid container direction='row' spacing={3}>
								<Grid item>
									<Typography className={classes.createDate} color='textSecondary' variant='h5' component='h2'>
										{localCreatedDate}
									</Typography>
								</Grid>
								{priorityObj && (
									<Grid title='Priority' item>
										<Typography className={classes.chip} variant='caption'>
											{priorityObj?.name}
										</Typography>
									</Grid>
								)}
							</Grid>
							<Grid xs={12} item container alignItems='center'>
								{!_.isEmpty(sticky_group) && (
									<Avatar
										className={classes.stickyGroupIconG}
										src={getImgUrl(token, sticky_group?.sticky_group_url, 50)}
										style={{ backgroundColor: stringToHslColor(`${sticky_group?.sticky_group}`) }}
									>
										{_.slice(sticky_group?.sticky_group, 0, 1)}
									</Avatar>
								)}
								<Typography color='textSecondary' title='Task Code' className={classes.cardNo} variant='caption' component='h2'>
									{sticky_code}
								</Typography>
								{/* <Grid container direction='row' spacing={3}>
									<Grid item>
										<Typography className={classes.createDate} color='textSecondary' variant='h5' component='h2'>
											{localCreatedDate}
										</Typography>
									</Grid>
									{priorityObj && (
										<Grid item title='Priority'>
											<Typography className={classes.chip} variant='caption'>
												{priorityObj?.name}
											</Typography>
										</Grid>
									)}
								</Grid> */}

								<DueDateInfo classes={classes} stickyStatus={sticky_status?.status} isApproval={is_approval} dueDate={due_date} />
								{attachmentsLen > 0 && (
									<Grid title='Attachments' alignItems='center' justifycontent='center'>
										<Typography className={classes.attachment} variant='caption' color='inherit'>
											<Icon fontSize='inherit'>insert_drive_file</Icon>
											<span>{attachmentsLen}</span>
										</Typography>
									</Grid>
								)}
							</Grid>

							{!_.isEmpty(assigned_to) && (
								<Grid container xs={12} item className={classes.assigneeSection} alignItems='center'>
									<Avatar
										className={classes.cardAvatar}
										src={getImgUrl(token, assigned_to?.profile_img, 50)}
										style={{ backgroundColor: stringToHslColor(`${assigned_to?.first_name} ${assigned_to?.last_name}`) }}
									>
										{_.slice(assigned_to?.first_name, 0, 1)}
									</Avatar>
									<Typography color='textSecondary' title={`${assigned_to?.email}`} className={classes.assigneeTxt} variant='h5' component='h2'>
										{formTheName(assigned_to?.first_name, assigned_to?.last_name)}
									</Typography>
								</Grid>
							)}
						</Grid>
					</CardActions>
					{!_.isEmpty(child_sticky_code) && (
						<CardActions className={classes.cardFooter}>
							<Grid container>
								<Grid xs={12} item container alignItems='center'>
									<div className={classes.progressbarContainer}>
										<div className={classes.progressbarTotal}>
											<div
												title={`Completed - ${completed_percentage}%`}
												style={{ width: `${completed_percentage}%` }}
												className={classes.completedBar}
											></div>
											<div
												title={`In Progress - ${inprogress_percentage}%`}
												style={{ width: `${inprogress_percentage}%` }}
												className={classes.inProgressbar}
											></div>
										</div>
										<div className={classes.progressContent}>
											<Typography variant='caption' color='textSecondary' className={classes.completedText}>
												{`${completed_percentage}% Completed`}
											</Typography>
										</div>
									</div>
								</Grid>
							</Grid>
						</CardActions>
					)}
				</header>
			</Card>
		)
	}
}

export default CardSec
