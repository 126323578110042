import { makeStyles, Grid, Typography, Button, TextField } from '@material-ui/core'
import { useCallback, useEffect, useState, useRef } from 'react'
import CreateFormDialog from './CreateFomDialog'
import LinkStickyForm from './LinkStickyForm'
import Autocomplete from '@material-ui/lab/Autocomplete'
import GlassApi from '../../../../../../services/glass/api'
import Axios from 'axios'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	formRoot: {
		margin: theme.spacing(2, 0),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(1, 0),
		},
	},
	mb2: {
		marginBottom: theme.spacing(2),
	},
	glassList: {
		marginTop: '10px',
	},
	orText: {
		marginTop: theme.spacing(4),
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(1),
		},
	},
	createBtn: {
		marginTop: theme.spacing(1),
	},
}))

const SubTaskForm = ({
	glassMembers,
	glassCode,
	closeDialog,
	stickyCode,
	needRefresh,
	onCreateSubtask,
	refreshOptions,
	formik,
	isOwnerOrReporter,
}) => {
	const classes = useStyles()
	const [openCreateForm, setOpenCreateForm] = useState(false)
	const [glassData, setGlassData] = useState({
		glassQuery: '',
		glassLoading: false,
		glassList: [],
	})
	const [stickyData, setStickyData] = useState({
		stickyQuery: '',
		stickyLoading: false,
		stickyList: [],
	})
	const [selectedGlass, setSelectedGlass] = useState([])
	const { glassQuery, glassList, glassLoading } = glassData
	const { stickyQuery, stickyList, stickyLoading } = stickyData
	const cancelExecutor = useRef()

	const toggleCreateForm = useCallback(() => {
		setOpenCreateForm(!openCreateForm)
	}, [openCreateForm])

	useEffect(() => {
		if (closeDialog) {
			setOpenCreateForm(false)
		}
	}, [closeDialog])



	const onChangeGlass = (e, value) => {
		formik?.setFieldValue('glass', value)
		formik?.setFieldValue('sticky', [])
		setSelectedGlass(value)
		fetchStickies(value)
	}


	
	const fetchStickies = useCallback((selectedGlass) => {
		// setStickyData((prevState) => ({ ...prevState, stickyLoading: true, stickyList: [] }))
        if (!_.isEmpty(selectedGlass)){
		GlassApi.fetchSubTaskStickies(
			selectedGlass?.glass_code,
			stickyCode,
			stickyQuery || '',
			0,
			10,
			(res) => {
				const list = _.get(res, 'data.data.sticky_title', [])
				const filteredList = _.filter(list, (detail) => !_.isEqual(detail?.sticky_code, stickyCode))
				setStickyData((prevState) => ({ ...prevState, stickyLoading: false, stickyList: filteredList || [] }))
			},
			(err) => {
				if (!Axios.isCancel(err)) {
					setStickyData((prevState) => ({ ...prevState, stickyLoading: false, stickyList: [] }))
				}
			}
		)
		}
	}, [stickyQuery])

	const fetchGlasses = useCallback((search) => {
		setGlassData((prevState) => ({ ...prevState, glassLoading: true, glassList: [] }))
		GlassApi.fetchSubTaskGlasses(
			glassQuery || '',
			0,
			10,
			cancelExecutor,
			(res) => {
				const list = _.get(res, 'data.data.glass', [])
				setGlassData((prevState) => ({ ...prevState, glassLoading: false, glassList: list || [] }))
			},
			(err) => {
				if (!Axios.isCancel(err)) {
					setGlassData((prevState) => ({ ...prevState, glassLoading: false, glassList: [] }))
				}
			}
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [glassQuery])

	const onTypeStickyAutoComplete = useCallback(
		_.debounce((e) => {
			setStickyData((prevState) => ({ ...prevState, stickyQuery: e?.target?.value }))
		}, 500),
		[stickyQuery]
	)

	const onTypeGlassAutoComplete = useCallback(
		_.debounce((e) => {
			setGlassData((prevState) => ({ ...prevState, glassQuery: e?.target?.value }))
		}, 500),
		[]
	)

	useEffect(() => {
		fetchStickies(selectedGlass)
	}, [stickyQuery])

	useEffect(() => {
		fetchGlasses()
	}, [glassQuery])

	// const onChangeSticky = (e, value) => {
	// 	formik?.setFieldValue('sticky', value)
	// 	setStickyData((prevState) => ({ ...prevState, stickyQuery: value?.sticky_title }))
	// 	console.log('onChangeSticky', stickyData, value)
	// }

 
	return (
		<div className={classes.formRoot}>
			<Grid container className={classes.mb2} alignItems='flex-start' justifyContent='center' spacing={1}>
				<Grid item lg={5} sm={5} xs={12}>
					<Typography variant='subtitle2' component='p'>
						Link Subtask
					</Typography>
					<div className={classes.glassList}>
					<Autocomplete
						name='glass_code'
						options={glassList}
						size={'small'}
						classes={{
						root: classes.autocompleteRoot,
							inputRoot: classes.autoCompleteInputRoot,
						}}
						onChange={
							// (e, value) => {
							onChangeGlass
						//reset dependent field
						// setFieldValue('assigned_to', null)
						// setFieldValue('report_to', null)
						// setFieldValue('labels', [])
						// setFieldValue('sticky_title_type', null)
						// const glassCode = _.get(value, 'glass_code')
						//call label api
						// fetchLabels(glassCode)
						// if (!_.isEmpty(glassCode)) {
						// 	onTypeAssigneeAutoComplete({}, '', null, glassCode)
						// 	fetchStickyTitle({}, '', null, glassCode, stickyFor)

						// }
						// handleChange({
						// 	target: {
						// 		name: 'glass_code',
						// 		value,
							// 	},
							// })
						// }
					    }
						loading={glassLoading}
						// onBlur={handleBlur}
						value={formik?.values?.glass}
						disabled={!isOwnerOrReporter}
						getOptionLabel={(option) => option.glass_name}
						onInputChange={onTypeGlassAutoComplete}
						getOptionSelected={(option, value) => option.glass_code === value.glass_code}
						renderInput={(params) => (
						<TextField
							{...params}
							name='glass_code'
							variant='outlined'
							label={'Select Board'}
							// error={(errors.glass_code && touched.glass_code) || false}
							// helperText={errors.glass_code && touched.glass_code && errors.glass_code}
							/>
						)}
					/>
					</div>
					<LinkStickyForm
						isOwnerOrReporter={isOwnerOrReporter}
						formik={formik}
						needRefresh={needRefresh}
						glassCode={glassCode}
						stickyCode={stickyCode}
						refreshOptions={refreshOptions}
						glassEmpty={_.isEmpty(formik?.values?.glass)}
						stickyOptions={stickyList}
						stickyLoading={stickyLoading}
						onInputChange={onTypeStickyAutoComplete}
						// onChangeSticky={onChangeSticky}
					/>
				</Grid>
				<Grid item lg={2} sm={2} xs={12}>
					<Typography className={classes.orText} color='textSecondary'>
						OR
					</Typography>
				</Grid>
				<Grid item lg={5} sm={5} xs={12}>
					<Typography variant='subtitle2' component='p'>
						Create Subtask
					</Typography>
					<Button
						className={classes.createBtn}
						disabled={!isOwnerOrReporter}
						color='primary'
						variant='contained'
						disableElevation
						onClick={toggleCreateForm}
					>
						Create
					</Button>
				</Grid>
			</Grid>
			<CreateFormDialog
				open={openCreateForm}
				handleClose={toggleCreateForm}
				glassMembers={glassMembers}
				closeDialog={closeDialog}
				onCreateSubtask={onCreateSubtask}
			/>
		</div>
	)
}

export default SubTaskForm
