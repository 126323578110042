import {
	Card,
	CardHeader,
	makeStyles,
	Typography,
	Avatar,
	CardContent,
	CardActions,
	ButtonBase,
	Icon,
	Grid,
	Button,
	Divider,
	Box,
	Snackbar,
	IconButton,
	FormControlLabel,
	Checkbox,
	TextField,
	Popover,
	MenuItem,
	Container,
	Paper,
} from '@material-ui/core'
import Attachments from 'components/Attachments'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { convertGmtToLocalTime, formTheName, getErrMsg, getImgUrl, stringToHslColor } from 'utils'
import _ from 'lodash'
import { Skeleton } from '@material-ui/lab'
import GlassApi from '../../services/glass/api'
import { useDispatch, useSelector } from 'react-redux'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { hideLoader, showLoader, showErrorMessage } from 'services/loader/actions'
import useLazyPaginate from 'hooks/useLazyPaginate'
import CommentBox from 'components/CommentBox'
import update from 'immutability-helper'
import PropTypes from 'prop-types'
import { AccountCircle } from '@material-ui/icons'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined'
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined'
import ActivityList from './Activity'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(1, 0),
		paddingBottom: theme.spacing(2),
		'& .MuiCardContent-root': {
			padding: theme.spacing(0, 1, 0, 7),
		},
		'& .MuiCardHeader-root': {
			padding: theme.spacing(1),
		},
		'& .MuiCardHeader-action': {
			marginTop: 0,
			marginRight: 0,
		},
		[theme.breakpoints.down('sm')]: {
			'& .MuiCardHeader-root': {
				padding: theme.spacing(1),
				paddingBottom: 0,
			},
			'& .MuiCardContent-root': {
				padding: theme.spacing(0, 1, 1, 1),
			},
			'& .MuiCardHeader-avatar': {
				marginRight: theme.spacing(1),
			},
		},
		'& .wysiwyg-mention': {
			textDecoration: 'none',
			color: '#1236ff',
			backgroundColor: '#f0fbff',
			padding: '1px 2px',
			borderRadius: '2px',
		},
	},
	cardRoot: {
		backgroundColor: '#fcfcfc',
		margin: theme.spacing(1, 0),
	},
	avatar: {
		width: 32,
		height: 32,
		fontSize: 17,
		// marginRight: theme.spacing(1),
	},
	chatCard: {
		paddingTop: '8px',
	},
	mainChat: {
		paddingTop: '8px',
		paddingBottom: '8px',
	},
	subChat: {
		paddingTop: '8px',
		paddingBottom: '8px',
		// paddingLeft: '40px',
	},
	commentAvatar: {
		width: 24,
		height: 24,
		fontSize: 9,
	},
	comment: {
		paddingTop: '8px',
		paddingBottom: '8px',
		flexFlow: 'nowrap',
		display: 'block',
	},
	reply: {
		paddingTop: '8px',
		paddingBottom: '8px',
		flexFlow: 'nowrap',
	},
	subCommentBox: {
		'& .MuiOutlinedInput-multiline': {
			[theme.breakpoints.down('300')]: {
				width: '70px',
			},
			[theme.breakpoints.between('300', '350')]: {
				width: '127px',
			},
			[theme.breakpoints.between('350', '395')]: {
				width: '175px',
			},
			[theme.breakpoints.between('395', '500')]: {
				width: '200px',
			},
			[theme.breakpoints.between('960', '1134')]: {
				width: '200px',
			},
			width: '300px',
			borderRadius: '24px',
			padding: '8.5px 14px',
		},
	},
	commentBox: {
		'& .MuiOutlinedInput-multiline': {
			[theme.breakpoints.down('300')]: {
				width: '70px',
			},
			[theme.breakpoints.between('300', '350')]: {
				width: '130px',
			},
			[theme.breakpoints.between('350', '395')]: {
				width: '175px',
			},
			[theme.breakpoints.between('395', '420')]: {
				width: '220px',
			},
			[theme.breakpoints.between('420', '530')]: {
				width: '258px',
			},
			[theme.breakpoints.between('960', '1185')]: {
				width: '265px',
			},
			width: '370px',
			borderRadius: '24px',
			padding: '8.5px 14px',
		},
	},
	loadMore: {
		paddingTop: '8px',
	},
	subLoadMore: {
		textAlign: 'end',
	},
	chatBox: {
		display: 'flex',
		flexDirection: 'column',
		'& .MuiGrid-item': {
			maxWidth: '-webkit-fill-available',
		},
	},
	chatHeader: {
		'& .MuiCardHeader-title': {
			fontSize: '14px',
		},
		'& .MuiCardHeader-subheader': {
			fontSize: '14px',
		},
	},
	chatText: {
		backgroundColor: '#F2F2F2',
		borderRadius: '0px 14px 14px 14px',
		padding: '5px 5px',
		// display: 'inline-flex',
		// display: 'inline-table',
		fontSize: '14px',
		'& .MuiCardHeader-root': {
			paddingTop: '2px',
			paddingLeft: '2px',
		},
		'& .MuiCardContent-root': {
			paddingLeft: '2px',
		},
	},
	activityReactions: {
		marginLeft: '0px',
		display: 'flex',
		flexDirection: 'row',
		paddingTop: '2px',
	},
	reactions: {
		marginLeft: '7px',
		display: 'flex',
		flexDirection: 'row',
		paddingTop: '2px',
	},
	likeIcon: {
		fontSize: '14px',
		padding: '3px 1px',
		color: '#65b17f',
		transform: 'scaleX(-1)',
	},
	reactionCount: {
		fontSize: '13px',
		color: 'slategray',
	},
	dislikeIcon: {
		fontSize: '14px',
		padding: '3px 1px',
		color: 'rgb(244, 67, 54)',
	},
	dot: {
		color: 'slategray',
	},
	bar: {
		color: 'slategray',
	},
	replyBtn: {
		fontSize: '10px',
		padding: '0px 0px',
		minWidth: '42px',
	},
	// title: {
	// 	marginBottom: theme.spacing(1),
	// },
	description: {
		margin: theme.spacing(1, 0),
		fontSize: '14px',
	},
	labelsWrap: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-end',
		width: '100%',
		gap: '5px',
	},
	labelBtnBase: {
		height: '28px',
		padding: '3px 9px',
		borderRadius: '2px',
		color: theme.palette.common.white,
	},
	pinnedIcon: {
		transform: 'rotate(45deg)',
	},
	attachmentsSection: {
		width: '100%',
	},
	sortButton: {
		textTransform: 'none',
		color: '#92929d',
		lineHeight: 'normal',
	},
	activitiesHead: { justifyContent: 'space-between' },
	loadingSkeleton: {
		display: 'flex',
		padding: '8px',
		margin: '8px 0px',
		'& .MuiSkeleton-circle': {
			marginRight: '10px',
		},
	},
	loadingIcon: {
		color: '#65b17f',
		cursor: 'pointer',
		display: 'inline-flex',
		alignItems: 'center',
		verticalAlign: 'middle',
		marginRight: '11px',
	},
	refreshIcon: {
		fontSize: '21px',
		padding: '3px 1px',
		color: '#65b17f',
		cursor: 'pointer',
		display: 'inline-flex',
		alignItems: 'center',
		marginRight: '11px',
		verticalAlign: 'middle',
	},
	activityForm: {
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: '#65b17f',
		},
	},
	lineSkeleton: {
		width: '100%',
	},
	activityTypeItem: {
		textAlign: '-webkit-right',
	},
	mb2: {
		marginBottom: theme.spacing(2),
	},
}))

const Activities = ({
	title,
	stickyId,
	glassCode,
	is_pinned,
	sessionId,
	isEditEnabled,
	showCommentBox,
	activityTypes,
	glassLabels,
	placeholder,
	socketRef,
	isSocketConnected,
	suggestions,
	history,
	showExcludeSystemMessage = false,
	handleCustomLabel,
	newCustomLabel,
}) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [buttonUI, setButtonUI] = useState({ btnText: 'Newest first', btnIcon: 'arrow_upward' })
	const [activityState, setActivityState] = useState({
		activityList: [],
		loading: true,
		limit: 10,
		offset: 0,
		totalCount: 0,
		sort_by: 'desc',
		showRefreshSnackbar: false,
		excludeSysLog: true,
	})
	const [subReplyState, setSubReplyState] = useState({
		subReplyList: [],
		subReplyLoading: true,
		subReplyLimit: 10,
		subReplyOffset: 0,
		subReply_totalCount: 0,
		subReply_sort_by: 'desc',
		subReply_showRefreshSnackbar: false,
		subReply_activityId: '',
		subReply_replyId: '',
	})
	const [replyActivityId, setReplyActivityId] = useState('')
	const [subReply_reply_Id, setSububReply_reply_Id] = useState('')
	const [refresh, setRefresh] = useState(false)

	const { activityList, limit, offset, totalCount, sort_by, loading, showRefreshSnackbar, excludeSysLog } = activityState
	const {
		subReplyList,
		subReplyLimit,
		subReplyOffset,
		subReply_totalCount,
		subReply_sort_by,
		subReplyLoading,
		subReply_showRefreshSnackbar,
		subReply_activityId,
		subReply_replyId,
	} = subReplyState
	const activityListSynced = useRef(activityList)
	const subReplyListSynced = useRef(subReplyList)

	useEffect(() => {
		activityListSynced.current = activityList
	}, [activityList])

	useEffect(() => {
		subReplyListSynced.current = subReplyList
	}, [subReplyList])

	const activityAddListener = useRef()
	const activityUpdateListener = useRef()
	const token = useSelector((state) => _.get(state, 'session.authToken'))
	const userDetails = useSelector((state) => _.get(state, 'session.user.userDetails', {}))

	useEffect(() => {
		setRefresh(false)
		if (loading) {
			GlassApi.getStickyActivities(
				sort_by,
				offset,
				limit,
				is_pinned ? true : null,
				glassCode,
				stickyId,
				excludeSysLog,
				(res) => {
					let actions = _.get(res, 'data.data.activities', [])
					setActivityState((prevState) => ({
						...prevState,
						loading: false,
						totalCount: _.get(res, 'data.data.total_count'),
						activityList: offset === 0 ? actions : [...prevState.activityList, ...actions],
					}))
				},
				(err) => {
					setActivityState((prevState) => ({ ...prevState, loading: false }))
					dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
				}
			)
		}
	}, [dispatch, glassCode, is_pinned, limit, offset, sort_by, stickyId, loading, excludeSysLog, refresh === true])

	const refreshActivity = () => {
		setActivityState((prevState) => ({
			...prevState,
			loading: true,
			activityList: [],
			limit: 5,
			offset: 0,
			totalCount: 0,
			sort_by: 'desc',
			showRefreshSnackbar: false,
		}))
		setRefresh(true)
		setReplyActivityId('')
		setSububReply_reply_Id('')
	}

	const updateActivityItem = useCallback(
		(activityItemToUpt) => {
			let opToPerform = null
			let subtractTotal = 0
			if (sort_by === 'desc') {
				let activityIndex = _.findIndex(activityListSynced.current, { activity_id: activityItemToUpt?.activity_id })
				if (activityIndex > -1) {
					const updateActivity = { [activityIndex]: { $set: { ...activityItemToUpt } } }
					opToPerform = !is_pinned ? updateActivity : !activityItemToUpt?.is_pinned ? { $splice: [[activityIndex, 1]] } : updateActivity
					subtractTotal = opToPerform === updateActivity ? 0 : -1
				} else {
					opToPerform = is_pinned ? { $unshift: [activityItemToUpt] } : null
					subtractTotal = is_pinned ? 1 : 0
				}
			}
			if (opToPerform === null) {
				setActivityState((prevState) => ({
					...prevState,
					showRefreshSnackbar: true,
				}))
			} else {
				setActivityState((prevState) => ({
					...prevState,
					activityList: update(prevState?.activityList, opToPerform),
					totalCount: prevState.totalCount + subtractTotal,
				}))
			}
		},
		[sort_by, is_pinned]
	)

	const updateSubReplyItem = useCallback((replyItemToUpt) => {
		let replyIndex = _.findIndex(subReplyListSynced.current, { reply_id: replyItemToUpt?.reply_id })
		let opToPerform = null
		if (replyIndex > -1) {
			const updateActivity = { [replyIndex]: { $set: { ...replyItemToUpt } } }
			opToPerform = updateActivity
		} else {
			opToPerform = null
		}
		if (opToPerform === null) {
			setSubReplyState((prevState) => ({
				...prevState,
			}))
		} else {
			setSubReplyState((prevState) => ({
				...prevState,
				subReplyList: update(prevState?.subReplyList, opToPerform),
			}))
		}
	}, [])

	const updateActivity = (activity) => {
		dispatch(showLoader('Loading please wait...'))
		let formData = new FormData()
		formData.append('is_pinned', _.toString(!_.get(activity, 'is_pinned', false)))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			updateActivityItem(res?.data?.data)
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		GlassApi.updateStickyActivities(glassCode, stickyId, activity?.activity_id, sessionId, formData).then(onSuccess, onFailure)
	}

	const updateActivitySubReplyReactions = (activity, replyId, subReplyId, value) => {
		let formData = {
			reactions: value,
		}
		const onSuccess = (res) => {
			updateSubReplyItem(res?.data?.data?.sub_reply?.[0])
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		GlassApi.updateStickyActivitiesSubReplyReactions(glassCode, stickyId, activity?.activity_id, replyId, subReplyId, sessionId, formData).then(
			onSuccess,
			onFailure
		)
	}

	const updateActivityReactions = (activity, value) => {
		let formData = {
			reactions: value,
		}
		const onSuccess = (res) => {
			updateActivityItem(res?.data?.data)
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		GlassApi.updateStickyActivitiesReactions(glassCode, stickyId, activity?.activity_id, sessionId, formData).then(onSuccess, onFailure)
	}

	const handleSort = () => {
		let buttonText = buttonUI.btnText === 'Newest first' ? 'Oldest first' : 'Newest first'
		let buttonIcon = buttonUI.btnIcon === 'arrow_upward' ? 'arrow_downward' : 'arrow_upward'
		setButtonUI({ btnText: buttonText, btnIcon: buttonIcon })
		setActivityState((prevState) => ({
			...prevState,
			sort_by: buttonText === 'Newest first' ? 'desc' : 'asc',
			offset: 0,
			activityList: [],
			showRefreshSnackbar: false,
			loading: true,
		}))
	}
	const [commentLoading, setCommentLoading] = useState(false)

	const onCommentBoxSubmit = (data, formik) => {
		setCommentLoading(true)
		let formData = new FormData()
		formData.append('desc', data?.desc)
		data?.activity_type_id !== null && formData.append('activity_type_id', data?.activity_type_id?.activity_id)
		formData.append('labels', _.join(data?.labels, ','))
		data?.attachments.map((file) => formData.append('attachments', file))

		const onSuccess = (res, formik) => {
			formik.resetForm()
			setCommentLoading(false)
			setActivityState((prevState) => ({
				...prevState,
				activityList: [res?.data?.data, ...prevState.activityList],
			}))
		}
		const onFailure = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
			setCommentLoading(false)
		}
		GlassApi.postStickyActivities(glassCode, stickyId, sessionId, formData).then((e) => onSuccess(e, formik), onFailure)
	}
	const bottomDividerRef = useRef()

	useLazyPaginate(bottomDividerRef, () => {
		if (!loading && totalCount > activityList.length) {
			setActivityState((prevState) => ({ ...prevState, offset: activityList.length, loading: true }))
		}
	})

	useEffect(() => {
		if (isSocketConnected) {
			activityAddListener.current = (resp) => {
				if (sort_by === 'desc') {
					if ((!is_pinned && (excludeSysLog ? !resp?.data?.is_log : true)) || (is_pinned && resp?.data?.is_pinned)) {
						setActivityState((prevState) => ({
							...prevState,
							offset: prevState.offset + 1,
							total_count: prevState.total_count + 1,
							activityList: [resp.data, ...prevState.activityList],
						}))
					}
				} else setActivityState((prevState) => ({ ...prevState, showRefreshSnackbar: true }))
			}
			activityUpdateListener.current = (resp) => {
				updateActivityItem(resp?.data)
			}
			socketRef.current?.on('sticky_activity_create', activityAddListener.current)
			socketRef.current?.on('sticky_activity_update', activityUpdateListener.current)
		}
		return () => {
			if (isSocketConnected && activityAddListener.current && activityUpdateListener.current) {
				socketRef.current?.off('sticky_activity_create', activityAddListener.current)
				// eslint-disable-next-line react-hooks/exhaustive-deps
				socketRef.current?.off('sticky_activity_update', activityUpdateListener.current)
			}
		}
	}, [socketRef, sort_by, updateActivityItem, updateActivity, isSocketConnected, is_pinned, excludeSysLog])

	const closeRefreshSnackBar = () => {
		setActivityState((prevState) => ({ ...prevState, showRefreshSnackbar: false }))
	}

	const onChangeSysExecCb = () => {
		setActivityState((prevState) => ({
			...prevState,
			offset: 0,
			activityList: [],
			showRefreshSnackbar: false,
			loading: true,
			excludeSysLog: !excludeSysLog,
		}))
		setRefresh(true)
		setReplyActivityId('')
		setSububReply_reply_Id('')
	}

	return (
		<div className={classes.root}>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				open={showRefreshSnackbar}
				autoHideDuration={6000}
				onClose={closeRefreshSnackBar}
				message={'Activities Updates Found!'}
				action={
					<React.Fragment>
						<Button color='secondary' size='small' onClick={handleSort}>
							Refresh
						</Button>
						<IconButton aria-label='close' color='inherit' className={classes.close} onClick={closeRefreshSnackBar}>
							<Icon>close</Icon>
						</IconButton>
					</React.Fragment>
				}
			/>

			{showCommentBox && (
				<div className={classes.mb2}>
					<CommentBox
						glassLabels={glassLabels}
						glassCode={glassCode}
						activityTypes={activityTypes}
						sessionId={sessionId}
						placeholder={placeholder}
						onSubmit={onCommentBoxSubmit}
						loading={commentLoading}
						suggestions={suggestions}
						handleCustomLabel={handleCustomLabel}
						newCustomLabel={newCustomLabel}
					/>
				</div>
			)}
			<Grid container direction='row' alignItems='center' className={classes.activitiesHead}>
				<Grid item>
					<Typography variant='subtitle2' component='p'>
						{title ? title : 'Activities'}
					</Typography>
				</Grid>
				<Grid item>
					{loading ? (
						<CircularProgress className={classes.loadingIcon} size={16} thickness={5} />
					) : (
						<Icon className={classes.refreshIcon} onClick={() => refreshActivity()}>
							refresh_icon
						</Icon>
					)}
					{showExcludeSystemMessage && (
						<FormControlLabel
							className={classes.activityForm}
							control={<Checkbox size='small' checked={excludeSysLog} onChange={onChangeSysExecCb} name='SysExCb' />}
							label={
								<Typography color='textSecondary' variant='body2'>
									Exclude System Messages
								</Typography>
							}
						/>
					)}
					<Button className={classes.sortButton} endIcon={<Icon>{buttonUI.btnIcon}</Icon>} onClick={handleSort}>
						{buttonUI.btnText}
					</Button>
				</Grid>
			</Grid>

			{!_.isEmpty(activityList) ? (
				_.map(activityList, (activity) => {
					return (
						<ActivityList
							activity={activity}
							token={token}
							history={history}
							userDetails={userDetails}
							glassLabels={glassLabels}
							glassCode={glassCode}
							activityTypes={activityTypes}
							sessionId={sessionId}
							commentLoading={commentLoading}
							suggestions={suggestions}
							subReplyList={subReplyList}
							subReply_replyId={subReply_replyId}
							updateActivityItem={updateActivityItem}
							setSubReplyState={setSubReplyState}
							// updateReplyItem={updateReplyItem}
							stickyId={stickyId}
							// setReplyState={setReplyState}
							// reply_activityId={reply_activityId}
							// replyList={replyList}
							updateActivityReactions={updateActivityReactions}
							// updateActivityReplyReactions={updateActivityReplyReactions}
							// reply_sort_by={reply_sort_by}
							// replyOffset={replyOffset}
							// replyLimit={replyLimit}
							setReplyActivityId={setReplyActivityId}
							subReply_reply_Id={subReply_reply_Id}
							updateActivitySubReplyReactions={updateActivitySubReplyReactions}
							subReply_sort_by={subReply_sort_by}
							subReplyLimit={subReplyLimit}
							setSububReply_reply_Id={setSububReply_reply_Id}
							subReplyOffset={subReplyOffset}
							replyActivityId={replyActivityId}
							isEditEnabled={isEditEnabled}
							updateActivity={updateActivity}
							isSocketConnected={isSocketConnected}
							socketRef={socketRef}
							handleCustomLabel={handleCustomLabel}
							newCustomLabel={newCustomLabel}
						/>
					)
				})
			) : (
				<>
					{!loading && (
						<Box padding={2}>
							<Typography component='div' align='center' variant='caption'>
								No Activites Found!
							</Typography>
						</Box>
					)}
				</>
			)}

			{loading && (
				<div className={classes.loadingSkeleton}>
					<Skeleton animation='wave' variant='circle' width={39} height={36} />
					<div className={classes.lineSkeleton}>
						<Grid container direction='row'>
							<Grid item xs={6}>
								<Skeleton animation='wave' height={18} width='90%' />
							</Grid>
							<Grid item xs={6} className={classes.activityTypeItem}>
								<Skeleton animation='wave' height={18} width='50%' />
							</Grid>
						</Grid>
						<Skeleton animation='wave' height={18} width='40%' />
						<Skeleton animation='wave' height={45} width='100%' />
					</div>
				</div>
			)}

			<div ref={bottomDividerRef}></div>
		</div>
	)
}

Activities.propTypes = {
	title: PropTypes.string,
	stickyId: PropTypes.string,
	glassCode: PropTypes.string,
	is_pinned: PropTypes.bool,
	sessionId: PropTypes.string,
	isEditEnabled: PropTypes.bool,
	showCommentBox: PropTypes.bool,
	activityTypes: PropTypes.array,
	glassLabels: PropTypes.array,
	placeholder: PropTypes.string,
	socketRef: PropTypes.object,
	isSocketConnected: PropTypes.bool,
	suggestions: PropTypes.array,
	showExcludeSystemMessage: PropTypes.bool,
}

export default Activities
