import React, { useState, useRef, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Checkbox, Chip, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography, IconButton, Icon } from '@material-ui/core'
import SearchBar from 'components/SearchBox'
import _ from 'lodash'
import ProjectApi from '../../../services/projects/api'
import { useEffect } from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import Axios from 'axios'
import update from 'immutability-helper'
import { usePermission } from 'hooks/usePermission'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'

import ProjectPopup from '../../Projects/FormDialog'

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.up('md')]: {
			position: 'absolute',
			height: '100%',
			width: '100%',
		},
	},
	container: {
		height: '100%',
		flexWrap: 'unset',
		flexDirection: 'column',
		[theme.breakpoints.up('md')]: {
			borderRight: `1px solid ${theme.palette.grey[300]}`,
		},
	},
	content: {
		padding: theme.spacing(1, 2, 0, 2),
		flex: 'unset',
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1, 2, 0, 2),
		},
	},
	searchBox: {
		padding: 0,
	},
	listWrapper: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		flex: 1,
		textAlign: 'center',
		overflow: 'auto',
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			background: `${theme.palette.primary.main}`,
		},
	},
	checkbox: {
		padding: '4px 9px',
	},
	listItemIco: {
		minWidth: 30,
	},
	listItemText: {
		color: theme.palette.grey[700],
	},
	dummyListItem: {
		height: 24,
		margin: theme.spacing(1),
	},
	showMoreBtn: {
		margin: '0 auto',
	},
	clearChip: {
		marginTop: theme.spacing(1.5),
		marginLeft: theme.spacing(0.5),
		marginBottom: theme.spacing(0),
	},
	title: {
		padding: theme.spacing(4, 2, 1, 2),
		flex: 'unset',
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2, 2, 0, 2),
		},
	},
	addBtn: {
		textAlign: 'right',
	},
}))

ProjectFilterList.propTypes = {
	controlled: PropTypes.any,
	uncontrolled: PropTypes.any,
	value: PropTypes.arrayOf(
		PropTypes.shape({
			project_name: PropTypes.string,
			project_code: PropTypes.string,
		})
	),
	defaultValue: PropTypes.arrayOf(
		PropTypes.shape({
			project_name: PropTypes.string,
			project_code: PropTypes.string,
		})
	),
	onChange: PropTypes.func.isRequired,
}

function ProjectFilterList({ value, defaultValue, onChange, ...otherProps }) {
	const classes = useStyles()
	const cancelExecutor = useRef()
	const [localVal, setValue] = useState(defaultValue || [])
	const [openPopup, setOpenPopup] = useState(false)
	const projectPerms = usePermission(ROLE_MODULES.PROJECTS)
	const isCreateAllowed = useMemo(() => _.get(projectPerms, PERMISSION_TYPE.CREATE, false), [projectPerms])

	const togglePopup = () => {
		setOpenPopup(!openPopup)
	}

	//check for this component is controlled
	const isControlled = 'controlled' in otherProps || !_.isUndefined(value)
	const curVal = isControlled ? value : localVal

	const [projState, setProjState] = useState({
		loading: true,
		searchTxt: '',
		projList: [],
		limit: 50,
		totalCount: 0,
		offset: 0,
	})

	const { loading, searchTxt, projList, limit, totalCount, offset } = projState

	const fetchProjects = useCallback(() => {
		setProjState((prevState) => ({ ...prevState, loading: true }))
		ProjectApi.fetchAllProjects(
			searchTxt,
			offset,
			limit,
			'project_name',
			cancelExecutor,
			(res) => {
				const list = _.get(res, 'data.data.projects', [])
				setProjState((prevState) => ({
					...prevState,
					loading: false,
					projList: offset === 0 ? list : [...prevState.projList, ...list],
					totalCount: _.get(res, 'data.data.total_count', 0),
				}))
			},
			(err) => {
				if (!Axios.isCancel(err)) setProjState((prevState) => ({ ...prevState, loading: false }))
			}
		)
	}, [limit, offset, searchTxt])

	useEffect(() => {
		fetchProjects()
		return () => {
			setProjState((prevState) => ({
				...prevState,
				loading: false,
			}))
		}
	}, [searchTxt, offset, limit, fetchProjects])

	const delayedQuery = useCallback(
		_.debounce((q) => {
			setProjState((prevState) => ({
				...prevState,
				searchTxt: q,
				offset: 0,
				totalCount: 0,
			}))
		}, 300),
		[]
	)

	const onChngSearchBox = (e) => {
		delayedQuery(e.target.value)
	}

	const onToggleListItem = (projectObj) => {
		const projIdx = _.indexOf(
			curVal,
			_.find(curVal, (project) => _.get(project, 'project_code') === _.get(projectObj, 'project_code'))
		)
		const toggleProjVal = projIdx === -1 ? { $push: [projectObj] } : { $splice: [[projIdx, 1]] }
		const toggledProjList = update(curVal, toggleProjVal)
		onChange && onChange(toggledProjList)
		if (!isControlled) setValue(toggledProjList)
	}

	const onResetBtnClick = () => {
		onChange && onChange([])
		if (!isControlled) setValue([])
	}

	const onClickShowMoreBtn = () => {
		setProjState((prevState) => ({
			...prevState,
			offset: prevState.offset + limit,
		}))
	}

	const finalProjList = useMemo(() => {
		const selectedProjIds = _.map(curVal, (project) => _.get(project, 'project_code'))
		var projListToShow = projList
		if (_.isEmpty(searchTxt)) {
			projListToShow = [...curVal, ..._.filter(projList, (project) => _.indexOf(selectedProjIds, _.get(project, 'project_code')) === -1)]
		}
		return _.orderBy(
			_.map(projListToShow, (project) => ({
				...project,
				isSelected: selectedProjIds.indexOf(_.get(project, 'project_code')) > -1,
			})),
			['isSelected', 'project_name'],
			['desc', 'asc']
		)
	}, [searchTxt, projList, curVal])

	const hideShowMoreBtn = totalCount === finalProjList.length

	return (
		<div className={classes.root}>
			<Grid className={classes.container} container>
				<Grid item xs={12} className={classes.title}>
					<Grid item xs={8}>
						<Typography variant='h6'>Projects</Typography>
					</Grid>
					{/* {isCreateAllowed && (
						<Grid item xs={4} className={classes.addBtn}>
							<IconButton size='small' onClick={togglePopup}>
								<Icon>add</Icon>
							</IconButton>
						</Grid>
					)} */}
				</Grid>
				<Grid item xs={12} className={classes.content}>
					<SearchBar onChange={onChngSearchBox} size='small' placeholder='Search Projects' containerClassName={classes.searchBox} />
					{curVal.length > 0 && (
						<Chip onDelete={onResetBtnClick} size='small' label={`Clear all (${curVal.length})`} className={classes.clearChip} variant='outlined' />
					)}
				</Grid>
				<Grid item xs={12} className={classes.listWrapper}>
					<FilterList value={curVal} classes={classes} loading={loading && offset === 0} onToggleListItem={onToggleListItem} list={finalProjList} />
					{!hideShowMoreBtn && (
						<Button disabled={loading} onClick={onClickShowMoreBtn} className={classes.showMoreBtn} size='small' color='primary'>
							{loading ? 'Loading...' : `Show More (${totalCount - finalProjList.length})`}
						</Button>
					)}
				</Grid>
			</Grid>
			<ProjectPopup open={openPopup} handleClose={togglePopup} refreshData={fetchProjects} action={PERMISSION_TYPE.CREATE} isEditAllowed={false} />
		</div>
	)
}

function FilterList({ classes, value, onToggleListItem, loading, list }) {
	const projIdList = _.map(value, (project) => _.get(project, 'project_code'))
	return (
		<List>
			{loading ? (
				<div>
					<Skeleton variant='rect' className={classes.dummyListItem} />
					<Skeleton variant='rect' className={classes.dummyListItem} />
					<Skeleton variant='rect' className={classes.dummyListItem} />
				</div>
			) : !_.isEmpty(list) ? (
				_.map(list, (project, index) => {
					const projectId = _.get(project, 'project_code', index)
					return (
						<ListItem
							key={projectId}
							role={undefined}
							dense
							button
							onClick={() => {
								onToggleListItem(project)
							}}
						>
							<ListItemIcon className={classes.listItemIco}>
								<Checkbox
									edge='start'
									size='small'
									className={classes.checkbox}
									checked={projIdList.indexOf(projectId) !== -1}
									tabIndex={-1}
									disableRipple
								/>
							</ListItemIcon>
							<ListItemText className={classes.listItemText} primary={_.get(project, 'project_name')} />
						</ListItem>
					)
				})
			) : (
				<div>
					<Typography variant='caption' component='div' align='center'>
						No Projects Found
					</Typography>
				</div>
			)}
		</List>
	)
}

export default ProjectFilterList
