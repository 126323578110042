import { put, call, takeLatest, cancelled, select, takeEvery, all } from 'redux-saga/effects'
import Axios from 'axios'
import { ACTION_TYPES, SAGA_ACTIONS } from '../../../constants'
import glassApi from '../api'
import _ from 'lodash'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { getErrMsg } from 'utils'
import { updateStickList } from '../actions'

/* NEW CODE START */

function* watchFetchGlassDetails() {
	yield takeLatest(SAGA_ACTIONS.FETCH_GLASS_DETAILS, fetchDetails)
}

function* fetchDetails(action) {
	yield put({ type: ACTION_TYPES.FETCH_GLASS_DETAILS_PENDING })
	const cancelSource = Axios.CancelToken.source()
	try {
		let glass = yield call(glassApi.fetchGlassDetails, action.glassCode, cancelSource)
		let layout = _.get(glass, 'data.data.settings.layout')
		const { glassDetails, stickyList } = yield all({
			glassDetails: glass,
			stickyList: layout !== 'list' ? call(glassApi.fetchStickyList, action.glassCode, '', cancelSource) : '',
		})
		yield put({
			type: ACTION_TYPES.FETCH_GLASS_DETAILS_FULFILLED,
			payload: { ..._.get(glassDetails, 'data.data'), stickyList: _.get(stickyList, 'data.data.panes') },
		})
	} catch (err) {
		yield put({
			type: ACTION_TYPES.FETCH_GLASS_DETAILS_REJECTED,
			payload: err,
		})
	} finally {
		if (yield cancelled()) {
			cancelSource.cancel('cancelled')
		}
	}
}

/* STICKY FILTER START */
function* watchSetFilter() {
	yield takeLatest(SAGA_ACTIONS.SAGA_SET_GLASS_FILTER, setFilterWorker)
}
function* setFilterWorker(action) {
	const cancelSource = Axios.CancelToken.source()
	const { glassCode, searchText, sessionId, payload, succCallback, errCallback } = action
	const glassDetails = yield select((state) => _.get(state, 'glass'))
	try {
		const resp = yield call(glassApi.setFilters, glassCode, sessionId, payload, cancelSource)
		const stickyList = yield call(glassApi.fetchStickyList, glassCode, searchText, cancelSource)
		yield put({
			type: ACTION_TYPES.FETCH_GLASS_DETAILS_FULFILLED,
			payload: { ...glassDetails, stickyList: _.get(stickyList, 'data.data.panes') },
		})
		succCallback && (yield call(succCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	} finally {
		if (yield cancelled()) {
			cancelSource.cancel('cancelled')
		}
	}
}

function* watchGetFilter() {
	yield takeLatest(SAGA_ACTIONS.SAGA_GET_GLASS_FILTER, getFilterWorker)
}
function* getFilterWorker(action) {
	const { glassCode, sessionId, successCallback, errCallback } = action
	try {
		const resp = yield call(glassApi.getFilters, glassCode, sessionId)
		yield put({
			type: ACTION_TYPES.GET_GLASS_FILTERS_SUCCESS,
			payload: _.get(resp, 'data.data'),
		})

		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

/* STICKY FILTER END */

/* STICKY LIST REFRESH START */

function* watchRefreshStickyList() {
	yield takeLatest(SAGA_ACTIONS.REFRESH_STICKY_LIST, refreshStickyList)
}
function* refreshStickyList(action) {
	const cancelSource = Axios.CancelToken.source()
	const { glassCode, searchText, errCallback } = action
	try {
		const stickyList = yield call(glassApi.fetchStickyList, glassCode, searchText, cancelSource)
		yield put(updateStickList(_.get(stickyList, 'data.data.panes')))
		// successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	} finally {
		if (yield cancelled()) {
			cancelSource.cancel('cancelled')
		}
	}
}

/* STICKY LIST REFRESH END */

function* watchUpdateGlassMember() {
	yield takeEvery(SAGA_ACTIONS.UPDATE_GLASS_MEMBER, updateGlassMember)
}

function* updateGlassMember(action) {
	//updateRedux - update to redux or not
	const { sessionId, glassCode, userId, role, updateRedux, successCallback, errCallback } = action
	if (updateRedux) yield put({ type: ACTION_TYPES.UPDATE_GLASS_MEMBER_PENDING, payload: { userId } })
	const cancelSource = Axios.CancelToken.source()
	try {
		const resp = yield call(glassApi.updateGlassMember, sessionId, glassCode, userId, role, cancelSource)
		if (updateRedux)
			yield put({
				type: ACTION_TYPES.UPDATE_GLASS_MEMBER_FULFILLED,
				payload: { userId, data: _.get(resp, 'data.data.glass_members[0]') },
			})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		if (updateRedux)
			yield put({
				type: ACTION_TYPES.UPDATE_GLASS_MEMBER_REJECTED,
				payload: { userId },
			})
		errCallback && (yield call(errCallback, err))
		yield put(showSnackbarWithTimeout(getErrMsg(err), 2500))
	} finally {
		if (yield cancelled()) {
			cancelSource.cancel('cancelled')
		}
	}
}

function* watchRemoveGlassMember() {
	yield takeEvery(SAGA_ACTIONS.REMOVE_GLASS_MEMBER, removeGlassMember)
}

function* removeGlassMember(action) {
	//updateRedux - update to redux or not
	const { sessionId, glassCode, userId, updateRedux, successCallback, errCallback } = action
	if (updateRedux) yield put({ type: ACTION_TYPES.UPDATE_GLASS_MEMBER_PENDING, payload: { userId } })
	const cancelSource = Axios.CancelToken.source()
	try {
		const resp = yield call(glassApi.removeGlassMember, sessionId, glassCode, userId, cancelSource)
		if (updateRedux)
			yield put({
				type: ACTION_TYPES.REMOVE_GLASS_MEMBER,
				payload: { userId },
			})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		if (updateRedux)
			yield put({
				type: ACTION_TYPES.UPDATE_GLASS_MEMBER_REJECTED,
				payload: { userId },
			})
		errCallback && (yield call(errCallback, err))
		yield put(showSnackbarWithTimeout(getErrMsg(err), 2500))
	} finally {
		if (yield cancelled()) {
			cancelSource.cancel('cancelled')
		}
	}
}

/* LABELS START */
function* watchGlassAddLabel() {
	yield takeEvery(SAGA_ACTIONS.ADD_GLASS_LABEL, glassAddLabel)
}

function* glassAddLabel(action) {
	const { sessionId, glassCode, labelName, labelColor, successCallback, errCallback } = action
	try {
		const resp = yield call(glassApi.createLabel, glassCode, labelName, labelColor, sessionId)
		yield put({
			type: ACTION_TYPES.ADD_GLASS_LABEL,
			payload: _.get(resp, 'data.data'),
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

function* watchGlassUpdateLabel() {
	yield takeEvery(SAGA_ACTIONS.UPDATE_GLASS_LABEL, glassUpdateLabel)
}

function* glassUpdateLabel(action) {
	const { sessionId, glassCode, labelId, labelName, labelColor, successCallback, errCallback } = action
	try {
		const resp = yield call(glassApi.updateLabel, glassCode, labelId, labelName, labelColor, sessionId)
		yield put({
			type: ACTION_TYPES.UPDATE_GLASS_LABEL,
			payload: { labelId, label: _.get(resp, 'data.data') },
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

function* watchGlassRemoveLabel() {
	yield takeEvery(SAGA_ACTIONS.REMOVE_GLASS_LABEL, glassRemoveLabel)
}

function* glassRemoveLabel(action) {
	const { sessionId, glassCode, labelId, successCallback, errCallback } = action
	try {
		const resp = yield call(glassApi.deleteLabel, glassCode, labelId, sessionId)
		yield put({
			type: ACTION_TYPES.REMOVE_GLASS_LABEL,
			payload: { labelId },
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}
/* LABELS END */

/* STICKY GROUP START */
function* watchGlassAddStickyGroup() {
	yield takeEvery(SAGA_ACTIONS.ADD_GLASS_STICKY_GROUP, glassAddStickyGroup)
}

function* glassAddStickyGroup(action) {
	const { sessionId, glassCode, data, successCallback, errCallback } = action
	try {
		const resp = yield call(glassApi.createStickyGroup, glassCode, data, sessionId)
		yield put({
			type: ACTION_TYPES.ADD_GLASS_STICKY_GROUP,
			payload: _.get(resp, 'data.data'),
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

function* watchGlassUpdateStickyGroup() {
	yield takeEvery(SAGA_ACTIONS.UPDATE_GLASS_STICKY_GROUP, glassUpdateStickyGroup)
}

function* glassUpdateStickyGroup(action) {
	const { sessionId, glassCode, data, successCallback, errCallback } = action
	try {
		const resp = yield call(glassApi.updateStickyGroup, glassCode, data, sessionId)
		yield put({
			type: ACTION_TYPES.UPDATE_GLASS_STICKY_GROUP,
			payload: { data: _.get(resp, 'data.data') },
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

function* watchGlassRemoveStickyGroup() {
	yield takeEvery(SAGA_ACTIONS.REMOVE_GLASS_STICKY_GROUP, glassRemoveStickyGroup)
}

function* glassRemoveStickyGroup(action) {
	const { sessionId, glassCode, stygpId, successCallback, errCallback } = action
	try {
		const resp = yield call(glassApi.deleteStickyGroup, glassCode, stygpId, sessionId)
		yield put({
			type: ACTION_TYPES.REMOVE_GLASS_STICKY_GROUP,
			payload: { stygpId },
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}
/* STICKY GROUP END */

/* PANE START */
function* glassPaneAdd(action) {
	const { sessionId, glassCode, paneName, successCallback, errCallback } = action
	try {
		const resp = yield call(glassApi.createPane, glassCode, paneName, sessionId)
		yield put({
			type: ACTION_TYPES.ADD_GLASS_PANE,
			payload: _.get(resp, 'data.data'),
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

function* watchGlassPaneAdd() {
	yield takeEvery(SAGA_ACTIONS.ADD_GLASS_PANE, glassPaneAdd)
}

function* watchRemoveGlassPane() {
	yield takeEvery(SAGA_ACTIONS.REMOVE_GLASS_PANE, removeGlassPane)
}

function* removeGlassPane(action) {
	const { sessionId, glassCode, paneId, successCallback, errCallback } = action
	try {
		const resp = yield call(glassApi.glassPaneRemove, glassCode, paneId, sessionId)
		yield put({
			type: ACTION_TYPES.REMOVE_GLASS_PANE,
			payload: { paneId },
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

function* watchUpdateGlassPane() {
	yield takeEvery(SAGA_ACTIONS.UPDATE_GLASS_PANE, updateGlassPane)
}

function* updateGlassPane(action) {
	const { sessionId, glassCode, paneId, paneName, panePosition, panePrevPosition, isEdit, successCallback, errCallback } = action
	try {
		const resp = yield call(glassApi.glassPaneUpdate, glassCode, paneId, paneName, panePosition, sessionId)
		yield put({
			type: ACTION_TYPES.UPDATE_GLASS_PANE,
			payload: { paneId, pane: _.get(resp, 'data.data') },
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		yield put({
			type: ACTION_TYPES.UPDATE_GLASS_PANE,
			payload: { paneId, pane: { pane_name: paneName, pane_id: paneId, pane_position: panePrevPosition, is_edit: isEdit } },
		})
		errCallback && (yield call(errCallback, err))
	}
}
/* PANE END */

/* STICKY START */
function* stickyAdd(action) {
	const { sessionId, glassCode, payload, successCallback, errCallback } = action
	try {
		const resp = yield call(glassApi.createSticky, glassCode, payload, sessionId)
		yield put({
			type: ACTION_TYPES.ADD_GLASS_STICKY,
			payload: _.get(resp, 'data.data'),
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

function* watchStickyAdd() {
	yield takeEvery(SAGA_ACTIONS.ADD_STICKY, stickyAdd)
}

function* watchMoveSticky() {
	yield takeEvery(SAGA_ACTIONS.MOVE_STICKY, moveSticky)
}

function* moveSticky(action) {
	const { sessionId, glassCode, sticky, toPaneId, prevStickyCode, successCallback, errCallback } = action
	try {
		const resp = yield call(glassApi.moveSticky, sessionId, glassCode, sticky?.sticky_code, toPaneId, prevStickyCode)
		//update sticky
		yield put({
			type: ACTION_TYPES.UPDATE_GLASS_STICKY,
			payload: _.get(resp, 'data.data'),
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		//revert sticky
		yield put({
			type: ACTION_TYPES.UPDATE_GLASS_STICKY,
			payload: sticky,
		})
		errCallback && (yield call(errCallback, err))
	}
}
function* watchRemoveSticky() {
	yield takeEvery(SAGA_ACTIONS.REMOVE_STICKY, removeSticky)
}
function* removeSticky(action) {
	const { sessionId, glassCode, stickyCode, successCallback, errCallback } = action
	try {
		const resp = yield call(glassApi.deleteSticky, glassCode, stickyCode, sessionId)
		yield put({
			type: ACTION_TYPES.REMOVE_GLASS_STICKY,
			payload: { sticky_code: stickyCode },
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}
function* watchRemoveMultiSticky() {
	yield takeEvery(SAGA_ACTIONS.REMOVE_MULTI_STICKY, removeMultiSticky)
}
function* removeMultiSticky(action) {
	const { sessionId, glassCode, data, successCallback, errCallback } = action
	try {
		const resp = yield call(glassApi.deleteMultiSticky, glassCode, data, sessionId)
		yield put({
			type: ACTION_TYPES.REMOVE_GLASS_MULTI_STICKY,
			payload: { data },
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}
// function* watchArchiveSticky() {
// 	yield takeEvery(SAGA_ACTIONS.ARCHIVE_STICKY, archiveSticky)
// }
// function* archiveSticky(action) {
// 	const { sessionId, glassCode, stickyCode, successCallback, errCallback } = action
// 	try {
// 		const resp = yield call(glassApi.archiveSticky, glassCode, stickyCode, sessionId)
// 		yield put({
// 			type: ACTION_TYPES.REMOVE_GLASS_STICKY,
// 			payload: { sticky_code: stickyCode },
// 		})
// 		successCallback && (yield call(successCallback, resp))
// 	} catch (err) {
// 		errCallback && (yield call(errCallback, err))
// 	}
// }

// function* watchUntagUser() {
// 	yield takeEvery(SAGA_ACTIONS.UNTAG_USER, UntagUser)
// }
// function* UntagUser(action) {
// 	const { sessionId, glassCode, stickyCode, successCallback, errCallback, user_id } = action
// 	const data = { user_id: user_id }
// 	try {
// 		const resp = yield call(glassApi.untagUser, data, glassCode, stickyCode, sessionId)
// 		yield put({
// 			type: ACTION_TYPES.UNTAG_USER,
// 			payload: { user_id: user_id },
// 		})
// 		successCallback && (yield call(successCallback, resp))
// 	} catch (err) {
// 		errCallback && (yield call(errCallback, err))
// 	}
// }
/* STICKY END */

/* ACTIVITY START */
function* watchGlassAddActType() {
	yield takeEvery(SAGA_ACTIONS.ADD_GLASS_ACTIVITY_TYPE, glassAddActType)
}

function* glassAddActType(action) {
	const { sessionId, glassCode, activityName, successCallback, errCallback } = action
	try {
		const resp = yield call(glassApi.createActivityType, glassCode, activityName, sessionId)
		yield put({
			type: ACTION_TYPES.ADD_GLASS_ACTIVITY_TYPE,
			payload: _.get(resp, 'data.data'),
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		console.log('err', err)
		errCallback && (yield call(errCallback, err))
	}
}

function* watchGlassUpdateActType() {
	yield takeEvery(SAGA_ACTIONS.UPDATE_GLASS_ACTIVITY_TYPE, glassUpdateActType)
}

function* glassUpdateActType(action) {
	const { sessionId, glassCode, actTypeId, activityName, successCallback, errCallback } = action
	try {
		const resp = yield call(glassApi.updateActivityType, glassCode, actTypeId, activityName, sessionId)
		yield put({
			type: ACTION_TYPES.UPDATE_GLASS_ACTIVITY_TYPE,
			payload: { actTypeId, activity: _.get(resp, 'data.data') },
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

function* watchGlassRemoveActType() {
	yield takeEvery(SAGA_ACTIONS.REMOVE_GLASS_ACTIVITY_TYPE, glassRemoveActType)
}

function* glassRemoveActType(action) {
	const { sessionId, glassCode, actTypeId, successCallback, errCallback } = action
	try {
		const resp = yield call(glassApi.deleteActivityType, glassCode, actTypeId, sessionId)
		yield put({
			type: ACTION_TYPES.REMOVE_GLASS_ACTIVITY_TYPE,
			payload: { actTypeId },
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}
/* ACTIVITY END */

/* STICKY ESC START */

function* watchGlassUpdateStickyEsc() {
	yield takeEvery(SAGA_ACTIONS.UPDATE_GLASS_STICKY_ESC, glassUpdateStickyEsc)
}

function* glassUpdateStickyEsc(action) {
	const { glassCode, sessionId, stickyEscVal, stickyReminderVal, successCallback, errCallback } = action
	try {
		const resp = yield call(glassApi.updateStickyEsc, glassCode, stickyEscVal, stickyReminderVal, sessionId)
		yield put({
			type: ACTION_TYPES.UPDATE_GLASS_STICKY_ESC,
			payload: {
				sticky_escalation: stickyEscVal,
				sticky_reminder: stickyReminderVal,
			},
		})
		successCallback && (yield call(successCallback, resp))
	} catch (err) {
		errCallback && (yield call(errCallback, err))
	}
}

/* STICKY ESC END */

/* NEW CODE END */

function* fetchConfig(action) {
	yield put({
		type: SAGA_ACTIONS.FETCH_GLASS_ASSIGNEES,
		glassId: action.glassId,
		data: { search_text: '', offset: 0, limit: 100 },
	})
	yield put({
		type: SAGA_ACTIONS.FETCH_GLASS_DETAILS,
		glassId: action.glassId,
	})
	yield put({
		type: ACTION_TYPES.FETCH_GLASS_CONFIG_PENDING,
		payload: { glassId: action?.glassId },
	})
	const cancelSource = Axios.CancelToken.source()
	try {
		const res = yield call(glassApi.fetchGlassConfig, action.glassId, cancelSource)
		yield put({
			type: ACTION_TYPES.FETCH_GLASS_CONFIG_FULFILLED,
			payload: _.get(res, 'data.data'),
		})
	} catch (err) {
		yield put({ type: ACTION_TYPES.FETCH_GLASS_CONFIG_REJECTED, payload: err })
	} finally {
		if (yield cancelled()) {
			cancelSource.cancel('cancelled')
		}
	}
}

function* fetchLaneDetails(action) {
	const cancelSource = Axios.CancelToken.source()
	try {
		const res = yield call(glassApi.fetchGlassConfig, action.glassId, cancelSource)
		yield put({
			type: ACTION_TYPES.UPDATE_LANE_POSITION,
			payload: _.get(res, 'data.data.lane_details', []),
		})
	} catch (err) {
		if (yield cancelled()) {
			cancelSource.cancel('cancelled')
		}
	}
}

function* fetchAssignees(action) {
	yield put({ type: ACTION_TYPES.FETCH_GLASS_ASSIGNEES_PENDING })
	const cancelSource = Axios.CancelToken.source()
	try {
		const res = yield call(glassApi.fetchAssignees, action.glassId, action.data, cancelSource)
		yield put({
			type: ACTION_TYPES.FETCH_GLASS_ASSIGNEES_FULFILLED,
			payload: res,
		})
	} catch (err) {
		yield put({
			type: ACTION_TYPES.FETCH_GLASS_ASSIGNEES_REJECTED,
			payload: err,
		})
	} finally {
		if (yield cancelled()) {
			cancelSource.cancel('cancelled')
		}
	}
}

function* changeGlassViewLayout(action) {
	const state = yield select()
	const activeGlassId = _.get(state, 'glass.activeGlassDetails.glass_id', '')
	const tempGlassLayout = _.get(state, 'glass.layoutConfig', {})
	yield put({
		type: ACTION_TYPES.UPDATE_GLASS_LAYOUT_CONFIG_FULFILLED,
		payload: action.data,
	})
	const cancelSource = Axios.CancelToken.source()
	try {
		const res = yield call(glassApi.updateGlassLayoutConfig, activeGlassId, { layout: { ...action.data } }, cancelSource)
	} catch (err) {
		yield put({
			type: ACTION_TYPES.UPDATE_GLASS_LAYOUT_CONFIG_FULFILLED,
			payload: tempGlassLayout,
		})
		yield put(showSnackbarWithTimeout('Error updating Layout!', 2500))
	} finally {
		if (yield cancelled()) {
			cancelSource.cancel('cancelled')
		}
	}
}

function* watchFetchGlassConfig() {
	yield takeLatest(SAGA_ACTIONS.FETCH_GLASS_CONFIG, fetchConfig)
}

function* watchFetchGlassAssignees() {
	yield takeLatest(SAGA_ACTIONS.FETCH_GLASS_ASSIGNEES, fetchAssignees)
}

function* watchGlassLayoutChange() {
	yield takeLatest(SAGA_ACTIONS.UPDATE_GLASS_LAYOUT_CONFIG, changeGlassViewLayout)
}

function* watchGlassLaneUpdateFetching() {
	yield takeLatest(SAGA_ACTIONS.FETCH_GLASS_LANES, fetchLaneDetails)
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
	watchFetchGlassDetails(),
	watchUpdateGlassMember(),
	watchRemoveGlassMember(),
	watchGlassAddStickyGroup(),
	watchGlassUpdateStickyGroup(),
	watchGlassRemoveStickyGroup(),
	watchGlassAddLabel(),
	watchGlassUpdateLabel(),
	watchGlassRemoveLabel(),
	watchGlassAddActType(),
	watchGlassUpdateActType(),
	watchGlassRemoveActType(),
	watchGlassUpdateStickyEsc(),
	watchGlassPaneAdd(),
	watchRemoveGlassPane(),
	watchUpdateGlassPane(),
	watchStickyAdd(),
	watchMoveSticky(),
	watchRemoveSticky(),
	watchRemoveMultiSticky(),
	// watchArchiveSticky(),
	watchSetFilter(),
	watchGetFilter(),
	watchRefreshStickyList(),
	// watchUntagUser(),
]
