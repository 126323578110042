import { makeStyles } from '@material-ui/core/styles'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { Button, Icon, Zoom, Fab, Typography } from '@material-ui/core'
import PageHeaderSort from 'components/PageHeaderSort'
import _ from 'lodash'
import { PAGE_MODES } from '../../../constants'
import React, { useMemo } from 'react'
import AgGridCustom from 'components/AgGridCustom'
import CustomPagination from '../../../components/CustomPagination'
import NewCardList from '../../../components/NewCardList'
import { withCellRenderState, ActionCellRenderer, CodeCellRenderer, NameCellRenderer, DateCellRenderer, TeamNameCellRenderer } from './customCells'
import LoadingIndicator from 'components/LoadingIndicator'
import TableCell from '../../../components/ProductTable/CustomTableCell'
import TableRow from '../../../components/ProductTable/CustomTableRow'
import { NoRecordsIllustration } from '../../../illustrators'

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2),
		// flex: 1,
	},
	nameCellContent: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'flex-start',
		'& .m-details': {
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
			'& .subText': {
				fontSize: '11px',
				color: '#999',
			},
		},
		'& .details-span': {
			lineHeight: 'unset',
			'& span': {
				lineHeight: 'inherit',
			},
		},
	},
	codeCellContent: {
		height: '100%',
		justifyContent: 'flex-start',
	},
	teamNameCellContent: {
		height: '100%',
		justifyContent: 'flex-start',
	},
	dateCellContent: {
		height: '100%',
		justifyContent: 'flex-start',
	},
	actionCellContent: {
		// float: 'right',
		'& .actionBtn': {
			marginRight: theme.spacing(1),
		},
	},
	fab: {
		position: 'fixed',
		zIndex: 100,
		bottom: '100px',
		right: '24px',
		display: 'none',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	NoRecordsFound: {
        display: 'flex',
        justifyContent: 'center',
    },
	infoContainer: {
		textAlign: 'center',
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		'& h6': {
			paddingTop: theme.spacing(2),
		},
	},
	noRecordsIllustration: {
		width: '100px',
		height: '100px',
	},
}))
const ProjectPageFilter = (props) => {
	const { isEmbedded, changeEmbededState, onChangeSearch, title, titleIcon, history, baseUrl, hideAddBtn, width, sortingDetails, sortableFields, setSortingDetails, sortFilter } = props

	const onClickAddBtn = (event) => {
		// if (!isEmbedded)
		history.push(`${baseUrl}/${PAGE_MODES.CREATE}`)
		// else changeEmbededState({ id: null, action: PAGE_MODES.CREATE })
	}

	const classes = useStyles()
	return (
		<>
			<PageHeaderSort
				title={title}
				titleIcon={titleIcon}
				actions={
					_.isEqual(hideAddBtn, false) ? (
						<Button
							onClick={onClickAddBtn}
							color='primary'
							variant='contained'
							size='medium'
							startIcon={<i className='material-icons'> add </i>}
							disableElevation
						>
							Add New
						</Button>
					) : null
				}
				onChangeSearch={onChangeSearch}
				sortable_fields={sortableFields}
				sortingDetails={sortingDetails}
				setSortingDetails={setSortingDetails}
				sortFilter={sortFilter}
			></PageHeaderSort>
			{/* {!hideAddBtn && !isWidthUp('sm', width) && (
				<Fab
					className={classes.fab}
					color='primary'
					size='small'
					onClick={onClickAddBtn}
				>
					<Icon>add</Icon>
				</Fab>
			)} */}
		</>
	)
}
const ProjectTableList = ({
	width,
	data,
	baseUrl,
	title,
	titleIcon,
	onChangeSearch,
	history,
	hideAddBtn,
	hideDeleteBtn,
	hideEditBtn,
	hideModelBtn,
	onClickEditMenu,
	onClickDeleteMenu,
	onClickEdit,
	onClickModel,
	showLoading,
	projectPerms,
	isEmbedded,
	changeEmbededState,
	changePage,
	cardList,
	page,
	totalCount,
	CardContent,
	paginationFooterDetails,
	limit,
	smallScreen,
	goToPage,
	setPageLimit,
	pageType,
	sortingDetails,
	sortableFields,
	setSortingDetails,
	handleSliderOpen,
	onClickGlassX,
	onClickDashX,
	disableDashxBtn,
	sortFilter,
}) => {
	const classes = useStyles()
	const gridHeaders = useMemo(() => {
		const { nameCellContent, actionCellContent, dateCellContent, codeCellContent, teamNameCellRenderer } = classes
		return [
			{
				headerName: 'Project Code',
				field: 'project_code',
				cellRenderer: 'codeCellRenderer',
				maxWidth: '170',
				cellRendererParams: {
					containerClass: codeCellContent,
				},
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Project Name',
				field: 'project_name',
				cellRenderer: 'nameCellRenderer',
				// minWidth: _.isEqual('xs', width) && 120,
				flex: _.isEqual('xs', width) ? 3 : 2,
				cellRendererParams: {
					containerClass: nameCellContent,
					isRenderMobView: _.isEqual('xs', width),
				},
			},
			{
				headerName: 'Team unit Name',
				field: 'tu_name',
				cellRenderer: 'teamNameCellRenderer',
				// minWidth: _.isEqual('xs', width) && 120,
				// flex: _.isEqual('xs', width) ? 3 : 2,
				cellRendererParams: {
					containerClass: teamNameCellRenderer,
				},
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Created Date & Time',
				field: 'created_date',
				flex: 2,
				cellRenderer: 'dateCellRenderer',
				cellRendererParams: {
					containerClass: dateCellContent,
				},
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Actions',
				field: ' ',
				// maxWidth: 115,
				flex: _.isEqual('xs', width) ? 2 : 1,
				cellRenderer: 'actionCellRenderer',
				cellRendererParams: {
					containerClass: actionCellContent,
					onClickEditMenu,
					onClickDeleteMenu,
					projectPerms,
				},
			},
		].filter(Boolean)
	}, [width, classes, onClickEditMenu, onClickDeleteMenu, projectPerms])

	const NoRecordsFound = () => {
		return (
			<TableRow>
				<TableCell>
					<div className={classes.infoContainer}>
						<NoRecordsIllustration className={classes.noRecordsIllustration} />
						<Typography variant='body1'>No Records Found</Typography>
					</div>
				</TableCell>
			</TableRow>
		)
	}

	const onRowClicked = (event, record) => {
		// console.log('record', event.data.project_code)
		const isSuppressed = event.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection
		if (!isSuppressed) {
			if (!isEmbedded) history.push(`${baseUrl}/${PAGE_MODES.VIEW}/${event.data.project_code}`)
			else changeEmbededState({ id: event.data.project_code, action: PAGE_MODES.VIEW })
		}
	}

	return (
		<div className={classes.root}>
			<ProjectPageFilter
				titleIcon={titleIcon}
				title={title}
				onChangeSearch={onChangeSearch}
				history={history}
				baseUrl={baseUrl}
				hideAddBtn={hideAddBtn}
				width={width}
				isEmbedded={isEmbedded}
				changeEmbededState={changeEmbededState}
				sortingDetails={sortingDetails}
				sortableFields={sortableFields}
				setSortingDetails={setSortingDetails}
				sortFilter={sortFilter}
			></ProjectPageFilter>
			{/* <AgGridCustom
				columnDefs={gridHeaders}
				rowData={data}
				loading={showLoading}
				frameworkComponents={{
					codeCellRenderer: withCellRenderState(CodeCellRenderer),
					nameCellRenderer: withCellRenderState(NameCellRenderer),
					teamNameCellRenderer: withCellRenderState(TeamNameCellRenderer),
					dateCellRenderer: withCellRenderState(DateCellRenderer),
					actionCellRenderer: withCellRenderState(ActionCellRenderer),
				}}
				// onRowClicked={onRowClicked}
				disableClickSelectionRenderers={['actionCellRenderer']}
				rowHeight={_.isEqual(width, 'xs') ? 60 : 50}
				noDataTxt='No Projects found'
			/> */}
			{(_.isEmpty(data) && !showLoading) ?
				<div className={classes.NoRecordsFound}><NoRecordsFound/></div> :
				showLoading ?
			    <LoadingIndicator /> :
				<NewCardList
					subtitle={'Active'}
					page={page}
					totalCount={totalCount}
					// changePage={changePage}
					pagination={true}
					data={data}
					mobileHeaderKeys={CardContent}
					pageType={pageType}
					handleSliderOpen={handleSliderOpen}
					onClickGlassX={onClickGlassX}
					onClickDashX={onClickDashX}
					disableDashxBtn={disableDashxBtn}
					onClickDeleteMenu={onClickDeleteMenu}
					onClickEdit={onClickEdit}
					hideDeleteBtn={hideDeleteBtn}
					hideEditBtn={hideEditBtn}
					onClickModel={onClickModel}
					hideModelBtn={hideModelBtn}
				/>
			}
			<CustomPagination
				data={data}
				paginationFooterDetails={paginationFooterDetails}
				page={page}
				limit={limit}
				smallScreen={smallScreen}
				goToPage={goToPage}
				setPageLimit={setPageLimit}
			/>
		</div>
	)
}
export default withWidth()(ProjectTableList)
