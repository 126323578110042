import { Button, Divider, Grid, makeStyles } from '@material-ui/core'
import _ from 'lodash'
import React from 'react'

const useStyles = makeStyles((theme) => ({
	footerRoot: {
		// borderTop: `1px solid ${theme.palette.grey[300]}`,
		position: 'sticky',
		bottom: 0,
		zIndex: 999,
		background: theme.palette.common.white,
	},
	centerAlign: {
		textAlign: 'center',
		borderRight: `1px solid ${theme.palette.divider}`,
		'& button, & a': {
			padding: theme.spacing(1.5),
			width: '100%',
		},
	},
}))

const Footer = ({ onclickDashboard, data, isMobileView }) => {
	const classes = useStyles()

	return (
		<Grid container className={classes.footerRoot}>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			{data?.dashboard_id && (
				<Grid item xs={12} sm={6} className={classes.centerAlign}>
					<Button size='small' color='primary' onClick={() => onclickDashboard(data)}>
						View Dashboard
					</Button>
				</Grid>
			)}
			{!_.isEmpty(data?.url) && (
				<Grid item xs={12} sm={6} className={classes.centerAlign}>
					<Button target='_blank' href={data?.url} size='small' color='primary'>
						View Document / Link
					</Button>
				</Grid>
			)}
			<Grid item xs={12}>
				<Divider />
			</Grid>
		</Grid>
	)
}

export default Footer
