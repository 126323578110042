import { makeStyles, Divider, Grid, Button } from '@material-ui/core'
import InputLabels from 'components/InputLabels'
import Attachments from 'components/Attachments'
import React, { useState, useMemo } from 'react'
import MoreInfo from './MoreInfo'
import StickyDetailView from './StickyDetailView'
import ResultsList from './ResultList'
import Activities from 'components/Activities'
import PropTypes from 'prop-types'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import { usePermission } from 'hooks/usePermission'
import _ from 'lodash'
import ProductList from './ProductList'
import PeoplexList from './PeoplexList'
import ModelxList from './ModelxList'
import RepeatTask from './RepeatTask'
import ModelDialog from './ModelDialog'

const useStyles = makeStyles((theme) => ({
	varticalSpace: {
		padding: theme.spacing(0, '20px', 3, '20px'),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1, 2),
		},
	},
	mt3: {
		padding: theme.spacing(3, 0, 0, 0),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2, 2, 0, 2),
		},
	},
	bold: {
		fontWeight: '500',
	},
	footerRoot: {
		position: 'sticky',
		bottom: 0,
		zIndex: 999,
		background: theme.palette.common.white,
	},
	centerAlign: {
		textAlign: 'center',
		'& button, & a': {
			padding: theme.spacing(1.5),
			width: '100%',
		},
	},
}))

const TaskInfo = ({
	glassMembers,
	glassStickyGroups,
	glassCode,
	glassLabels,
	sessionId,
	formik,
	stickyDetails,
	isEditEnabled,
	isGuest,
	attachmentEditEnabled,
	loading,
	stickyId,
	onAddAttachments,
	attachmentLoading,
	onDeleteAttachment,
	socketRef,
	isSocketConnected,
	membersSuggestions,
	isOwnerOrReporter,
	isMember,
	history,
	updateStickyDetails,
}) => {
	const classes = useStyles()

	const [openModel, setOpenModel] = useState(false)
	const [record, setRecord] = useState(null)

	const modelPerms = usePermission(ROLE_MODULES.MODELX)
	const isViewAllowed = useMemo(() => _.get(modelPerms, PERMISSION_TYPE.VIEW, false), [modelPerms])
	const isEditAllowed = useMemo(() => _.get(modelPerms, PERMISSION_TYPE.EDIT, false), [modelPerms])

	const openModelScreen = (record) => {
		setOpenModel(true)
		setRecord(record)
	}

	const closeModelScreen = () => {
		setOpenModel(false)
	}

	return (
		<div>
			<StickyDetailView
				glassMembers={glassMembers}
				membersSuggestions={membersSuggestions}
				formik={formik}
				disabled={!isEditEnabled}
				estimationEnabled={attachmentEditEnabled}
				glassCode={glassCode}
				stickyCode={stickyId}
				stickyDetails={stickyDetails}
				glassStickyGroups={glassStickyGroups}
			/>
			{_.isEmpty(stickyDetails?.parent_sticky_code) && (
				<div className={classes.varticalSpace}>
					<RepeatTask
						glassCode={glassCode}
						stickyCode={stickyId}
						sessionId={sessionId}
						stickyDetails={stickyDetails}
						updateStickyDetails={updateStickyDetails}
						disabled={!isEditEnabled}
					/>
				</div>
			)}
			<div className={classes.varticalSpace}>
				<InputLabels
					disabled={!isEditEnabled}
					name='labels'
					hideAddLabel={isMember}
					hideEditLabel={isMember}
					onChange={formik.handleChange}
					value={formik?.values?.labels}
					labels={glassLabels}
					glassCode={glassCode}
					sessionId={sessionId}
					isDescription={true}
				/>
			</div>
			{_.isEqual(stickyDetails?.sticky_for, 'discoverx') && (
				<div className={classes.spacing}>
					<ResultsList glassId={glassCode} cardId={stickyId} history={history} />
				</div>
			)}
			{_.isEqual(stickyDetails?.sticky_for, 'rudder') && (
				<div className={classes.spacing}>
					<ResultsList glassId={glassCode} cardId={stickyId} history={history} />
				</div>
			)}
			{_.isEqual(stickyDetails?.sticky_for, 'products') && (
				<div className={classes.spacing}>
					<ProductList glassId={glassCode} cardId={stickyId} history={history} />
				</div>
			)}
			{_.isEqual(stickyDetails?.sticky_for, 'people') && (
				<div className={classes.spacing}>
					<PeoplexList glassId={glassCode} cardId={stickyId} history={history} />
				</div>
			)}
			{_.isEqual(stickyDetails?.sticky_for, 'model') && (
				<div className={classes.spacing}>
					<ModelxList glassId={glassCode} cardId={stickyId} history={history} />
				</div>
			)}
			<div className={classes.varticalSpace}>
				<Attachments
					name='attachments'
					attachmentList={formik?.values?.attachments}
					enableAddBtn={attachmentEditEnabled}
					disableDelete={!attachmentEditEnabled}
					showDivider={false}
					onAddAttachments={onAddAttachments}
					loading={attachmentLoading}
					onDeleteAttachment={onDeleteAttachment}
					enableUrlBtn={true}
				/>
			</div>
			<div className={classes.varticalSpace}>
				<MoreInfo stickyDetails={stickyDetails} loading={loading} />
			</div>
			<div className={classes.varticalSpace}>
				<Activities
					title={'Pinned Activities'}
					isEditEnabled={isOwnerOrReporter}
					sessionId={sessionId}
					is_pinned={true}
					glassCode={glassCode}
					stickyId={stickyId}
					socketRef={socketRef}
					isSocketConnected={isSocketConnected}
				/>
			</div>
			{(isViewAllowed || isEditAllowed) && _.isEqual(stickyDetails?.is_model, true) && (
				<Grid container className={classes.footerRoot}>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12} sm={12} className={classes.centerAlign}>
						<Button size='small' color='primary' onClick={() => openModelScreen(stickyDetails)}>
							Models
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
				</Grid>
			)}
			<ModelDialog
				dialogTitle=''
				open={openModel}
				setOpenModel={setOpenModel}
				onClose={closeModelScreen}
				stickyData={record}
				isPickFrom='Tasks'
			></ModelDialog>
		</div>
	)
}

TaskInfo.propTypes = {
	glassCode: PropTypes.string,
	glassMembers: PropTypes.array,
	glassLabels: PropTypes.array,
	sessionId: PropTypes.string,
	formik: PropTypes.object,
	stickyDetails: PropTypes.object,
	isEditEnabled: PropTypes.bool,
	attachmentEditEnabled: PropTypes.bool,
	stickyId: PropTypes.string,
	loading: PropTypes.bool,
	onAddAttachments: PropTypes.func,
	attachmentLoading: PropTypes.bool,
	onDeleteAttachment: PropTypes.func,
	socketRef: PropTypes.object,
	isSocketConnected: PropTypes.bool,
	membersSuggestions: PropTypes.array,
}

export default TaskInfo
